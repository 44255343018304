import React, { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Content } from "@prismicio/client"
import AccountPageBase from "components/pages/account/AccountPageBase"
import { shouldShowDisclaimerOnly, fetchComponentsFromAltPrismic } from "utils"
import { useMobileOrTabletMode, useSignupData } from "hooks"
import { DisclaimerContextProvider } from "shared"
import Header from "./Header"
import { Container } from "./Layout.styled"
import AccountHeader from "./account/header/AccountHeader"
import Disclaimer from "./Disclaimer"
import useRiskWarning from "hooks/useRiskWarning"

type Props = {
  children: JSX.Element
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  riskWarnings: any
  translations: Content.TranslationDocument[]
  category: string
  subCategory?: string
}

const Layout = ({ children, riskWarnings, translations, category, subCategory }: Props) => {
  const { jurisdiction } = useSignupData()
  const { riskWarning } = useRiskWarning({ riskWarnings, translations })

  const [isSignupMaintMode, setSignupMaintenanceMode] = useState<boolean>(false)
  const { push } = useRouter()
  const isMobileOrTablet = useMobileOrTabletMode()

  const fetchTN2MaintenancePage = async () => {
    const res = await fetchComponentsFromAltPrismic(jurisdiction)
    if (res) {
      setSignupMaintenanceMode(res.signup_maintenanceMode)
    }
  }
  const isAccountPage = category === "account"
  const isNotWidgetPage = category !== "widget"
  const isPaymentPage = subCategory === "payments"

  useEffect(() => {
    if (jurisdiction) {
      fetchTN2MaintenancePage()
    }
  }, [jurisdiction])

  useEffect(() => {
    if (isSignupMaintMode) {
      push("/maintenance")
    } else {
      // TO-DO add account maintenance mode here
    }
  }, [isSignupMaintMode])

  const displayContent = () => {
    if (isAccountPage) {
      return <AccountPageBase>{children}</AccountPageBase>
    }

    return children
  }

  return (
    <DisclaimerContextProvider savePreference={riskWarning?.data?.save_preference}>
      <Container>
        {isNotWidgetPage &&
          // Add the header to all components except the ones in disabledHeaderForComponents
          (isAccountPage ? (
            <AccountHeader />
          ) : (
            <Header
              disclaimer={riskWarning}
              showDisclaimerOnly={!isMobileOrTablet && shouldShowDisclaimerOnly(category, subCategory)}
            />
          ))}
        {displayContent()}
        {isPaymentPage && (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          <Disclaimer {...(riskWarning?.data as any)} isOnAccountPage={true} />
        )}
      </Container>
    </DisclaimerContextProvider>
  )
}

export default Layout
