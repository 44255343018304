import styled from "@emotion/styled"
import { TextField as MUITextfield } from "@mui/material"
import { lightGreyColor } from "styles"
import { WidgetProps } from "components/pages/auth-widget/auth-widget"

export const Container = styled(MUITextfield)<{ widgetprops?: WidgetProps }>`
  fieldset {
    border-radius: 13px;
    border-color: ${lightGreyColor};
    background-color: ${(props) => props.widgetprops && "rgba(199, 198, 207, 0.5)"};
    background-color: ${(props) => props.widgetprops && "rgb(97 97 97 / 50%)"};
    color: ${(props) => props.widgetprops && "white"};
  }
  label {
    color: ${(props) => props.widgetprops && "#92a2b3"};
  }
  .MuiOutlinedInput-input {
    color: ${(props) => props.widgetprops && "#92a2b3"};
  }
`

export const textfieldStyle = (sx: object) => ({
  width: "100%",
  ...sx
})
