import styled from "@emotion/styled"
import Carousel from "react-multi-carousel"
import { darkBlueColor, whiteColor } from "styles"

export const ReactCarousel = styled(Carousel)<{ display: boolean }>`
  ul {
    display: ${(props) => (props.display ? "flex" : "none")};
  }
`

export const muiButtonGroupBoxStyle = {
  width: "800px",
  position: "absolute",
  top: "50%",
  left: "calc(50% - 400px)"
}

export const muiArrowBoxStyle = (darkButtons: boolean, disabled: boolean) => ({
  width: "50.17px",
  height: "50.17px",
  opacity: "0.2",
  border: `2px solid ${darkButtons ? darkBlueColor : whiteColor}`,
  borderRadius: "50%",
  cursor: "pointer",
  "&:hover": {
    opacity: "1"
  },
  pointerEvents: `${disabled ? "none" : "initial"}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
})

export const muiArrowStyle = (darkButtons: boolean) => ({
  color: darkButtons ? darkBlueColor : whiteColor
})
