import styled from "@emotion/styled"

export const searchfieldStyle = {
  width: "85px",
  minWidth: "85px"
}

export const PhonePrefix = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: 70px;
`
