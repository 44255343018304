import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getRtkQueryTagTypes } from "redux/utils"
import { CreateUserType } from "redux/features/signupapi/types"
import { AccountDataType } from "utils"

const LOCAL_STORAGE_ACCESS_TOKEN = "access_token"

export const oldAccountApi = createApi({
  reducerPath: "oldaccountapi",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    newUserSignup: builder.mutation<CreateUserType, { body: AccountDataType }>({
      query: ({ body }) => ({
        url: "/accounts/create",
        method: "POST",
        body
      })
    })
  })
})

export const { useNewUserSignupMutation } = oldAccountApi
