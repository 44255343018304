import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { getRtkQueryTagTypes } from "redux/utils"

const LOCAL_STORAGE_ACCESS_TOKEN = "access_token"

export const sumSubAccountsApi = createApi({
  reducerPath: "sumsubaccountsapi",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    sumSubTokenRequest: builder.mutation({
      query: (requestedData) => ({
        url: "/sumsub_token",
        method: "POST",
        body: requestedData
      })
    })
  })
})

export const sumSubApi = createApi({
  reducerPath: "sumsub",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_REACT_APP_API_URL
  }),
  endpoints: (builder) => ({
    sumSub: builder.mutation({
      query: (requestedData) => ({
        url: "/sumsub",
        method: "POST",
        body: requestedData
      })
    })
  })
})

export const { useSumSubTokenRequestMutation } = sumSubAccountsApi
export const { useSumSubMutation } = sumSubApi
