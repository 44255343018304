import styled from "@emotion/styled"
import { mediumGreyColor } from "styles"

export const titleStyle = {
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "22px",
  borderBottom: `1px solid ${mediumGreyColor}`,
  width: "100%",
  paddingBottom: "40px",
  marginBottom: "15px",
  textAlign: "center"
}

export const FieldsWrapper = styled.div`
  width: 100%;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`

export const buttonStyle = {
  minHeight: "46px",
  height: "46px",
  fontWeight: 400
}

export const mobileDrawerStyle = {
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px"
}

export const MobileDrawerContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
