import styled from "@emotion/styled"
import { lightGreyColor } from "styles"

export const Container = styled.div<{ deactivated: boolean }>`
  padding: 25px 0;
  border-bottom: 1px solid ${lightGreyColor};
  width: 100%;
  opacity: ${(props) => (props.deactivated ? "0.4" : "1")};
  pointer-events: ${(props) => (props.deactivated ? "none" : "auto")};
  transition: opacity 0.2s;
`

export const titleStyle = {
  fontSize: "14px",
  fontWeight: "500 !important",
  lineHeight: "10px"
}

export const textStyle = {
  fontSize: "13px",
  lineHeight: "10px",
  color: "#7c8286 !important"
}

export const textButtonStyle = {
  padding: "0 2px",
  fontSize: "13px",
  fontWeight: 500
}

export const fieldStyle = {
  flex: "calc(50% - 15px)",
  maxWidth: "calc(50% - 15px)"
}

export const searchfieldStyle = {
  maxWidth: "100%",
  minWidth: "350px"
}

export const inputStyle = {
  fontSize: "13px"
}

export const saveButtonStyle = {
  minHeight: "38px",
  height: "38px",
  fontWeight: 400
}
