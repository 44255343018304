import React, { useState, useRef, useCallback } from "react"
import { ActionButton, ActionCard, Loading, Text } from "components"
import { CLOUDTRADE, DEMO, LIVE, LOCAL_STORAGE_SELECTED_MT4_ACCOUNT, MT4, TradingAccountType, goToPage } from "utils"
import { useAccountData, useMobileOrTabletMode } from "hooks"
import { Row, theme } from "styles"
import AccountBalance from "./AccountBalance"
import Equity from "./Equity"
import AccountCardMenu from "./AccountCardMenu"
import AccountLaunchButton from "./AccountLaunchButton"
import {
  AccountDataWrapper,
  CardContent,
  LeftColumn,
  RightColumn,
  actionCardStyle,
  bottomButtonStyle
} from "./AccountCard.styled"
import AccountCardIcon, { AccountCardDemoChip } from "./AccountCardIcon"
import { useMediaQuery } from "@mui/material"
import { useFeatureFlagEnabled } from "utils/posthog"
import { FeatureFlag } from "utils"

type Props = {
  account: TradingAccountType
  isSelected?: boolean
  handleClick?: () => void
  displayMenuButton?: boolean
  displayBottomButtons?: boolean
  displayHoverLaunchButton?: boolean
  sx?: object
  isOverviewPage?: boolean
  id?: string
}

const AccountCard = ({
  isSelected,
  account,
  handleClick,
  displayMenuButton,
  displayBottomButtons,
  displayHoverLaunchButton,
  sx,
  isOverviewPage,
  id
}: Props) => {
  const { updateSelectedAccount, getCloudtradeAccountData, cloudtradeAccountDataLoading, displayErrorModal } =
    useAccountData()

  const isMobileOrTablet = useMobileOrTabletMode()
  const showMenu = displayMenuButton && !useMediaQuery(theme.breakpoints.down("lg"))
  const isFeatFlagTrue = useFeatureFlagEnabled(FeatureFlag.TN2_TRADER_DEV)
  const {
    external_id: externalId,
    balance,
    backend,
    id: accountId,
    type,
    display_name: displayName,
    currency,
    LoginName
  } = account

  // Launch Button
  const [displayLaunchButton, setDisplayLaunchButton] = useState(false)
  const onMouseEnter = () => {
    if (displayHoverLaunchButton) {
      setDisplayLaunchButton(true)
    }
  }
  const onMouseLeave = () => {
    if (displayHoverLaunchButton) {
      setDisplayLaunchButton(false)
    }
  }

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null)

  const onLaunchButtonClick = useCallback(() => {
    if (backend?.type === MT4) {
      localStorage.setItem(LOCAL_STORAGE_SELECTED_MT4_ACCOUNT, LoginName)
      window.open("/account/mt4webplatform", "_blank")
    } else {
      getCloudtradeAccountData(accountId, backend.derivative_type, isFeatFlagTrue)
    }
  }, [backend, accountId, LoginName, getCloudtradeAccountData])

  const debouncedOnLaunchButtonClick = () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current)
    }
    debounceTimerRef.current = setTimeout(() => {
      onLaunchButtonClick()
    }, 300)
  }

  const onDepositButtonClick = () => {
    updateSelectedAccount(accountId)
    goToPage("/account/deposit")
  }

  return (
    <ActionCard
      handleClick={handleClick}
      isSelected={isSelected}
      sx={actionCardStyle(sx)}
      id={id || accountId.toString()}
    >
      {showMenu && type === LIVE && (
        <AccountCardMenu isTNAccount={backend?.type === CLOUDTRADE} isLiveAccount accountId={account.id} />
      )}

      <CardContent onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} mobile={isMobileOrTablet}>
        <LeftColumn>
          <AccountDataWrapper>
            <Text text={displayName} variant="h6" />
            <Text text={externalId} />
          </AccountDataWrapper>
          <AccountCardIcon backendType={backend.type} isOverviewPage={isOverviewPage} type={type} />
        </LeftColumn>
        <RightColumn>
          <AccountBalance currency={currency} balance={balance?.cash_balance} />
          <Equity currency={currency} equity={balance?.total_equity} />
        </RightColumn>
        {displayHoverLaunchButton && (
          <AccountLaunchButton show={displayLaunchButton} onLaunchButtonClick={debouncedOnLaunchButtonClick} />
        )}
      </CardContent>
      {displayBottomButtons && (
        <Row justifyContent={type === LIVE ? "flex-start" : "space-between"} gap="30" margin="0 25px 20px 25px">
          <ActionButton
            displayText="Launch"
            variant="text"
            onClick={debouncedOnLaunchButtonClick}
            sx={bottomButtonStyle}
          />
          {type === LIVE && (
            <ActionButton displayText="Deposit" variant="text" onClick={onDepositButtonClick} sx={bottomButtonStyle} />
          )}
          {type === DEMO && isOverviewPage && (
            <AccountCardDemoChip isOverviewPage={isOverviewPage} backendType={backend.type} />
          )}
        </Row>
      )}
      {cloudtradeAccountDataLoading && <Loading />}
      {displayErrorModal()}
    </ActionCard>
  )
}

export default AccountCard
