import styled from "@emotion/styled"
import { lightGreyColor } from "styles"

export const NoRowsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const dataGridWrapperStyle = (height: number, rowHeight: number) => styled.div`
  width: 100%;
  height: ${height ? `${height}px` : "100%"};

  .css-pay28z-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    display: block;
    line-height: 52px;
    height: ${rowHeight}px;
    text-align: left;
  }
`

export const dataGridStyle = {
  border: `1ps solid ${lightGreyColor}`
}
