import { getToken } from "utils"

export const getAddressDetails = (text: string) => {
  return fetch("https://api.everythinglocation.com/address/verify?geocode", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      lqtkey: process.env.NEXT_PUBLIC_LOQATE_API_KEY,
      input: [{ Address: text }]
    })
  }).then((response) => response.json())
}

export type SocialSignupDataType = {
  email: string
  firstName: string
  lastName: string
  birthday: string
  telephone: string
  address: string
}

export const getSocialSignupData = async ({
  email,
  firstName,
  lastName,
  birthday,
  telephone,
  address
}: SocialSignupDataType) => {
  // Address
  const fullAddress = await getAddressDetails(address)
  let countryOfResidence = ""
  let countryOfResidenceName = ""
  let addrCity = ""
  let addrLine1 = ""
  let addrLine2 = ""
  let addrZip = ""
  if (fullAddress && fullAddress.output && fullAddress.output.length > 0) {
    const pulledAddress = fullAddress.output[0]
    countryOfResidence = pulledAddress.Country || ""
    countryOfResidenceName = pulledAddress.CountryName || ""
    addrCity = pulledAddress.Locality || ""
    addrLine1 = pulledAddress.Address1 || ""
    addrLine2 = pulledAddress.Address2 || ""
    addrZip = pulledAddress.PostalCode || ""
  }

  return {
    welcome: {
      email
    },
    social: {
      first_name: firstName,
      last_name: lastName || firstName
    },
    "country-of-residence": {
      country_of_residence: countryOfResidence,
      country_of_residence_name: countryOfResidenceName
    },
    "date-of-birth": {
      date_of_birth: birthday
    },
    "address-and-contact-details": {
      addr_city: addrCity,
      addr_line_1: addrLine1,
      addr_line_2: addrLine2,
      addr_zip: addrZip,
      telephone
    }
  }
}

export default async function handler(req, res) {
  const signup = fetch(`${process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT}/accounts/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`
    }
  })
  signup.then(async (response) => {
    const signupResults = await response.json()
    res.status(200).json(signupResults)
  })
}
