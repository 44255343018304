import React, { useState, useEffect } from "react"
import { Drawer, OptionType } from "components"
import { useMobileMode } from "hooks"
import { lightGreyColor } from "styles"
import { Option } from "./SearchfieldDrawer.styled"

type Props = {
  drawerOpen: boolean
  setDrawerOpen: (value: boolean) => void
  optionsList: OptionType[]
  updateFieldValues: (value: string) => void
  anchorEl: HTMLElement
  handleClose: () => void
  countrySearchPlaceholder?: string
}

const SearchfieldDrawer = ({
  drawerOpen,
  setDrawerOpen,
  optionsList,
  updateFieldValues,
  anchorEl,
  handleClose,
  countrySearchPlaceholder
}: Props) => {
  const isMobile = useMobileMode()

  const [options, setOptions] = useState(optionsList)
  const [searchValue, setSearchValue] = useState("")

  const onSearchHandle = (value: string) => {
    setSearchValue(value)
  }

  useEffect(() => {
    if (searchValue === "") {
      setOptions(optionsList)
    } else {
      setOptions(
        optionsList.filter((option) => option.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
      )
    }
  }, [searchValue, optionsList])

  const onOptionHandle = (value: string) => {
    updateFieldValues(value)
    setDrawerOpen(false)
  }

  return (
    <Drawer
      open={drawerOpen}
      toggleDrawer={setDrawerOpen}
      hasSearchField
      onSearchHandle={onSearchHandle}
      searchValue={searchValue}
      anchorEl={anchorEl}
      handleClose={handleClose}
      label={countrySearchPlaceholder}
    >
      {options.map((option) => (
        <Option
          key={option.value}
          onClick={() => onOptionHandle(option.value)}
          padding={isMobile ? "20px 0" : "10px 20px"}
          borderBottom={isMobile ? `1px solid ${lightGreyColor}` : "none"}
        >
          {option.prefix}
          {option.label}
        </Option>
      ))}
    </Drawer>
  )
}

export default SearchfieldDrawer
