import { useMediaQuery } from "@mui/material"
import React from "react"
import { theme } from "styles"

const TnLogoIcon = () => {
  let height = 24
  let width = 24
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down("lg"))
  if (isTabletOrSmaller) {
    height = 20
    width = 20
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M15.6376 22.5143L11.8842 14.3413L8.11844 22.5268L6.84231 19.7543L11.8809 8.80134L11.885 8.79883L15.64 16.9609L21.2647 4.74034C19.1024 1.86004 15.6845 0 11.8388 0C7.99313 0 4.61979 1.83581 2.45499 4.68436H18.7487L17.6705 7.0282H10.1464L5.567 16.9827L4.29087 14.2102L7.59496 7.0282H1.06097C0.379212 8.54314 0 10.2269 0 12C0 18.6271 5.30073 24 11.8388 24C18.3769 24 23.6777 18.6271 23.6777 12C23.6777 10.2904 23.324 8.6643 22.6884 7.19198L15.6376 22.5135V22.5143Z"
        fill="#FC5000"
      />
      <path d="M22.6093 1.99539L24 4.51555L22.6909 7.19949L21.2655 4.73949L22.6093 1.99539Z" fill="#FC5000" />
    </svg>
  )
}
export default TnLogoIcon
