import React, { useState, useEffect, useContext } from "react"
import { Content } from "@prismicio/client"
import { Text } from "components"
import { lightGreyColor, mainColor } from "styles"
import { SignupSectionType } from "utils"
import { LanguageContext } from "context"
import { Container, Section, Bar, textStyle } from "./ProgressBar.styled"

type Props = {
  sections: SignupSectionType[]
  currentSection?: SignupSectionType
  sectionTranslation?: Content.TranslationDocument
}

type ItemProp = {
  value: SignupSectionType
  label: string
}

const ProgressBar = ({ currentSection, sections, sectionTranslation }: Props) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [isTranslation, setIsTranslation] = useState(false)
  const { language } = useContext(LanguageContext)
  const translation = sectionTranslation.data.slices.filter(
    (slice: Content.TranslationDocumentDataSlicesSlice) => slice.primary.language === language.language_code
  )
  useEffect(() => {
    if (translation) {
      setIsTranslation(true) // to avoid hydration errors
    }
  }, [translation])

  useEffect(() => {
    if (currentSection) {
      setCurrentStepIndex(sections.findIndex((section) => section === currentSection))
    }
  }, [currentSection, sections])

  if (!currentSection) {
    return null
  }

  const matchingItems = (translation[0]?.items as ItemProp[])?.filter((item) => sections.includes(item.value))

  const renderedSections = sections.map((section, index) => {
    const barColor = currentStepIndex >= index ? mainColor : lightGreyColor
    const matchingItem = matchingItems?.find((item) => item.value === section)
    return (
      <Section key={`progress_bar_${section}`}>
        <Bar barColor={barColor} />
        <Text
          text={isTranslation && matchingItem ? matchingItem.label : section}
          variant="h4"
          sx={textStyle(currentStepIndex, index)}
        />
      </Section>
    )
  })

  return <Container data-testid="progress-bar">{renderedSections}</Container>
}

export default ProgressBar
