import * as Sentry from "@sentry/react"
import { WebAuth, User } from "auth0-js"
import {
  ObjectWithFieldsType,
  USER_ALREADY_REGISTERED_USING_NORMAL_SIGNUP,
  clearLocalStorage,
  getCxdRef,
  getLocalStorage,
  goToPage,
  redirectAfterLogin,
  setLocalStorage,
  sendMessageToWindow,
  LOCAL_STORAGE_SANITISED_USER_INFO,
  getLastPageUserWasOn,
  LOCAL_STORAGE_USER_INFO,
  LOCAL_STORAGE_USER_INFO_TS,
  LOCAL_STORAGE_ACCESS_TOKEN,
  IncompleteSignupInfo
} from "utils"

export const LOCAL_STORAGE_TOKEN_EXPIRY = "token_expiration_time"
const LOCAL_STORAGE_SIGNUP_DATA = "signup_data"
const LOCAL_STORAGE_AUTH_STATER = "authState"
const LOCAL_STORAGE_AUTH_NONCE = "authNonce"
const LOCAL_STORAGE_LAST_PAGE = "lastPageUserWasOn"
const LOCAL_STORAGE_REFERRAL_CODE = "referral_code"

export const setToken = (authResult) => {
  if (authResult && authResult.access_token) {
    sessionStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, authResult.access_token)
    setLocalStorage(LOCAL_STORAGE_ACCESS_TOKEN, authResult.access_token as string)
    setCookieForPlatform(authResult)
  }
}

export const setCookieForPlatform = (authResult) => {
  if (authResult && authResult.access_token) {
    document.cookie = `access_token=${authResult.access_token};max-age=28800;domain=.${window.location.host};path=/`
  } // DEV-4536 - store TN trader auth0 cookie for 8 hours.
}

export const setTokenExpiry = (authResult) => {
  if (authResult && authResult.access_token) {
    const expiresAt = Date.now() + 3600000 * 8 // add 8 hours to the current time
    localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRY, String(expiresAt))
  }
}

export const getToken = () => {
  return sessionStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
}

export const loadUser = async (authResult): Promise<User> => {
  if (authResult && authResult.access_token) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT}/auth0/user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authResult.access_token || getToken()}`
        }
      })
        .then((response) => {
          if (response.status === 429) {
            Sentry.captureException(response)
            reject(response)
          }
          resolve(response.clone().json())
        })
        .catch((err) => {
          Sentry.captureException(err)
          // TODO: check if migration issue and show msg
          reject(err)
        })
    })
  }
}

export const fetchUser = async () => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}auth/me`)
    if (response.status === 401) {
      console.warn("User not authenticated")
      return null
    }

    if (!response.ok) {
      console.error("Failed to fetch user", response.status)
      return null
    }
    const contentType = response.headers.get("Content-Type")
    if (contentType && contentType.includes("application/json")) {
      const user = await response.json()
      if (user.accessToken) {
        setToken({ access_token: user.accessToken })
        setTokenExpiry({ access_token: user.accessToken })
        setCookieForPlatform({ access_token: user.accessToken })
        return user.accessToken
      } else {
        console.error("No token found in response")
      }
      return user
    } else {
      console.error("Response is not JSON or empty")
      return null
    }
  } catch (error) {
    console.error("Error fetching user:", error)
    return null
  }
}

export const getSocialLogin = async (provider) => {
  window.location.href = `${process.env.NEXT_PUBLIC_API_URL}auth/login?provider=${provider}`
}

export const signUp = async (email: string, password: string, firstName: string, lastName: string) => {
  const cxdRef = getCxdRef()
  return new Promise((resolve, reject) => {
    const signupVars = {
      connection: "metadata",
      email,
      password,
      user_metadata: {
        first_name: firstName,
        last_name: lastName,
        cxdRef,
        referral_code: ""
      }
    }
    const refCode = getLocalStorage(LOCAL_STORAGE_REFERRAL_CODE)
    if (refCode) signupVars.user_metadata.referral_code = refCode
    const signup = new WebAuth({
      domain: "tradenationdev.eu.auth0.com",
      clientID: "vLr6lkhhkNF4UuQKaPq1aNED3gznC10l",
      responseType: "token id_token",
      redirectUri: process.env.NEXT_PUBLIC_SOCIAL_REDIRECT_URI,
      scope: "openid",
      audience: process.env.NEXT_PUBLIC_AUTH0_LOCK_AUDIENCE
    })
    signup.signup(signupVars, (signupErr, signupResult) => {
      if (signupErr) {
        console.error(signupErr, "Error")
        reject(signupErr)
      }
      resolve(signupResult)
    })
  })
}

export const getUserAuth0Token = (email: string, password: string, useAudience = false) => {
  const domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN
  const audience = `${domain}/api/v2/`
  const body = {
    realm: "metadata",
    client_id: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    scope: "openid",
    grant_type: "http://auth0.com/oauth/grant-type/password-realm",
    username: email,
    password
  }
  if (useAudience) {
    body["audience"] = audience
  }
  return new Promise((resolve) => {
    const login = fetch(`${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/oauth/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
    login.then(async (response) => {
      const authResult = await response.json()
      setLocalStorage(LOCAL_STORAGE_ACCESS_TOKEN, authResult.access_token as string)
      resolve(authResult)
    })
  })
}

export const signupUser = async (data: ObjectWithFieldsType) => {
  return new Promise((resolve, reject) => {
    const { email, password } = data
    const auth = new WebAuth({
      domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
      clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
      audience: process.env.NEXT_PUBLIC_AUTH0_LOCK_AUDIENCE
    })

    auth.signup(
      {
        connection: "metadata",
        responseType: "token",
        email,
        password
      },
      (signupErr, signupResult) => {
        if (signupErr) {
          reject(signupErr)
        } else {
          resolve(signupResult)
        }
      }
    )
  })
}

export const postIncompleteSignUpInfo = async (form) => {
  const inCompleteForm = { ...form }

  return new Promise((resolve, reject) => {
    fetch(`${process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT}/finsa/postSignupInfo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`
      },
      body: JSON.stringify(inCompleteForm)
    })
      .then((response) => {
        Sentry.setContext("post incomplete signup info", inCompleteForm)
        Sentry.captureMessage(`Incomplete signup info saved successfully`)
        resolve(response.clone())
      })
      .catch((err) => {
        Sentry.captureException(err)
        reject(err)
      })
  })
}

export const checkTokenReRouteToLogin = () => {
  const hasSessionToken = sessionStorage.getItem("access_token")
  const hasLocalToken = localStorage.getItem("access_token")
  if (hasSessionToken || hasLocalToken) {
    return
  } else {
    sessionStorage.setItem("lastLocation", window.location.pathname)
    goToPage("/login")
  }
}
export const userExistsRedirect = (userData) => {
  clearLocalStorage(LOCAL_STORAGE_SIGNUP_DATA)
  if (userData) {
    setLocalStorage(LOCAL_STORAGE_USER_INFO, userData)
    setLocalStorage(LOCAL_STORAGE_USER_INFO_TS, `${Date.now() + 30000}`)
  }
  redirectAfterLogin()
}

export const getSignupInfo = async (email: string) => {
  const signupInfoResponse = await fetch(
    `${process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT}/finsa/getSignupInfo?email=${email}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`
      }
    }
  )
  const signupInfo = await signupInfoResponse.json()
  return signupInfo
}

export const getIncompleteSignUpInfoAndUserData = async (
  userExists: (message: string) => void,
  validationMessages: { [key: string]: string },
  updateData?: (any) => void
) => {
  return new Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    signupInfo: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userData: any
    gotRedirected: boolean
  }>(async (resolve, reject) => {
    try {
      let gotRedirected = false
      const token = getLocalStorage(LOCAL_STORAGE_ACCESS_TOKEN)
      const userData = (await loadUser({
        access_token: token
      })) as {
        email: string
        message?: string
        user_metadata?: { suitability_check_failed_at?: any }
        app_metadata?: { clients?: { client_profile_id?: number }[] }
      }

      if (userData) {
        Sentry.addBreadcrumb({ category: "login", message: `Found existing user: ${userData.email}` })
        //@ts-ignore
        if (userData.user_metadata && userData.user_metadata.suitability_check_failed_at) {
          //@ts-ignore
          const dateThen = new Date(userData.user_metadata.suitability_check_failed_at)
          const dateNow = new Date()
          //@ts-ignore
          const differenceInMs = dateNow - dateThen
          const ninetyDaysInMs = 90 * 24 * 60 * 60 * 1000
          const isWithinThreeMonths = differenceInMs <= ninetyDaysInMs

          if (isWithinThreeMonths) {
            Sentry.addBreadcrumb({
              category: "login",
              message: `${userData.email} was declined within the last 3 months`
            })
            window.location.href = `${process.env.NEXT_PUBLIC_HOST_NAME}signup/declined-account`
          }
        }

        if (userData.message) {
          Sentry.addBreadcrumb({ category: "login", message: `Did not find user profile` })
          Sentry.captureException(userData.message)
          const err = "We are having trouble finding your account. Please contact support@tradenation.com"
          console.log(err)
          reject(err)
          return
        }
        if (userData.app_metadata?.clients && userData.app_metadata.clients[0].client_profile_id) {
          Sentry.addBreadcrumb({
            category: "login",
            message: `Existing user profile found for ${userData.email}, redirecting`
          })
          userExistsRedirect(userData)
          gotRedirected = true
          resolve({
            signupInfo: null,
            userData,
            gotRedirected
          })
        }
        const signupInfo = await getSignupInfo(userData.email)
        const lastStep = getLastPageUserWasOn(signupInfo?.last_page_user_was_on)
        if (updateData && signupInfo && signupInfo.application_completed !== "yes") {
          updateData({
            ...signupInfo,
            last_page_user_was_on: lastStep,
            lastPageUserWasOn: lastStep
          })
          setLocalStorage(LOCAL_STORAGE_LAST_PAGE, lastStep)
          if (lastStep) {
            if (window.location.href.includes("signup")) {
              Sentry.addBreadcrumb({
                category: "login",
                message: `${userData.email} has incomplete signup, redirecting tp ${lastStep}`
              })
              goToPage(lastStep)
            }
            if (window.location.href.includes("login")) {
              Sentry.addBreadcrumb({
                category: "login",
                message: `${userData.email} has incomplete signup, redirecting tp ${lastStep}`
              })
              goToPage(`/signup/${lastStep}`)
            }
          } else if (lastStep === "") {
            // Patch for DEV-4207, get to the root cause
            if (window.location.href.includes("signup")) {
              goToPage("/country-of-residence")
            }
            if (window.location.href.includes("login")) {
              goToPage("/signup/country-of-residence")
            }
          }
          gotRedirected = true
        }
        resolve({
          signupInfo: { ...signupInfo, last_page_user_was_on: lastStep },
          userData,
          gotRedirected
        })
      }
    } catch (error) {
      if (error.status === 429) {
        Sentry.captureException("Too many attempts")
        userExists(validationMessages.cannot_login_try_again_later)
        return
      } else {
        Sentry.captureException(error)
      }
      reject(error)
    }
  })
}

export const getIncompleteSignUpInfo = async (isTn1: boolean | string = false) => {
  try {
    const accessToken = isTn1 || getToken()
    const userData = await loadUser({ access_token: accessToken })
    const clientProfileId = userData.app_metadata?.clients?.[0]?.client_profile_id

    const response = await fetch(
      `${process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT}/finsa/getSignupInfo?email=${userData.email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        }
      }
    )

    const signupInfo: IncompleteSignupInfo = await response.json()
    if (clientProfileId) {
      return { ...signupInfo, application_completed: "yes" }
    }

    return signupInfo
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
}

export const getIncompleteSignupInfoAndRedirect = async (updateData: (res: object) => void) => {
  try {
    const incompleteSignupInfo = await getIncompleteSignUpInfo()
    if (incompleteSignupInfo?.application_completed === "yes") {
      throw new Error("User exists and completed application.")
    }

    const lastStep = getLastPageUserWasOn(incompleteSignupInfo.last_page_user_was_on)

    updateData({
      ...incompleteSignupInfo,
      last_page_user_was_on: lastStep
    })

    if (lastStep) {
      Sentry.addBreadcrumb({
        category: "signup",
        message: `${incompleteSignupInfo?.welcome?.email} resumes signup process. Redirecting to ${lastStep}`,
        level: "info"
      })
      Sentry.setUser({ email: incompleteSignupInfo?.welcome?.email })

      setLocalStorage(LOCAL_STORAGE_LAST_PAGE, lastStep)

      if (window.location.href.includes("signup")) {
        goToPage(lastStep)
      } else if (window.location.href.includes("login")) {
        goToPage(`/signup/${lastStep}`)
      }

      return true
    }

    return false
  } catch (error) {
    console.error("An error occurred:", error)
    throw error // rethrow to catch in component
  }
}

export const redirectIfErrorFound = (currentUrl: string) => {
  if (currentUrl.indexOf("error_description") !== -1 || currentUrl.indexOf("access_denied") !== -1) {
    goToPage("/signup/welcome")
  }
}

export const authorizeTradingView = (state) => {
  if (state) {
    const tvWebAuth = new WebAuth({
      domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
      clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID_TRADINGVIEW,
      redirectUri: process.env.NEXT_PUBLIC_TRADINGVIEW_REDIRECT_URI,
      responseType: "code",
      scope: "openid",
      audience: process.env.NEXT_PUBLIC_AUTH0_LOCK_AUDIENCE
    })
    tvWebAuth.authorize({ connection: "metadata", state })
  }
}

export const sendMessageToTn2IfLoginSuccessful = (action, data) => {
  const webAuth = new WebAuth({
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    responseType: "token",
    scope: "openid profile email",
    audience: process.env.NEXT_PUBLIC_AUTH0_LOCK_AUDIENCE
  })

  webAuth.parseHash({ hash: window.location.hash }, async (err, authResponse) => {
    if (err) {
      console.log(err)
      return
    }
    const state = sessionStorage.getItem("trading_view_state")
    if (authResponse?.accessToken && state) {
      sendMessageToWindow({
        action,
        data
      })
    }
  })
}

export const sdkLogin = (loginData: ObjectWithFieldsType, errorCallback, redirectUri?) => {
  const webAuth = new WebAuth({
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    redirectUri: redirectUri || process.env.NEXT_PUBLIC_SOCIAL_REDIRECT_URI,
    responseType: "token",
    scope: "openid profile email",
    audience: process.env.NEXT_PUBLIC_AUTH0_LOCK_AUDIENCE
  })

  webAuth.login(
    {
      realm: "metadata",
      username: loginData.username,
      password: loginData.password
    },
    (error) => {
      if (error) {
        errorCallback(error)
      }
    }
  )
}

export const checkIfUserAlreadyRegistered = async (userToken: string) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT}/finsa/checkSocialEmail`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    })
      .then(async (response) => {
        resolve(response.clone().json())
      })
      .catch((err) => {
        Sentry.captureException(err)
        reject(err)
      })
  })
}

export const checkIfUserIsValid = async (
  startSignup: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    incompleteSignupInfo: { [key: string]: any },
    email: string
  ) => void,
  userExists: (errorMessage: string) => void,
  validationMessages: { [key: string]: string },
  setIsUserLoading: any
) => {
  const currentUrl = window.location.href
  redirectIfErrorFound(currentUrl)

  if (window.location.search.includes("callback")) {
    setIsUserLoading(true)
    const userToken = await fetchUser()

    if (userToken) {
      await checkIfUserAlreadyRegistered(userToken).then(
        async (alreadyRegisteredResults: { pass: boolean; msg?: string }) => {
          if (!alreadyRegisteredResults.pass) {
            setLocalStorage(USER_ALREADY_REGISTERED_USING_NORMAL_SIGNUP, "yes")
            userExists(alreadyRegisteredResults.msg)
          } else {
            const { signupInfo, userData } = await getIncompleteSignUpInfoAndUserData(userExists, validationMessages)
            const isSocial = userData.identities[0].isSocial
            if (userData) {
              let userDetails: { firstName: string; lastName: string }
              if (isSocial) {
                userDetails = {
                  firstName: userData?.given_name,
                  lastName: userData?.family_name
                }
              } else {
                userDetails = {
                  firstName: userData?.user_metadata?.first_name,
                  lastName: userData?.user_metadata?.last_name
                }
              }
              sessionStorage.setItem(LOCAL_STORAGE_SANITISED_USER_INFO, JSON.stringify(userDetails))
            }
            startSignup(signupInfo, userData ? userData?.email : "")
          }
        }
      )
    } else {
      Sentry.captureException("Token could not be fetched; user is invalid.")
      console.error("Token could not be fetched; user is invalid.")
    }
  }
}

export const handleLogout = async () => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}auth/logout`, {
      method: "GET",
      credentials: "include"
    })

    if (!response.ok) {
      console.error("Logout failed", response.status, response.statusText)
    }
  } catch (error) {
    console.error("Logout failed", error)
  }
}
