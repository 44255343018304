import styled from "@emotion/styled"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  z-index: 101;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`

export const Bar = styled.div<{ barColor: string }>`
  height: 4px;
  background-color: ${(props) => props.barColor};
  border-radius: 6px;
`

export const textStyle = (currentStepIndex: number, index: number) => ({
  opacity: currentStepIndex >= index ? 1 : 0.2,
  fontWeight: 500,
  fontSize: "13px",
  fontFamily: "Kanit, sans-serif"
})
