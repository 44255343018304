/* eslint-disable @typescript-eslint/no-unused-vars */
import "@fontsource/montserrat" // Defaults to weight 400
import React, { useState, useEffect } from "react"
import { useRouter } from "next/router"
import { AppProps } from "next/app"
import Head from "next/head"
import Link from "next/link"
import localFont from "next/font/local"
import * as prismic from "@prismicio/client"
import { Provider } from "react-redux"
import { PrismicProvider } from "@prismicio/react"
import { PrismicPreview } from "@prismicio/next"
import { Alert, CssBaseline, ThemeProvider } from "@mui/material"
import { Loading, NextPageWithLayout, Layout, DevButton } from "components"
import { Container } from "components/pages/_app.styled"
import {
  getLocalStorage,
  setLocalStorage,
  SignupDataType,
  AccountDataType,
  BrandsType,
  ObjectWithFieldsType,
  CountryInfoType,
  getAffiliates,
  countryToJurisdictionMapping,
  getFieldValue,
  injectGTM,
  FeatureToggles,
  Sentry,
  isDevEnvironment
} from "utils"
import { useInterceptNextDataHref } from "hooks"
import { store } from "redux/store"
import { LanguageContextProvider } from "context"
import { theme } from "styles"
import "styles/globals.css"
import { repositoryName } from "../prismicio"
import { RichTextField } from "@prismicio/types"
import { Prompt } from "components/types"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const LOCAL_STORAGE_SIGNUP_DATA = "signup_data"

const defaultContext = {
  validationMessages: {} as { [key: string]: string },
  setValidationMessages: (messages) => {},
  signupData: {} as SignupDataType,
  updateSignupData: (data: SignupDataType) => {},
  jurisdiction: "",
  updateJurisdiction: (data: string) => {},
  stepUid: "",
  updateStepUid: (data: string) => {},
  currentStepSignupData: {} as ObjectWithFieldsType,
  updateCurrentStepSignupData: (data: ObjectWithFieldsType) => {},
  currentStepNextData: {} as ObjectWithFieldsType,
  updateCurrentStepNextData: (data: ObjectWithFieldsType) => {},
  bottomAreaHeight: 0,
  updateBottomAreaHeight: (data: number) => {},
  accountData: {} as AccountDataType,
  updateAccountData: (data: AccountDataType) => {},
  brands: { available: [], total: [] } as BrandsType,
  updateBrands: (data: BrandsType) => {},
  selectedAccount: null as number | null,
  updateSelectedAccount: (account: number | null) => {},
  loading: false,
  setLoading: (arg: boolean) => {},
  userCountryInformation: {} as CountryInfoType,
  updateUserCountryInformation: (data: CountryInfoType) => {},
  promptMessage: {
    message: "",
    success: true,
    richTextMessage: undefined
  } as Prompt,
  updatePromptMessage: (data: Prompt) => {},
  featureToggles: {} as FeatureToggles | null,
  setFeatureToggles: (arg: FeatureToggles | null) => {},
  kycModalData: { kycDue: false, display: false, dismissible: false },
  updateKycModalData: (data: { kycDue: boolean; display: boolean; dismissible: boolean }) => {}
}

export const Context = React.createContext(defaultContext)

const isNotLocalDevServer = process.env.NODE_ENV === "production"

const helveticaNeueFont = localFont({
  src: [
    {
      path: "./fonts/HelveticaNeueLight.otf",
      weight: "400",
      style: "normal"
    },
    {
      path: "./fonts/HelveticaNeueMedium.otf",
      weight: "500",
      style: "normal"
    },
    {
      path: "./fonts/HelveticaNeueBold.otf",
      weight: "700",
      style: "normal"
    }
  ]
})

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = ({ Component, pageProps, router }: AppPropsWithLayout) => {
  const PerPageLayout = Component.Layout ?? Component

  const [userCountryInformation, updateUserCountryInformation] = useState<null | CountryInfoType>()

  useInterceptNextDataHref({
    router,
    namespace: "signup"
  })

  // Page Title
  const { asPath } = useRouter()

  const getPageTitle = () => {
    if (asPath.includes("signup")) {
      return "Sign Up | Trade Nation"
    }
    if (asPath.includes("forgot-password")) {
      return "Forgotten Password | Trade Nation"
    }
    if (asPath.includes("login")) {
      return "Log In | Trade Nation"
    }
    if (asPath.includes("account")) {
      return "Your Trading Account | Trade Nation"
    }
    return "Trade Nation"
  }

  const [pageTitle, setPageTitle] = useState(getPageTitle())
  useEffect(() => {
    setPageTitle(getPageTitle())
  }, [asPath])

  useEffect(() => {
    if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_ENV !== "DEV") {
      const style = document.createElement("style")
      style.innerHTML = `
        .grecaptcha-badge {
          visibility: hidden !important;
        }
      `
      document.head.appendChild(style)
    }
  }, [])
  const updateUsersCountryInformation = async () => {
    if (isNotLocalDevServer) {
      try {
        const res = await fetch("https://cdn.tradenation.com/ip/details")
        const json = await res.json()
        if (json) {
          updateUserCountryInformation({
            country: json["cloudfront-viewer-country"] // "GB"
          })
        } else {
          updateUserCountryInformation(null)
        }
      } catch (error) {
        console.log("isNotLocalDevServer and getIP API failed", error)
        updateUserCountryInformation(null)
        Sentry.captureException(error)
      }
    } else {
      updateUserCountryInformation({
        country: "GB"
      })
    }
  }

  useEffect(() => {
    updateUsersCountryInformation()
  }, [])

  const [featureToggles, setFeatureToggles] = useState<null | FeatureToggles>(null)

  // Signup
  const [signupData, setSignupData] = useState<SignupDataType>({})
  const updateSignupData = (data: SignupDataType) => {
    setSignupData((prev) => ({ ...prev, ...data }))
    setLocalStorage(LOCAL_STORAGE_SIGNUP_DATA, data)
  }

  // user updates jurisdiction, keep in Context, SSOT
  const [jurisdiction, setJurisdiction] = useState("")
  const updateJurisdiction = (data: string) => {
    setJurisdiction(data)
  }

  useEffect(() => {
    if (userCountryInformation && userCountryInformation.country) {
      const corFromCache = sessionStorage.getItem("ip_cor")
      if (!corFromCache) {
        sessionStorage.setItem("ip_cor", userCountryInformation.country)
      }

      if (!jurisdiction) {
        const cache = getLocalStorage(LOCAL_STORAGE_SIGNUP_DATA)
        const cacheJurisdiction = getFieldValue(cache, "jurisdiction")
        if (cacheJurisdiction) {
          updateJurisdiction(cacheJurisdiction)
        } else {
          updateJurisdiction(countryToJurisdictionMapping[userCountryInformation.country].jurisdiction)
        }
      }
    }
  }, [userCountryInformation])

  const [stepUid, setStepUid] = useState("")
  const updateStepUid = (data: string) => {
    setStepUid(data)
  }

  const [currentStepSignupData, setCurrentStepSignupData] = useState<ObjectWithFieldsType>({})
  const updateCurrentStepSignupData = (data: ObjectWithFieldsType) => {
    setCurrentStepSignupData(data)
  }

  const [currentStepNextData, setCurrentStepNextData] = useState<ObjectWithFieldsType>({})
  const updateCurrentStepNextData = (data: ObjectWithFieldsType) => {
    setCurrentStepNextData(data)
  }

  const [bottomAreaHeight, setBottomAreaHeight] = useState(0)
  const updateBottomAreaHeight = (data: number) => {
    setBottomAreaHeight(data)
  }

  const [promptMessage, setPromptMessage] = useState<Prompt>({
    message: "",
    success: false,
    richTextMessage: undefined
  })
  const updatePromptMessage = (data: Prompt) => {
    setPromptMessage(data)
  }

  // Account
  const [accountData, setAccountData] = useState<AccountDataType>({})
  const updateAccountData = (data: AccountDataType) => {
    setAccountData(data)
  }
  const [brands, setBrands] = useState<BrandsType>({
    available: [],
    total: []
  })
  const updateBrands = (data: BrandsType) => {
    setBrands(data)
  }
  const [selectedAccount, setSelectedAccount] = useState<number | null>(null)
  const updateSelectedAccount = (account: number | null) => {
    setSelectedAccount(account)
  }

  // KYC Modal
  const [kycModalData, setKycModalData] = useState({ kycDue: false, display: false, dismissible: false })
  const updateKycModalData = (data: { kycDue: boolean; display: boolean; dismissible: boolean }) => {
    setKycModalData(data)
  }

  // Loading to when changing pages
  const [loading, setLoading] = useState(true)

  const trackAffiliateURLParams = async () => {
    await getAffiliates()
  }

  useEffect(() => {
    // When router STARTS redirecting the user to a new page
    const handleRouteChange = () => {
      setLoading(true)
    }
    router.events.on("routeChangeStart", handleRouteChange)

    // When router FINISHES redirecting the user to a new page
    const handleRouteComplete = () => {
      setLoading(false)
    }
    router.events.on("routeChangeComplete", handleRouteComplete)

    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
      router.events.off("routeChangeComplete", handleRouteComplete)
    }
  }, [router.events])

  useEffect(() => {
    setLoading(false)
    trackAffiliateURLParams()
    setSignupData(getLocalStorage(LOCAL_STORAGE_SIGNUP_DATA))
  }, [])

  const lastPageUserWasOn = signupData && (signupData.last_page_user_was_on as unknown as string) === stepUid

  // Move token to CI
  const client = prismic.createClient("tnfev2", {
    accessToken:
      "MC5ZN3ZySnhFQUFDUUFYbzVz.Bmjvv73vv73vv73vv70P77-9ZmgR77-977-977-977-9Yu-_vS94KC0M77-9SlLvv73vv71lWe-_vSlX",
    routes: []
  })

  useEffect(() => {
    if (!isDevEnvironment()) {
      injectGTM(process.env.NEXT_PUBLIC_GTM_MEASUREMENT_ID)
    }
  }, [])

  const [validationMessages, setValidationMessages] = useState(pageProps?.validation?.messages?.data)

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Provider store={store}>
        <PrismicProvider
          internalLinkComponent={({ href, ...props }) => (
            <Link href={href}>
              <a {...props} />
            </Link>
          )}
          client={client}
        >
          <ThemeProvider theme={theme}>
            <Context.Provider
              value={{
                validationMessages,
                setValidationMessages,
                signupData,
                updateSignupData,
                jurisdiction,
                updateJurisdiction,
                stepUid,
                updateStepUid,
                currentStepSignupData,
                updateCurrentStepSignupData,
                currentStepNextData,
                updateCurrentStepNextData,
                bottomAreaHeight,
                updateBottomAreaHeight,
                accountData,
                updateAccountData,
                brands,
                updateBrands,
                selectedAccount,
                updateSelectedAccount,
                loading,
                setLoading,
                userCountryInformation,
                updateUserCountryInformation: updateUsersCountryInformation,
                promptMessage,
                updatePromptMessage,
                featureToggles,
                setFeatureToggles,
                kycModalData,
                updateKycModalData
              }}
            >
              <LanguageContextProvider languages={pageProps?.translation?.supportedLanguages}>
                <CssBaseline />
                <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}>
                  <Layout
                    riskWarnings={pageProps?.riskWarnings}
                    translations={pageProps?.translation?.disclaimerTranslations}
                    category={pageProps?.category}
                    subCategory={pageProps?.subCategory}
                  >
                    <Container className={helveticaNeueFont.className} path={asPath}>
                      {isDevEnvironment() && <DevButton />}
                      <PerPageLayout {...pageProps}>
                        {lastPageUserWasOn && (
                          <Alert severity="info">{validationMessages.this_is_last_page_you_were_on}</Alert>
                        )}
                        {loading ? <Loading /> : <Component {...pageProps} />}
                      </PerPageLayout>
                    </Container>
                  </Layout>
                </GoogleReCaptchaProvider>
              </LanguageContextProvider>
            </Context.Provider>
          </ThemeProvider>
        </PrismicProvider>
      </Provider>
    </>
  )
}

export default MyApp
