import { ActionButtonActionType, Language } from "components"
import { useSignupData } from "hooks"
import {
  ObjectWithFieldsType,
  SignupDataType,
  AccountDataType,
  goToPage,
  getLocalStorage,
  setLocalStorage,
  LIVE,
  DEMO,
  postIncompleteSignUpInfo,
  LOCAL_STORAGE_PLATFORM_CURRENCIES,
  PrismicSignStepType,
  SignupSectionType,
  getActionButtons,
  compareValidFieldsAndValues
} from "utils"

const LOCAL_STORAGE_LAST_PAGE = "lastPageUserWasOn"
const LOCAL_STORAGE_REFERRAL_CODE = "referral_code"

const QUESTION_STEPS = [
  "initial-experience-questions",
  "experience-questions",
  "knowledge-questions",
  "qualifier-questions",
  "initial-knowledge-questions"
]

const maps: { [field: string]: string[] } = {
  personal: [
    "email",
    "first_name",
    "last_name",
    "title",
    "telephone",
    "za_province",
    "id_card",
    "au_state",
    "birth_country",
    "it_province",
    "tax_code",
    "cpr",
    "gender",
    "nif_code",
    "cpf_number",
    "mothers_maiden_name",
    "personal_identity_code_is"
  ],
  about: [
    "date_of_birth",
    "addr_street",
    "addr_line_2",
    "addr_city",
    "addr_zip",
    "national_insurance_number",
    "belgian_national_number",
    "bulgarian_personal_number",
    "personal_identity_code_dk",
    "national_identification_number_cz",
    "passport_number",
    "personal_identification_code_fi",
    "personal_identification_code_is",
    "estonian_personal_code",
    "es_tax_id",
    "dss_investor_share",
    "personal_id_hr",
    "fiscal_code",
    "national_identity_card_number_li",
    "national_identity_card_number_nl",
    "personal_code_lt",
    "personal_code_lv",
    "national_identification_number_mt",
    "personal_id_no",
    "national_identification_number_pl",
    "tax_number_pl",
    "tax_number_pt",
    "national_identification_number_ro",
    "personal_identity_number",
    "personal_identification_number_si",
    "personal_number_sk",
    "national_passport_number_1",
    "national_passport_number_2"
  ],
  additional: [
    "employment_status",
    "employment_occupation",
    "employment_industry",
    "funds_annual_income",
    "funds_savings",
    "funds_source",
    "source_funds",
    "approx_annual_income",
    "approx_asset_value"
  ]
}

const compactMaps: { [value: string]: (value: string) => string } = {
  id_card: (val: string) => val && val.replace(/[ .-]/g, "").trim().toUpperCase(),
  tax_code: (val: string) => val && val.replace(/[ .-]/g, "").trim().toUpperCase(),
  fiscal_code: (val: string) => val && val.replace(/[ .-]/g, "").trim().toUpperCase(),
  national_identity_card_number_li: (val: string) => val && val.replace(/[ .-]/g, "").trim().toUpperCase(),
  personal_code_lt: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  personal_code_lv: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  national_identification_number_mt: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  personal_id_no: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  national_identification_number_pl: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  national_identification_number_ro: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  personal_identification_number_si: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  national_passport_number_1: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  national_passport_number_2: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  national_insurance_number: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  belgian_national_number: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  bulgarian_personal_number: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  personal_identity_code_dk: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  personal_number_sk: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  national_identification_number_cz: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  personal_identity_code_is: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  estonian_personal_code: (val: string) => val.replace(/[ .-]/g, "").trim().toUpperCase(),
  personal_identity_code_fi: (val: string) => val.replace(/[ ]/g, "").trim().toUpperCase(),
  nif_code: (val: string) =>
    val
      .replace(/([ .-]|ES)/g, "")
      .trim()
      .toUpperCase(),
  es_tax_id: (val: string) =>
    val
      .replace(/([ .-]|ES)/g, "")
      .trim()
      .toUpperCase(),
  personal_id_hr: (val: string) =>
    val
      .replace(/([ .-]|HR)/g, "")
      .trim()
      .toUpperCase(),
  national_identity_card_number_nl: (val: string) => val.replace(/[ .-]/g, "").trim().padStart(9, "0"),
  tax_number_pl: (val: string) => val.replace(/([ .-]|PL)/g, "").trim(),
  tax_number_pt: (val: string) => val.replace(/([ .-]|PT)/g, "").trim(),
  personal_identity_number: (val: string) => val.replace(/[ +.-]/g, "").trim(),
  telephone: (val: string) => val.replace(/[() .-]/g, "").trim()
}

export const flattenData = (data: SignupDataType) => {
  return Object.keys(data).reduce((result: SignupDataType, step) => {
    if (QUESTION_STEPS.includes(step)) {
      result[step] = data[step]
    } else {
      const newData = Object.keys(data[step]).reduce((acc: ObjectWithFieldsType, key) => {
        if (compactMaps[key]) {
          acc[key] = compactMaps[key](data[step][key])
        } else {
          acc[key] = data[step][key]
        }
        return acc
      }, {})
      result.data = { ...result.data, ...newData }
    }
    return result
  }, {})
}

export const transformPostBody = (signupData: SignupDataType, language: Language) => {
  const {
    data,
    "initial-experience-questions": prevExperience,
    "experience-questions": experienceQuestions,
    "knowledge-questions": knowledgeQuestions,
    "qualifier-questions": qualifierQuestions,
    "initial-knowledge-questions": initialKnowledgeQuestions
  } = flattenData(signupData)
  const isLiveSignup = data.account_type === LIVE
  const isDemoSignup = data.account_type === DEMO
  const utmSourceCache = localStorage.getItem("utm_source")
  const postBody: ObjectWithFieldsType = {}
  const btaRef = getLocalStorage("bta")
  postBody.signup_raw = {
    ...signupData,
    "device-details": {
      entity: "Website",
      screen_size_width: window.innerWidth,
      screen_size_height: window.innerHeight,
      operating_system: window.navigator.platform
    },
    preferred_language: {
      language_code: language.language_code
    },
    signal_dp: {
      bta: btaRef ? btaRef : null
    },
    media_source:
      signupData["source-question"].source_question_label === "Trading View"
        ? "tradingview"
        : signupData["source-question"].source_question_label,
    utm_source: utmSourceCache ? utmSourceCache : null
  }
  // Brand and currency
  postBody.brand = data.brand_id
  postBody.default_currency = {}
  postBody.default_currency[postBody.brand] = data.currency
  // we don't use previous_experience anymore in the signup flow, so
  // we're adding ONBOARDING in case we run into any issues
  // in ACCOUNT API
  postBody.previous_experience = "ONBOARDING"
  postBody.applicant_id = data.applicant_id
  postBody.countriesDoNotMatch = data.countriesDoNotMatch

  // Account
  if (isDemoSignup) {
    postBody.account = data.account_type
  } else {
    postBody.account = {
      type: data.type,
      currency: data.currency,
      currency_code: data.currency_code,
      platform: data.platform,
      backend_id: data.backend_id
    }
    if (data.mt4_password) {
      postBody.account.mt4_password = data.mt4_password
    }
    if (data.mt4_account_type) {
      postBody.account.mt4_account_type = data.mt4_account_type
    }
    if (data.mt4usergroup_name) {
      postBody.account.mt4usergroup_name = data.mt4usergroup_name
    }
  }

  // Initial Experience
  if (prevExperience?.experience) {
    postBody.previous_experience = prevExperience.experience
  }

  // Rest of fields
  Object.keys(maps).forEach((parentKey) => {
    postBody[parentKey] = {}
    maps[parentKey].forEach((key) => {
      if (data[key]) {
        postBody[parentKey] = {
          ...postBody[parentKey],
          [key]: data[key]
        }
      }
    })
  })

  // Extra personal fields
  postBody.personal = {
    ...postBody.personal,
    addr_country: data.country_of_residence,
    addr_country_full: data.country_of_residence_name,
    title: postBody.personal.title || "0",
    ...(postBody.personal.au_state ? { au_state: Number(postBody.personal.au_state) } : {}),
    ...(postBody.personal.gender ? { gender: Number(postBody.personal.gender) } : {})
  }

  // Extra about fields
  if (postBody.brand === "eu") {
    postBody.about = {
      ...postBody.about,
      nationality: data.country_of_citizenship
    }
  }

  // Experience, knowledge and qualifier questions
  if (experienceQuestions) {
    postBody.experience = experienceQuestions
  }
  if (knowledgeQuestions) {
    postBody.knowledge = knowledgeQuestions
  }
  if (qualifierQuestions) {
    postBody.qualifier = qualifierQuestions
  }

  // Legal
  postBody.legal = {}
  // const cxdRef = getCxdRef()
  // TO-DO - bring in Affiliates capture to POST to ACCT API / SF etc. - APT

  if (["au", "bs", "za"].includes(postBody.brand)) {
    if (isDemoSignup) {
      // demo signup skip T&C
      postBody.legal = {
        confirm: true,
        consent: true,
        read_riskwarnings: true
      }
    } else {
      postBody.legal = {
        confirm: !!data.confirm,
        consent: !!data.consent,
        read_riskwarnings: !!data.read_riskwarnings
      }
      // if (cxdRef) {
      //   postBody.legal.consent_share_data = !!data.consent_share_data
      // }
    }
  } else {
    // for UK
    if (isDemoSignup) {
      // demo signup skip T&C
      postBody.legal = {
        terms_type_confirmation: true,
        terms_read_confirmation: true
      }
    } else {
      // doesnt sit there on original/legacy postBody version.
      postBody.legal = {
        terms_type_confirmation: !!data.terms_type_confirmation,
        terms_read_confirmation: !!data.terms_read_confirmation
      }
      // if (cxdRef) {
      //   postBody.legal.consent_share_data = !!data.consent_share_data
      // }
    }
    postBody.legal.personal_information_confirmation = true

    if (isDemoSignup) {
      postBody.legal.privacy_policy_confirmation = !!data.privacy_policy_confirmation
    }

    if (isLiveSignup) {
      postBody.legal.privacy_policy_confirmation = true
    }
  }

  // Map experience knowledge question from global knowledge question data
  if (postBody.brand === "eu" && postBody.experience) {
    postBody.experience.qualification = ((experience) => {
      switch (experience) {
        case "BOTH":
          return "xp_qualification_1"
        case "COURSE":
          return "xp_qualification_2"
        case "PREVIOUS_ROLE":
          return "xp_qualification_3"
        case "NEITHER":
          return "xp_qualification_4"
        default:
          return null
      }
    })(postBody.previous_experience)
  }

  // If demo account we never ask certain questions
  if (isDemoSignup) {
    postBody.previous_experience = "NEITHER"

    if (postBody.personal.addr_country === "ZA") {
      postBody.personal.za_province = "EC"
      postBody.personal.id_card = "0000000000000"
    }

    if (postBody.personal.addr_country === "ES") {
      postBody.personal.nif_code = "12345678AB"
      postBody.personal.mothers_maiden_name = "Doe"
    }

    if (postBody.personal.addr_country === "IT") {
      postBody.about.fiscal_code = "XXXXXX12X12X111X"
      postBody.personal.tax_code = "XXXXXX12X12X111X"

      postBody.personal.birth_country = "IT"
      postBody.personal.it_province = "RM"
    }

    if (postBody.personal.addr_country === "DK") {
      postBody.about.cpr = "0000000000"
    }

    if (postBody.knowledge) delete postBody.knowledge
    if (postBody.experience) delete postBody.experience
  }

  if (!isDemoSignup || !signupData.failed) {
    postBody.about.general_knowledge = data.general_knowledge
  }

  // hack to allow the removal of similar fields for ES and IT
  if (isLiveSignup) {
    if (postBody.personal.addr_country === "IT" && postBody.about.nationality === "IT") {
      postBody.about.fiscal_code = postBody.personal.tax_code
    }
    if (postBody.personal.addr_country === "ES" && postBody.about.nationality === "ES") {
      postBody.about.es_tax_id = postBody.personal.nif_code
    }
    if (postBody.personal.addr_country === "BR" && data.cpf_number) {
      postBody.personal.cpf = data.cpf_number.replace(/\D/g, "")
    }
  }

  postBody.personal.opt_in = !!data.opt_in_marketing

  // Referral code
  const refCode = getLocalStorage(LOCAL_STORAGE_REFERRAL_CODE)

  if (refCode) {
    postBody.referral_code = refCode
  }

  // Initial knowledge questions
  if (initialKnowledgeQuestions?.general_knowledge) {
    postBody.about.general_knowledge = initialKnowledgeQuestions.general_knowledge
  }

  // AU extra fields
  if (postBody.personal.addr_country === "AU" && postBody.brand === "au") {
    postBody.personal.day_trading = "same_day"
    postBody.personal.read_privacy_policy = true
    postBody.personal.cfd_long_position = "price_increase_profit_increase"
    postBody.personal.research_understanding = "yes"
    postBody.personal.previous_exp = "yes"
    postBody.personal.market_profits = "profit_50"
  }

  // 'Where Did You Hear About Us?' (capturing where users have come from)
  postBody.personal.promotionalSource = data.where_hear_about_us
  postBody.legal.over_18_privacy_accepted = !!data.age_18_plus
  return postBody as AccountDataType
}

export const upgradeToLive = (signupData: SignupDataType, updateSignupData: (data: SignupDataType) => void) => {
  updateSignupData({
    ...signupData,
    "account-type-selection": { account_type: LIVE }
  })

  goToPage("/signup/description")
}

export const getLastPageUserWasOn = (page: string) => {
  if (!page) {
    return page
  }

  if (page.includes("welcome") || page.includes("login")) {
    return "country-of-residence"
  }

  return page
}

export const updateLastVisitedStep = async (
  signupData: SignupDataType,
  step: string,
  language: Language,
  isLanguageSelector = false
) => {
  const lastStep = getLastPageUserWasOn(step)
  if (!isLanguageSelector) {
    setLocalStorage(LOCAL_STORAGE_LAST_PAGE, lastStep)
  }
  await postIncompleteSignUpInfo({
    incomplete_form: {
      ...signupData,
      last_page_user_was_on: lastStep,
      lastPageUserWasOn: lastStep,
      platform_currencies: {
        platform_currency:
          signupData?.platform_currencies?.platform_currency || getLocalStorage(LOCAL_STORAGE_PLATFORM_CURRENCIES)
      },
      preferred_language: { language_code: language.language_code }
    }
  })
}

export const goToPreviousStep = async (signupData: SignupDataType, prevStep: string, language: Language) => {
  await updateLastVisitedStep(signupData, prevStep, language)
  goToPage(`/signup/${prevStep}`)
}

export const getPreviousStepsAndSections = (
  steps: PrismicSignStepType[]
): {
  sections: SignupSectionType[]
  prevSteps: { [step: string]: string }
} => {
  const { getCurrentSignupData } = useSignupData()
  // Get the previous step of every step and sections
  const firstStep = steps.find((_step) => _step.data.isFirstStep)
  let loopStep = { ...firstStep }
  const prevSteps: { [step: string]: string } = { [firstStep.uid]: "" }
  const sections: SignupSectionType[] = []
  let reachedLastStep = false
  while (!reachedLastStep) {
    // Sections
    const { section, slices } = loopStep?.data || { section: null, slices: [] }
    if (section && !sections.includes(section)) {
      sections.push(section)
    }

    const actionButtons = getActionButtons(slices)
    if (actionButtons.length > 0) {
      const hasSubmitButton = !!actionButtons.find((button) => button.actionType === ActionButtonActionType.Submit)
      if (hasSubmitButton) {
        reachedLastStep = true
      } else {
        const continueButton = actionButtons.find((button) => button.actionType === ActionButtonActionType.Continue)
        let redirectTo = continueButton.defaultRedirect
        continueButton.redirectOptions.forEach((option) => {
          if (compareValidFieldsAndValues(option.conditionalField, option.conditionalValue, getCurrentSignupData())) {
            redirectTo = option.redirectTo
          }
        })
        prevSteps[redirectTo] = loopStep.uid
        loopStep = { ...steps.find((_step) => _step.uid === redirectTo) }
      }
    } else {
      reachedLastStep = true
    }
  }
  return { sections, prevSteps }
}
