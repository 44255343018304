import React from "react"
import { accentGreenColor, mainColor, whiteColor } from "styles"
import { Svg } from "./AnimatedLogo.styled"

type Props = {
  width: number
  height: number
  onClick: () => void
  hasLink?: boolean
}

const AnimatedMobileLogo = ({ width, height, onClick, hasLink }: Props) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 65 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      cursor={hasLink ? "pointer" : "initial"}
    >
      <circle id="background" cx="30" cy="30" r="30" fill={whiteColor} />
      <path
        id="circle1"
        d="M39.6375 56.3018L30.1236 35.8504L20.5786 56.3152L17.3532 49.3994L30.1259 22.0078H30.137L39.6553 42.4214L53.9139 11.8578C51.124 8.18487 47.5251 5.20444 43.3966 3.14797C39.2681 1.0915 34.7211 0.0143433 30.1088 0.000142266C25.4964 -0.0140588 20.9429 1.03508 16.8018 3.06609C12.6607 5.0971 9.04351 8.0553 6.23109 11.711H47.5275L44.7937 17.5723H25.7215L14.1122 42.4681L10.8779 35.5346L19.2529 17.5723H2.6898C0.619905 22.1262 -0.278834 27.1252 0.0753099 32.1149C0.429454 37.1045 2.02525 41.9265 4.71758 46.1424C7.40991 50.3582 11.1134 53.8342 15.4912 56.2543C19.8691 58.6743 24.7824 59.9616 29.7845 59.9992C34.7865 60.0367 39.7187 58.8233 44.1323 56.4692C48.546 54.1152 52.3012 50.6952 55.0566 46.5202C57.8119 42.3453 59.4799 37.5478 59.9089 32.564C60.3379 27.5802 59.5143 22.5683 57.513 17.9839L39.6375 56.3018Z"
        fill={mainColor}
      />
      <path
        id="diamond"
        d="M57.3106 4.98438L60.8363 11.2884L57.5175 18.0017L53.905 11.849L57.3106 4.98438Z"
        fill={accentGreenColor}
      />

      <defs>
        <radialGradient id="shiningGradient" cx="50%" cy="50%" r="20%" fx="50%" fy="50%">
          <stop offset="0%" stopColor="rgba(255,255,255,1)" />
          <stop offset="100%" stopColor="rgba(255,255,255,0.1)" />
        </radialGradient>
      </defs>
      <circle id="circle2" cx="57" cy="11" r="7" fill="#49ceae" />
      <path
        id="diamond"
        d="M57.3106 4.98438L60.8363 11.2884L57.5175 18.0017L53.905 11.849L57.3106 4.98438Z"
        fill={accentGreenColor}
      />
      <circle id="circle3" cx="57" cy="11" r="20" fill="url(#shiningGradient)" />
    </Svg>
  )
}

export default AnimatedMobileLogo
