import React, { ReactNode } from "react"
import { Card } from "@mui/material"
import { useMobileMode } from "hooks"
import { BottomLeft, muiCardManageAccountsStyle } from "./ActionCard.styled"

type Props = {
  children?: ReactNode
  handleClick: () => void
  bottomLeftText?: string
  sx?: object
  isSelected?: boolean
  id?: string
}

const ActionCard = ({ children, handleClick, bottomLeftText, isSelected = false, sx = {}, id }: Props) => {
  const isMobile = useMobileMode()

  return (
    <Card sx={muiCardManageAccountsStyle(isSelected, isMobile, sx)} onClick={handleClick} id={id}>
      {children}
      {bottomLeftText && <BottomLeft>{bottomLeftText}</BottomLeft>}
    </Card>
  )
}

export default ActionCard
