import React, { useContext, useEffect, useState } from "react"
import Image from "next/image"
import Flags from "country-flag-icons/react/3x2"
import { Language, Text } from "components"
import { LanguageContext } from "context"
import { shouldAllowTranslation } from "utils"
import { Context } from "pages/_app"
import {
  LanguageSelectorStyledContainer,
  OptionsContainer,
  SelectorContainer,
  Option,
  languageNameStyle,
  selectedLanguageStyle
} from "./LanguageSelector.styled"
import DownArrowSVG from "./down-arrow.svg"

const LanguageSelector = () => {
  const { jurisdiction } = useContext(Context)
  const { setLanguage, language, supportedLanguages } = useContext(LanguageContext)
  const [selectedLanguage, setSelectedLanguage] = useState(language)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleNewLanguage = (newLang: Language) => {
    setLanguage(newLang)
    setSelectedLanguage(newLang)
    setIsOpen(false)
  }

  useEffect(() => {
    setSelectedLanguage(language)
  }, [language])

  const mapLangsToFlags = (languageCode: string, isOption = false) => {
    const flag = supportedLanguages?.find((lang) => lang.language_code === languageCode)?.flag
    const size = isOption ? 18 : 25
    if (flag) {
      return <Image src={flag.url} width={size} height={size} alt={`${languageCode}-flag`} />
    }
    const flagProps = { height: size, width: size }
    if (languageCode === "en") {
      return <Flags.GB {...flagProps} />
    }
    const FlagComponent = Flags[languageCode.toUpperCase()]
    return FlagComponent ? <FlagComponent {...flagProps} /> : <></>
  }

  if (supportedLanguages?.length < 2 || !shouldAllowTranslation(jurisdiction)) {
    return null
  }

  return (
    <LanguageSelectorStyledContainer>
      {mapLangsToFlags(selectedLanguage.language_code)}
      <SelectorContainer onClick={handleClick}>
        <Text text={selectedLanguage.language_code.toUpperCase()} sx={selectedLanguageStyle} />
        <Image src={DownArrowSVG} alt="down arrow" />

        <OptionsContainer isOpen={isOpen}>
          {isOpen &&
            supportedLanguages &&
            supportedLanguages.length > 1 &&
            supportedLanguages.map((eachLanguage) => (
              <Option onClick={() => handleNewLanguage(eachLanguage)} key={eachLanguage.language_code}>
                {mapLangsToFlags(eachLanguage.language_code, true)}
                <Text
                  text={eachLanguage.language_display_name}
                  sx={{
                    ...languageNameStyle,
                    whiteSpace: "nowrap",
                    fontWeight: selectedLanguage.language_code === eachLanguage.language_code ? 600 : 300
                  }}
                />
              </Option>
            ))}
        </OptionsContainer>
      </SelectorContainer>
    </LanguageSelectorStyledContainer>
  )
}

export default LanguageSelector
