import React, { useState } from "react"
import { IconButton, InputAdornment, InputProps } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { AllPasswordTextfieldTypes, ExtraFieldProps } from "components"
import { Container, PasswordField, passwordFieldStyle, eyeIconStyle } from "./PasswordTextfield.styled"
import { WidgetProps } from "components/pages/auth-widget/auth-widget"

const PasswordTextfield = ({
  currentValue,
  required,
  placeholder,
  handleChange,
  fieldName,
  sx = {},
  widgetprops
}: AllPasswordTextfieldTypes & ExtraFieldProps & { widgetprops?: WidgetProps | WidgetProps[] }) => {
  const [showPassword, setShowPassword] = useState(false)

  const onChange = (e: { target: { value: string } }) => {
    handleChange({ [fieldName]: e.target.value })
  }

  const onPasswordFieldChange = (e: { target: { value: string } }) => {
    onChange(e)
  }

  const inputProps: InputProps = {}
  inputProps.endAdornment = (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
        {showPassword ? <VisibilityIcon sx={eyeIconStyle} /> : <VisibilityOffIcon sx={eyeIconStyle} />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <Container>
      <PasswordField
        id={fieldName}
        label={placeholder}
        variant="outlined"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        color={widgetprops && ("secondaryLightBlue" as any)}
        required={required}
        onChange={onPasswordFieldChange}
        value={currentValue}
        sx={passwordFieldStyle(sx)}
        type={!showPassword ? "password" : "text"}
        InputProps={inputProps}
        widgetprops={widgetprops}
      />
    </Container>
  )
}

export default PasswordTextfield
