import { FieldVariation, TextAlignType } from "components"

export const boxStyle = (variation: FieldVariation, textAlign: TextAlignType = "left") => {
  if (variation === FieldVariation.Checkbox) {
    return {}
  }

  return {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: variation === FieldVariation.CardGroup ? "40px" : "20px",
    textAlign,
    label: { fontSize: "14px", fontWeight: "400" },
    input: { fontSize: "14px" }
  }
}

export const iconButtonStyle = {
  padding: 0
}
