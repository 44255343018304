import * as prismic from "@prismicio/client"
import * as prismicNext from "@prismicio/next"
import slicemachine from "./slicemachine.config.json"

/**
 * The project's Prismic repository name.
 */
export const repositoryName = prismic.getRepositoryName(slicemachine.apiEndpoint)

// Update the routes array to match your project's route structure
/** @type {prismic.ClientConfig['routes']} **/
const routes = [
  {
    type: "signupstep",
    path: "/signup/:uid"
  },
  {
    type: "accountoverview",
    path: "/account/overview"
  },
  {
    type: "accountsettings",
    path: "/account/settings"
  },
  {
    type: "accountcheckemail",
    path: "/account/check-email"
  },
  {
    type: "accountcreatenewpassword",
    path: "/account/create-new-password"
  },
  {
    type: "accountpayments",
    path: "/account/payments"
  },
  {
    type: "accountwithdraw",
    path: "/account/withdraw"
  },
  {
    type: "accountdeposit",
    path: "/account/deposit"
  },
  {
    type: "login",
    path: "/login"
  },
  {
    type: "forgotpassword",
    path: "/login/forgot-password"
  },
  {
    type: "resetpassword",
    path: "/login/reset-password"
  }
]

export function linkResolver(doc: { uid: string; type: string }) {
  const prefix = ""
  const uid = doc.uid

  if (doc.type === "footer" || doc.type === "header" || doc.type === "disclaimer") {
    return `${prefix}/`
  }
  if (doc.type === "homepage") {
    return `${prefix}/`
  }
  if (doc.type === "brand" || doc.type === "compliance" || doc.type === "landing") {
    return `${prefix}/${uid}`
  }
  if (doc.type === "article") {
    return `${prefix}/articles/${uid}`
  }
  if (doc.type === "article_gallery_configuration") {
    return `${prefix}/articles/config-preview`
  }
  return null
}

export const createClient = ({ previewData, req, ...config }: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(slicemachine.apiEndpoint, {
    routes,
    ...config
  })

  prismicNext.enableAutoPreviews({ client, previewData, req })

  return client
}
