import { configureStore } from "@reduxjs/toolkit"
import { loginApi } from "redux/features/loginapi/loginApi"
import { signupApi } from "redux/features/signupapi/signupApi"
import { accountApi } from "redux/features/accountapi/accountApi"
import { oldAccountApi } from "redux/features/accountapi/oldAccountApi"
import { socialsApi } from "redux/features/socialsapi/socials"
import { sumSubAccountsApi, sumSubApi } from "redux/features/sumsub/sumSubApi"

export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [signupApi.reducerPath]: signupApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [oldAccountApi.reducerPath]: oldAccountApi.reducer,
    [sumSubAccountsApi.reducerPath]: sumSubAccountsApi.reducer,
    [sumSubApi.reducerPath]: sumSubApi.reducer,
    [socialsApi.reducerPath]: socialsApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    // return []
    return getDefaultMiddleware().concat(
      loginApi.middleware,
      signupApi.middleware,
      accountApi.middleware,
      oldAccountApi.middleware,
      sumSubAccountsApi.middleware,
      sumSubApi.middleware,
      socialsApi.middleware
    )
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
