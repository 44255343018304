import React from "react"
import Image from "next/image"
import { IconType, validObject } from "utils"

type Props = {
  icon?: IconType
  sx?: object
  onLoad?: () => void
  priority?: boolean
}

const Icon = ({ icon, sx = {}, onLoad, priority = false }: Props) => {
  if (!validObject(icon)) {
    return null
  }

  const { url, alt, dimensions } = icon

  if (!url) {
    return null
  }

  const myLoader = ({ src, width }: { src: string; width: number }) => {
    return `${src}?w=${width}`
  }

  return (
    <Image
      loader={myLoader}
      onLoad={onLoad}
      src={url}
      alt={alt || ""}
      width={dimensions?.width || 100}
      height={dimensions?.height || 100}
      style={sx}
      priority={priority}
    />
  )
}

export default Icon
