import React, { useEffect, useMemo, useCallback, useState } from "react"
import { Autocomplete, Box, Grow, Paper, TextField } from "@mui/material"
import { Loading, ExtraFieldProps, OptionType, SearchfieldType, CountryFlag } from "components"
import { useMobileMode, useSignupData } from "hooks"
import {
  COUNTRY_OF_RESIDENCE_FIELD_NAME,
  GeneralApiFieldType,
  NATIONALITY_FIELD_NAME,
  ObjectWithFieldsType
} from "utils"
import { useGetFieldsInfoQuery } from "redux/features/signupapi/signupApi"
import { textfieldStyle, boxStyle } from "./Searchfield.styled"
import SearchfieldDrawer from "./SearchfieldDrawer"

type Props = SearchfieldType &
  ExtraFieldProps & {
    phoneNumberPrefix?: string
    ignoreJurisdictionChange?: boolean
    disableClearInputFields?: string[]
  }

const Searchfield = ({
  currentValue,
  handleChange,
  placeholder,
  options,
  fieldName,
  api,
  sx = {},
  phoneNumberPrefix,
  countrySearchPlaceholder,
  ignoreJurisdictionChange = false,
  disableClearInputFields = []
}: Props) => {
  const { data: fields = [], isFetching } = useGetFieldsInfoQuery({ url: api as string }, { skip: !api })

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { currentStepSignupData, jurisdiction, updateJurisdiction, updateCurrentStepNextData } = useSignupData()

  const removeClearInputIconFields = [
    COUNTRY_OF_RESIDENCE_FIELD_NAME,
    NATIONALITY_FIELD_NAME,
    ...disableClearInputFields
  ]

  const updateFieldValues = (value: string) => {
    const extraFields: ObjectWithFieldsType = {}
    const previousData = fields.find((field) => field.option.value === currentValue) as GeneralApiFieldType
    if (fieldName === COUNTRY_OF_RESIDENCE_FIELD_NAME) {
      extraFields.previous_jurisdiction = previousData?.jurisdiction || ""
      extraFields.previous_jurisdiction_name = previousData?.jurisdiction_name || ""
    }

    if (!value) {
      handleChange({ [fieldName]: "", ...extraFields })
    } else {
      if (fields.length > 0) {
        const data = fields.find((field) => field.option.value === value) as GeneralApiFieldType
        const { option: opt, banned, ...otherFields } = data || {}

        if (fieldName === COUNTRY_OF_RESIDENCE_FIELD_NAME) {
          if (banned === "true") {
            extraFields.banned_country_message = data.country_of_residence_name
          } else if (currentStepSignupData.banned_nationality === "true") {
            extraFields.banned_country_message = `${currentStepSignupData.nationality_name} citizens`
          } else {
            extraFields.banned_country_message = ""
          }
          extraFields.country_of_residence_name = opt.label
          extraFields.banned_cor = banned
          extraFields.banned = currentStepSignupData.banned_nationality === "true" ? "true" : banned
        } else if (fieldName === NATIONALITY_FIELD_NAME) {
          extraFields.nationality_name = opt.label

          if (banned === "true") {
            extraFields.banned_country_message = `${data.option.label} citizens`
          } else if (currentStepSignupData.banned_cor === "true") {
            extraFields.banned_country_message = currentStepSignupData.country_of_residence_name
          } else {
            extraFields.banned_country_message = ""
          }
          extraFields.banned_nationality = banned
          extraFields.banned = currentStepSignupData.banned_cor === "true" ? "true" : banned
        }

        const newData = {
          ...currentStepSignupData,
          [fieldName]: value || "",
          ...otherFields,
          ...extraFields
        }

        if (
          fieldName === COUNTRY_OF_RESIDENCE_FIELD_NAME &&
          previousData &&
          previousData.jurisdiction &&
          previousData.jurisdiction !== data.jurisdiction &&
          newData.banned === "false" &&
          !ignoreJurisdictionChange
        ) {
          updateCurrentStepNextData(newData)
        } else {
          handleChange(newData)
        }
      } else {
        handleChange({ [fieldName]: value || "", ...extraFields })
      }
    }
  }

  useEffect(() => {
    const data = fields.find((field) => field.option.value === currentValue) as GeneralApiFieldType

    if (data && data.jurisdiction && data.jurisdiction !== jurisdiction) {
      updateJurisdiction(data.jurisdiction)
    }
  }, [currentValue])

  useEffect(() => {
    if (fields.length > 0 && currentValue) {
      updateFieldValues(currentValue)
    }
  }, [fields])

  const selectedValueOnLoad = useMemo(() => {
    return options.find((option) => option.value === currentValue) || null
  }, [])

  const onChange = (event: React.SyntheticEvent, option: OptionType | null) => {
    updateFieldValues(option?.value || "")
  }

  const getOptions = useCallback(() => {
    if (fields.length > 0) {
      return fields.map(({ option }) => ({
        value: option.value,
        label: option.label,
        prefix: <CountryFlag countryCode={option.value} fieldName={fieldName} />
      }))
    }

    return options.map((option) => ({
      value: option.value,
      label: option.label,
      prefix: option.prefix
    }))
  }, [fields, options])

  useEffect(() => {
    if (fields.length > 0) {
      const corFromCache = sessionStorage.getItem("ip_cor")
      if ([COUNTRY_OF_RESIDENCE_FIELD_NAME, NATIONALITY_FIELD_NAME].includes(fieldName) && corFromCache) {
        if (!currentValue) {
          updateFieldValues(corFromCache)
        }
      }
    }
  }, [fieldName, fields, currentValue])

  // Bottom Drawer for mobile
  const isMobile = useMobileMode()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setDrawerOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setDrawerOpen(false)
  }

  const displayDrawer = () => {
    if (isMobile || phoneNumberPrefix) {
      return (
        <SearchfieldDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          optionsList={getOptions()}
          updateFieldValues={updateFieldValues}
          anchorEl={anchorEl}
          handleClose={handleClose}
          countrySearchPlaceholder={countrySearchPlaceholder}
        />
      )
    }

    return null
  }

  const openOptionsList = () => {
    if (isMobile || phoneNumberPrefix) {
      setDrawerOpen(true)
    } else {
      setDropdownOpen(true)
    }
  }

  // This is a phone number searchfield
  if (phoneNumberPrefix) {
    return (
      <>
        <Box
          sx={{ ...boxStyle, ...sx }}
          component="fieldset"
          onClick={handleClick}
          aria-describedby="country-code-popover"
        >
          <legend>{placeholder}</legend>
          <CountryFlag countryCode={currentValue} fieldName={fieldName} margin="0 5px 0 3px" />
          {phoneNumberPrefix}
        </Box>
        {displayDrawer()}
      </>
    )
  }

  if (isFetching) {
    return <Loading />
  }

  return (
    <>
      <Autocomplete
        open={dropdownOpen}
        onOpen={openOptionsList}
        onClose={() => setDropdownOpen(false)}
        value={getOptions().find((option) => option.value === currentValue) || null}
        disablePortal
        options={getOptions()}
        disableClearable={removeClearInputIconFields.includes(fieldName)}
        onChange={onChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        defaultValue={selectedValueOnLoad}
        sx={sx}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            sx={{
              fieldset: textfieldStyle
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment:
                fieldName.includes("country") || fieldName.includes("nationality") ? (
                  <CountryFlag countryCode={currentValue} fieldName={fieldName} margin="0 6px" />
                ) : null
            }}
          />
        )}
        PaperComponent={(props) => (
          <Grow in>
            <Paper {...props} />
          </Grow>
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <CountryFlag countryCode={option.value} fieldName={fieldName} margin="0 10px 0 0" />
            {option.label}
          </li>
        )}
      />
      {displayDrawer()}
    </>
  )
}

export default Searchfield
