import React from "react"
import { Icon } from "components"
import { IconType } from "utils"
import { iconStyle } from "./PlatformBanner.styled"

type Props = {
  onLoad?: () => void
  image: IconType
  alt: string
  borderTopLeftRadius?: string
  borderTopRightRadius?: string
  priority?: boolean
}

const PlatformBanner = ({
  onLoad = () => {},
  image,
  alt,
  borderTopLeftRadius = "0",
  borderTopRightRadius = "0",
  priority
}: Props) => {
  return (
    <Icon
      onLoad={onLoad}
      icon={{
        url: image.url,
        alt,
        dimensions: {
          width: image.dimensions.width,
          height: image.dimensions.height
        }
      }}
      priority={priority}
      sx={iconStyle(borderTopLeftRadius, borderTopRightRadius)}
    />
  )
}

export default PlatformBanner
