import { Apple, Google, LinkedIn } from "@mui/icons-material"
import React from "react"
import { getSocialLogin } from "utils/auth0"

type WidgetSignupSocialButtonsProps = {
  socialType: string
  onClick: () => void
}

const WidgetSignupSocialButtons = ({ socialType, onClick }: WidgetSignupSocialButtonsProps) => (
  <>
    {socialType === "Google" && (
      <Google
        sx={{
          color: "white",
          mr: "18px"
        }}
        onClick={async () => {
          onClick()
          await getSocialLogin("google-oauth2")
        }}
      />
    )}
    {socialType === "LinkedIn" && (
      <LinkedIn
        sx={{ color: "white", mr: "18px", marginLeft: "7px" }}
        onClick={async () => {
          onClick()
          await getSocialLogin("custom-linkedin")
        }}
      />
    )}
    {socialType === "Apple" && (
      <Apple
        sx={{ color: "white", mr: "18px", marginLeft: "-8px" }}
        onClick={async () => {
          onClick()
          await getSocialLogin("apple")
        }}
      />
    )}
  </>
)

export default WidgetSignupSocialButtons
