import React, { useMemo, useState } from "react"
import * as Sentry from "@sentry/react"
import { Elements } from "prismic-reactjs"
import {
  Loading,
  GoogleButton,
  LinkedInButton,
  SocialNameType,
  AppleButton,
  FacebookButton,
  SocialButtonType
} from "components"
import { WidgetSocialButtons } from "components/socialbuttons/WidgetSocialButtons"
import { WidgetProps } from "components/pages/auth-widget/auth-widget"
import { useModal, useSignupData } from "hooks"
import { LOCAL_STORAGE_ACCESS_TOKEN, SliceType, getRichText, getSocialButtons, goToPage, setLocalStorage } from "utils"
import { useCheckAccountMutation } from "redux/features/loginapi/loginApi"
import { getSocialSignupData, SocialSignupDataType } from "pages/api/signup"

type Props = {
  slices: SliceType[]
  widgetprops?: WidgetProps
  isSignupWidget?: boolean
}

const SocialButtons = ({ slices, widgetprops, isSignupWidget }: Props) => {
  const { signupData, updateSignupData } = useSignupData()

  const socialButtons = useMemo(() => getSocialButtons(slices), [slices])

  const { openModal, displayModal } = useModal()

  const [loading, setLoading] = useState(false)
  const openErrorModal = () =>
    openModal({
      title: getRichText([
        {
          text: "FAIL",
          type: Elements.heading2
        }
      ]),
      text: getRichText([
        {
          text: "Something went wrong when trying to get your data.",
          type: Elements.paragraph
        },
        {
          text: "Please try again later.",
          type: Elements.paragraph
        }
      ]),
      handleClose: () => setLoading(false)
    })

  const [checkAccount, { isLoading: checkAccountLoading }] = useCheckAccountMutation()
  const onSuccess = async (data: SocialSignupDataType) => {
    const { email, firstName, lastName, birthday, telephone, address } = data

    try {
      const result = await checkAccount({
        body: { email }
      }).unwrap()

      if (result.access_token) {
        setLocalStorage(LOCAL_STORAGE_ACCESS_TOKEN, result.access_token)
        goToPage("/account/overview")
      } else {
        const socialSignupData = await getSocialSignupData({
          email,
          firstName,
          lastName,
          birthday,
          telephone,
          address
        })
        updateSignupData({ ...signupData, ...socialSignupData })
      }
    } catch (error) {
      openErrorModal()
      Sentry.captureException(error)
    }
  }

  const buttonProps = (button: SocialButtonType) => ({
    onClick: () => setLoading(true),
    onFail: openErrorModal,
    onSuccess,
    icon: button.icon,
    name: button.name,
    type: button.type
  })

  const buttonsProps = () => ({
    onClick: () => setLoading(true)
  })

  const displayGoogleButton = () => {
    const button = socialButtons.find((b) => b.type === SocialNameType.Google)

    if (button && button.display) {
      return <GoogleButton {...buttonProps(button)} isSignupWidget={isSignupWidget} />
    }
    return null
  }

  const displayAppleButton = () => {
    const button = socialButtons.find((b) => b.type === SocialNameType.Apple)

    if (button && button.display) {
      return <AppleButton {...buttonProps(button)} isSignupWidget={isSignupWidget} />
    }
    return null
  }

  const displayFacebookButton = () => {
    const button = socialButtons.find((b) => b.type === SocialNameType.Facebook)

    if (button && button.display) {
      return <FacebookButton {...buttonProps(button)} isSignupWidget={isSignupWidget} />
    }
    return null
  }

  const displayLinkedInButton = () => {
    const button = socialButtons.find((b) => b.type === SocialNameType.LinkedIn)

    if (button && button.display) {
      return <LinkedInButton {...buttonProps(button)} isSignupWidget={isSignupWidget} />
    }
    return null
  }
  const displayWidgetSocials = () => {
    return <WidgetSocialButtons {...buttonsProps()} />
  }

  return (
    <>
      {widgetprops ? (
        <>
          {displayWidgetSocials()}
          {displayModal()}
          {(loading || checkAccountLoading) && <Loading />}
        </>
      ) : (
        <>
          {displayGoogleButton()}
          {displayAppleButton()}
          {displayFacebookButton()}
          {displayLinkedInButton()}
          {displayModal()}
          {(loading || checkAccountLoading) && <Loading />}
        </>
      )}
    </>
  )
}

export default SocialButtons
