import React, { useEffect, useState, useMemo, useCallback, useContext } from "react"
import * as Sentry from "@sentry/react"
import { Elements } from "prismic-reactjs"
import { isMobile } from "react-device-detect"
import {
  AppMetadataType,
  clearLocalStorage,
  DEMO,
  FULL,
  LIVE,
  LOCAL_STORAGE_ACCESS_TOKEN,
  removeUrlParam,
  TradingAccountType,
  UserMetadataType,
  getRichText,
  clearAllSignupData,
  MT4,
  CLOUDTRADE,
  SUCCESS,
  LOCAL_STORAGE_USER_INFO,
  LOCAL_STORAGE_USER_INFO_FORCE_UPDATE,
  LOCAL_STORAGE_USER_INFO_TS,
  SPREAD,
  CFD,
  goToLogin
} from "utils"
import { useModal, useSignupData } from "hooks"
import { AddAccountProps } from "redux/types"
import accountApi, {
  useAddAccountMutation,
  useCloudtradeAccountDataMutation,
  useSwapAccountMutation,
  useWithdrawMutation
} from "redux/features/accountapi/accountApi"
import { Context } from "pages/_app"
import { useDispatch } from "react-redux"

export const useAccountData = () => {
  const {
    accountData,
    updateAccountData,
    selectedAccount,
    updateSelectedAccount,
    brands,
    updateBrands,
    kycModalData,
    updateKycModalData
  } = React.useContext(Context)
  const isLoggedIn = useCallback(
    () => Object.keys(accountData).length || localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN),
    [accountData]
  )
  const dispatch = useDispatch()

  // Clear all data
  const { updateSignupData } = useSignupData()

  const removeCookie = (cookieName: string) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  }

  const clearAllData = () => {
    // Clear cache and signupData when going to welcome page
    removeCookie("appSession.0")
    removeCookie("appSession.1")
    clearAllSignupData()
    localStorage.removeItem("access_token")
    sessionStorage.removeItem("access_token")
    removeCookie("access_token") // remove platform login cookie on logout
    updateSignupData({})
  }

  // const ifCookieAcceptedNukePostHog = () => {
  //   const cookies = document.cookie.split("; ")
  //   const optanonCookie = cookies.find((cookie) => cookie.startsWith("actualOptanonConsent="))
  //   if (optanonCookie) {
  //     const cookieValue = decodeURIComponent(optanonCookie.split("=")[1])
  //     if (cookieValue.includes("C0002")) {
  //       posthog.reset()
  //     }
  //   }
  // }
  // Logout
  const logout = () => {
    removeUrlParam("m")
    updateAccountData({})
    clearAllData()
    clearLocalStorage(LOCAL_STORAGE_USER_INFO)
    clearLocalStorage(LOCAL_STORAGE_USER_INFO_TS)
    clearLocalStorage(LOCAL_STORAGE_USER_INFO_FORCE_UPDATE)
    // ifCookieAcceptedNukePostHog()
    goToLogin()

    // Add posthog reset event to GTM dataLayer
    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    // @ts-ignore
    window.dataLayer.push({
      event: "posthogReset"
    })

    dispatch(accountApi.util.resetApiState())
  }

  // Error modal
  const { openModal, displayModal } = useModal()
  const handleModalClose = () => {
    goToLogin()
  }
  const openUserErrorModal = (
    title = "Unauthorised",
    text = ["There was a problem logging into the application.", "Please sign-out and sign-in again."]
  ) => {
    openModal({
      title: getRichText([
        {
          text: title,
          type: Elements.heading2
        }
      ]),
      text: getRichText(
        text.map((line: string) => ({
          text: line,
          type: Elements.paragraph
        }))
      ),
      handleClose: handleModalClose,
      buttons: [
        {
          text: "OK",
          onClick: logout
        }
      ],
      zIndex: 1400
    })
  }

  // Brands
  const [isEuBrand, setIsEuBrand] = useState<boolean>(false)
  const [liveAccounts, setLiveAccounts] = useState<TradingAccountType[]>([])
  const [tnAccounts, setTnAccounts] = useState<TradingAccountType[]>([])
  const [tnCFDAccounts, setTnCFDAccounts] = useState<TradingAccountType[]>([])
  const [tnSpreadAccounts, setTnSpreadAccounts] = useState<TradingAccountType[]>([])
  const [mt4Accounts, setMt4Accounts] = useState<TradingAccountType[]>([])
  const [demoMt4Accounts, setDemoMt4Accounts] = useState<TradingAccountType[]>([])
  const [demoAccounts, setDemoAccounts] = useState<TradingAccountType[]>([])
  const [userMetadata, setUserMetadata] = useState<UserMetadataType>()
  const [appMetadata, setAppMetadata] = useState<AppMetadataType>()
  const accountType = useMemo(() => appMetadata?.clients[0].type, [appMetadata])
  const hasLiveAccountNotApproved = useMemo(
    () => appMetadata?.clients[0].type === LIVE && appMetadata?.clients[0].state !== FULL,
    [appMetadata]
  )

  useEffect(() => {
    if (accountData?.app_metadata) {
      // Live and Demo accounts
      const tradingAccounts = accountData.app_metadata.trading_accounts
      setLiveAccounts(tradingAccounts.filter((ta) => ta.type === LIVE))
      setDemoAccounts(tradingAccounts.filter((ta) => ta.type === DEMO))
      // App Metadata
      setAppMetadata(accountData.app_metadata)
    }
    // User Metadata
    if (accountData?.user_metadata) {
      setUserMetadata(accountData.user_metadata)
    }
  }, [accountData])

  useEffect(() => {
    const mt4AccountsList = liveAccounts.filter((acc) => acc.backend.type === MT4)
    setMt4Accounts(mt4AccountsList)
  }, [liveAccounts])

  useEffect(() => {
    const demoMt4AccountsList = demoAccounts.filter((acc) => acc.backend.type === MT4)
    setDemoMt4Accounts(demoMt4AccountsList)
  }, [demoAccounts])

  useEffect(() => {
    const tnAccountsList = liveAccounts.filter((acc) => acc.backend.type === CLOUDTRADE)
    setTnAccounts(tnAccountsList)
  }, [liveAccounts])

  useEffect(() => {
    setTnCFDAccounts(tnAccounts.filter((acc) => acc.backend?.derivative_type?.includes(CFD)))
    setTnSpreadAccounts(
      tnAccounts.filter((acc) => acc.backend?.derivative_type?.includes(SPREAD) || !acc.backend.derivative_type)
    )
  }, [tnAccounts])

  useEffect(() => {
    if (brands?.available[0]?.brand === "eu") {
      setIsEuBrand(true)
    }
  }, [brands])

  // Add account
  const [addAccountApi, { isLoading: addAccountLoading }] = useAddAccountMutation()
  const { updatePromptMessage } = useSignupData()
  const addAccount = async (body: AddAccountProps, handleClose: () => void) => {
    let success = false
    let message = "There was an error creating the account."

    const result = await addAccountApi({ body }).unwrap()
    if (result.status === SUCCESS || result?.external_id) {
      success = true
      message = `Account (#${result.external_id}) has been created.`
      handleClose()
    }

    updatePromptMessage({ message, success })
  }

  // Payments
  const getSelectedAccountData = () => {
    return liveAccounts.find((account) => account.id === selectedAccount)
  }

  // Withdraws
  const [withdrawApi, { isLoading: withdrawLoading }] = useWithdrawMutation()
  const { validationMessages } = useContext(Context)
  const withdraw = async (accountId: number, amount: string) => {
    try {
      const result = await withdrawApi({
        accountId,
        body: { amount: Number(amount) }
      }).unwrap()

      if (!result) {
        return { statusCode: 202 }
      }

      if (result.statusCode !== 202) {
        updatePromptMessage({ message: result.message, success: false })
      }

      return result
    } catch (error) {
      const {
        data: { message },
        status
      } = error
      if (status === 429) {
        updatePromptMessage({
          message: validationMessages.cannot_withdraw,
          success: false
        })
      } else if (status === 403) {
        updatePromptMessage({
          message: validationMessages.insufficient_funds,
          success: false
        })
      } else {
        updatePromptMessage({ message, success: false })
      }
      Sentry.captureException(error)

      return { statusCode: status, message }
    }
  }

  // Swap Free Account
  const [swapAccountApi, { isLoading: swapAccountLoading }] = useSwapAccountMutation()

  const swapFailed = (message = "") => {
    if (message) {
      updatePromptMessage({
        message: message,
        success: false
      })
    }
  }

  const getSwapAccountMessage = (status?: number, message?: string) => {
    if ([400, 404].includes(status)) {
      return message
    }

    return "There was a problem trying to swap the accounts. Please try again later."
  }

  const swapAccount = async () => {
    const body = {
      account_id: 0,
      is_at_client_level: true,
      accepted_terms_and_conditions: true
    }

    try {
      const result = await swapAccountApi({ body }).unwrap()

      if (result?.success) {
        return true
      } else {
        swapFailed(getSwapAccountMessage(result?.status, result?.data?.message))
        return false
      }
    } catch (error) {
      swapFailed(getSwapAccountMessage(null, ""))
      Sentry.captureException(error)
      return false
    }
  }

  // Trading View Connect
  const shouldDisplayTradingViewButton = tnAccounts.length > 0

  const connectTradingView = () => {
    window.open("https://www.tradingview.com/chart/?trade-now=TRADENATION", "_blank")
  }

  // Cloudtrade
  const [cloudtradeAccountData, { isLoading: cloudtradeAccountDataLoading }] = useCloudtradeAccountDataMutation()

  const cloudtradeAccountDataFail = () => {
    openModal({
      title: getRichText([
        {
          text: "Unauthorised",
          type: Elements.heading2
        }
      ]),
      text: getRichText([
        {
          text: "Error retrieving cloudtrade credentials.",
          type: Elements.paragraph
        }
      ]),
      handleClose: logout
    })
  }

  const getCloudtradeAccountData = async (accountId: number, accType: string, isFeatFlagTrue: boolean) => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
    if (!accessToken) {
      cloudtradeAccountDataFail()
    } else {
      // TN TRADER FEATURE FLAG ROUTING
      if (isFeatFlagTrue && accType === "Spread") {
        localStorage.setItem("selected_account", accountId.toString())
        return window.open(`${process.env.NEXT_PUBLIC_HOST_NAME}platform/`, "_blank")
      }
      // TN TRADER FEATURE FLAG ROUTING
      try {
        const body = { account_id: accountId }
        const res = await cloudtradeAccountData({ body }).unwrap()
        if (res.url) {
          const { url } = res
          if (isMobile) {
            window.location.href = `${process.env.NEXT_PUBLIC_HOST_NAME}account/open-app?link=${url}`
          } else {
            window.open(url, "_blank")
          }
        }
      } catch (error) {
        Sentry.captureException(error)
        cloudtradeAccountDataFail()
      }
    }
  }

  // KYC Modal
  const updateKycModalDisplay = ({ display }: { display?: boolean } = {}) => {
    if (userMetadata) {
      const { kyc_status: kycStatus, kyc_reminder_sent: kycReminderSent } = userMetadata?.about || {}

      const kycDue = ["kyc due", "kyc overdue"].includes(kycStatus?.toLowerCase())

      updateKycModalData({
        kycDue,
        display: display !== undefined ? display : kycDue,
        dismissible: !kycReminderSent
      })
    }
  }

  return {
    accountData,
    updateAccountData,
    accountType,
    hasLiveAccountNotApproved,
    liveAccounts,
    demoAccounts,
    allAccounts: [...liveAccounts, ...demoAccounts],
    tnAccounts,
    tnCFDAccounts,
    tnSpreadAccounts,
    mt4Accounts,
    demoMt4Accounts,
    userMetadata,
    appMetadata,
    brands,
    updateBrands,
    selectedAccount,
    getSelectedAccountData,
    updateSelectedAccount,
    addAccount,
    addAccountLoading,
    withdraw,
    withdrawLoading,
    logout,
    clearAllData,
    displayErrorModal: displayModal,
    swapAccount,
    swapAccountLoading,
    shouldDisplayTradingViewButton,
    connectTradingView,
    getCloudtradeAccountData,
    isEuBrand,
    cloudtradeAccountDataLoading,
    openUserErrorModal,
    isLoggedIn,
    kycModalData,
    updateKycModalDisplay
  }
}
