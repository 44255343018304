import React, { useState, useMemo, useEffect, useContext } from "react"
import {
  PhoneTextfieldType,
  ExtraFieldProps,
  Loading,
  Textfield,
  Searchfield,
  FieldVariation,
  CountryFlag
} from "components"
import { CountryApiType, ObjectWithFieldsType, fieldValidation, getCxdRef, getFieldValue } from "utils"
import { Row } from "styles"
import { Context } from "pages/_app"
import { useGetFieldsInfoQuery } from "redux/features/signupapi/signupApi"
import { PhonePrefix, searchfieldStyle } from "./PhoneTextfield.styled"

const PhoneTextfield = (props: PhoneTextfieldType & ExtraFieldProps) => {
  const { validationMessages } = useContext(Context)
  const {
    currentValue,
    required,
    placeholder,
    countrySelectorPlaceholder,
    countrySearchPlaceholder,
    handleChange,
    fieldName,
    signupData,
    setErrorMessage
  } = props
  // Fetch the list of countries to get the country prefix and flag
  const { data = [], isFetching } = useGetFieldsInfoQuery({
    url: "finsa/countries",
    affiliate_id: getCxdRef()! || ""
  })
  const countries = useMemo(
    () =>
      (data as unknown as CountryApiType[]).map((d) => ({
        code: d.option.value,
        label: d.option.label,
        phonePrefix: d.phone_number_prefix
      })),
    [data]
  )

  const [selectedCountry, setSelectedCountry] = useState(null)
  useEffect(() => {
    if (!selectedCountry) {
      let phoneCountryCode = getFieldValue(signupData, "countryCode") as string
      if (!phoneCountryCode) {
        phoneCountryCode = getFieldValue(signupData, "country_of_residence")
      }

      setSelectedCountry(countries.find((c) => c.code === phoneCountryCode))
    }
  }, [countries, signupData])

  useEffect(() => {
    if (selectedCountry && selectedCountry.code) {
      handleChange({
        phone_country_code: {
          countryCode: selectedCountry.code
        }
      })
    }
  }, [selectedCountry])

  const onChange = (value: ObjectWithFieldsType) => {
    const country = {
      countryCode: selectedCountry.code as string
    }
    const finalValue = {
      ...value,
      [fieldName]: `${selectedCountry?.phonePrefix}${value[fieldName]}`,
      phone_country_code: country
    }

    handleChange(finalValue)
    setErrorMessage(fieldValidation(props, finalValue[fieldName], signupData, validationMessages, required))
  }

  const onCountryChange = (value: ObjectWithFieldsType) => {
    setSelectedCountry(countries.find((c) => c.code === value.countryCode))
    handleChange({ [fieldName]: "" })
  }

  return (
    <>
      <Row wrap="initial">
        <Searchfield
          currentValue={selectedCountry?.code}
          handleChange={onCountryChange}
          placeholder={countrySelectorPlaceholder}
          countrySearchPlaceholder={countrySearchPlaceholder}
          options={countries.map((c) => ({
            value: c.code,
            label: c.label,
            prefix: (
              <PhonePrefix>
                <CountryFlag countryCode={c.code} fieldName="country" />
                {c.phonePrefix}
              </PhonePrefix>
            )
          }))}
          phoneNumberPrefix={selectedCountry?.phonePrefix}
          fieldName="countryCode"
          controlledFieldsDisplay={[]}
          required={required}
          variation={FieldVariation.Searchfield}
          sx={searchfieldStyle}
        />
        <Textfield
          currentValue={currentValue.replace(selectedCountry?.phonePrefix, "")}
          handleChange={onChange}
          placeholder={placeholder}
          required={false}
          fieldName={fieldName}
          controlledFieldsDisplay={[]}
          testid="phone-textfield"
        />
      </Row>
      {isFetching && <Loading />}
    </>
  )
}

export default PhoneTextfield
