import { useContext, useState } from "react"
import { Context } from "pages/_app"
import { ActionButtonActionType, FieldVariation } from "components"
import {
  COUNTRY_OF_RESIDENCE_FIELD_NAME,
  CURRENT_STEP_NUMBER_OF_PARTS,
  CURRENT_STEP_PART,
  ObjectWithFieldsType,
  SignupDataType,
  SliceType,
  compareValidFieldsAndValues,
  getActionButtons,
  getAddressFields,
  getFieldValue,
  getFieldsAndInfoBoxes,
  goToPage,
  // postHogStepInit,
  sendMessageToWindow,
  updateLastVisitedStep
} from "utils"
import { LanguageContext } from "context/LanguageContext"

export const useSignupData = () => {
  const {
    signupData,
    updateSignupData,
    jurisdiction,
    updateJurisdiction,
    stepUid,
    updateStepUid,
    currentStepNextData,
    updateCurrentStepNextData,
    currentStepSignupData,
    updateCurrentStepSignupData,
    bottomAreaHeight,
    updateBottomAreaHeight,
    promptMessage,
    updatePromptMessage
  } = useContext(Context)
  const { language } = useContext(LanguageContext)

  const [savingDataLoading, setSavingDataLoading] = useState(false)

  const initializeStep = (slices: SliceType[], skipUpdateSignupData = false) => {
    const { fields } = getFieldsAndInfoBoxes(slices)

    const getFields = () => {
      return fields.reduce((acc: ObjectWithFieldsType, field) => {
        const { fieldName, defaultValue, variation } = field
        const newFieldValues: ObjectWithFieldsType = {
          [fieldName]: getFieldValue(signupData, fieldName)
        }
        // Populate the field with default value when it's not getting a value from local storage
        if (!newFieldValues[fieldName] && defaultValue) {
          newFieldValues[fieldName] = defaultValue
        }
        // Address field names
        if (variation === FieldVariation.AddressSearchfield) {
          const countryOfResidence = getFieldValue(signupData, COUNTRY_OF_RESIDENCE_FIELD_NAME)
          const { addressLine1, addressLine2, addressCity, addressPostcode, gender } = getAddressFields(fieldName)
          newFieldValues[addressLine1] = getFieldValue(signupData, addressLine1)
          newFieldValues[addressLine2] = getFieldValue(signupData, addressLine2)
          newFieldValues[addressCity] = getFieldValue(signupData, addressCity)
          newFieldValues[addressPostcode] = getFieldValue(signupData, addressPostcode)
          if (countryOfResidence === "ZA") {
            newFieldValues[gender] = getFieldValue(signupData, gender)
          } else if (countryOfResidence === "IT") {
            newFieldValues[gender] = getFieldValue(signupData, gender)
          }
          delete newFieldValues[fieldName]
        }
        return {
          ...acc,
          ...newFieldValues
        }
      }, {})
    }

    if (!skipUpdateSignupData) {
      updateCurrentStepSignupData({
        ...getFields()
      })
    }
  }

  const getCurrentSignupData = (includeStepNextData = true) => {
    const currentStepData = signupData ? signupData[stepUid] || {} : {}
    return {
      ...signupData,
      [stepUid]: {
        ...currentStepData,
        ...currentStepSignupData,
        ...(includeStepNextData ? currentStepNextData : {})
      }
    }
  }

  const continueToNextStep = async (
    slices: SliceType[],
    extraData: SignupDataType = {},
    isWidget = false,
    redirect = true
  ) => {
    setSavingDataLoading(true)
    const actionButtons = getActionButtons(slices)

    const continueButton = actionButtons.find((button) => button.actionType === ActionButtonActionType.Continue)
    const { defaultRedirect, redirectOptions } = continueButton

    const currentSignupData = {
      ...getCurrentSignupData(),
      ...extraData
    }

    if (Object.keys(currentSignupData[stepUid]).length > 0 || Object.keys(signupData || {}).length === 0) {
      updateSignupData(currentSignupData)
    }

    if (redirect) {
      let redirected = false

      redirectOptions.forEach(async (option) => {
        if (compareValidFieldsAndValues(option.conditionalField, option.conditionalValue, currentSignupData)) {
          redirected = true
          await updateLastVisitedStep(currentSignupData, option.redirectTo, language)
          const redirectTarget = `/signup/${option.redirectTo}`
          if (isWidget) {
            sendMessageToWindow({
              action: "signup",
              data: {
                redirectTarget
              }
            })
          } else {
            goToPage(redirectTarget)
          }
        }
      })

      if (!redirected) {
        await updateLastVisitedStep(currentSignupData, defaultRedirect, language)
        const redirectTarget = `/signup/${defaultRedirect}`

        if (isWidget) {
          sendMessageToWindow({
            action: "signup",
            data: {
              redirectTarget
            }
          })
        } else {
          goToPage(redirectTarget)
        }
      }
    }

    setSavingDataLoading(false)
  }

  const currentStepPart = currentStepSignupData[CURRENT_STEP_PART] || 0

  const updateCurrentStepPart = (data: number) => {
    updateCurrentStepSignupData({
      ...currentStepSignupData,
      [CURRENT_STEP_PART]: data
    })

    updateSignupData({
      ...signupData,
      [stepUid]: {
        ...signupData[stepUid],
        [CURRENT_STEP_PART]: data
      }
    })

    // Post Hog
    // postHogStepInit()
  }

  const currentStepNumberOfParts = currentStepSignupData[CURRENT_STEP_NUMBER_OF_PARTS] || 1

  const updateCurrentStepNumberOfParts = (data: number) => {
    updateCurrentStepSignupData({
      ...currentStepSignupData,
      [CURRENT_STEP_NUMBER_OF_PARTS]: data
    })
  }

  return {
    signupData,
    updateSignupData,
    jurisdiction,
    updateJurisdiction,
    stepUid,
    updateStepUid,
    currentStepSignupData,
    updateCurrentStepSignupData,
    initializeStep,
    getCurrentSignupData,
    currentStepPart,
    updateCurrentStepPart,
    currentStepNumberOfParts,
    currentStepNextData,
    updateCurrentStepNextData,
    updateCurrentStepNumberOfParts,
    continueToNextStep,
    bottomAreaHeight,
    updateBottomAreaHeight,
    savingDataLoading,
    promptMessage,
    updatePromptMessage
  }
}
