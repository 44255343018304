import styled from "@emotion/styled"
import { blackColor, darkBlueColor, lightGreyColor } from "styles"

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
`

export const Content = styled.div<{
  padding: string
  width: string
  titleMargin: string
  textAlign: string
  textFontSize: string
  textLineHeight: string
}>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  overflow-y: auto;

  h2 {
    text-align: center;
    color: ${blackColor};
    font-weight: 700;
    margin: ${(props) => props.titleMargin};
  }

  p,
  li {
    font-size: ${(props) => props.textFontSize};
    line-height: ${(props) => props.textLineHeight};
    font-weight: 400;
    color: ${blackColor};
    text-align: ${(props) => props.textAlign};
  }
`

export const MenuArea = styled.div`
  background-color: ${lightGreyColor};
  padding: 80px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 230px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }

  h2 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
  }
`

export const ButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`

export const actionButtonStyle = {
  flex: 1,
  borderRadius: "85px"
}

export const dialogPaperStyle = (backgroundColor: string, width: string, maxHeight?: string, overflowY?: string) => ({
  backgroundColor,
  width,
  maxWidth: "initial",
  maxHeight: maxHeight || "min(95%, 740px)",
  position: "relative",
  borderRadius: "20px",
  overflowY: overflowY || "auto"
})

export const closeIconStyle = {
  color: darkBlueColor
}

export const iconButtonStyle = {
  position: "absolute",
  top: "9px",
  right: "9px"
}
