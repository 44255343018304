export const COUNTRIES = [
  { label: "Aland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bonaire, Saint Eustatius and Saba", value: "BQ" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Cote d'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curacao", value: "CW" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and McDonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory", value: "PS" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Saint Bartelemey", value: "BL" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Martin", value: "MF" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Taiwan", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Venezuela", value: "VE" },
  { label: "Vietnam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Zambia", value: "ZM" }
]

export const getCountry = (countryCode: string) => {
  return COUNTRIES.find((country) => country.value === countryCode)?.value || ""
}

type CountryToJurisdictionMapping = {
  [key: string]: {
    country: string
    jurisdiction: string
  }
}

export const countryToJurisdictionMapping: CountryToJurisdictionMapping = {
  "42": {
    country: "Gaza Strip",
    jurisdiction: "FSA"
  },
  FI: {
    country: "Finland",
    jurisdiction: "SCB"
  },
  DK: {
    country: "Denmark",
    jurisdiction: "SCB"
  },
  NZ: {
    country: "New Zealand",
    jurisdiction: "FSA"
  },
  SE: {
    country: "Sweden",
    jurisdiction: "SCB"
  },
  NO: {
    country: "Norway",
    jurisdiction: "SCB"
  },
  AD: {
    country: "Andorra",
    jurisdiction: "SCB"
  },
  IS: {
    country: "Iceland",
    jurisdiction: "SCB"
  },
  SM: {
    country: "San Marino",
    jurisdiction: "SCB"
  },
  AX: {
    country: "Åland Islands",
    jurisdiction: "SCB"
  },
  SJ: {
    country: "Svalbard and Mayen",
    jurisdiction: "SCB"
  },
  TK: {
    country: "Tokelau",
    jurisdiction: "SCB"
  },
  FO: {
    country: "Faroe islands",
    jurisdiction: "SCB"
  },
  SG: {
    country: "Singapore",
    jurisdiction: "FSA"
  },
  GL: {
    country: "Greenland",
    jurisdiction: "SCB"
  },
  EE: {
    country: "Estonia",
    jurisdiction: "SCB"
  },
  AU: {
    country: "Australia",
    jurisdiction: "ASIC"
  },
  UY: {
    country: "Uruguay",
    jurisdiction: "FSA"
  },
  VA: {
    country: "Vatican City State (Holy See)",
    jurisdiction: "SCB"
  },
  IE: {
    country: "Ireland",
    jurisdiction: "SCB"
  },
  DE: {
    country: "Germany",
    jurisdiction: "SCB"
  },
  NL: {
    country: "Netherlands",
    jurisdiction: "SCB"
  },
  FR: {
    country: "France",
    jurisdiction: "SCB"
  },
  CH: {
    country: "Switzerland",
    jurisdiction: "SCB"
  },
  BM: {
    country: "Bermuda",
    jurisdiction: "FSA"
  },
  CX: {
    country: "Christmas Island",
    jurisdiction: "FSA"
  },
  CC: {
    country: "Cocos (Keeling) Islands",
    jurisdiction: "FSA"
  },
  NF: {
    country: "Norfolk Island",
    jurisdiction: "FSA"
  },
  PF: {
    country: "French Polynesia",
    jurisdiction: "FSA"
  },
  GP: {
    country: "Guadeloupe",
    jurisdiction: "FSA"
  },
  YT: {
    country: "Mayotte",
    jurisdiction: "FSA"
  },
  NC: {
    country: "New Caledonia",
    jurisdiction: "FSA"
  },
  BL: {
    country: "Saint Barthélemy",
    jurisdiction: "FSA"
  },
  MF: {
    country: "Saint Martin (French part)",
    jurisdiction: "FSA"
  },
  PM: {
    country: "Saint Pierre and Miquelon",
    jurisdiction: "FSA"
  },
  WF: {
    country: "Wallis and Futuna",
    jurisdiction: "FSA"
  },
  GF: {
    country: "French Guiana",
    jurisdiction: "FSA"
  },
  BN: {
    country: "Brunei Darussalam",
    jurisdiction: "FSA"
  },
  LU: {
    country: "Luxembourg",
    jurisdiction: "SCB"
  },
  MQ: {
    country: "Martinique",
    jurisdiction: "FSA"
  },
  RE: {
    country: "Réunion",
    jurisdiction: "FSA"
  },
  IO: {
    country: "British Indian Ocean Territory",
    jurisdiction: "FSA"
  },
  FK: {
    country: "Falkland Islands (Malvinas)",
    jurisdiction: "FSA"
  },
  PN: {
    country: "Pitcairn",
    jurisdiction: "FSA"
  },
  SH: {
    country: "Saint Helena, Ascension and Tristan",
    jurisdiction: "FSA"
  },
  GB: {
    country: "United Kingdom",
    jurisdiction: "FCA"
  },
  LT: {
    country: "Lithuania",
    jurisdiction: "SCB"
  },
  AT: {
    country: "Austria",
    jurisdiction: "SCB"
  },
  BE: {
    country: "Belgium",
    jurisdiction: "Banned"
  },
  BQ: {
    country: "Bonaire, Sint Eustatius and Saba",
    jurisdiction: "FSA"
  },
  CA: {
    country: "Canada",
    jurisdiction: "Banned"
  },
  PR: {
    country: "Puerto Rico",
    jurisdiction: "Banned"
  },
  GG: {
    country: "Guernsey ",
    jurisdiction: "SCB"
  },
  JP: {
    country: "Japan",
    jurisdiction: "FSA"
  },
  TW: {
    country: "Taiwan",
    jurisdiction: "FSA"
  },
  LI: {
    country: "Liechtenstein",
    jurisdiction: "SCB"
  },
  IM: {
    country: "Isle Of Man",
    jurisdiction: "SCB"
  },
  PT: {
    country: "Portugal",
    jurisdiction: "SCB"
  },
  KR: {
    country: "South Korea",
    jurisdiction: "FSA"
  },
  JE: {
    country: "Jersey",
    jurisdiction: "SCB"
  },
  HK: {
    country: "Hong Kong",
    jurisdiction: "FSA"
  },
  AS: {
    country: "American Samoa",
    jurisdiction: "Banned"
  },
  LV: {
    country: "Latvia",
    jurisdiction: "SCB"
  },
  MP: {
    country: "North Mariana Islands",
    jurisdiction: "FSA"
  },
  CL: {
    country: "Chile",
    jurisdiction: "FSA"
  },
  CK: {
    country: "Cook Islands",
    jurisdiction: "FSA"
  },
  GU: {
    country: "Guam",
    jurisdiction: "FSA"
  },
  ES: {
    country: "Spain",
    jurisdiction: "Banned"
  },
  SI: {
    country: "Slovenia",
    jurisdiction: "SCB"
  },
  CZ: {
    country: "Czech Republic",
    jurisdiction: "SCB"
  },
  VI: {
    country: "United States Virgin Islands",
    jurisdiction: "Banned"
  },
  UM: {
    country: "United States Minor Outlying Islands",
    jurisdiction: "Banned"
  },
  MC: {
    country: "Monaco",
    jurisdiction: "SCB"
  },
  US: {
    country: "United States",
    jurisdiction: "Banned"
  },
  NU: {
    country: "Niue",
    jurisdiction: "FSA"
  },
  NR: {
    country: "Nauru",
    jurisdiction: "FSA"
  },
  GR: {
    country: "Greece",
    jurisdiction: "SCB"
  },
  PL: {
    country: "Poland",
    jurisdiction: "SCB"
  },
  MS: {
    country: "Montserrat",
    jurisdiction: "FSA"
  },
  IL: {
    country: "Israel",
    jurisdiction: "Banned"
  },
  BW: {
    country: "Botswana",
    jurisdiction: "FSCA"
  },
  AI: {
    country: "Anguilla",
    jurisdiction: "FSA"
  },
  QA: {
    country: "Qatar",
    jurisdiction: "FSA"
  },
  GE: {
    country: "Georgia",
    jurisdiction: "SCB"
  },
  BT: {
    country: "Bhutan",
    jurisdiction: "FSA"
  },
  TV: {
    country: "Tuvalu",
    jurisdiction: "FSA"
  },
  IT: {
    country: "Italy",
    jurisdiction: "SCB"
  },
  NA: {
    country: "Namibia",
    jurisdiction: "FSCA"
  },
  SK: {
    country: "Slovakia",
    jurisdiction: "SCB"
  },
  FM: {
    country: "Micronesia",
    jurisdiction: "FSA"
  },
  FJ: {
    country: "Fiji",
    jurisdiction: "FSA"
  },
  SC: {
    country: "Seychelles",
    jurisdiction: "SCB"
  },
  MH: {
    country: "Marshall Islands",
    jurisdiction: "FSA"
  },
  AG: {
    country: "Antigua and Barbuda",
    jurisdiction: "FSA"
  },
  WS: {
    country: "Samoa",
    jurisdiction: "FSA"
  },
  AW: {
    country: "Aruba",
    jurisdiction: "FSA"
  },
  TC: {
    country: "Turks & Caicos",
    jurisdiction: "FSA"
  },
  OM: {
    country: "Oman",
    jurisdiction: "FSA"
  },
  RW: {
    country: "Rwanda",
    jurisdiction: "FSA"
  },
  VC: {
    country: "Saint Vincent and the Grenadines",
    jurisdiction: "FSA"
  },
  GQ: {
    country: "Equatorial Guinea",
    jurisdiction: "FSA"
  },
  MT: {
    country: "Malta",
    jurisdiction: "SCB"
  },
  CR: {
    country: "Costa Rica",
    jurisdiction: "FSA"
  },
  BZ: {
    country: "Belize",
    jurisdiction: "FSA"
  },
  KI: {
    country: "Kiribati",
    jurisdiction: "FSA"
  },
  MU: {
    country: "Mauritius",
    jurisdiction: "FSA"
  },
  CY: {
    country: "Cyprus",
    jurisdiction: "SCB"
  },
  MO: {
    country: "Macau",
    jurisdiction: "FSA"
  },
  RO: {
    country: "Romania",
    jurisdiction: "SCB"
  },
  GD: {
    country: "Grenada",
    jurisdiction: "FSA"
  },
  BS: {
    country: "Bahamas",
    jurisdiction: "FSA"
  },
  CV: {
    country: "Cape Verde",
    jurisdiction: "FSA"
  },
  HR: {
    country: "Croatia",
    jurisdiction: "SCB"
  },
  SA: {
    country: "Saudi Arabia",
    jurisdiction: "FSA"
  },
  KW: {
    country: "Kuwait",
    jurisdiction: "FSA"
  },
  CW: {
    country: "Curacao",
    jurisdiction: "FSA"
  },
  VG: {
    country: "British Virgin Islands",
    jurisdiction: "FSA"
  },
  BB: {
    country: "Barbados",
    jurisdiction: "FSA"
  },
  PW: {
    country: "Palau",
    jurisdiction: "FSA"
  },
  HU: {
    country: "Hungary",
    jurisdiction: "SCB"
  },
  TL: {
    country: "Timor-Leste",
    jurisdiction: "FSA"
  },
  BH: {
    country: "Bahrain",
    jurisdiction: "FSA"
  },
  LC: {
    country: "Saint Lucia",
    jurisdiction: "FSA"
  },
  AM: {
    country: "Armenia",
    jurisdiction: "SCB"
  },
  DM: {
    country: "Dominica",
    jurisdiction: "FSA"
  },
  AE: {
    country: "United Arab Emirates",
    jurisdiction: "FSA"
  },
  ST: {
    country: "Sao Tome and Principe",
    jurisdiction: "FSA"
  },
  BG: {
    country: "Bulgaria",
    jurisdiction: "SCB"
  },
  GM: {
    country: "Gambia",
    jurisdiction: "FSA"
  },
  MV: {
    country: "Maldives",
    jurisdiction: "FSA"
  },
  KN: {
    country: "Saint Kitts and Nevis",
    jurisdiction: "FSA"
  },
  LS: {
    country: "Lesotho",
    jurisdiction: "FSCA"
  },
  MY: {
    country: "Malaysia",
    jurisdiction: "FSA"
  },
  KZ: {
    country: "Kazakhstan",
    jurisdiction: "FSA"
  },
  MK: {
    country: "North Macedonia",
    jurisdiction: "SCB"
  },
  ME: {
    country: "Montenegro",
    jurisdiction: "SCB"
  },
  MD: {
    country: "Moldova",
    jurisdiction: "SCB"
  },
  AR: {
    country: "Argentina",
    jurisdiction: "FSA"
  },
  CN: {
    country: "China",
    jurisdiction: "FSA"
  },
  TO: {
    country: "Tonga",
    jurisdiction: "FSA"
  },
  SX: {
    country: "St Maarten",
    jurisdiction: "FSA"
  },
  GH: {
    country: "Ghana",
    jurisdiction: "FSA"
  },
  GI: {
    country: "Gibraltar",
    jurisdiction: "SCB"
  },
  GY: {
    country: "Guyana",
    jurisdiction: "FSA"
  },
  PE: {
    country: "Peru",
    jurisdiction: "FSA"
  },
  MW: {
    country: "Malawi",
    jurisdiction: "FSA"
  },
  CO: {
    country: "Colombia",
    jurisdiction: "FSA"
  },
  EC: {
    country: "Ecuador",
    jurisdiction: "FSA"
  },
  SR: {
    country: "Suriname",
    jurisdiction: "FSA"
  },
  VU: {
    country: "Vanuatu",
    jurisdiction: "FSA"
  },
  TN: {
    country: "Tunisia",
    jurisdiction: "FSA"
  },
  DO: {
    country: "Dominican Republic",
    jurisdiction: "FSA"
  },
  RS: {
    country: "Serbia",
    jurisdiction: "FSA"
  },
  SV: {
    country: "El Salvador",
    jurisdiction: "FSA"
  },
  AO: {
    country: "Angola",
    jurisdiction: "FSA"
  },
  KY: {
    country: "Cayman Islands",
    jurisdiction: "FSA"
  },
  JO: {
    country: "Jordan",
    jurisdiction: "FSA"
  },
  MN: {
    country: "Mongolia",
    jurisdiction: "FSA"
  },
  SB: {
    country: "Solomon Islands",
    jurisdiction: "FSA"
  },
  PG: {
    country: "Papua New Guinea",
    jurisdiction: "FSA"
  },
  IN: {
    country: "India",
    jurisdiction: "FSA"
  },
  BY: {
    country: "Belarus",
    jurisdiction: "SCB"
  },
  AQ: {
    country: "Antarctica",
    jurisdiction: "FSA"
  },
  BV: {
    country: "Bouvet Island",
    jurisdiction: "FSA"
  },
  TF: {
    country: "French Southern Territories",
    jurisdiction: "FSA"
  },
  HM: {
    country: "Heard Island and McDonald Islands",
    jurisdiction: "FSA"
  },
  GS: {
    country: "South Georgia and the South Sandwich Islands",
    jurisdiction: "FSA"
  },
  TT: {
    country: "Trinidad and Tobago",
    jurisdiction: "FSA"
  },
  GA: {
    country: "Gabon",
    jurisdiction: "FSA"
  },
  DZ: {
    country: "Algeria",
    jurisdiction: "FSA"
  },
  ZM: {
    country: "Zambia",
    jurisdiction: "FSA"
  },
  EG: {
    country: "Egypt",
    jurisdiction: "FSA"
  },
  UZ: {
    country: "Uzbekistan",
    jurisdiction: "FSA"
  },
  JM: {
    country: "Jamaica",
    jurisdiction: "FSA"
  },
  ID: {
    country: "Indonesia",
    jurisdiction: "FSA"
  },
  CI: {
    country: "Cote D'Ivoire",
    jurisdiction: "FSA"
  },
  LK: {
    country: "Sri Lanka",
    jurisdiction: "FSA"
  },
  TG: {
    country: "Togo",
    jurisdiction: "FSA"
  },
  PY: {
    country: "Paraguay",
    jurisdiction: "FSA"
  },
  BO: {
    country: "Bolivia",
    jurisdiction: "FSA"
  },
  MX: {
    country: "Mexico",
    jurisdiction: "FSA"
  },
  NP: {
    country: "Nepal",
    jurisdiction: "FSA"
  },
  EH: {
    country: "Western Sahara",
    jurisdiction: "FSA"
  },
  BR: {
    country: "Brazil",
    jurisdiction: "FSA"
  },
  CU: {
    country: "Cuba",
    jurisdiction: "FSA"
  },
  CG: {
    country: "Congo (Brazzaville)",
    jurisdiction: "FSA"
  },
  MA: {
    country: "Morocco",
    jurisdiction: "FSA"
  },
  ZA: {
    country: "South Africa",
    jurisdiction: "FSCA"
  },
  GT: {
    country: "Guatemala",
    jurisdiction: "FSA"
  },
  TH: {
    country: "Thailand",
    jurisdiction: "FSA"
  },
  LA: {
    country: "Laos",
    jurisdiction: "FSA"
  },
  UA: {
    country: "Ukraine",
    jurisdiction: "SCB"
  },
  BJ: {
    country: "Benin",
    jurisdiction: "FSA"
  },
  ET: {
    country: "Ethiopia",
    jurisdiction: "FSA"
  },
  KG: {
    country: "Kyrgyzstan",
    jurisdiction: "FSA"
  },
  BA: {
    country: "Bosnia and Herzegovina",
    jurisdiction: "FSA"
  },
  DJ: {
    country: "Djibouti",
    jurisdiction: "FSA"
  },
  TM: {
    country: "Turkmenistan",
    jurisdiction: "FSA"
  },
  XK: {
    country: "Kosovo",
    jurisdiction: "SCB"
  },
  BD: {
    country: "Bangladesh",
    jurisdiction: "FSA"
  },
  VN: {
    country: "Vietnam",
    jurisdiction: "FSA"
  },
  MR: {
    country: "Mauritania",
    jurisdiction: "FSA"
  },
  HN: {
    country: "Honduras",
    jurisdiction: "FSA"
  },
  AZ: {
    country: "Azerbaijan",
    jurisdiction: "SCB"
  },
  AL: {
    country: "Albania",
    jurisdiction: "SCB"
  },
  KM: {
    country: "Comoros",
    jurisdiction: "FSA"
  },
  KE: {
    country: "Kenya",
    jurisdiction: "FSA"
  },
  NE: {
    country: "Niger",
    jurisdiction: "FSA"
  },
  TJ: {
    country: "Tajikistan",
    jurisdiction: "FSA"
  },
  SL: {
    country: "Sierra Leone",
    jurisdiction: "FSA"
  },
  SZ: {
    country: "Eswatini",
    jurisdiction: "FSCA"
  },
  SN: {
    country: "Senegal",
    jurisdiction: "FSA"
  },
  BF: {
    country: "Burkina Faso",
    jurisdiction: "FSA"
  },
  LR: {
    country: "Liberia",
    jurisdiction: "FSA"
  },
  PS: {
    country: "West Bank (Palestinian Territory, Occupied)",
    jurisdiction: "FSA"
  },
  PA: {
    country: "Panama",
    jurisdiction: "FSA"
  },
  PK: {
    country: "Pakistan",
    jurisdiction: "FSA"
  },
  CF: {
    country: "Central African Rep",
    jurisdiction: "Banned"
  },
  TD: {
    country: "Chad",
    jurisdiction: "FSA"
  },
  GN: {
    country: "Guinea",
    jurisdiction: "FSA"
  },
  TZ: {
    country: "Tanzania",
    jurisdiction: "FSA"
  },
  SD: {
    country: "Sudan",
    jurisdiction: "Banned"
  },
  PH: {
    country: "Philippines",
    jurisdiction: "FSA"
  },
  TR: {
    country: "Turkey",
    jurisdiction: "SCB"
  },
  MG: {
    country: "Madagascar",
    jurisdiction: "FSA"
  },
  CM: {
    country: "Cameroon",
    jurisdiction: "FSA"
  },
  ER: {
    country: "Eritrea",
    jurisdiction: "FSA"
  },
  ZW: {
    country: "Zimbabwe",
    jurisdiction: "FSA"
  },
  UG: {
    country: "Uganda",
    jurisdiction: "FSA"
  },
  KH: {
    country: "Cambodia",
    jurisdiction: "FSA"
  },
  NI: {
    country: "Nicaragua",
    jurisdiction: "FSA"
  },
  LB: {
    country: "Lebanon",
    jurisdiction: "SCB"
  },
  RU: {
    country: "Russia",
    jurisdiction: "Banned"
  },
  IQ: {
    country: "Iraq",
    jurisdiction: "SCB"
  },
  NG: {
    country: "Nigeria",
    jurisdiction: "FSA"
  },
  GW: {
    country: "Guinea Bissau",
    jurisdiction: "SCB"
  },
  BI: {
    country: "Burundi",
    jurisdiction: "FSA"
  },
  VE: {
    country: "Venezuela",
    jurisdiction: "FSA"
  },
  MZ: {
    country: "Mozambique",
    jurisdiction: "FSA"
  },
  ML: {
    country: "Mali",
    jurisdiction: "Banned"
  },
  LY: {
    country: "Libya",
    jurisdiction: "Banned"
  },
  YE: {
    country: "Yemen",
    jurisdiction: "Banned"
  },
  SS: {
    country: "South Sudan",
    jurisdiction: "Banned"
  },
  SO: {
    country: "Somalia",
    jurisdiction: "Banned"
  },
  MM: {
    country: "Myanmar",
    jurisdiction: "Banned"
  },
  CD: {
    country: "Democratic Republic of the Congo",
    jurisdiction: "Banned"
  },
  AF: {
    country: "Afghanistan",
    jurisdiction: "Banned"
  },
  HT: {
    country: "Haiti",
    jurisdiction: "Banned"
  },
  SY: {
    country: "Syria",
    jurisdiction: "Banned"
  },
  IR: {
    country: "Iran",
    jurisdiction: "Banned"
  },
  KP: {
    country: "North Korea",
    jurisdiction: "Banned"
  }
}

export const jurisdictionToLocaleMapping: Record<string, string> = {
  SCB: "en-bs",
  FCA: "en-gb",
  ASIC: "en-au",
  FSCA: "en-za",
  FSA: "en-sc",
  Banned: "en-sc"
}

export const jurisdictionToLocationMapping: Record<string, string> = {
  SCB: "BS",
  FCA: "UK",
  ASIC: "AU",
  FSA: "SC",
  FSCA: "ZA",
  Banned: "SC"
}

export const brandToJurisdictionMapping: Record<string, string> = {
  bs: "SCB",
  eu: "FCA",
  au: "ASIC",
  sc: "FSA",
  za: "FSCA"
}
