export const getCookie = (cname: string) => {
  const name = cname + "="
  const ca = document.cookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const isPerformanceCookieAccepted = () => {
  const cookie = getCookie("OptanonConsent")
  return cookie.includes("CC0002%3A1")
}

export const isTrackingCookieAccepted = () => {
  const cookie = getCookie("OptanonConsent")
  return cookie.includes("CC0004%3A1")
}
