import React, { useRef, useEffect, useContext } from "react"
import { InputBaseComponentProps, TextField } from "@mui/material"
import { Text } from "components"
import { Container, textfieldInputStyle, textfieldStyle, incorrectCodeStyle } from "./TextfieldInputBoxes.styled"
import { Context } from "pages/_app"

type Props = {
  value: string[]
  handleChange: (text: string[]) => void
  numberOfBoxes?: number
  incorrectCode?: boolean
}

const TextfieldInputBoxes = ({ value, handleChange, numberOfBoxes = 6, incorrectCode = false }: Props) => {
  const { validationMessages } = useContext(Context)
  const valueRefs = useRef<HTMLDivElement[]>([])

  const removeCharacter = (code: string[], pos: number) => {
    if (code[pos] === "" && pos > 0) {
      code[pos - 1] = ""
      valueRefs.current[pos - 1].focus()
    } else {
      code[pos] = ""
    }
    return code
  }

  const onChange = (characters: string, pos: number) => {
    let code = [...value]

    // When removing the character
    if (characters === "") {
      code = removeCharacter(code, pos)
    }

    const chars = characters.split("").filter((c) => ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ""].includes(c))
    if (chars.length + pos > value.length) {
      chars.length = value.length - pos
    }

    chars.forEach((character, index) => {
      if (pos + index < code.length) {
        code[pos + index] = character
      }
    })

    handleChange(code)
    // Focus on next box
    if (pos < code.length - 1 && characters !== "") {
      let refIndex = pos + chars.length
      if (refIndex > code.length - 1) {
        refIndex = code.length - 1
      }
      valueRefs.current[refIndex].focus()
    }
  }

  useEffect(() => {
    if (value.every((elem) => !elem)) {
      valueRefs.current[0].focus()
    }
  }, [value])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, pos: number) => {
    if (e.key === "Backspace") {
      const code = removeCharacter([...value], pos)
      handleChange(code)
    } else if (e.key === "ArrowRight" && pos < numberOfBoxes - 1) {
      valueRefs.current[pos + 1].focus()
    } else if (e.key === "ArrowLeft" && pos > 0) {
      valueRefs.current[pos - 1].focus()
    }
  }

  return (
    <div>
      <Container data-test-id="textfield-input-boxes">
        {Array.from(Array(numberOfBoxes).keys()).map((boxPos) => (
          <TextField
            key={`verification_code_${boxPos}`}
            type="tel"
            label=""
            variant="filled"
            onChange={(e) => onChange(e.target.value, boxPos)}
            value={value[boxPos]}
            inputProps={{
              style: textfieldInputStyle as InputBaseComponentProps
            }}
            InputProps={{ autoComplete: "off", disableUnderline: true }}
            autoFocus={boxPos === 0}
            inputRef={(elem) => valueRefs.current.push(elem)}
            onKeyDown={(e) => handleKeyDown(e, boxPos)}
            sx={textfieldStyle(incorrectCode)}
          />
        ))}
      </Container>
      {incorrectCode && <Text text={validationMessages.incorrect_code} sx={incorrectCodeStyle} />}
    </div>
  )
}

export default TextfieldInputBoxes
