import { red } from "@mui/material/colors"
import { createTheme } from "@mui/material/styles"
import { accentGreenColor, darkBlueColor, mainColor } from "styles"
import { Inter, Kanit } from "next/font/google"

declare module "@mui/material/styles" {
  interface Palette {
    primaryOrange: Palette["primary"]
    primaryGreen: Palette["primary"]
    secondaryMidnightBlue: Palette["primary"]
    secondaryPurple: Palette["primary"]
    secondaryLightBlue: Palette["primary"]
    secondaryYellow: Palette["primary"]
  }
  interface PaletteOptions {
    primaryOrange: PaletteOptions["primary"]
    primaryGreen: PaletteOptions["primary"]
    secondaryMidnightBlue: PaletteOptions["primary"]
    secondaryPurple: PaletteOptions["primary"]
    secondaryLightBlue: PaletteOptions["primary"]
    secondaryYellow: PaletteOptions["primary"]
  }
}

export const kanit = Kanit({
  weight: ["300", "400", "500", "600", "700"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["Kanit", "sans-serif"]
})

export const inter = Inter({
  weight: ["300", "400", "500", "600", "700"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["Kanit", "sans-serif"]
})
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    article: true
    lg2: true
    lg3: true
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    primaryOrange: {
      main: mainColor
    },
    primaryGreen: {
      main: accentGreenColor
    },
    secondaryMidnightBlue: {
      main: darkBlueColor
    },
    secondaryPurple: {
      main: "#513799"
    },
    secondaryLightBlue: {
      main: "#C2E5FC"
    },
    secondaryYellow: {
      main: "#FFD55A"
    },
    error: {
      main: red.A400
    }
  },
  typography: {
    fontFamily: inter.style.fontFamily,
    button: {
      textTransform: "none"
    },
    allVariants: {
      color: darkBlueColor
    },
    h1: {
      fontWeight: "500",
      fontSize: "23px",
      lineHeight: "33px",
      margin: "0"
    },
    h2: {
      fontSize: "20px",
      fontWeight: "700",
      margin: "0"
    },
    h3: {
      fontSize: "18px",
      fontWeight: "700",
      margin: "0"
    },
    h4: {
      fontSize: "14px",
      lineHeight: "15px",
      fontWeight: "700",
      margin: "0"
    },
    h5: {
      fontSize: "12px",
      fontWeight: "500",
      margin: "0"
    },
    subtitle1: {
      fontSize: "20px",
      lineHeight: "32px",
      fontWeight: "600"
    },
    subtitle2: {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: "600"
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "300"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 457,
      md: 768,
      article: 881,
      lg: 1024,
      lg2: 1025,
      lg3: 1100,
      xl: 1440
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "underline",
          cursor: "pointer",
          fontWeight: "500",
          color: darkBlueColor
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            color: darkBlueColor
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: () => ({
          fontSize: "15px",
          fontWeight: "600",
          lineHeight: "20px",
          padding: "20px 25px",
          textTransform: "none",
          textAlign: "center",
          border: "none",
          borderRadius: "85px",
          boxShadow: "none",
          minWidth: "115px",
          minHeight: "60px",
          transition: "opacity 0.2s",
          "&.Mui-disabled": {
            opacity: "0.5"
          },
          [theme.breakpoints.down("sm")]: {
            fontWeight: 400
          }
        })
      }
    }
  }
})

// Mobile theme
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    lineHeight: "24px"
  }
}

theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
    lineHeight: "24px"
  }
}

export { theme }
