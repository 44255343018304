import React, { useState, useEffect, useContext } from "react"
import { Box, FormControl } from "@mui/material"
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined"
import {
  RichText,
  CardGroup,
  Checkbox,
  Dropdown,
  Textfield,
  PasswordTextfield,
  PhoneTextfield,
  Datefield,
  RadioGroup,
  Searchfield,
  AddressSearchfield,
  Text,
  IconButton,
  AllFieldsType,
  FieldVariation,
  TextAlignType,
  WidgetProps
} from "components"
import { Row } from "styles"
import {
  AU_STATES,
  ExtraValidationType,
  fieldValidation,
  getAddressFields,
  getFieldValue,
  ObjectWithFieldsType,
  SignupDataType
} from "utils"
import { useFieldErrorMessage } from "hooks"
import { boxStyle, iconButtonStyle } from "./Field.styled"
import { Context } from "pages/_app"
import { RichTextField } from "@prismicio/types"

type Props = AllFieldsType & {
  currentValue?: string
  handleChange?: (values: ObjectWithFieldsType) => void
  signupData?: SignupDataType
  extraValidation?: ExtraValidationType[]
  key?: string | undefined
  extraErrorMessages?: string[]
  textAlign?: TextAlignType
  autoFocus?: boolean
  required?: boolean
  variation?: FieldVariation
  title?: RichTextField
  description?: RichTextField
  hasEditIcon?: boolean
  fieldName?: string
  widgetprops?: WidgetProps
  isLoginOrSignup?: boolean
}

const Field = (props: Props) => {
  const { validationMessages } = useContext(Context)
  const { errorMessage, setErrorMessage, error, displayErrorMessage } = useFieldErrorMessage()
  const {
    required,
    currentValue,
    variation,
    title,
    description,
    signupData,
    extraValidation,
    hasEditIcon,
    textAlign = "left" as TextAlignType | undefined,
    fieldName,
    handleChange,
    extraErrorMessages
  } = props

  const [showEditIcon, setShowEditIcon] = useState(hasEditIcon)

  const [checkValidation, setCheckValidation] = useState(false)
  const handleFieldChange = (newValues: ObjectWithFieldsType) => {
    setCheckValidation(true)
    handleChange(newValues)
  }

  useEffect(() => {
    const isNotPhoneTextField = variation !== FieldVariation.PhoneTextfield
    const isNotAddressSearchField = variation !== FieldVariation.AddressSearchfield

    if (checkValidation && isNotAddressSearchField && isNotPhoneTextField) {
      setErrorMessage(fieldValidation(props, currentValue, signupData, validationMessages, required, extraValidation))
    } else if (errorMessage.length > 0 && isNotPhoneTextField) {
      setErrorMessage([])
    }
  }, [checkValidation, currentValue, required, signupData, variation, props, validationMessages])

  const displayFieldWithEditIcon = () => {
    let text = [currentValue]
    if (variation === FieldVariation.Datefield) {
      text = [currentValue]
    } else if (variation === FieldVariation.AddressSearchfield) {
      const { addressLine1, addressLine2, addressCity, addressPostcode, auState } = getAddressFields(fieldName)
      const addressLine1Value = getFieldValue(signupData, addressLine1)
      const addressLine2Value = getFieldValue(signupData, addressLine2)
      const addressCityValue = getFieldValue(signupData, addressCity)
      const addressPostcodeValue = getFieldValue(signupData, addressPostcode)
      const auStateValue = getFieldValue(signupData, auState)
      text = [addressLine1Value]
      text.push(addressLine2Value)
      text.push(addressCityValue)
      text.push(addressPostcodeValue)
      if (auStateValue) {
        text.push(AU_STATES.find((state) => state.value === auStateValue).label)
      }
    }

    return (
      <Row alignItems="flex-start">
        <div>
          {text.map((t) => (
            <Text text={t} key={t} />
          ))}
        </div>
        <IconButton icon={<BorderColorOutlinedIcon />} onClick={() => setShowEditIcon(false)} sx={iconButtonStyle} />
      </Row>
    )
  }

  const displayField = () => {
    if (showEditIcon) {
      return displayFieldWithEditIcon()
    }

    switch (variation) {
      case FieldVariation.Dropdown:
        return <Dropdown {...props} handleChange={handleFieldChange} />
      case FieldVariation.Checkbox:
        return <Checkbox {...props} handleChange={handleFieldChange} />
      case FieldVariation.Textfield:
        return <Textfield {...props} testid={props.fieldName} handleChange={handleFieldChange} />
      case FieldVariation.EmailTextfield:
        return <Textfield {...props} handleChange={handleFieldChange} />
      case FieldVariation.PasswordTextfield:
      case FieldVariation.ConfirmPasswordTextfield:
        return <PasswordTextfield {...props} handleChange={handleFieldChange} />
      case FieldVariation.PhoneTextfield:
        return <PhoneTextfield {...props} handleChange={handleFieldChange} setErrorMessage={setErrorMessage} />
      case FieldVariation.CardGroup:
        return <CardGroup {...props} handleChange={handleFieldChange} />
      case FieldVariation.Datefield:
        return <Datefield {...props} handleChange={handleFieldChange} error={error} />
      case FieldVariation.RadioGroup:
        return <RadioGroup {...props} handleChange={handleFieldChange} />
      case FieldVariation.Searchfield:
        return <Searchfield {...props} handleChange={handleFieldChange} />
      case FieldVariation.AddressSearchfield:
        return <AddressSearchfield {...props} handleChange={handleFieldChange} />
      default:
        return null
    }
  }

  const errorMessagePadding = variation === FieldVariation.Checkbox ? "5px 0 0 17px" : "0"

  return (
    <Box sx={boxStyle(variation, textAlign)} data-test-id={variation} id={props.isLoginOrSignup && "loginOrSignup"}>
      <RichText field={title} />
      <RichText field={description} />
      <FormControl required={required} error={error} fullWidth>
        {displayField()}
        {checkValidation && displayErrorMessage(errorMessagePadding, extraErrorMessages)}
      </FormControl>
    </Box>
  )
}

export default Field
