import { darkBlueColor, lightGreyColor, whiteColor } from "styles"

export const muiButtonStyle = (lightBackground: boolean, textUnderline: boolean, variant: string, sx: object) => {
  let style: object = {
    backgroundColor: lightBackground ? lightGreyColor : darkBlueColor,
    color: lightBackground ? darkBlueColor : whiteColor,
    overflow: "hidden",
    "&.Mui-disabled": {
      backgroundColor: lightBackground ? lightGreyColor : darkBlueColor,
      color: lightBackground ? darkBlueColor : whiteColor
    },
    "&:hover": {
      backgroundColor: lightBackground ? lightGreyColor : darkBlueColor,
      color: lightBackground ? darkBlueColor : whiteColor,
      textDecoration: textUnderline ? "underline" : "none",
      border: "none"
    },
    textDecoration: textUnderline ? "underline" : "none",
    transition: "background-color 0.2s, color 0.2s"
  }

  if (variant === "text") {
    style = {
      ...style,
      padding: "0",
      minHeight: "initial",
      minWidth: "initial",
      backgroundColor: "transparent",
      color: darkBlueColor,
      "&.Mui-disabled": {
        ...style["&.Mui-disabled"],
        backgroundColor: "transparent",
        color: darkBlueColor
      },
      "&:hover": {
        ...style["&:hover"],
        backgroundColor: "transparent",
        color: darkBlueColor
      }
    }
  }

  return { ...style, ...sx }
}
