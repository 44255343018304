import React, { ReactNode, useContext, useEffect, useState } from "react"
import { Loading } from "components"
import { useAccountData } from "hooks"
import {
  LOCAL_STORAGE_USER_INFO,
  brandToJurisdictionMapping,
  checkTokenIsStillValid,
  goToPage,
  postHogIdentify
} from "utils"
import { accountApi, useGetUserQuery } from "redux/features/accountapi/accountApi"
import { Context } from "pages/_app"

type Props = {
  children?: ReactNode
}

let userApiCallInterval

const AccountPageBase = ({ children }: Props) => {
  const { accountData, updateAccountData, openUserErrorModal, displayErrorModal, updateBrands } = useAccountData()

  // User Data
  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
    error: errorUserData,
    isSuccess: isSuccessUserData,
    refetch
  } = useGetUserQuery()

  // This state variable exists to make sure we don't check for the error on the first render
  // because RTK stores the return value from the previous fetch, and could return error with
  // a value if it failed before.
  const [canCheckError, setCanCheckError] = useState(false)

  useEffect(() => {
    if (isLoadingUserData) {
      setCanCheckError(true)
    }
  }, [isLoadingUserData])

  useEffect(() => {
    if (userData) {
      updateAccountData(userData)
      localStorage.setItem(LOCAL_STORAGE_USER_INFO, JSON.stringify(userData))

      postHogIdentify(userData)
    } else {
      const userInfoLocalStorage = localStorage.getItem(LOCAL_STORAGE_USER_INFO)
      if (userInfoLocalStorage) {
        updateAccountData(JSON.parse(userInfoLocalStorage))
      }
    }
  }, [userData])

  const [inCreatedState, setInCreatedState] = useState(true)
  const [stopUserApiCall, setStopUserApiCall] = useState(false)
  const clearUserApiCall = () => {
    setInCreatedState(false)
    if (userApiCallInterval) {
      clearInterval(userApiCallInterval)
    }
  }
  useEffect(() => {
    if (userData?.app_metadata?.clients?.length > 0) {
      const isCreated = userData.app_metadata.clients[0].state === "Created"

      if (isCreated) {
        if (!userApiCallInterval) {
          userApiCallInterval = setInterval(() => {
            refetch()
          }, 3000)

          setTimeout(() => {
            setStopUserApiCall(true)
          }, 60000)
        }
      } else {
        clearUserApiCall()
      }
    }
  }, [userData])

  useEffect(() => {
    if (stopUserApiCall) {
      clearUserApiCall()
    }
  }, [stopUserApiCall])

  const accountDataExists = () => {
    const userInfoLocalStorage = localStorage.getItem(LOCAL_STORAGE_USER_INFO)

    return !!userInfoLocalStorage || Object.keys(accountData).length > 0
  }

  useEffect(() => {
    if (errorUserData && canCheckError && !isLoadingUserData) {
      const { status } = errorUserData as { status: number }

      if (!accountDataExists() || [401, 403].includes(status)) {
        openUserErrorModal()
        clearUserApiCall()
      }
    }
  }, [errorUserData, canCheckError, isLoadingUserData])

  const resetTokenExpirationTime = () => {
    const newExpirationTime = Date.now() + 8 * 60 * 60 * 1000 // 8 hours in the future
    localStorage.setItem("token_expiration_time", newExpirationTime.toString())
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isValid = checkTokenIsStillValid()
      if (!isValid) {
        sessionStorage.clear()
        localStorage.clear()
        goToPage("/login")
      }
    }, 60000 * 10) // Check every 10 minutes

    const handleMouseMove = () => {
      resetTokenExpirationTime()
    }

    window.addEventListener("mousemove", handleMouseMove)

    return () => {
      clearInterval(intervalId)
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [])

  // Brands
  const [getBrands, { isFetching: isFetchingBrands }] = accountApi.endpoints.getBrands.useLazyQuery()

  useEffect(() => {
    const fetchBrands = async () => {
      const brands = await getBrands().unwrap()
      if (brands && brands.available?.length > 0) {
        updateBrands(brands)
      }
    }

    if (isSuccessUserData && !isFetchingUserData) {
      fetchBrands()
    }
  }, [isSuccessUserData, isFetchingUserData])

  // Jurisdiction
  const { updateJurisdiction } = useContext(Context)
  useEffect(() => {
    const clients = userData?.app_metadata?.clients
    if (clients?.length) {
      const { brand } = clients[0]
      const jurisdiction = brandToJurisdictionMapping[brand]
      if (jurisdiction) {
        updateJurisdiction(jurisdiction)
      }
    }
  }, [userData])

  return (
    <>
      {children}
      {displayErrorModal()}
      {(isFetchingUserData || isFetchingBrands || inCreatedState) && <Loading />}
    </>
  )
}

export default AccountPageBase
