import styled from "@emotion/styled"
import { lightGreyColor } from "styles"

export const Container = styled.div`
  margin-bottom: 20px;
`

export const FieldContainer = styled.div`
  margin-bottom: 30px;
`

export const paperStyle = {
  backgroundColor: lightGreyColor,
  padding: "30px 30px 0 30px"
}
