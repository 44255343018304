import { styled as muiStyled } from "@mui/material/styles"
import { darkBlueColor, whiteColor, lightGreyColor } from "styles"

export const Container = muiStyled("div")(({ theme }) => ({
  backgroundColor: lightGreyColor,
  padding: "20px",
  position: "fixed",
  bottom: "0",
  left: "0",
  right: "0",
  transition: "bottom 0s ease 0s, top 300ms ease-in-out 0s",

  "&:before": {
    // MUI breaks if we just use content: ""
    // eslint-disable-next-line prettier/prettier
    content: '""',
    width: "50px",
    height: "50px",
    position: "absolute",
    left: "0",
    top: "-50px",
    borderRadius: "50%",
    boxShadow: `-25px 25px 0 0 ${lightGreyColor}`
  },

  "&:after": {
    // MUI breaks if we just use content: ""
    // eslint-disable-next-line prettier/prettier
    content: '""',
    width: "50px",
    height: "50px",
    position: "absolute",
    right: "0",
    top: "-50px",
    borderRadius: "50%",
    boxShadow: `25px 25px 0 0 ${lightGreyColor}`
  },

  [theme.breakpoints.up("sm")]: {
    backgroundColor: lightGreyColor,
    padding: "20px 40px",
    position: "relative",

    "&:before": {
      content: "none"
    },

    "&:after": {
      content: "none"
    }
  }
}))

export const textStyle = {
  color: { xs: whiteColor, sm: darkBlueColor },
  fontSize: { xs: "10px", sm: "13px" },
  lineHeight: { xs: "13px", sm: "20px" }
}
