import React, { ChangeEvent } from "react"
import { FormControlLabel, Switch } from "@mui/material"

type Props = {
  checked: boolean
  labelOff: string
  labelOn: string
  onChange: (checked: boolean) => void
}

const Toggle = ({ checked, labelOff, labelOn, onChange }: Props) => {
  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onHandleChange} />}
      label={checked ? labelOn : labelOff}
      data-testid="toggle-label"
    />
  )
}

export default Toggle
