import React, { useContext } from "react"
import { ActionButton, ActionButtonActionType } from "components"
import { useSignupData } from "hooks"
import { goToPreviousStep } from "utils"
import { LanguageContext } from "context/LanguageContext"

type Props = {
  prevStep?: string
  displayText?: string
  sx?: object
}

const PrevButton = ({ prevStep, displayText, sx = {} }: Props) => {
  const { signupData, currentStepPart, updateCurrentStepPart } = useSignupData()
  const { language } = useContext(LanguageContext)

  if (!prevStep) {
    return null
  }

  const onClick = async () => {
    if (currentStepPart === 0) {
      sessionStorage.setItem("button", "back")
      goToPreviousStep(signupData, prevStep, language)
    } else {
      updateCurrentStepPart(currentStepPart - 1)
    }
  }

  return (
    <ActionButton
      displayText={displayText || "Back"}
      onClick={onClick}
      lightBackground
      sx={sx}
      actionType={ActionButtonActionType.Back}
    />
  )
}

export default PrevButton
