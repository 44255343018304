import React, { useState, useEffect } from "react"
import speakeasy from "speakeasy"
import QRCode from "qrcode"
import Image from "next/image"
import { ActionButton, ActionButtonActionType, TextfieldInputBoxes } from "components"
import { PageContentCenterAligned } from "styles"
import { LOCAL_STORAGE_2FA, clear2faCode, setLocalStorage } from "utils"
import { actionButtonStyle } from "./AppTwoFactorAuthentication.styled"

type Props = {
  onCodeVerify: (valid: boolean) => void
}

const AppTwoFactorAuthentication = ({ onCodeVerify }: Props) => {
  const [secret, setSecret] = useState<{
    otpauth_url?: string
    ascii?: string
    base32?: string
    hex?: string
  }>({})
  const [qrCodeImage, setQrCodeImage] = useState("")

  useEffect(() => {
    setSecret(speakeasy.generateSecret({ name: "Trade Nation Login" }))
  }, [])

  useEffect(() => {
    if (secret.otpauth_url) {
      QRCode.toDataURL(secret.otpauth_url, (err, image_data) => {
        setQrCodeImage(image_data)
      })
    }
  }, [secret])

  const [code, setCode] = useState(clear2faCode())
  const onCodeSubmit = () => {
    if (secret.hex) {
      const isVerified = speakeasy.totp.verify({
        secret: secret.hex,
        encoding: "hex",
        token: code.join(""),
        window: 1
      })

      if (isVerified) {
        setLocalStorage(LOCAL_STORAGE_2FA, secret)
      }

      onCodeVerify(isVerified)
    }
  }

  return (
    <PageContentCenterAligned>
      {qrCodeImage && (
        <>
          <Image src={`${qrCodeImage}`} alt="QR CODE" width={180} height={180} />
          <TextfieldInputBoxes value={code} handleChange={setCode} />
          <ActionButton
            displayText="Submit"
            onClick={onCodeSubmit}
            disabled={!code.every((elem) => elem)}
            sx={actionButtonStyle}
            actionType={ActionButtonActionType.Continue}
          />
        </>
      )}
    </PageContentCenterAligned>
  )
}

export default AppTwoFactorAuthentication
