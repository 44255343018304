import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { CreateUserType } from "redux/types"
import { getRtkQueryTagTypes } from "redux/utils"
import { AccountDataType, PlatformBackendType, GeneralApiFieldType, ObjectWithFieldsType } from "utils"

const LOCAL_STORAGE_ACCESS_TOKEN = "access_token"

export const signupApi = createApi({
  reducerPath: "signupapi",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    createUser: builder.mutation<CreateUserType, { body: AccountDataType }>({
      query: ({ body }) => ({
        url: "/create-user",
        method: "POST",
        body
      })
    }),
    // General api call to get values for fields
    getFieldsInfo: builder.query<GeneralApiFieldType[], { url: string; affiliate_id?: string }>({
      query: ({ url, affiliate_id }) => `${url}${affiliate_id ? `?affiliate_id=${affiliate_id}` : ""}`
    }),
    getAffiliateMt4Usergroups: builder.query<GeneralApiFieldType[], { affiliate_id: string; brand: string }>({
      query: ({ affiliate_id, brand }) => `/finsa/getAffiliateMt4Usergroups?affiliate_id=${affiliate_id}&brand=${brand}`
    }),
    getBackendByCountry: builder.query<PlatformBackendType, { countryCode: string; affiliate_id?: string }>({
      query: ({ countryCode, affiliate_id }) =>
        `/finsa/backend-by-country?country_code=${countryCode}${
          window.location.href.includes("signup") ? "&is_onboarding=true" : ""
        }${affiliate_id ? `&affiliate_id=${affiliate_id}` : ""}`
    }),
    scoreCheck: builder.mutation<{ pass: boolean; question_amount?: string }, { body: ObjectWithFieldsType }>({
      query: ({ body }) => ({
        url: "/finsa/check",
        method: "POST",
        body
      })
    })
  })
})

export const {
  useCreateUserMutation,
  useGetFieldsInfoQuery,
  useGetAffiliateMt4UsergroupsQuery,
  useGetBackendByCountryQuery,
  useScoreCheckMutation
} = signupApi
