import styled from "@emotion/styled"
import { Box, Chip, Typography } from "@mui/material"
import { darkBlueColor, darkGreyColor, primaryGreyColor, theme, whiteColor } from "styles"

export const SubHeaderStyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${theme.breakpoints.down("sm")} {
    align-items: flex-start;
  }
`

export const SubHeaderTypographyText = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${darkBlueColor};
  margin-bottom: 40px;
`

export const SubHeaderChip = styled(Chip)<{
  borderradius?: string
  cursor?: string
}>`
  margin-left: 16px;
  border-radius: ${(props) => props.borderradius || "3"}px;
  color: ${darkBlueColor};
  cursor: ${(props) => props.cursor || "initial"};
`

export const chipIconStyle = {
  color: darkBlueColor,
  fontSize: "14px",
  backgroundColor: whiteColor,
  borderRadius: "50%",
  height: "17px",
  width: "17px",
  marginLeft: "10px",
  padding: "1px"
}

export const subHeaderButtonStyle = (isMobileOrTablet?: boolean) => ({
  backgroundColor: primaryGreyColor,
  color: darkBlueColor,
  "&:hover": {
    backgroundColor: darkGreyColor,
    color: whiteColor
  },
  marginTop: isMobileOrTablet ? "50px" : "0",
  marginBottom: "20px",
  width: "180px",
  transition: "background-color 0.2s, color 0.2s",
  fontWeight: "400",
  sm: {
    width: "22px",
    height: "22px"
  }
})

export const subHeaderDividerStyle = (isMobileOrTablet?: boolean) => ({
  marginTop: isMobileOrTablet ? "10px" : "80px",
  marginBottom: "80px",
  height: "1px",
  flexShrink: 0,
  borderColor: primaryGreyColor
})
