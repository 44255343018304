import styled from "@emotion/styled"
import { scrollStyle } from "styles"

export const TextContainer = styled.div<{ textAlign: string; margin: string }>`
  text-align: ${(props) => props.textAlign || "left"};
  overflow-y: auto;
  margin: ${(props) => props.margin};
  ${scrollStyle}
`

export const iconStyle = (iconSx = {}) => ({
  marginBottom: "20px",
  ...iconSx
})
