import React, { useState, useEffect, Fragment } from "react"
import { Collapse } from "@mui/material"
import { TransitionGroup } from "react-transition-group"
import { Text, ActionButton, OptionType, Textfield, Searchfield, FieldVariation } from "components"
import {
  COUNTRY_OF_RESIDENCE_FIELD_NAME,
  EMPLOYMENT_INDUSTRY_FIELD_NAME,
  EMPLOYMENT_STATUS_FIELD_NAME,
  JOB_ROLE_FIELD_NAME,
  NATIONALITY_FIELD_NAME
} from "utils"
import { useAccountData } from "hooks"
import { Row } from "styles"
import {
  Container,
  fieldStyle,
  saveButtonStyle,
  textButtonStyle,
  textStyle,
  titleStyle,
  inputStyle,
  searchfieldStyle
} from "./KYCDetails.styled"
import { Area, EMPLOYMENT_STATUS_VALUES_HIDE_FIELDS } from "./KYCModal"
import KYCFinancesModal from "./KYCFinancesModal"

type Props = {
  title: string
  fields: {
    value: string
    display?: string
    fieldName: string
    label: string
    required?: boolean
    options: OptionType[]
    hide?: boolean
  }[]
  deactivated: boolean
  toggleArea: (area: Area, open: boolean) => void
  area: Area
  updateFields: ({}) => void
  countryOfResidence?: string
}

enum Step {
  Display,
  Edit
}

const KYCDetails = ({ title, fields, deactivated, toggleArea, area, updateFields, countryOfResidence }: Props) => {
  const { appMetadata } = useAccountData()

  const [currentStep, setCurrentStep] = useState(Step.Display)

  const updateCurrentStep = (step: Step) => {
    setCurrentStep(step)
    toggleArea(area, step === Step.Edit)
  }

  const displayTitleAndButton = () => {
    const onEditClick = () => {
      updateCurrentStep(Step.Edit)
    }

    const onCancelClick = () => {
      updateCurrentStep(Step.Display)
    }

    return (
      <Row>
        <Text text={title} sx={titleStyle} />
        {currentStep === Step.Display ? (
          <ActionButton
            displayText={displayText ? "Edit" : "Add"}
            variant="text"
            textUnderline
            onClick={onEditClick}
            sx={textButtonStyle}
          />
        ) : (
          <ActionButton
            displayText="Cancel"
            variant="text"
            textUnderline
            onClick={onCancelClick}
            sx={textButtonStyle}
          />
        )}
      </Row>
    )
  }

  // Display Text Mode
  const [displayText, setDisplayText] = useState("")

  useEffect(() => {
    setDisplayText(
      fields
        .filter((field) => !field.hide)
        .map((field) => field.display || field.value)
        .filter((text) => !!text)
        .join(", ")
    )
  }, [fields])

  const displayDisplayStep = () => {
    if (currentStep === Step.Display || deactivated) {
      return (
        <Collapse>
          <Row alignItems="flex-start">
            <Text text={displayText || "Incomplete"} sx={textStyle} />
          </Row>
        </Collapse>
      )
    }
    return null
  }

  // Edit Mode
  const [fieldValues, setFieldValues] = useState({})

  useEffect(() => {
    setFieldValues(
      fields.reduce((acc, field) => {
        return {
          ...acc,
          [field.fieldName]: {
            value: field.value,
            display: field.display,
            hide: field.hide
          }
        }
      }, {})
    )
  }, [fields, currentStep])

  const checkDisplayField = (field) => {
    return (
      (!field.displayIfCountryOfResidenceIs || field.displayIfCountryOfResidenceIs?.includes(countryOfResidence)) &&
      !fieldValues[field.fieldName].hide
    )
  }

  const displayField = (field) => {
    if (!checkDisplayField(field)) {
      return null
    }

    const isNationality = field.fieldName === NATIONALITY_FIELD_NAME
    const isCountryOfResidence = field.fieldName === COUNTRY_OF_RESIDENCE_FIELD_NAME

    const handleSearchfieldChange = (value: { [fieldName: string]: string }) => {
      setFieldValues({
        ...fieldValues,
        [field.fieldName]: {
          value: isNationality ? value.nationality : value.country_of_residence,
          display: isNationality ? value.nationality_name : value.country_of_residence_name
        }
      })
    }

    const handleFieldChange = (value: { [fieldName: string]: string }) => {
      const fieldName = Object.keys(value)[0]
      let display = ""

      if (field.options.length > 0) {
        display = field.options.find((op) => op.value === value[fieldName])?.label || ""
      }

      const newCurrentFieldValue = {
        [fieldName]: {
          value: value[fieldName],
          display
        }
      }

      if (fieldName === EMPLOYMENT_STATUS_FIELD_NAME) {
        let newFieldValues = {}
        const hideFields = EMPLOYMENT_STATUS_VALUES_HIDE_FIELDS.includes(value[fieldName])

        Object.keys(fieldValues).map((fieldKey) => {
          if (fieldKey === EMPLOYMENT_STATUS_FIELD_NAME) {
            newFieldValues = {
              ...newFieldValues,
              ...newCurrentFieldValue
            }
          } else {
            newFieldValues = {
              ...newFieldValues,
              [fieldKey]: {
                ...fieldValues[fieldKey],
                hide: hideFields
              }
            }
          }
        })

        setFieldValues(newFieldValues)
      } else {
        setFieldValues({
          ...fieldValues,
          ...newCurrentFieldValue
        })
      }
    }

    const commonProps = {
      currentValue: fieldValues[field.fieldName].value,
      placeholder: field.label,
      fieldName: field.fieldName,
      required: field.required,
      controlledFieldsDisplay: [],
      disableClearInputFields: [
        COUNTRY_OF_RESIDENCE_FIELD_NAME,
        "au_state",
        "province",
        NATIONALITY_FIELD_NAME,
        EMPLOYMENT_STATUS_FIELD_NAME,
        EMPLOYMENT_INDUSTRY_FIELD_NAME,
        JOB_ROLE_FIELD_NAME,
        "annual_income",
        "asset_worth"
      ]
    }

    if (isNationality || isCountryOfResidence) {
      return (
        <Searchfield
          {...commonProps}
          api={"finsa/countries?nationality=true"}
          options={[]}
          variation={FieldVariation.Searchfield}
          handleChange={handleSearchfieldChange}
          sx={searchfieldStyle}
          ignoreJurisdictionChange
        />
      )
    }

    if (field.options.length > 0) {
      let options = field.options

      if (field.fieldName === JOB_ROLE_FIELD_NAME) {
        const employmentIndustryValue = fieldValues[EMPLOYMENT_INDUSTRY_FIELD_NAME].value
        if (!employmentIndustryValue) {
          options = []
        } else {
          options = field.options.filter((opt) => opt.value.startsWith(employmentIndustryValue))
        }
      }

      return (
        <Searchfield
          {...commonProps}
          options={options}
          variation={FieldVariation.Searchfield}
          handleChange={handleFieldChange}
          sx={fieldStyle}
        />
      )
    }

    return <Textfield {...commonProps} textInputSx={inputStyle} handleChange={handleFieldChange} sx={fieldStyle} />
  }

  const saveAndClose = () => {
    updateCurrentStep(Step.Display)
    updateFields(fieldValues)
  }

  const displayEditStep = () => {
    const onSaveClick = () => {
      const brand = appMetadata?.clients[0].brand || ""

      if (area === Area.Finances && ["eu", "au"].includes(brand)) {
        const values = Object.keys(fieldValues).map((field) => fieldValues[field].value)
        const lowestBands = fields.map((field) => field.options[field.options.length - 1].value)
        if (values.includes(lowestBands[0]) && values.includes(lowestBands[1])) {
          setDisplayFinancesModal(true)
        } else {
          saveAndClose()
        }
      } else {
        saveAndClose()
      }
    }

    const disabledSaveButton = () => {
      // The button is disabled if a required field is not populated
      const emptyMandatoryFields = fields.filter(
        (field) => !fieldValues[field.fieldName].value && field.required && checkDisplayField(field)
      )
      if (emptyMandatoryFields.length > 0) {
        return true
      }

      // The button is disabled if not field's value was updated
      return fields.filter((field) => field.value !== fieldValues[field.fieldName].value).length === 0
    }

    if (currentStep === Step.Edit && !deactivated) {
      return (
        <Collapse>
          <Row margin="30px 0 0 0" gap="30">
            {fields.map((field) => (
              <Fragment key={field.fieldName}>{displayField(field)}</Fragment>
            ))}
          </Row>
          <Row margin="30px 0 0 0">
            <ActionButton
              displayText="Save"
              onClick={onSaveClick}
              sx={saveButtonStyle}
              disabled={disabledSaveButton()}
            />
          </Row>
        </Collapse>
      )
    }
    return null
  }

  // Finances Modal
  const [displayFinancesModal, setDisplayFinancesModal] = useState(false)
  const closeFinancesModal = () => {
    setDisplayFinancesModal(false)
  }
  const confirmFinances = () => {
    saveAndClose()
    closeFinancesModal()
  }

  return (
    <>
      <Container data-testid="kyc-details-container" deactivated={deactivated}>
        {displayTitleAndButton()}
        <TransitionGroup>
          {displayDisplayStep()}
          {displayEditStep()}
        </TransitionGroup>
      </Container>
      <KYCFinancesModal show={displayFinancesModal} handleClose={closeFinancesModal} handleConfirm={confirmFinances} />
    </>
  )
}

export default KYCDetails
