import { useEffect, useState } from "react"
import { Elements } from "prismic-reactjs"
import { getRichText } from "utils"
import { useAccountData, useModal } from "hooks"

const AccountPendingModal = () => {
  const { appMetadata, userMetadata } = useAccountData()
  const { openModal, displayModal, closeModal } = useModal()

  const [isInPendingStatus, setIsInPendingStatus] = useState(false)

  useEffect(() => {
    const cached = localStorage.getItem("has_canx_pending_modal")
    const hasCancelledModalBefore = cached === "true"

    if (appMetadata && appMetadata.clients && appMetadata.clients.length > 0) {
      const justPending = appMetadata.clients.filter((each) =>
        ["Pending", "Alert", "Review", "Created"].includes(each.state)
      )

      setIsInPendingStatus(justPending && justPending.length > 0 && !hasCancelledModalBefore)
    }
  }, [appMetadata])

  const close = () => {
    localStorage.setItem("has_canx_pending_modal", "true")
    closeModal()
  }

  useEffect(() => {
    if (isInPendingStatus) {
      const displayName = userMetadata?.personal?.first_name || userMetadata?.first_name || ""
      openModal({
        title: getRichText([
          {
            text: `Thanks for your application${displayName ? `, ${displayName}` : ""}!`,
            type: Elements.heading2
          }
        ]),
        text: getRichText([
          {
            text: "We are working hard to set up your account as quickly as possible. Please look out for an email from us with an update on your application.",
            type: Elements.paragraph
          }
        ]),
        handleClose: close,
        buttons: [
          {
            text: "Continue",
            onClick: close
          }
        ]
      })
    } else {
      closeModal()
    }
  }, [isInPendingStatus])

  return displayModal()
}

export default AccountPendingModal
