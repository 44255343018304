import styled from "@emotion/styled"
import { darkBlueColor, whiteColor } from "styles"

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

export const PlaceholderNavbar = styled.div<{ isDesktop: boolean }>`
  height: 88px;
  background-color: ${(props) => (props.isDesktop ? darkBlueColor : whiteColor)};
`
