import React, { useEffect } from "react"
import ResizeObserver from "resize-observer-polyfill"
import { useWindowSize } from "hooks"
import { DisclaimerContext } from "shared"

// forward ref to the ref passed down
export const useDisclaimerHeight = (
  ref: React.RefObject<HTMLElement>,
  dismissed: boolean, // dismissed passed to use in effect dependency array
  isLoaded: boolean,
  expanded: boolean
) => {
  const { height, setHeight } = React.useContext(DisclaimerContext)
  const windowSize = useWindowSize()

  // use ResizeObserver to update the height when the disclaimer is resized
  useEffect(() => {
    if (!ref.current) return // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        setHeight(ref.current.offsetHeight)
      }
    })
    resizeObserver.observe(ref.current)
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect() // clean up
  }, [])

  // update the height when the window is resized
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight)
    }
  }, [ref, windowSize.width, dismissed, expanded])

  useEffect(() => {
    // after 1ms to allow the disclaimer to render
    setTimeout(() => {
      if (ref.current) {
        setHeight(ref.current.offsetHeight)
      }
    }, 1)
  }, [isLoaded])

  if (!ref.current) return 0

  return height
}
