import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { LoginType } from "redux/types"
import { getRtkQueryTagTypes } from "redux/utils"

export const socialsApi = createApi({
  reducerPath: "socialsapi",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_REACT_APP_API_URL
  }),
  endpoints: (builder) => ({
    socialLogin: builder.mutation<
      LoginType,
      {
        body: {
          email: string
        }
      }
    >({
      query: ({ body }) => ({
        url: "/social",
        method: "POST",
        body
      })
    })
  })
})

export const { useSocialLoginMutation } = socialsApi
