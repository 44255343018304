import React from "react"
import Image from "next/image"
import { ActionButton } from "components"
import { useAccountData } from "hooks"
import TradingViewLogo from "./TradingViewLogo.svg"
import ConnectIcon from "./ConnectIcon.svg"
import { Container, connectButtonStyle } from "./TradingViewCTA.styled"

const TradingViewCTA = () => {
  const { connectTradingView, shouldDisplayTradingViewButton } = useAccountData()
  if (!shouldDisplayTradingViewButton) {
    return null
  }

  const connectIcon = {
    src: ConnectIcon,
    alt: "Connect",
    height: 15,
    width: 8
  }

  return (
    <Container>
      <Image src={TradingViewLogo} alt="Trading View" height={23} width={119} />
      <ActionButton
        id="connect-to-tv-cta"
        displayText="Connect"
        onClick={connectTradingView}
        sx={connectButtonStyle}
        image={connectIcon}
      />
    </Container>
  )
}

export default TradingViewCTA
