import React from "react"
import { formatCurrencyValue, getCurrencySymbol } from "utils"
import { Container, EquityValue } from "./Equity.styled"

type Props = {
  equity: string
  currency: string
}

const Equity = ({ equity, currency }: Props) => {
  const currencySymbol = `${getCurrencySymbol(currency)} `
  const formattedBalance = formatCurrencyValue(equity)
  return (
    <Container>
      Equity:
      <EquityValue>
        {currencySymbol}
        {formattedBalance}
      </EquityValue>
    </Container>
  )
}

export default Equity
