import React, { ReactElement } from "react"
import { Paper, Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TableRow } from "@mui/material"
import { TableHeaderType } from "components"
import { muiTableStyle, muiTableHeadCell, muiTableBodyRow } from "./BasicTable.styled"

type Props = {
  headers: TableHeaderType[]
  rows: {
    id: string
    columns: {
      [field: string]: {
        align: TableCellProps["align"]
        display: () => ReactElement | string
      }
    }
  }[]
}

const BasicTable = ({ headers, rows }: Props) => {
  const fields = headers.map((header) => header.field)

  return (
    <TableContainer component={Paper}>
      <Table sx={muiTableStyle} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                align={header.align || ("left" as TableCellProps["align"])}
                sx={muiTableHeadCell(header)}
                key={header.field}
              >
                {header.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} sx={muiTableBodyRow}>
              {fields.map((field) => (
                <TableCell key={`${JSON.stringify(row)}-${field}`} align={row.columns[field].align || "left"}>
                  {row.columns[field].display()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
