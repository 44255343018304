import React from "react"
import { accentGreenColor, mainColor, whiteColor } from "styles"
import { Svg } from "./AnimatedLogo.styled"

type Props = {
  fontColor?: string
  width?: number
  height: number
  withoutText?: boolean
  onClick: () => void
  hasLink?: boolean
}

const AnimatedDesktopLogo = ({ fontColor = whiteColor, width, height, withoutText, onClick, hasLink }: Props) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox={withoutText ? "0 0 60 60" : "0 0 387 60"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      cursor={hasLink ? "pointer" : "initial"}
    >
      <circle id="circle-white" cx="30" cy="30" r="30" fill={whiteColor} />
      <path
        id="circle1"
        d="M39.6375 56.3018L30.1236 35.8504L20.5786 56.3152L17.3532 49.3994L30.1259 22.0078H30.137L39.6553 42.4214L53.9139 11.8578C51.124 8.18487 47.5251 5.20444 43.3966 3.14797C39.2681 1.0915 34.7211 0.0143433 30.1088 0.000142266C25.4964 -0.0140588 20.9429 1.03508 16.8018 3.06609C12.6607 5.0971 9.04351 8.0553 6.23109 11.711H47.5275L44.7937 17.5723H25.7215L14.1122 42.4681L10.8779 35.5346L19.2529 17.5723H2.6898C0.619905 22.1262 -0.278834 27.1252 0.0753099 32.1149C0.429454 37.1045 2.02525 41.9265 4.71758 46.1424C7.40991 50.3582 11.1134 53.8342 15.4912 56.2543C19.8691 58.6743 24.7824 59.9616 29.7845 59.9992C34.7865 60.0367 39.7187 58.8233 44.1323 56.4692C48.546 54.1152 52.3012 50.6952 55.0566 46.5202C57.8119 42.3453 59.4799 37.5478 59.9089 32.564C60.3379 27.5802 59.5143 22.5683 57.513 17.9839L39.6375 56.3018Z"
        fill={mainColor}
      />
      <path
        id="diamond"
        d="M57.3106 4.98438L60.8363 11.2884L57.5175 18.0017L53.905 11.849L57.3106 4.98438Z"
        fill={accentGreenColor}
      />
      {!withoutText && (
        <g id="letters" fill={fontColor}>
          <path d="M 88.785156 40.519531 C 88.785156 40.671875 88.636719 40.796875 88.457031 40.796875 L 82.046875 40.796875 C 81.863281 40.796875 81.71875 40.675781 81.71875 40.519531 L 81.972656 29.941406 L 81.828125 23.34375 L 74.980469 23.34375 C 74.800781 23.34375 74.65625 23.222656 74.65625 23.066406 L 74.65625 19.484375 C 74.65625 19.335938 74.800781 19.207031 74.980469 19.207031 L 95.519531 19.207031 C 95.703125 19.207031 95.847656 19.328125 95.847656 19.484375 L 95.847656 23.066406 C 95.847656 23.222656 95.703125 23.34375 95.519531 23.34375 L 88.675781 23.34375 L 88.53125 29.941406 Z M 88.785156 40.519531 " />
          <path d="M 125.042969 40.453125 C 125.1875 40.640625 125.078125 40.792969 124.828125 40.792969 L 117.726562 40.792969 C 117.546875 40.792969 117.398438 40.730469 117.328125 40.605469 L 113.667969 35.363281 L 111.929688 33.203125 L 109.285156 33.203125 L 109.394531 40.515625 C 109.394531 40.664062 109.25 40.792969 109.070312 40.792969 L 102.875 40.792969 C 102.691406 40.792969 102.546875 40.671875 102.546875 40.515625 L 102.65625 30.152344 L 102.546875 19.484375 C 102.546875 19.335938 102.691406 19.207031 102.875 19.207031 L 113.851562 19.207031 C 120.589844 19.207031 124.390625 21.554688 124.390625 26.210938 C 124.390625 29.265625 122.472656 31.484375 118.957031 32.53125 L 125.042969 40.460938 Z M 109.359375 23.242188 L 109.285156 29.132812 L 114.210938 29.132812 C 116.023438 29.132812 117.871094 28.363281 117.871094 26.324219 C 117.871094 24.289062 116.496094 23.242188 114.03125 23.242188 Z M 109.359375 23.242188 " />
          <path d="M 148.699219 19.425781 L 156.957031 40.488281 C 157.03125 40.707031 156.882812 40.796875 156.667969 40.796875 L 150.402344 40.796875 C 150.21875 40.796875 150.074219 40.707031 150.039062 40.550781 L 148.335938 35.832031 L 139.460938 35.832031 L 137.832031 40.550781 C 137.792969 40.707031 137.648438 40.796875 137.46875 40.796875 L 131.238281 40.796875 C 131.019531 40.796875 130.910156 40.707031 130.984375 40.488281 L 139.425781 19.425781 C 139.496094 19.273438 139.640625 19.207031 139.824219 19.207031 L 148.335938 19.207031 C 148.515625 19.207031 148.625 19.269531 148.699219 19.425781 Z M 143.769531 23.21875 L 140.980469 31.359375 L 146.707031 31.359375 Z M 143.769531 23.21875 " />
          <path d="M 163.441406 40.792969 C 163.261719 40.792969 163.113281 40.671875 163.113281 40.515625 L 163.261719 30.152344 L 163.113281 19.484375 C 163.113281 19.335938 163.261719 19.207031 163.441406 19.207031 L 172.824219 19.207031 C 180.214844 19.207031 186.769531 22.292969 186.769531 30.222656 C 186.769531 38.148438 180.285156 40.796875 172.894531 40.796875 L 163.441406 40.796875 Z M 169.851562 30.027344 L 169.960938 36.625 L 173.183594 36.625 C 177.097656 36.625 179.777344 34.804688 179.777344 30.179688 C 179.777344 25.550781 176.953125 23.332031 173.039062 23.332031 L 169.960938 23.332031 Z M 169.851562 30.027344 " />
          <path d="M 212.597656 36.910156 L 212.597656 40.519531 C 212.597656 40.671875 212.453125 40.796875 212.269531 40.796875 L 194.015625 40.796875 C 193.832031 40.796875 193.6875 40.675781 193.6875 40.519531 L 193.871094 30.191406 L 193.6875 19.484375 C 193.6875 19.335938 193.832031 19.207031 194.015625 19.207031 L 211.980469 19.207031 C 212.164062 19.207031 212.308594 19.328125 212.308594 19.484375 L 212.308594 23.066406 C 212.308594 23.222656 212.164062 23.34375 211.980469 23.34375 L 200.5 23.34375 L 200.425781 27.382812 L 210.859375 27.382812 C 211.039062 27.382812 211.183594 27.503906 211.183594 27.664062 L 211.183594 31.242188 C 211.183594 31.398438 211.039062 31.519531 210.859375 31.519531 L 200.425781 31.519531 L 200.5 36.644531 L 212.269531 36.644531 C 212.453125 36.644531 212.597656 36.765625 212.597656 36.921875 Z M 212.597656 36.910156 " />
          <path d="M 237.445312 40.519531 C 237.445312 40.671875 237.300781 40.796875 237.121094 40.796875 L 231.613281 40.796875 C 231.433594 40.796875 231.289062 40.675781 231.289062 40.519531 L 231.398438 30.09375 L 231.289062 19.484375 C 231.289062 19.335938 231.433594 19.207031 231.613281 19.207031 L 238.75 19.207031 C 238.933594 19.207031 239.078125 19.269531 239.148438 19.394531 L 248.023438 32.839844 L 247.84375 29.632812 L 247.84375 19.484375 C 247.84375 19.335938 247.988281 19.207031 248.167969 19.207031 L 253.675781 19.207031 C 253.855469 19.207031 254 19.328125 254 19.484375 L 253.855469 30.09375 L 254 40.519531 C 254 40.671875 253.855469 40.796875 253.675781 40.796875 L 246.320312 40.796875 C 246.140625 40.796875 246.03125 40.738281 245.960938 40.609375 L 237.265625 27.289062 L 237.445312 31.078125 Z M 237.445312 40.519531 " />
          <path d="M 277.910156 19.425781 L 286.167969 40.488281 C 286.242188 40.707031 286.097656 40.796875 285.878906 40.796875 L 279.613281 40.796875 C 279.429688 40.796875 279.285156 40.707031 279.25 40.550781 L 277.546875 35.832031 L 268.671875 35.832031 L 267.042969 40.550781 C 267.003906 40.707031 266.859375 40.796875 266.679688 40.796875 L 260.449219 40.796875 C 260.230469 40.796875 260.125 40.707031 260.195312 40.488281 L 268.636719 19.425781 C 268.707031 19.273438 268.851562 19.207031 269.035156 19.207031 L 277.546875 19.207031 C 277.726562 19.207031 277.835938 19.269531 277.910156 19.425781 Z M 272.984375 23.21875 L 270.195312 31.359375 L 275.917969 31.359375 Z M 272.984375 23.21875 " />
          <path d="M 301.5625 40.519531 C 301.5625 40.671875 301.417969 40.796875 301.238281 40.796875 L 294.824219 40.796875 C 294.644531 40.796875 294.5 40.675781 294.5 40.519531 L 294.753906 29.941406 L 294.609375 23.34375 L 287.761719 23.34375 C 287.582031 23.34375 287.4375 23.222656 287.4375 23.066406 L 287.4375 19.484375 C 287.4375 19.335938 287.582031 19.207031 287.761719 19.207031 L 308.300781 19.207031 C 308.484375 19.207031 308.628906 19.328125 308.628906 19.484375 L 308.628906 23.066406 C 308.628906 23.222656 308.484375 23.34375 308.300781 23.34375 L 301.453125 23.34375 L 301.308594 29.941406 Z M 301.5625 40.519531 " />
          <path d="M 322.285156 40.519531 C 322.285156 40.671875 322.140625 40.796875 321.957031 40.796875 L 315.835938 40.796875 C 315.65625 40.796875 315.511719 40.675781 315.511719 40.519531 L 315.65625 30.09375 L 315.511719 19.484375 C 315.511719 19.335938 315.65625 19.207031 315.835938 19.207031 L 321.957031 19.207031 C 322.140625 19.207031 322.285156 19.328125 322.285156 19.484375 L 322.140625 30.09375 Z M 322.285156 40.519531 " />
          <path d="M 329.421875 30.0625 C 329.421875 23.21875 334.5625 18.679688 342.640625 18.679688 C 350.71875 18.679688 355.898438 23.089844 355.898438 30.0625 C 355.898438 36.570312 350.683594 41.320312 342.640625 41.320312 C 334.601562 41.320312 329.421875 36.601562 329.421875 30.0625 Z M 348.910156 30.0625 C 348.910156 25.777344 346.808594 23 342.640625 23 C 338.476562 23 336.375 25.65625 336.375 30.0625 C 336.375 34.472656 338.585938 37 342.679688 37 C 346.769531 37 348.910156 34.34375 348.910156 30.0625 Z M 348.910156 30.0625 " />
          <path d="M 368.976562 40.519531 C 368.976562 40.671875 368.832031 40.796875 368.652344 40.796875 L 363.144531 40.796875 C 362.964844 40.796875 362.820312 40.675781 362.820312 40.519531 L 362.925781 30.09375 L 362.820312 19.484375 C 362.820312 19.335938 362.964844 19.207031 363.144531 19.207031 L 370.28125 19.207031 C 370.460938 19.207031 370.605469 19.269531 370.679688 19.394531 L 379.554688 32.839844 L 379.371094 29.632812 L 379.371094 19.484375 C 379.371094 19.335938 379.519531 19.207031 379.699219 19.207031 L 385.207031 19.207031 C 385.386719 19.207031 385.53125 19.328125 385.53125 19.484375 L 385.386719 30.09375 L 385.53125 40.519531 C 385.53125 40.671875 385.386719 40.796875 385.207031 40.796875 L 377.851562 40.796875 C 377.671875 40.796875 377.5625 40.738281 377.488281 40.609375 L 368.796875 27.289062 L 368.976562 31.078125 Z M 368.976562 40.519531 " />
        </g>
      )}
      <defs>
        <radialGradient id="shiningGradient" cx="50%" cy="50%" r="10%" fx="50%" fy="50%">
          <stop offset="0%" stopColor="rgba(255,255,255,1)" />
          <stop offset="100%" stopColor="rgba(255,255,255,0.1)" />
        </radialGradient>
      </defs>
      <circle id="circle2" cx="57" cy="11" r="7" fill="#49ceae" />
      <path
        id="diamond"
        d="M57.3106 4.98438L60.8363 11.2884L57.5175 18.0017L53.905 11.849L57.3106 4.98438Z"
        fill={accentGreenColor}
      />
      <circle id="circle3" cx="57" cy="11" r="13" fill="url(#shiningGradient)" />
    </Svg>
  )
}

export default AnimatedDesktopLogo
