import React, { Fragment } from "react"
import { Checkbox as MUICheckbox, FormControlLabel } from "@mui/material"
import { RichText, CheckboxType, ExtraFieldProps } from "components"
import {
  Container,
  Unchecked,
  Checked,
  TextWithLink,
  muiFormControlLabelStyle,
  muiCheckboxStyle
} from "./Checkbox.styled"

type Props = CheckboxType &
  ExtraFieldProps & {
    onTextClick?: (value: string) => void
    allTextClickable?: boolean
    hasBoxContainer?: boolean
  }

const Checkbox = ({
  label,
  value,
  currentValue,
  required,
  handleChange,
  fieldName,
  content,
  onTextClick,
  allTextClickable,
  hasBoxContainer = false
}: Props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ [fieldName]: event.target.checked ? value : "" })
  }

  const onAllTextClick = (e) => {
    e.preventDefault()
    onTextClick("")
  }

  const displayLabel = () => {
    if (content.length > 0) {
      const displayContent = (c: { text: string; clickValue: string }, index: number) => {
        const key = `${c.text}-${index}`

        if (c.clickValue) {
          const onClickHandler = (e) => {
            e.preventDefault()
            onTextClick(c.clickValue)
          }

          return (
            <Fragment key={key}>
              <TextWithLink onClick={onClickHandler}>{c.text}</TextWithLink>{" "}
            </Fragment>
          )
        }

        return <Fragment key={key}>{`${c.text} `}</Fragment>
      }
      return <p>{content.map((c, index) => displayContent(c, index))}</p>
    }

    return (
      <>
        {allTextClickable ? (
          <TextWithLink onClick={onAllTextClick}>
            <RichText field={label} />
          </TextWithLink>
        ) : (
          <RichText field={label} />
        )}
        {!required && <p>[Optional]</p>}
      </>
    )
  }

  return (
    <Container hasBoxContainer={hasBoxContainer}>
      <FormControlLabel
        control={
          <MUICheckbox
            onChange={onChange}
            checked={!!currentValue}
            sx={muiCheckboxStyle}
            icon={<Unchecked />}
            checkedIcon={<Checked />}
          />
        }
        label={displayLabel()}
        sx={muiFormControlLabelStyle(hasBoxContainer ? "center" : "flex-start")}
      />
    </Container>
  )
}

export default Checkbox
