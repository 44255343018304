/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/react"
import { Content } from "@prismicio/client"
import { createTN2Client } from "./tn2-prismic"

export const getTranslations = async (client, uid, locale) => {
  let pageTranslation: Content.TranslationDocument | null = null
  let welcomePageLeftSideTranslation: Content.TranslationDocument | null = null
  let validationMessagesTranslation: Content.TranslationDocument | null = null
  let sectionsTranslation: Content.TranslationDocument | null = null

  if (!pageTranslation) {
    try {
      pageTranslation = await client.getByUID("translation", uid as string, {
        lang: locale
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }
  if (!sectionsTranslation) {
    try {
      sectionsTranslation = await client.getByUID("translation", "sections" as string, {
        lang: locale
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  if (!welcomePageLeftSideTranslation) {
    try {
      welcomePageLeftSideTranslation = await client.getByUID("translation", "welcome-page-left-side" as string, {
        lang: locale
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  try {
    validationMessagesTranslation = await client.getByUID("translation", "validation-messages" as string, {
      lang: locale
    })
  } catch (error) {
    Sentry.captureException(error)
  }

  const clientTN2 = createTN2Client()
  let supportedLanguages: any = null
  let arr = []
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    supportedLanguages = (await clientTN2.getSingle("languages", {
      lang: locale
    })) as any

    if (
      supportedLanguages &&
      supportedLanguages.data &&
      supportedLanguages.data.languages &&
      supportedLanguages.data.languages[0]
    ) {
      arr = supportedLanguages.data.languages.filter((lang) => lang.enable === true)
    } else {
      arr = [{ language_code: "en", language_display_name: "English" }]
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  let disclaimerTranslations
  try {
    disclaimerTranslations = (await clientTN2.getAllByUIDs("translation", ["disclaimer"], {
      lang: "*"
    })) as any
  } catch (error) {
    Sentry.captureException(error)
  }

  return {
    pageTranslation,
    welcomePageLeftSideTranslation,
    validationMessagesTranslation,
    sectionsTranslation,
    supportedLanguages: arr,
    disclaimerTranslations
  }
}

export const getValidationRulesAndMessages = async (client) => {
  const validationMessagesDocument = await client.getSingle("validationmessages")

  return {
    validation: {
      messages: validationMessagesDocument
    }
  }
}

export const getRiskWarnings = async (client) => {
  let riskWarnings

  try {
    riskWarnings = await client.getAllByType("disclaimer", { lang: "*" })
  } catch (error) {
    Sentry.captureException(error)
    return null
  }

  return riskWarnings
}
