import { darkBlueColor, shadowColor, redColor, whiteColor, accentGreenColor } from "styles"

export const snackbarStyle = {
  backgroundColor: whiteColor,
  color: darkBlueColor,
  fontSize: "15px",
  lineHeight: "20px",
  borderRadius: "73px",
  marginTop: "25px",
  padding: "10px 75px 10px 15px",
  minWidth: "calc(100% - 30px)",
  minHeight: "40px",
  boxShadow: `0px 0px 20px 0px ${shadowColor}`,
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
}

export const iconStyle = (success = true) => ({
  color: success ? accentGreenColor : redColor,
  fontSize: "28px"
})
