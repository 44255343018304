import React, { ReactNode, useRef, useEffect } from "react"
import ActionButtons from "components/pages/signup/Common/ActionButtons"
import BottomInfoBoxes from "components/pages/signup/Common/BottomInfoBoxes"
import { useDesktopXLModeDown, useMobileMode, useSignupData } from "hooks"
import { SliceType } from "utils"
import { Column, Row } from "styles"
import { Container } from "./BottomArea.styled"

type Props = {
  children?: ReactNode
  slices?: SliceType[]
  onContinueClick?: () => void
  prevStep?: string
  canSkip?: boolean
  continueButtonDisabled?: boolean
}

const BottomArea = ({
  children,
  slices,
  onContinueClick,
  prevStep,
  canSkip = false,
  continueButtonDisabled = false
}: Props) => {
  const isMobile = useMobileMode()
  const isDesktopXLDown = useDesktopXLModeDown()
  const { updateBottomAreaHeight } = useSignupData()

  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    if (ref && ref.current) {
      if (isDesktopXLDown && !isMobile) {
        updateBottomAreaHeight(10)
      } else {
        updateBottomAreaHeight(ref.current.offsetHeight)
      }
    }
  }, [ref?.current?.offsetHeight, isMobile, isDesktopXLDown])

  const displayContent = () => {
    if (children) {
      return <>{children}</>
    }
    return (
      <>
        <BottomInfoBoxes slices={slices} />
        <Row justifyContent={prevStep ? "space-between" : "flex-end"} margin="10px 0 0 0">
          <ActionButtons
            slices={slices}
            onClick={onContinueClick}
            prevStep={prevStep}
            canSkip={canSkip}
            continueButtonDisabled={continueButtonDisabled}
          />
        </Row>
      </>
    )
  }

  return (
    <Container ref={ref} isDesktopXLDown={isDesktopXLDown} isMobile={isMobile}>
      <Column gap="20px" flex={isMobile ? "1" : "initial"} justifyContent={isMobile ? "flex-end" : "initial"}>
        {displayContent()}
      </Column>
    </Container>
  )
}

export default BottomArea
