import { useState, useEffect } from "react"

export const useGeolocation = () => {
  const [geolocation, setGeolocation] = useState<{ country: string }>({
    country: ""
  })

  useEffect(() => {
    const getIpAndLocation = async () => {
      const key = process.env.NEXT_PUBLIC_IPFY_KEY
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_IPFY_URL}?format=json`)
      const { ip } = await res.json()
      const locationRes = await fetch(
        `${process.env.NEXT_PUBLIC_GEO_IPFY_URL}?${new URLSearchParams({
          apiKey: key as string,
          ipAddress: ip as string
        })}`
      )
      const { location } = await locationRes.json()
      setGeolocation(location)
    }
    getIpAndLocation()
  }, [])

  return geolocation
}
