import styled from "@emotion/styled"

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const CardContentCenterAligned = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const muiCardStyle = (sx: object) => ({
  width: 350,
  maxWidth: "100%",
  minHeight: 130,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  ...sx
})

export const muiCardActionsStyle = {
  paddingLeft: "16px",
  paddingBottom: "20px"
}
