import React, { ReactNode, useRef, useEffect } from "react"
import { Popover, SwipeableDrawer } from "@mui/material"
import { Container as Textfield, textfieldStyle } from "components/textfield/Textfield.styled"
import { lightGreyColor, accentGreenColor } from "styles"
import { useMobileMode } from "hooks"
import {
  Container,
  Content,
  TopHandle,
  paperStyle,
  TextfieldWrapper,
  popoverStyle,
  popoverPaperStyle,
  PopoverContainer,
  swipeableDrawerStyle
} from "./Drawer.styled"

type Props = {
  children?: ReactNode
  anchor?: "top" | "right" | "bottom" | "left"
  open: boolean
  toggleDrawer: (toggle: boolean) => void
  hasSearchField?: boolean
  onSearchHandle?: (value: string) => void
  searchValue?: string
  anchorEl?: HTMLElement
  handleClose?: () => void
  label?: string
}

const Drawer = ({
  children,
  anchor = "bottom",
  open,
  toggleDrawer,
  hasSearchField,
  onSearchHandle,
  searchValue,
  anchorEl,
  handleClose,
  label
}: Props) => {
  const isMobile = useMobileMode()

  const textfieldRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // This setTimeout is needed, otherwise the input won't focus
    setTimeout(() => {
      if (open && textfieldRef && textfieldRef.current) {
        textfieldRef.current.focus()
      }
    }, 100)
  }, [open, textfieldRef])

  const onSearchfieldChange = (event: { target: { value: string } }) => {
    onSearchHandle(event.target.value)
  }

  const displayContent = () => {
    let marginTop = "0"
    if (isMobile) {
      marginTop = hasSearchField ? "110px" : "40px"
    }

    return (
      <>
        {hasSearchField && (
          <TextfieldWrapper mobile={isMobile}>
            <Textfield
              inputRef={textfieldRef}
              label={label || "Search"}
              value={searchValue}
              onChange={onSearchfieldChange}
              sx={textfieldStyle({
                fieldset: {
                  backgroundColor: lightGreyColor,
                  zIndex: -1
                },
                backgroundColor: isMobile ? "initial" : lightGreyColor,
                borderRadius: isMobile ? "initial" : "13px",
                label: { color: accentGreenColor }
              })}
            />
          </TextfieldWrapper>
        )}
        <Content
          marginTop={marginTop}
          minWidth={isMobile ? "initial" : "520px"}
          padding={isMobile ? "0 20px 20px 20px" : "0"}
        >
          {children}
        </Content>
      </>
    )
  }

  if (isMobile) {
    return (
      <SwipeableDrawer
        anchor={anchor}
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        PaperProps={{
          elevation: 0,
          style: paperStyle(hasSearchField)
        }}
        sx={swipeableDrawerStyle}
      >
        <Container>
          <TopHandle />
          {displayContent()}
        </Container>
      </SwipeableDrawer>
    )
  }

  return (
    <Popover
      id="country-code-popover"
      open={open}
      sx={popoverStyle}
      PaperProps={{
        style: popoverPaperStyle
      }}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    >
      <PopoverContainer>{displayContent()}</PopoverContainer>
    </Popover>
  )
}

export default Drawer
