import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { theme } from "styles"

export const TextLogoWrapper = styled.div`
  width: 250px;
  height: 40px;
  display: flex;
  margin-bottom: 3px;
  align-items: center;

  ${theme.breakpoints.down("sm")} {
    width: 250px;
    height: 40px;
  }
  @media (max-width: 380px) {
    display: none;
  }
`

export const LogoWithoutBrandNameWrapper = styled.div`
  width: 44px;
  height: 41px;
  margin-left: 26px;
  margin-right: auto;
  display: flex;
  align-items: center;

  ${theme.breakpoints.down("md")} {
    width: 29px;
    height: 29px;
  }
`

const DELAY = 1

const diamondAnimation = keyframes`
  0% {
    opacity:1;
  }

  20% {
    transform:translateX(-27px) translateY(17px);
  }

  60% {
    transform:translateX(-18px) translateY(38px);
  }

  100% {
    transform:translate(0,0);
    opacity:1;
  }
`

const rotateAnimation = keyframes`
  to {
    transform:rotateY(0)
  }
`

const shineAnimation = keyframes`
  0% {
    transform:scale(0);
  }

  50% {
    transform:scale(1);
  }

  100% {
    transform:scale(0);
  }
`

const appearAnimation = keyframes`
  to {
    opacity:1;
  }
`

export const Svg = styled.svg<{ cursor: string }>`
  #diamond {
    opacity: 0;
    transform: translateX(-32px) translateY(28px);
    animation: ${diamondAnimation} 0.6s linear forwards;
    animation-delay: ${DELAY}s;
  }

  #circle-white,
  #circle1 {
    transform: rotateY(-90deg);
    transform-box: fill-box;
    transform-origin: center;
    animation: ${rotateAnimation} 0.3s linear forwards;
    animation-delay: ${DELAY}s;
  }

  #circle2,
  #circle3 {
    transform-box: fill-box;
    transform-origin: center;
    transform: scale(0);
    animation: ${shineAnimation} 0.9s linear forwards;
    animation-delay: ${DELAY + 0.6}s;
  }

  g#letters path {
    opacity: 0;
    animation: ${appearAnimation} 0.2s linear forwards;
    animation-delay: ${DELAY}s;
  }

  cursor: ${(props) => props.cursor};
`
