import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import { isPerformanceCookieAccepted } from "utils"
import { useSignupData } from "./signupData"

function useHelpscout() {
  const { jurisdiction } = useSignupData()
  const { asPath } = useRouter()
  const currentPage = asPath.split("/").pop()

  const [performanceCookieAccepted, setPerformanceCookieAccepted] = useState(false)
  useEffect(() => {
    setPerformanceCookieAccepted(isPerformanceCookieAccepted())
  }, [])

  const [beaconId, setBeaconId] = useState("f1c62e7e-efa4-4e53-9faf-74f89ef980ef")
  const [beaconJurisdiction, setBeaconJurisdiction] = useState("FSA")
  useEffect(() => {
    setBeaconJurisdiction(jurisdiction)
  }, [jurisdiction])

  useEffect(() => {
    if (beaconJurisdiction) {
      switch (beaconJurisdiction) {
        case "FCA":
          setBeaconId("a49e4382-e1f4-4dfd-834d-23034e7590f4")
          break
        case "ASIC":
          setBeaconId("2eb586a2-946c-45f5-b75f-1141ed570b1a")
          break
        case "SCB":
          setBeaconId("74c7c814-e7a5-4237-a95b-029e7c4c4d0d")
          break
        case "FSA":
          setBeaconId("f1c62e7e-efa4-4e53-9faf-74f89ef980ef")
          break
        case "FSCA":
          setBeaconId("c78636c9-8adb-4eac-aeb5-8691c6bfdede")
          break
        default:
          break
      }
    }
  }, [beaconJurisdiction, beaconId])

  useEffect(() => {
    if (window.Beacon && performanceCookieAccepted && currentPage !== "mt4webplatform") {
      window.Beacon("destroy")
      window.Beacon("init", beaconId)
    }
  }, [beaconId, performanceCookieAccepted, currentPage])
}

export default useHelpscout
