import React, { useMemo } from "react"
import { InfoBox, AllFieldsType, InfoBoxType, SliceNameType } from "components"
import { SliceType, getFieldsAndInfoBoxes } from "utils"
import { useSignupData } from "hooks"

type Props = {
  slices: SliceType[]
}

const BottomInfoBoxes = ({ slices }: Props) => {
  const { getCurrentSignupData } = useSignupData()

  const { fieldsAndInfoBoxes } = getFieldsAndInfoBoxes(slices)

  const infoBoxes = useMemo(() => {
    return fieldsAndInfoBoxes.filter(
      (fieldData: AllFieldsType | InfoBoxType) =>
        fieldData.sliceType === SliceNameType.InfoBox && fieldData.displayAtBottom
    )
  }, [fieldsAndInfoBoxes])

  return (
    <>
      {infoBoxes.map((fieldData: InfoBoxType) => {
        return <InfoBox key={JSON.stringify(fieldData)} signupData={getCurrentSignupData()} {...fieldData} />
      })}
    </>
  )
}

export default BottomInfoBoxes
