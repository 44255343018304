import styled from "@emotion/styled"
import { ListItemButton, MenuList, Paper, Popper, Box, Link, Theme } from "@mui/material"
import { darkBlueColor, whiteColor } from "styles"

export const StyledListItemButton = styled(ListItemButton)<{
  hastitle?: boolean
  href?: string
  target?: string
}>`
  padding-left: ${({ hastitle }) => (hastitle ? "48px" : " 32px")};

  & > .MuiTypography-root {
    margin-bottom: 0px;
    color: ${whiteColor};
  }
`

export const StyledPopper = styled(Popper)`
  z-index: 1000;
  border-top: 1px solid #384553;
`

export const StyledPaper = styled(Paper)`
  width: 100vw;
  min-height: 348px;
  background-color: ${darkBlueColor};
  border-radius: 0;
  color: ${whiteColor};
`

export const StyledGridBox = styled(Box)<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  padding: 32px 62px;
`

export const StyledMenuList = styled(MenuList)`
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  ${(props: { theme: Theme }) => props.theme.breakpoints.up("xl")} {
    margin: 0 30px;
  }
`

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const StyledLink = styled(Link)<{ titlesMinHeight: number }>`
  min-height: ${(props) => props.titlesMinHeight}px;
`

export const tabletListStyle = {
  display: "flex",
  flexDirection: "column"
}

export const fadeStyle = {
  transformOrigin: "top center"
}
