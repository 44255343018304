import styled from "@emotion/styled"
import { TextField as MUITextfield } from "@mui/material"
import { lightGreyColor, whiteColor } from "styles"
import type { WidgetProps } from "components/pages/auth-widget/auth-widget"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const PasswordField = styled(MUITextfield)<{
  widgetprops?: WidgetProps[] | WidgetProps
}>`
  fieldset {
    border-radius: 13px;
    border-color: ${lightGreyColor};
    background-color: ${(props) => props.widgetprops && "rgb(97 97 97 / 50%)"};
    color: ${(props) => props.widgetprops && "white"};
  }
  label {
    color: ${(props) => props.widgetprops && "#92a2b3"};
  }
  .MuiOutlinedInput-input {
    color: ${(props) => props.widgetprops && "#92a2b3"};
  }
`

export const PasswordRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 5px;

  p {
    font-size: 13px;
    line-height: 20px;
  }
`

export const passwordFieldStyle = (sx: object) => ({
  width: "100%",
  ...sx
})

export const eyeIconStyle = {
  color: whiteColor
}
