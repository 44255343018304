import React, { ReactNode } from "react"

type Props = {
  children?: ReactNode
  index: number
  value: number
}

const TabPanel = (props: Props) => {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
    >
      {value === index && children}
    </div>
  )
}

export default TabPanel
