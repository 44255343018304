import { RichTextField } from "@prismicio/types"
import { accentGreenColor, darkBlueColor, darkGreyColor, lightGreyColor, whiteColor } from "styles"
import { isRichTextValid } from "utils"

export const muiCardStyle = (
  selected: boolean,
  textfieldOnClick: boolean,
  textfieldMode: boolean,
  title: RichTextField
) => ({
  padding: "15px 20px",
  marginBottom: "20px",
  cursor: "pointer",
  backgroundColor: selected && !textfieldOnClick ? darkGreyColor : lightGreyColor,
  color: selected && !textfieldOnClick ? whiteColor : darkBlueColor,
  transition: "background-color .2s, color .2s, box-shadow .2s",
  borderRadius: "25px",
  border: textfieldMode ? `1px solid ${accentGreenColor}` : 0,
  "> p": {
    fontSize: "14px",
    color: selected && !textfieldOnClick ? whiteColor : darkBlueColor
  },
  "> h3": {
    fontSize: "16px",
    fontWeight: 700,
    color: selected && !textfieldOnClick ? whiteColor : darkBlueColor
  },
  textAlign: isRichTextValid(title) ? "left" : "center"
})

export const textfieldStyle = {
  padding: 0,
  textAlign: "center"
}

export const textfieldInputPropsStyle = {
  textAlign: "center",
  padding: 0
}
