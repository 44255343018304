import React, { useMemo } from "react"
import { Text } from "components"
import { useMobileOrTabletMode } from "hooks"
import { Container, Content, BackgroundImageWithLink } from "./Article.styled"

export type Props = {
  title: string
  uid: string
  url: string
  lastPublicationDate?: string
  target?: string
  isCover?: boolean
  metaTitle?: string
}

const Article = ({ title, uid, url, target, lastPublicationDate, isCover }: Props) => {
  const isMobileOrTablet = useMobileOrTabletMode()

  const height = useMemo(() => {
    if (isCover) {
      return isMobileOrTablet ? "250px" : "initial"
    }
    return isMobileOrTablet ? "100%" : "50%"
  }, [isMobileOrTablet])

  return (
    <Container role="img" height={height} url={url}>
      <BackgroundImageWithLink href={uid} target={target} passHref url={url} />
      <Content>
        <Text
          text={lastPublicationDate}
          sx={{
            fontFamily: "Montserrat, Helvetica Neue, Arial",
            fontSize: "18px !important"
          }}
        />
        <Text
          text={title}
          variant="h4"
          sx={{
            fontFamily: "Montserrat, Helvetica Neue, Arial",
            fontSize: "26px !important"
          }}
        />
      </Content>
    </Container>
  )
}

export default Article
