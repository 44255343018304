import React from "react"
import { ActionButton, ActionButtonActionType, ActionButtonType } from "components"
import { ObjectWithFieldsType, SignupDataType, SliceType, getActionButtons } from "utils"
import { useSignupData } from "hooks"

type Props = {
  canSkip: boolean
  slices: SliceType[]
  actionButtonAction: (newData: SignupDataType, actionButton?: ActionButtonType) => Promise<void>
}

const SkipButton = ({ canSkip, slices, actionButtonAction }: Props) => {
  const { signupData, currentStepSignupData, stepUid: uid } = useSignupData()

  const actionButtons = getActionButtons(slices)

  if (!canSkip) {
    return null
  }

  const onSkipClick = () => {
    const emptyData = Object.keys(currentStepSignupData).reduce((acc: ObjectWithFieldsType, field) => {
      return {
        ...acc,
        [field]: ""
      }
    }, {})
    const actionButton = actionButtons.find((button: ActionButtonType) =>
      [ActionButtonActionType.Continue, ActionButtonActionType.Submit].includes(button.actionType)
    )
    actionButtonAction({ ...signupData, [uid]: emptyData }, actionButton)
  }

  return (
    <ActionButton
      displayText="Skip"
      variant="outlined"
      onClick={onSkipClick}
      actionType={ActionButtonActionType.Continue}
    />
  )
}

export default SkipButton
