import React, { ReactNode, useState, useEffect } from "react"
import { useRouter } from "next/router"

type RedirectContextProps = {
  redirectAcknowledged: boolean
  setRedirectAcknowledged: (show: boolean) => void
  isChangingRoute: boolean
}

export const RedirectContext = React.createContext<RedirectContextProps>({
  redirectAcknowledged: true,
  setRedirectAcknowledged: () => {},
  isChangingRoute: false
})

interface RedirectContextProviderProps {
  children: ReactNode
}

const RedirectContextProvider: React.FC<RedirectContextProviderProps> = ({ children }) => {
  const [redirectAcknowledged, setRedirectAcknowledged] = useState<boolean>(true)
  const [isChangingRoute, setIsChangingRoute] = useState<boolean>(false)

  const router = useRouter()

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      setIsChangingRoute(true)
    })
  }, [])

  useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      setIsChangingRoute(false)
    })
  }, [])

  useEffect(() => {
    if (!redirectAcknowledged && !isChangingRoute) {
      const timer = setTimeout(() => {
        setRedirectAcknowledged(true)
      }, 5000)
      return () => clearTimeout(timer)
    }
    return () => {}
  }, [redirectAcknowledged, isChangingRoute])

  return (
    <RedirectContext.Provider
      value={{
        redirectAcknowledged,
        setRedirectAcknowledged,
        isChangingRoute
      }}
    >
      {children}
    </RedirectContext.Provider>
  )
}

export default RedirectContextProvider
