import styled from "@emotion/styled"
import { lightGreyColor, mainColor } from "styles"

export const Container = styled.div<{ hasBoxContainer: boolean }>`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.hasBoxContainer ? `1px solid ${lightGreyColor}` : "0")};
  border-radius: 13px;
  padding: ${(props) => (props.hasBoxContainer ? "20px" : "0")};
  text-align: left;

  p {
    font-size: ${(props) => (props.hasBoxContainer ? "17px" : "13px")};
    line-height: 25px;
    font-weight: 400;
  }
`

export const Unchecked = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${mainColor};
  border-radius: 6px;
`

export const Checked = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${mainColor};
  border-radius: 6px;
`

export const TextWithLink = styled.span`
  font-weight: 500;
  text-decoration: underline;
`

export const muiCheckboxStyle = {
  marginRight: "5px"
}

export const muiFormControlLabelStyle = (alignItems: string) => ({
  alignItems
})
