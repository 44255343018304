import React, { useContext, ReactNode, useState, useEffect } from "react"
import { Link, TypographyProps } from "@mui/material"
import { PrismicRichText } from "@prismicio/react"
import { RTLinkNode, RichTextField } from "@prismicio/types"
import ReactMarkdown from "react-markdown"
import { formatText, getId, isRichTextValid } from "utils"
import { useSignupData } from "hooks"
import { DisclaimerContext } from "shared"
import { whiteColor } from "styles"
import { TypographyWithScrollMargin, listStyle } from "./RichText.styled"

type Props = {
  field: RichTextField
  displayEmptyText?: boolean
  indentedList?: boolean
  widget?: boolean
}

const RichText: React.FC<Props & TypographyProps> = (props) => {
  const { getCurrentSignupData } = useSignupData()

  const { field, displayEmptyText, indentedList, widget, ...rest } = props
  const { dismissed, height } = useContext(DisclaimerContext)

  // Hack to avoid className not matching with SSR
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  if (!loaded) {
    return null
  }

  if (!isRichTextValid(field) && !displayEmptyText) {
    return null
  }

  const extraProps = { field, ...rest }

  return (
    <PrismicRichText
      field={formatText(field, getCurrentSignupData())}
      components={{
        paragraph: ({ children }) => (
          <TypographyWithScrollMargin
            variant="body1"
            dismissed={dismissed}
            disclaimerheight={height}
            textcolor={widget ? whiteColor : ""}
            {...extraProps}
          >
            {children}
          </TypographyWithScrollMargin>
        ),
        heading1: ({ children, text }) => (
          <TypographyWithScrollMargin
            dismissed={dismissed}
            disclaimerheight={height}
            id={getId(text)}
            textcolor={widget ? whiteColor : ""}
            variant="h1"
            header="true"
            {...extraProps}
          >
            {children}
          </TypographyWithScrollMargin>
        ),
        heading2: ({ children, text }) => (
          <TypographyWithScrollMargin
            dismissed={dismissed}
            disclaimerheight={height}
            id={getId(text)}
            variant="h2"
            header="true"
            {...extraProps}
          >
            {children}
          </TypographyWithScrollMargin>
        ),
        heading3: ({ children, text }) => (
          <TypographyWithScrollMargin
            dismissed={dismissed}
            disclaimerheight={height}
            id={getId(text)}
            variant="h3"
            header="true"
            {...extraProps}
          >
            {children}
          </TypographyWithScrollMargin>
        ),
        heading4: ({ children, text }) => (
          <TypographyWithScrollMargin
            dismissed={dismissed}
            disclaimerheight={height}
            id={getId(text)}
            variant="h4"
            header="true"
            {...extraProps}
          >
            {children}
          </TypographyWithScrollMargin>
        ),
        heading5: ({ children, text }) => (
          <TypographyWithScrollMargin
            dismissed={dismissed}
            disclaimerheight={height}
            id={getId(text)}
            variant="h5"
            header="true"
            {...extraProps}
          >
            {children}
          </TypographyWithScrollMargin>
        ),
        heading6: ({ children, text }) => (
          <TypographyWithScrollMargin
            dismissed={dismissed}
            disclaimerheight={height}
            id={getId(text)}
            variant="h6"
            header="true"
            {...extraProps}
          >
            {children}
          </TypographyWithScrollMargin>
        ),
        list: ({ children }) => <ul style={listStyle(indentedList) as React.CSSProperties}>{children}</ul>,
        hyperlink: ({ children, node }: { children: ReactNode; node: RTLinkNode }) => {
          // external link that should open in new tab
          if (node?.data?.link_type === "Web" && node?.data?.target === "_blank") {
            return (
              <Link href={node?.data?.url} target="_blank" rel="noopener noreferrer">
                {children}
              </Link>
            )
          }

          // external link that should open in same tab
          if (node?.data?.link_type === "Web" || node?.data?.link_type === "Media") {
            return <Link href={node?.data?.url}>{children}</Link>
          }

          // internal link
          const prefix = node.data.lang && node.data?.lang !== "en-sc" ? `/${node.data?.lang}` : ""
          return <Link href={`${prefix}${node?.data?.url}`}>{children}</Link>
        },
        preformatted: ({ text }) => {
          return <ReactMarkdown>{text}</ReactMarkdown>
        }
      }}
      fallback={<p />}
    />
  )
}

export default RichText
