import React, { useContext } from "react"
import { Field, FieldVariation, SliceNameType } from "components"
import { ObjectWithFieldsType, SignupDataType, addressValidation, getAddressFields, postCodeValidation } from "utils"
import { commonPropsStyle } from "./ManualAddress.styled"
import { Context } from "pages/_app"

type Props = {
  checkValidation: boolean
  signupData: SignupDataType
  fieldName: string
  country: string
  addressLine1Value: string
  updateAddressLine1: (data: ObjectWithFieldsType) => void
  addressLine2Value: string
  updateAddressLine2: (data: ObjectWithFieldsType) => void
  addressCityValue: string
  updateAddressCity: (data: ObjectWithFieldsType) => void
  addressPostcodeValue: string
  updateAddressPostcode: (data: ObjectWithFieldsType) => void
  line1Placeholder: string
  line2Placeholder: string
  cityPlaceholder: string
  postcodePlaceholder: string
}

const ManualAddress = ({
  checkValidation,
  signupData,
  fieldName,
  country,
  addressLine1Value,
  updateAddressLine1,
  addressLine2Value,
  updateAddressLine2,
  addressCityValue,
  updateAddressCity,
  addressPostcodeValue,
  updateAddressPostcode,
  line1Placeholder,
  line2Placeholder,
  cityPlaceholder,
  postcodePlaceholder
}: Props) => {
  const { addressLine1, addressLine2, addressCity, addressPostcode } = getAddressFields(fieldName)

  const commonProps = (firstElement = false) => ({
    controlledFieldsDisplay: [],
    checkValidation,
    required: true,
    signupData,
    sliceType: SliceNameType.Field as SliceNameType.Field,
    sx: firstElement ? {} : commonPropsStyle
  })

  const { validationMessages } = useContext(Context)

  return (
    <>
      <Field
        placeholder={line1Placeholder}
        handleChange={updateAddressLine1}
        variation={FieldVariation.Textfield}
        fieldName={addressLine1}
        currentValue={addressLine1Value}
        extraErrorMessages={addressValidation(addressLine1Value, false, false, validationMessages)}
        {...commonProps(true)}
      />
      <Field
        placeholder={line2Placeholder}
        handleChange={updateAddressLine2}
        variation={FieldVariation.Textfield}
        fieldName={addressLine2}
        currentValue={addressLine2Value}
        extraErrorMessages={addressValidation(addressLine2Value, true, false, validationMessages)}
        {...commonProps()}
        required={false}
      />
      <Field
        placeholder={cityPlaceholder}
        handleChange={updateAddressCity}
        variation={FieldVariation.Textfield}
        fieldName={addressCity}
        currentValue={addressCityValue}
        extraErrorMessages={addressValidation(addressCityValue, false, true, validationMessages)}
        {...commonProps()}
      />
      {country !== "SC" && (
        <Field
          placeholder={postcodePlaceholder}
          handleChange={updateAddressPostcode}
          variation={FieldVariation.Textfield}
          fieldName={addressPostcode}
          currentValue={addressPostcodeValue}
          extraErrorMessages={postCodeValidation(addressPostcodeValue, validationMessages, country)}
          {...commonProps()}
        />
      )}
    </>
  )
}

export default ManualAddress
