import React from "react"
import { InfoBox, InfoBoxType, Text } from "components"
import { ObjectWithFieldsType } from "utils"
import { darkBlueColor, whiteColor } from "styles"
import { InfoBoxesArea, accountTypeTextStyle } from "./MT4AccountBoxes.styled"

type Props = {
  infoBoxes: InfoBoxType[]
  updateAccountType: (fieldName: string, fieldValue: string) => void
  data: ObjectWithFieldsType
  hideTopBorder?: boolean
}

const MT4AccountBoxes = ({ infoBoxes, updateAccountType, data, hideTopBorder = false }: Props) => {
  if (infoBoxes.length > 1) {
    return (
      <InfoBoxesArea hideTopBorder={hideTopBorder}>
        <Text text="Select your account type" sx={accountTypeTextStyle} />
        {infoBoxes.map((infoBox) => (
          <InfoBox
            key={infoBox.fieldValue}
            onClick={() => updateAccountType(infoBox.fieldName, infoBox.fieldValue)}
            {...infoBox}
            defaultTitleColor={
              data[infoBox.fieldName] === infoBox.fieldValue ? infoBox.defaultTitleColor : darkBlueColor
            }
            defaultTextColor={data[infoBox.fieldName] === infoBox.fieldValue ? infoBox.defaultTextColor : darkBlueColor}
            defaultBackgroundColor={
              data[infoBox.fieldName] === infoBox.fieldValue ? infoBox.defaultBackgroundColor : whiteColor
            }
            border={`1px solid ${infoBox.defaultBackgroundColor}`}
          />
        ))}
      </InfoBoxesArea>
    )
  }

  return null
}

export default MT4AccountBoxes
