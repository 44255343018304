import React, { ReactElement, useEffect } from "react"
import AddressSearch, { Address } from "react-loqate"
import { FormControl } from "@mui/material"
import { Text } from "components"
import { lightMainColor } from "styles"
import {
  List,
  TextField,
  optionsListBottomMultiTextBottomStyle,
  optionsListBottomMultiTextTopStyle,
  optionsListBottomTextStyle,
  searchFieldTextStyle
} from "./SearchAddress.styled"

const INPUT_CLASS = "input__text"

type Props = {
  country: string
  error: boolean
  placeholder: string
  ADDRESS_LIST_CLASS: string
  onAddressSelect: (address: Address) => void
  setEnterManually: (value: boolean) => void
  highlightedIndex: number
  setHighlightedIndex: (value: number) => void
  cantFindText: string
  manualEntryText: string
}

const SearchAddress = ({
  country,
  error,
  placeholder,
  ADDRESS_LIST_CLASS,
  onAddressSelect,
  setEnterManually,
  highlightedIndex,
  setHighlightedIndex,
  cantFindText,
  manualEntryText
}: Props) => {
  const Searchfield = (props: object) => <TextField label={placeholder} sx={searchFieldTextStyle} {...props} />

  const OptionsListBottom = () => {
    return (
      <Text
        multiText={[
          {
            text: cantFindText,
            sx: optionsListBottomMultiTextTopStyle
          },
          {
            text: manualEntryText,
            onClick: () => setEnterManually(true),
            sx: optionsListBottomMultiTextBottomStyle
          }
        ]}
        sx={optionsListBottomTextStyle}
      />
    )
  }

  const OptionsList = (
    props: object & { children?: ReactElement[] },
    ref: React.LegacyRef<HTMLDivElement> | undefined
  ) => {
    const { children, ...rest } = props

    children?.push(<OptionsListBottom key="options-list-bottom" />)

    const styledChildren = React.Children.map(children, (child, index) => {
      if (index === highlightedIndex) {
        return React.cloneElement(child, {
          style: { ...child.props.style, backgroundColor: lightMainColor }
        })
      }

      if (child.props?.suggestion?.Text !== "") {
        return child
      }
      return null
    })

    useEffect(() => {
      const handleEnter = (e) => {
        if (e.key === "Enter") {
          const selectedAddress = children[highlightedIndex]
          if (selectedAddress?.props?.onClick) {
            selectedAddress.props.onClick()
            setHighlightedIndex(-1)
          }
        }
      }
      document.addEventListener("keydown", handleEnter)
      return () => {
        document.removeEventListener("keydown", handleEnter)
      }
    }, [])

    return (
      <List {...rest} ref={ref}>
        {styledChildren}
      </List>
    )
  }

  return (
    <FormControl error={error} fullWidth>
      <AddressSearch
        locale="en_GB"
        apiKey={process.env.NEXT_PUBLIC_LOQATE_API_KEY as string}
        components={{
          Input: Searchfield,
          List: React.forwardRef(OptionsList)
        }}
        className="address-search-box"
        classes={{
          list: ADDRESS_LIST_CLASS,
          input: INPUT_CLASS
        }}
        onSelect={onAddressSelect}
        inline={false}
        debounce={100}
        countries={[`${country}`]}
      />
    </FormControl>
  )
}

export default SearchAddress
