import styled from "@emotion/styled"
import { AppBar } from "@mui/material"
import { darkBlueColor, theme } from "styles"

export const StyledAppBar = styled(AppBar)<{
  disclaimerheight: number
}>`
  background-color: ${darkBlueColor};
  height: 88px;
  ${theme.breakpoints.down("lg")} {
    height: 68px;
  }
  ${theme.breakpoints.down("md")} {
    height: 63px;
  }
  justify-content: center;
  box-shadow: none;
  transition: top 0.3s;

  .MuiContainer-root {
    height: 100%;
  }
  .MuiToolbar-root {
    height: 100%;
  }
`

export const toolbarStyle = {
  backgroundColor: darkBlueColor,
  pb: 2,
  paddingTop: "16px"
}
