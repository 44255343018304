import styled from "@emotion/styled"

export const NotificationBackground = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  z-index: 9999;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`

export const NotificationContainer = styled.div`
  margin-top: 75px;
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 9999px;
  width: 60rem;
  padding: 1rem;
  align-self: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  animation: slideIn 0.5s ease-out;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const NotificationIcon = styled.div`
  margin-top: 8px;
  margin-right: 1rem;
  margin-left: 15px;
`

export const NotificationTitle = styled.div`
  color: #4a5568;
`
