import styled from "@emotion/styled"

export const Container = styled.div`
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`

export const Content = styled.div<{ textColor: string; background: string }>`
  padding: 180px 30px 30px 30px;
  z-index: 1;
  position: relative;
  background: ${(props) => props.background};
  h2 {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 30px;
    color: ${(props) => props.textColor};
  }

  span,
  p,
  li {
    font-size: 15px;
    color: ${(props) => props.textColor};
  }
`
