import React, { useMemo } from "react"
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridFilterModel,
  GridCallbackDetails,
  GridPaginationModel
} from "@mui/x-data-grid"
import { NoRowsMessage, dataGridStyle, dataGridWrapperStyle } from "./DataGridTable.styled"

type Props = {
  page?: number
  rowCount?: number
  paginationMode?: "client" | "server"
  rows: GridRowModel[]
  columns: GridColDef[]
  pageSize?: number
  rowsPerPageOptions?: number[]
  disableSelectionOnClick?: boolean
  sortingMode?: "client" | "server"
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void
  filterMode?: "client" | "server"
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails) => void
  onPageChange?: (model: GridPaginationModel, details: GridCallbackDetails) => void
  height?: number
  noRowsMessage?: string
  rowHeight?: number
}

const DataGridTable = ({
  page = 0,
  rows,
  columns,
  rowCount = 50,
  paginationMode = "client",
  pageSize = 10,
  rowsPerPageOptions = [10, 20, 50],
  disableSelectionOnClick,
  sortingMode = "client",
  onSortModelChange = () => {},
  filterMode = "client",
  onFilterModelChange = () => {},
  onPageChange,
  height,
  noRowsMessage = "No rows",
  rowHeight = 52
}: Props) => {
  const DataGridWrapper = useMemo(() => dataGridWrapperStyle(height, rowHeight), [height, rowHeight])

  return (
    <DataGridWrapper>
      <DataGrid
        paginationModel={{ page, pageSize }}
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        paginationMode={paginationMode}
        pageSizeOptions={rowsPerPageOptions}
        disableRowSelectionOnClick={disableSelectionOnClick}
        sortingMode={sortingMode}
        onSortModelChange={onSortModelChange}
        filterMode={filterMode}
        onFilterModelChange={onFilterModelChange}
        onPaginationModelChange={onPageChange}
        slots={{
          noRowsOverlay: () => <NoRowsMessage>{noRowsMessage}</NoRowsMessage>
        }}
        disableColumnSelector
        rowHeight={rowHeight}
        sx={dataGridStyle}
      />
    </DataGridWrapper>
  )
}

export default DataGridTable
