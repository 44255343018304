import styled from "@emotion/styled"
import { theme } from "styles"

export const BalanceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  ${theme.breakpoints.down("lg")} {
    margin: 0;
  }
`

export const BalanceValue = styled.div`
  font-size: 40px;
  font-weight: 300;
  line-height: 40px;
  margin-left: 3px;

  ${theme.breakpoints.down("lg")} {
    font-size: 30px;
    line-height: 33px;
  }
`

export const CurrencySymbol = styled.div`
  font-size: 15px;
  font-weight: 300;
  line-height: 21px;
`
