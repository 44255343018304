import { PlatformInfoType } from "components"
import { SignupDataType, getFieldValue } from "utils"

export const getBackground = (backgroundTop: string, backgroundBottom: string, transparentHeight: number) => {
  const halfHeight = transparentHeight / 2
  return `linear-gradient(180deg, transparent 0%, transparent ${halfHeight}px, ${backgroundTop} ${transparentHeight}px, ${backgroundBottom} 100%);`
}

export const getInitialSelectedPlatform = (platforms: PlatformInfoType[], signupData: SignupDataType) => {
  const backendId = getFieldValue(signupData, "backend_id")

  if (!backendId) {
    // Returns the first platform given by prismic if none is yet selected
    return {
      platform: platforms[0],
      index: 0
    }
  }

  const selectedPlatformIndex = platforms.findIndex((p) => p.backend_id === backendId)

  if (selectedPlatformIndex > -1) {
    return {
      platform: platforms[selectedPlatformIndex],
      index: selectedPlatformIndex
    }
  }

  return {
    platform: platforms[0],
    index: 0
  }
}

export type DynamicSrcType = {
  url: string
  dimensions: {
    height: number
    width: number
  }
}
