import styled from "@emotion/styled"
import { accentGreenColor, darkBlueColor, whiteColor } from "styles"

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`

export const Container = styled.div<{
  backgroundColor: string
  iconAlign: string
  hasOpacity: boolean
  titleColor: string
  textColor: string
  textFontSize?: string
  textFontWeight?: string
  isWidget?: boolean
}>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: ${(props) => (props.backgroundColor ? "15px" : "0")};
  align-items: ${(props) => props.iconAlign};
  opacity: ${(props) => (props.hasOpacity ? "0.5" : "1")};
  text-align: left;

  & h3 {
    font-size: ${(props) => (props.backgroundColor ? "14px" : "18px")};
    color: ${(props) => props.titleColor || whiteColor};
  }

  & p {
    font-size: ${({ textFontSize, backgroundColor }) => (textFontSize || backgroundColor ? "13px" : "17px")};
    font-weight: ${(props) => (props.textFontWeight ? props.textFontWeight : props.backgroundColor ? "" : "300")};
    line-height: ${(props) => (props.backgroundColor ? "20px" : "25px")};
    color: ${({ textColor, backgroundColor }) => textColor || (backgroundColor ? whiteColor : accentGreenColor)};
    text-align: left;
    & a {
      color: ${(props) => props.isWidget && whiteColor};
    }
  }
`

export const ActionText = styled.span`
  cursor: pointer;
  color: ${darkBlueColor};
  text-decoration: underline;
`

export const paperStyle = (backgroundColor: string, onClick: () => void, border: string, isWidget: boolean) => ({
  transition: "background-color .2s",
  backgroundColor: isWidget ? "transparent" : backgroundColor,
  cursor: onClick ? "pointer" : "initial",
  borderRadius: "15px",
  border
})

export const forgotPasswordTextStyle = {
  color: whiteColor
}
