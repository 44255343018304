/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react"
import { Content } from "@prismicio/client"
import { LoginPageType, PrismicSignStepType, SliceType, TranslationType, shouldAllowTranslation } from "utils"
import { LanguageContext } from "context"
import { Context } from "pages/_app"

export const useTranslation = (
  translations: TranslationType,
  document: Content.AllDocumentTypes | PrismicSignStepType | LoginPageType,
  validationMessagesDocument?: Content.ValidationmessagesDocument
) => {
  const { language } = useContext(LanguageContext)
  const { validationMessages, setValidationMessages, jurisdiction } = useContext(Context)
  const [documentWithTranslatedContent, setTranslatedContent] = useState(document)

  const { pageTranslation, validationMessagesTranslation } = translations

  useEffect(() => {
    if (language.language_code !== "en" && shouldAllowTranslation(jurisdiction)) {
      if (pageTranslation && document) {
        const newDocument = JSON.parse(JSON.stringify(document))

        const translationSlices = pageTranslation.data.slices.filter(
          (slice: Content.TranslationDocumentDataSlicesSlice) => slice.primary.language === language.language_code
        )

        // Translate Document's Data
        const documentDataTranslationSlice = translationSlices.find(
          (slice) => !slice.primary.slice_id && slice.primary.language === language.language_code
        )
        if (documentDataTranslationSlice) {
          Object.keys(documentDataTranslationSlice.primary).forEach((key) => {
            if (newDocument.data[key] && documentDataTranslationSlice.primary[key]) {
              newDocument.data[key] = documentDataTranslationSlice.primary[key]
            }
          })
        }

        // Translate Document's Slices
        if ("slices" in newDocument.data && newDocument.data.slices.length) {
          newDocument.data.slices.forEach((slice: any, index) => {
            const translationSlice = translationSlices.find(
              (s) => s.primary.language === language.language_code && s.primary.slice_id === slice.primary.slice_id
            )
            // Translation for a slice found
            if (translationSlice) {
              // Translate primary fields
              Object.keys(slice.primary).forEach((key) => {
                if (slice.primary[key] && translationSlice.primary[key]) {
                  slice.primary[key] = translationSlice.primary[key]
                }
              })

              // Translate items
              const newItems = JSON.parse(JSON.stringify(slice.items))
              newItems.forEach((item, i) => {
                Object.keys(item).forEach((key) => {
                  if (item[key] && translationSlice.items[i] && translationSlice.items[i][key]) {
                    newItems[i][key] = translationSlice.items[i][key]
                    // Make sure that we do not send translated responses to cube (DEV-3831)
                    if (slice.slice_type === "field" && slice.variation === "cardGroup" && key === "description") {
                      newItems[i].originalDescription = slice.items[i].description
                    }
                  }
                })
              })
              slice.items = newItems
              ;(newDocument.data as any).slices[index] = slice
            }
          })
        }
        setTranslatedContent(newDocument)
      }

      if (validationMessagesTranslation && validationMessages) {
        // Global validation messages
        const validationMessagesTranslationSlice = validationMessagesTranslation.data.slices.find(
          (slice: SliceType & { primary: { language: string } }) =>
            slice.slice_type === "validation_messages_data_translation" &&
            slice.primary.language === language.language_code
        )
        const newValidationMessages = JSON.parse(JSON.stringify(validationMessages))
        Object.keys(validationMessages).forEach((key) => {
          if (newValidationMessages[key] && validationMessagesTranslationSlice.primary[key]) {
            newValidationMessages[key] = validationMessagesTranslationSlice.primary[key]
          }
        })
        setValidationMessages(newValidationMessages)
      } else {
        if (validationMessagesDocument) {
          setValidationMessages(validationMessagesDocument.data)
        }
      }
    } else {
      setTranslatedContent(document)
      if (validationMessagesDocument?.data) {
        setValidationMessages(validationMessagesDocument.data)
      }
    }
  }, [language, jurisdiction, document])

  return documentWithTranslatedContent
}
