import { InfoBoxType } from "components"
import { MT4UserGroupAndTypeType, PlatformBackendCurrencyType, PlatformCurrencyType } from "utils"

export const getMT4AccountType = (
  currencies: PlatformBackendCurrencyType,
  currency: string,
  infoBoxes: InfoBoxType[],
  platform: string,
  platformCurrency: PlatformCurrencyType
) => {
  const selectedCurrency: MT4UserGroupAndTypeType[] = currencies
    ? currencies[currency]
    : platformCurrency[platform].currency[currency]

  if (!selectedCurrency || selectedCurrency.length === 0) {
    return []
  }

  if (selectedCurrency.length === 1) {
    return infoBoxes.filter((box) => box.fieldValue === selectedCurrency[0].type)
  }

  if (selectedCurrency.length > 1) {
    const selectedCurrencyTypes = selectedCurrency.map((c) => c.type)
    return infoBoxes.filter((box) => selectedCurrencyTypes.includes(box.fieldValue))
  }
}
