import * as prismic from "@prismicio/client"
import * as prismicNext from "@prismicio/next"

const returnV2Endpoint = () => {
  if (process.env.NEXT_PUBLIC_ENV === "STAGE") {
    return "https://tnfev2-stage.cdn.prismic.io/api/v2"
  }

  if (process.env.NEXT_PUBLIC_ENV === "PROD") {
    return "https://tnfev2.cdn.prismic.io/api/v2"
  }

  return "https://tnfev2-dev.cdn.prismic.io/api/v2"
}

const V2_ENDPOINT = returnV2Endpoint()

export const repositoryName = prismic.getRepositoryName(V2_ENDPOINT)

export const createTN2Client = () => {
  const endpoint = returnV2Endpoint()
  const client = prismic.createClient(endpoint)
  prismicNext.enableAutoPreviews({
    client
  })
  return client
}
