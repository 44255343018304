import React from "react"
import { Text } from "components"
import { lightGreyColor, orangeColor } from "styles"
import { Separator, InnerSeparator } from "./WordSeparator.styled"

type Props = {
  text: string
  lineColor?: string
  margin?: string
  isWidget?: boolean
  sx?: object
}

const WordSeparator = ({ text, lineColor = lightGreyColor, margin, isWidget, sx }: Props) => {
  return (
    <Separator lineColor={lineColor} margin={margin}>
      <InnerSeparator>
        <Text
          text={isWidget ? text.toUpperCase() : text || "or"}
          sx={
            sx || {
              color: orangeColor,
              fontWeight: isWidget && 700
            }
          }
        />
      </InnerSeparator>
    </Separator>
  )
}

export default WordSeparator
