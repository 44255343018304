import React, { Fragment, ElementType, useState, useEffect } from "react"
import { Typography, TypographyProps } from "@mui/material"
import { typographyStyle } from "./Text.styled"

type Props = {
  text?: string
  multiText?: {
    link?: string
    text: string
    sx?: object
    onClick?: () => void
  }[]
  variant?: TypographyProps["variant"]
  opacity?: boolean
  sx?: object
}

const Text = ({ text, multiText, variant, opacity, sx = {} }: Props) => {
  // Hack to avoid className not matching with SSR
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  if (!loaded) {
    return null
  }

  const displayMultiText = () => {
    const addSpace = (index: number) => {
      return index > 0 ? " " : ""
    }
    return multiText?.map(({ link, text: mText, sx: mSx = {}, onClick }, index: number) => (
      <Fragment key={mText}>
        {addSpace(index)}
        {link ? (
          <a href={link} style={mSx} onClick={onClick}>
            {mText}
          </a>
        ) : (
          <span style={mSx} onClick={onClick}>
            {mText}
          </span>
        )}
      </Fragment>
    ))
  }

  return (
    <Typography
      variant={variant || "body1"}
      component={(variant || "p") as ElementType}
      sx={typographyStyle(opacity, sx)}
    >
      {multiText ? displayMultiText() : text}
    </Typography>
  )
}

export default Text
