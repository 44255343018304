import styled from "@emotion/styled"
import { darkBlueColor, mediumGreyColor, theme, whiteColor } from "styles"

export const Container = styled.div`
  background-color: ${mediumGreyColor};
  padding: 12px 20px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  position: fixed;
  bottom: 130px;
  right: 30px;
  z-index: 1000;
  max-width: 314px;
  ${theme.breakpoints.down("lg")} {
    padding: 12px 12px;
    gap: 10px;
  }
  ${theme.breakpoints.down("md")} {
    padding: 12px 12px;
    gap: 10px;
    bottom: 130px;
    right: 15px;
  }
`

export const connectButtonStyle = {
  backgroundColor: whiteColor,
  color: darkBlueColor,
  padding: "6px 12px",
  height: "34px",
  minHeight: "34px",
  borderRadius: "37px",
  "&:hover": {
    backgroundColor: whiteColor,
    color: darkBlueColor
  },
  gap: "7px"
}
