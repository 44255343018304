import React, { forwardRef, useContext, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Container, useMediaQuery } from "@mui/material"
import * as prismicH from "@prismicio/helpers"
import type { RichTextField } from "@prismicio/types"
import { darkBlueColor, theme } from "styles"
import { useDisclaimerHeight } from "hooks"
import { DisclaimerContext } from "shared"
import {
  DisclaimerSection,
  DisclaimerBox,
  DisclaimerText,
  CloseBtn,
  ControlButton,
  ControlButtons,
  DISMISS_LENGTH_MS,
  AccountPageSection,
  AccountPageDisclaimerWrapper
} from "./Disclaimer.styled"
import { LegalElement } from "./Footer.styled"
import { Text } from "components"

const Disclaimer = forwardRef(function DisclaimerComponent(
  {
    disclaimer_text,
    short_disclaimer_text,
    desktop_font_size,
    mobile_font_size,
    dismissable,
    risk_percentage,
    banner_position,
    background_colour: background,
    font_colour,
    isOnAccountPage,
    accounts_footer_text
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any & { isOnAccountPage: boolean },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref // not used but included here to avoid console error
) {
  const [dismissing, setDismissing] = useState<boolean>(false)
  const { dismissed, expanded, setDismissed, setExpanded } = useContext(DisclaimerContext)

  const placeholder = "[RiskPercentage]"
  const replacedRiskPercentage = (text: RichTextField) => risk_percentage && prismicH.asText(text).includes(placeholder)

  const getUpdatedText = (text: RichTextField) => {
    let disclaimerTextWithRiskPercentage
    if (replacedRiskPercentage(text)) {
      disclaimerTextWithRiskPercentage = JSON.parse(JSON.stringify(text))
      disclaimerTextWithRiskPercentage[0].text = disclaimerTextWithRiskPercentage[0].text.replace(
        /\[RiskPercentage\]/,
        risk_percentage
      )

      const lengthDiff = placeholder.length - String(risk_percentage).length
      const startIndex = prismicH.asText(text).indexOf(placeholder)
      disclaimerTextWithRiskPercentage[0].spans = disclaimerTextWithRiskPercentage[0].spans.map(
        (span: { start: number; end: number; type: string }) => {
          if (span.start <= startIndex && span.end >= startIndex + placeholder.length) {
            return {
              ...span,
              end: span.end - lengthDiff
            }
          }
          if (span.start > startIndex + placeholder.length - 1) {
            return {
              ...span,
              start: span.start - lengthDiff,
              end: span.end - lengthDiff
            }
          }
          return span
        }
      )
    }

    return disclaimerTextWithRiskPercentage ?? text
  }

  const handleClose = () => {
    setDismissing(true)
    setTimeout(() => {
      setDismissed(true)
    }, DISMISS_LENGTH_MS)
  }

  const shortDisclaimerText = prismicH.asText(short_disclaimer_text)

  const isExpandable = shortDisclaimerText !== null && banner_position

  const isTablet = useMediaQuery(theme.breakpoints.down("md"))

  const disclaimerRef = useRef(null)
  useDisclaimerHeight(disclaimerRef, dismissed, true, expanded)

  if (isOnAccountPage) {
    return (
      <AccountPageSection>
        <AccountPageDisclaimerWrapper>
          <Container maxWidth="xl" sx={{ background: "white", p: 0 }}>
            <LegalElement>
              <Text
                text={prismicH.isFilled.richText(accounts_footer_text) ? prismicH.asText(accounts_footer_text) : ""}
              />
            </LegalElement>
          </Container>
        </AccountPageDisclaimerWrapper>
      </AccountPageSection>
    )
  }

  return (
    <DisclaimerSection
      dismissing={dismissing}
      top={banner_position}
      dismissed={dismissed}
      background={background}
      ref={disclaimerRef}
      isOnAccountPage={isOnAccountPage}
    >
      <Container maxWidth={"xl"}>
        {dismissable && !banner_position && (
          <CloseBtn
            onClick={handleClose}
            desktop_font_size={desktop_font_size}
            mobile_font_size={mobile_font_size}
            font_colour={font_colour}
            theme={theme}
          >
            Close
            <CloseIcon />
          </CloseBtn>
        )}
        <DisclaimerBox dismissable={dismissable} topAligned={banner_position} isExpandable={isExpandable} theme={theme}>
          <DisclaimerText
            field={
              expanded || !isExpandable || !isTablet
                ? getUpdatedText(disclaimer_text)
                : getUpdatedText(short_disclaimer_text)
            }
            desktop_font_size={desktop_font_size}
            mobile_font_size={mobile_font_size}
            font_colour={font_colour}
            theme={theme}
          />
          {banner_position && (
            <ControlButtons theme={theme}>
              {isExpandable && (
                <div className="expand-button-wrapper">
                  <ControlButton onClick={() => setExpanded((currentState) => !currentState)} theme={theme}>
                    {expanded ? (
                      <ExpandLessIcon htmlColor={font_colour ?? darkBlueColor} />
                    ) : (
                      <ExpandMoreIcon htmlColor={font_colour ?? darkBlueColor} />
                    )}
                  </ControlButton>
                </div>
              )}
              {dismissable && (
                <div>
                  <ControlButton onClick={handleClose} theme={theme}>
                    <CloseIcon htmlColor={font_colour ?? darkBlueColor} />
                  </ControlButton>
                </div>
              )}
            </ControlButtons>
          )}
        </DisclaimerBox>
      </Container>
    </DisclaimerSection>
  )
})

export default Disclaimer
