import React, { useEffect, useRef } from "react"
import { Dialog, PaperProps } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { ActionButton, IconButton, RichText } from "components"
import TopTextInfo from "components/pages/signup/Common/TopTextInfo"
import { getIdFromRichText, ModalType } from "utils"
import { whiteColor } from "styles"
import { useMobileMode } from "hooks"
import {
  ModalContainer,
  Content,
  MenuArea,
  ButtonsArea,
  actionButtonStyle,
  closeIconStyle,
  iconButtonStyle,
  dialogPaperStyle
} from "./Modal.styled"

const Modal = ({
  icon,
  title,
  text,
  element,
  buttons,
  open,
  handleClose,
  backgroundColor = whiteColor,
  children,
  hasCloseIcon,
  textAlign = "center",
  menu = [],
  titleMargin,
  textFontSize,
  textLineHeight,
  indentedList,
  maxHeight,
  overflowY,
  width = "400",
  zIndex
}: ModalType) => {
  const isMobile = useMobileMode()
  const hasMenu = menu.length > 0

  let contentPadding = "30px"
  if (hasMenu) {
    contentPadding = "80px 30px 30px 60px"
  } else if (hasCloseIcon) {
    contentPadding = "40px 30px 30px 30px"
  }

  const displayModalContent = () => {
    const topTextRef = useRef(null)

    useEffect(() => {
      if (topTextRef.current) {
        topTextRef.current.resetScroll()
      }
    }, [text])

    return (
      <Content
        padding={contentPadding}
        width={hasMenu ? "calc(100% - 230px)" : "initial"}
        titleMargin={titleMargin}
        textAlign={textAlign}
        textFontSize={textFontSize}
        textLineHeight={textLineHeight}
      >
        <TopTextInfo
          ref={topTextRef}
          textAlign={textAlign}
          icon={icon}
          title={title}
          description={text}
          gap={20}
          padding={hasMenu ? "0 30px 30px 0" : null}
          margin="0"
          indentedList={indentedList}
          textFontSize={textFontSize}
          textLineHeight={textLineHeight}
        />
        {text ? children : element || children}

        {buttons && buttons.length > 0 && (
          <ButtonsArea>
            {buttons.map((button) => (
              <ActionButton
                displayText={button.text}
                key={button.text}
                onClick={button.onClick}
                sx={{ ...actionButtonStyle, ...(button.sx || {}) }}
                lightBackground={button.lightBackground}
              />
            ))}
          </ButtonsArea>
        )}
      </Content>
    )
  }

  const displayMenu = () => {
    return (
      <MenuArea>
        {menu.map((el) => (
          <span key={JSON.stringify(el.label)} onClick={el.onClick}>
            <RichText field={el.label} />
          </span>
        ))}
      </MenuArea>
    )
  }

  const displayModalContainer = () => {
    if (hasMenu) {
      return (
        <ModalContainer>
          {displayMenu()}
          {displayModalContent()}
        </ModalContainer>
      )
    }
    return displayModalContent()
  }

  const dialogWidth = isMobile ? `min(${hasMenu ? 430 : width}px, 90%)` : `min(${hasMenu ? 850 : width}px, 90%)`

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: dialogPaperStyle(backgroundColor, dialogWidth, maxHeight, overflowY) as Partial<PaperProps>
        }}
        data-test-id="modal"
        id={`modal-${getIdFromRichText(title)}`}
        sx={{ zIndex }}
      >
        {hasCloseIcon && handleClose && (
          <IconButton
            id="modal-close"
            icon={<CloseIcon sx={closeIconStyle} />}
            sx={iconButtonStyle}
            onClick={handleClose}
          />
        )}
        {displayModalContainer()}
      </Dialog>
    </div>
  )
}

export default Modal
