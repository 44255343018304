import styled from "@emotion/styled"
import { Avatar, Box, IconButton, MenuItem } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { darkBlueColor, mediumGreyColor, theme, whiteColor } from "styles"

export const UserMenuContainer = styled(Box)`
  flex-grow: 0;
  margin-right: 16px;
  margin-left: 16px;
`

export const UserBoxContainer = styled(Box)`
  display: flex;
  align-tems: center;
  text-lign: center;
`

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`

export const userNameTextStyle = {
  color: whiteColor,
  ml: 2,
  fontWeight: 400
}

export const menuPaperStyle = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  width: "auto",
  borderRadius: "15px",
  mt: 1.5,
  "& .MuiAvatar-root": {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1
  },
  "&:before": {
    // eslint-disable-next-line
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    right: 32,
    width: 15,
    height: 15,
    backgroundColor: "background.paper",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 0
  }
}

export const StyledMenuItem = styled(MenuItem)<{ isLast?: boolean }>`
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: ${({ isLast }) => (isLast ? `20px` : "initial")};
  ${({ isLast }) => isLast && `margin-bottom:20px`};
  &:hover {
    background: none;
  }
`

export const menuItemTypographyStyle = (sx?: object) => ({
  color: darkBlueColor,
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "1px",
  ...sx
})

export const menuItemViewDashboardStyle = {
  color: darkBlueColor,
  fontSize: "14px",
  fontWeight: 300,
  lineHeight: "12px",
  mt: 1
}

export const dividerStyle = {
  margin: "30px",
  marginTop: "26px !important"
}

export const kycButtonStyle = {
  backgroundColor: mediumGreyColor,
  borderRadius: "4px",
  padding: "7px 20px",
  marginTop: "15px",
  maxheight: "29px",
  minHeight: "29px",
  color: darkBlueColor,
  fontSize: "12px",
  lineHeight: "15px",
  fontWeight: 400
}

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)<{ open: boolean }>`
  color: #fff;
  transform: rotate(${({ open }) => (open ? "180deg" : "0")});
  transition: 0.3s;
`

export const StyledAvatar = styled(Avatar)`
  background-color: ${whiteColor};
  color: ${darkBlueColor};
  border-radius: 14px;
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${theme.breakpoints.down("md")} {
    width: 33px;
    height: 33px;
    border-radius: 12px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 31.4px;
    height: 31.4px;
    border-radius: 10px;
  }
`
