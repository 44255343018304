import React from "react"
import { ExtraFieldProps, FieldVariation, TextfieldProps, WidgetProps } from "components"
import { Container, textfieldStyle } from "./Textfield.styled"

export const Textfield = ({
  currentValue,
  required,
  placeholder,
  handleChange,
  fieldName,
  sx = {},
  textInputSx = {},
  type = "text",
  variant = "outlined",
  inputProps = {},
  InputProps = {},
  autoFocus = false,
  textAlign,
  disabled = false,
  widgetprops,
  variation,
  testid
}: TextfieldProps & ExtraFieldProps & WidgetProps & { variation?: string; textInputSx?: object }) => {
  const onChange = (event: { target: { value: string } }) => {
    const value = variation === FieldVariation.EmailTextfield ? event.target.value?.trim() : event.target.value

    handleChange({ [fieldName]: value })
  }

  return (
    <Container
      id={fieldName}
      label={placeholder}
      variant={variant}
      required={required}
      onChange={onChange}
      value={currentValue}
      sx={textfieldStyle(sx)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color={widgetprops && ("secondaryLightBlue" as any)}
      type={type}
      InputProps={variant === "outlined" ? InputProps : { disableUnderline: true, ...InputProps }}
      inputProps={{
        ...inputProps,
        style: {
          ...(inputProps?.style || {}),
          textAlign: textAlign || "left",
          fontWeight: 400,
          ...textInputSx
        },
        "data-testid": testid
      }}
      autoFocus={autoFocus}
      disabled={disabled}
      widgetprops={widgetprops}
    />
  )
}

export default Textfield
