import styled from "@emotion/styled"
import { Typography } from "@mui/material"

export const TypographyWithScrollMargin = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "dismissed" && prop !== "disclaimerheight" && prop !== "direction"
})<{
  dismissed?: boolean
  disclaimerheight?: number
  direction?: string
  header?: string
  textcolor?: string
}>`
  scroll-margin-top: ${({ dismissed, disclaimerheight }) => (!dismissed ? `${88 + disclaimerheight}px` : "88px")};

  text-align: ${({ direction }) => (direction === "rtl" ? "right" : "inherit")};
  font-weight: ${({ header }) => (header ? 500 : 400)};
  color: ${({ textcolor }) => textcolor};
  margin-top: 6px;
  white-space: break-spaces;

  a {
    font-weight: bold;
  }
`

export const listStyle = (indentedList = false) => ({
  listStyleType: "none",
  padding: "0",
  margin: "0",
  marginLeft: indentedList ? "30px" : "0",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  fontSize: "17px",
  lineHeight: "25px",
  fontWeight: 400
})
