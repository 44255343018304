import styled from "@emotion/styled"
import { whiteColor } from "styles"

export const Container = styled.div<{
  isDesktopXLDown: boolean
  isMobile: boolean
}>`
  position: ${(props) => (props.isDesktopXLDown && !props.isMobile ? "relative" : "fixed")};
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${(props) => (props.isDesktopXLDown && !props.isMobile ? "0 0 20px 0" : "0 20px 20px 20px")};
  background: ${whiteColor};
  max-width: 600px;
  margin: ${(props) => (props.isDesktopXLDown && !props.isMobile ? 0 : "0 auto")};
  z-index: 1;
`
