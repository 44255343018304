import styled from "@emotion/styled"
import { styled as muiStyled } from "@mui/material/styles"

export const mainColor = "#fc5000"
export const lightMainColor = "#fedccc"
export const greyColor = "#bbbbbb"
export const mediumGreyColor = "#e7e9eb"
export const lightGreyColor = "#f6f4f2"
export const darkGreyColor = "#032133"
export const darkBlueColor = "#050F19"
export const whiteColor = "#fff"
export const blackColor = "#000"
export const greenColor = "#008045"
export const accentGreenColor = "#3ead92"
export const accentGreenColorLight = "#3ead9260"
export const blueColor = "#0c8ce9"
export const redColor = "#d90000"
export const shadowColor = "#0000001a"
export const primaryGreyColor = "#f5f3f2"
export const lightOrangeColor = "#ff9e82"
export const orangeColor = "#ff5000"

export const Page = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${(props) => props.backgroundColor || "initial"};
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PageContainer = muiStyled("div")<{
  wide?: boolean
  backgroundColor?: string
  gap?: string
  padding?: string
  margin?: string
  textColor?: string
  maxWidth?: string
  overflow?: string
  backgroundImage?: string
}>(({ theme, wide, backgroundColor, gap, padding, margin, textColor, maxWidth, overflow, backgroundImage }) => ({
  display: "flex",
  flexDirection: "column",
  gap: gap || "20px",
  flex: 1,
  width: "100%",
  maxWidth: maxWidth || (wide ? theme.breakpoints.values.xl : "600px"),
  padding: padding || "50px 20px 20px 20px",
  backgroundColor: backgroundColor || "initial",
  margin: margin || "initial",
  color: textColor || "initial",
  position: "relative",
  overflow: overflow || "initial",
  backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
  backgroundRepeat: "no-repeat"
}))

export const PageWelcomeContent = styled.div<{
  isSignupLoading: boolean
  isWidget?: boolean
  isMobile?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isWidget && props.isMobile ? "10px" : "20px")};
  justify-content: space-between;
  flex: 1;
  background-color: ${(props) => !props.isWidget && whiteColor};
  padding: ${(props) => (props.isWidget && props.isMobile ? "" : "52px 85px 30px 85px")};
  border-radius: 20px;
  width: ${(props) => (props.isWidget && props.isMobile ? "100%" : "600px")};
  position: relative;
  filter: ${(props) => (props.isSignupLoading ? "blur(4px)" : "")};

  @media (max-width: 900px) {
    width: ${(props) => !props.isMobile && props.isWidget && "max-content"};
    padding: ${(props) => !props.isMobile && props.isWidget && "0"};
  }
`

export const PageWelcomeContainer = styled.div<{ minWidth?: string }>`
  position: relative;
  z-index: 1;
  min-width: ${(props) => props.minWidth || "0"}px;
  max-width: 500px;
`

export const PageTitleAndDescription = styled.div<{
  gap?: string
  padding?: string
  textFontSize?: string
  textLineHeight?: string
}>`
  padding: ${(props) => props.padding || "0"};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || "5"}px;

  p {
    font-size: ${(props) => props.textFontSize || "16px"};
    line-height: ${(props) => props.textLineHeight || "25px"};
    font-weight: 300;
  }
`

export const PageContentCenterAligned = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`

export const Row = styled.div<{
  alignItems?: string
  justifyContent?: string
  margin?: string
  gap?: string
  wrap?: string
}>`
  display: flex;
  flex-wrap: ${(props) => props.wrap || "wrap"};
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  gap: ${(props) => props.gap || "20"}px;
  margin: ${(props) => props.margin || "0"};
`

export const RowCenterAligned = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 15px;
`

export const RowRightAligned = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${(props) => props.gap || "15px"};
`

export const Column = styled.div<{
  gap?: string
  flex?: string
  justifyContent?: string
}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || "0"};
  flex: ${(props) => props.flex || "initial"};
  justify-content: ${(props) => props.justifyContent || "initial"};
`

export const ColumnCenterAligned = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const scrollStyle = `
  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 40px;
    background-color: ${lightGreyColor};
  }
`
