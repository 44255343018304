import * as Sentry from "@sentry/react"
import pack from "../package.json"

const match = /^https:\/\/[0-9a-f]*@([^\/]*)\/([0-9]*)$/.exec(
  process.env.NEXT_PUBLIC_SENTRY_DSN || "https://47a17cc2aa834c0db5a372a5b9772275@sentry.finsatechnology.com/7"
)
if (!match) throw new Error("Invalid self-hosted Sentry DSN format")
const [dsn, host, projectId]: string[] = match

const sentryEnvMap: {
  DEV: string
  STAGE: string
  PROD: string
} = {
  DEV: "development",
  STAGE: "stage",
  PROD: "production"
}
const environment: string = sentryEnvMap[process.env.NEXT_PUBLIC_ENV || "PROD"] || "local"

const release = process.env.NEXT_PUBLIC_SENTRY_RELEASE || `${pack.name}@${pack.version}`

const tunnel = `/next-api/logs?h=${host}&p=${projectId}`

if (!process.env.CI) {
  if (typeof window !== "undefined") {
    Sentry.init({
      dsn,
      environment,
      release,
      tunnel
    })
    Sentry.captureMessage("Site loaded")
    Sentry.setUser(null)
  }
}

export { Sentry }
