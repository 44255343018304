import React, { useRef, useState } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import { Box, Button, Stack, useMediaQuery } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { ActionButton, AnimatedLogo } from "components"
import UserMenu from "components/layout/account/header/UserMenu"
import { StyledAppBar } from "components/layout/Header.styled"
import { useAccountData, useMobileOrTabletMode } from "hooks"
import { MT4 } from "utils"
import {
  bottomBoxStyle,
  buttonStyle,
  MiddleMenuContainer,
  StyledContainer,
  StyledToolbar
} from "./AccountHeader.styled"
import useHelpscout from "hooks/useHelpscout"
import Script from "next/script"
import { theme } from "styles"

const desktopMenuPages = ["Overview", "Accounts", "Payments"]
export const pages = desktopMenuPages.concat(["Settings", "Logout"])

const AccountHeader = () => {
  const { asPath } = useRouter()
  const { appMetadata } = useAccountData()
  const appBarRef = useRef(null)

  const currentPage = asPath.split("/").pop()
  useHelpscout()

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"))
  let logoProps = {}
  if (isMobileOrTablet) {
    logoProps = { height: 29, width: 29, withoutText: true }
  } else {
    logoProps = { width: 207, withoutText: false }
  }

  const displayMT4DownloadButton = () => {
    const mt4Account = appMetadata?.trading_accounts.find((acc) => acc.backend.type === MT4)

    if (currentPage !== "mt4webplatform" || !mt4Account || !mt4Account.platform_url) {
      return null
    }

    const downloadMT4Platform = () => {
      window.open(mt4Account.platform_url)
    }

    return <ActionButton displayText="Download MT4" onClick={downloadMT4Platform} />
  }

  return (
    <StyledAppBar position="sticky" disclaimerheight={88} ref={appBarRef}>
      <StyledContainer maxWidth="xl">
        <StyledToolbar disableGutters>
          <AnimatedLogo {...logoProps} />
          <MiddleMenuContainer>
            <Stack spacing={6} direction="row">
              {desktopMenuPages.map((page) => (
                <Button
                  variant="text"
                  key={page}
                  disableElevation
                  sx={buttonStyle(page, currentPage)}
                  LinkComponent={Link}
                  href={page.toLowerCase()}
                >
                  {page}
                </Button>
              ))}
            </Stack>
          </MiddleMenuContainer>
          <UserMenu />
          {displayMT4DownloadButton()}
        </StyledToolbar>
        <Script
          id="beacon_v2_id"
          dangerouslySetInnerHTML={{
            __html:
              // eslint-disable-next-line quotes, @typescript-eslint/quotes
              '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});'
          }}
          async
        />
      </StyledContainer>
    </StyledAppBar>
  )
}

export default AccountHeader
