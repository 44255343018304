import { RichTextField } from "@prismicio/types"
import { asText } from "@prismicio/helpers"

export const hasBackgroundColor = (slice: {
  primary: {
    apply_secondary_background: boolean
    background_color: string
  }
  slice_type: string
}) => {
  if (slice?.primary.apply_secondary_background) {
    return true
  }

  const slicesWithBackgroundSelect = ["trading_how_it_should_be", "made_to_trade", "two_col_diamons_text"] // select must not be 'Transparent'

  const slicesWithBackgroundPicker = ["hero_left_text"] // picker must be null

  // if slice.slice_type in slicesWithBackgroundSelect, then prevSlice.primary.background_colour is 'Transparent'
  if (slicesWithBackgroundSelect.find((item) => item === slice.slice_type)) {
    return slice.primary.background_color !== "Transparent"
  }

  // if prevSlice.slice_type in slicesWithBackgroundPicker, then prevSlice.primary.background_color is null
  if (slicesWithBackgroundPicker.find((item) => item === slice.slice_type)) {
    return slice.primary.background_color !== null
  }

  return false
}

export const getId = (text: string) =>
  text
    ?.trim()
    ?.replace(/[^a-zA-Z0-9\s]/g, "")
    ?.replace(/\s/g, "-")
    ?.toLowerCase()

export const getIdFromRichText = (richText: RichTextField) => getId(asText(richText))
