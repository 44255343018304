import React, { useState, useEffect } from "react"
import { Snackbar, Alert } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import { useSignupData } from "hooks"
import { iconStyle, snackbarStyle } from "./Prompt.styled"
import { delay } from "utils/general"
import RichText from "components/text/RichText"

const Prompt: React.FC = () => {
  const { promptMessage, updatePromptMessage } = useSignupData()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (promptMessage.message || promptMessage.richTextMessage) {
      setOpen(true)
    }
  }, [promptMessage])

  const Icon = () => {
    if (promptMessage.success) {
      return <CheckCircleIcon sx={iconStyle()} />
    }
    return <ErrorIcon sx={iconStyle(false)} />
  }

  const onClose = async () => {
    setOpen(false)

    await delay(100)

    updatePromptMessage({
      message: "",
      success: false
    })
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert icon={<Icon />} sx={snackbarStyle}>
        {promptMessage.richTextMessage ? <RichText field={promptMessage.richTextMessage} /> : promptMessage.message}
      </Alert>
    </Snackbar>
  )
}

export default Prompt
