import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { LoginType } from "redux/types"

export const loginApi = createApi({
  reducerPath: "loginapi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_REACT_APP_API_URL
  }),
  endpoints: (builder) => ({
    login: builder.mutation<
      LoginType,
      {
        body: {
          username: string
          password: string
        }
      }
    >({
      query: ({ body }) => ({
        url: "/login",
        method: "POST",
        body
      })
    }),
    checkAccount: builder.mutation<
      LoginType,
      {
        body: {
          email: string
        }
      }
    >({
      query: ({ body }) => ({
        url: "/check-account",
        method: "POST",
        body
      })
    }),
    socials: builder.mutation<
      LoginType,
      {
        body: {
          provider: string
        }
      }
    >({
      query: ({ body }) => ({
        url: "/socials",
        method: "POST",
        body
      })
    }),
    checkCaptchaAndSignupUser: builder.mutation<
      any,
      {
        body: {
          token: string
          user: {
            email: string
            password: string
          }
          skipCaptcha: boolean
        }
      }
    >({
      query: ({ body }) => ({
        url: "/verify-recaptcha-and-signup-user",
        method: "POST",
        body
      })
    })
  })
})

export const { useLoginMutation, useCheckAccountMutation, useSocialsMutation, useCheckCaptchaAndSignupUserMutation } =
  loginApi
