export const headerStyle = {
  paddingBottom: "20px"
}

export const multiTextStyle = {
  opacity: 0.7
}

export const textStyle = {
  paddingTop: "20px",
  fontSize: "14px"
}

export const actionButtonStyle = {
  marginTop: "30px"
}
