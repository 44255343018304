import { ReactNode } from "react"
import { RichTextField } from "@prismicio/types"
import {
  PrismicActionButtonType,
  AllPrismicFieldsType,
  PrismicHiddenFieldType,
  PrismicScoreFieldType,
  PrismicPasswordTextfieldType,
  PrismicEmailTextfieldType,
  PrismicInfoBoxType,
  OptionType,
  PrismicModalInfoType,
  PrismicPlatformInfoType,
  PrismicSocialButtonType,
  PrismicSumsubVerificationType,
  PrismicMt4PasswordType
} from "components"
import { Content, RTHeading1Node } from "@prismicio/client"
import { TransactionType } from "components/pages/account/payments/types"
export type FeatureToggles = {
  tradingview: boolean
}
export type CountryInfoType = {
  country: string
}
export type RichTextFieldWithDirection = RichTextField & {
  direction?: string
}

export enum SignupSectionType {
  SetUp = "Set Up",
  PersonalDetails = "Personal Details",
  FinancialProfile = "Financial Profile",
  IDCheck = "ID Check"
}

export type SliceType = AllPrismicFieldsType &
  PrismicActionButtonType &
  PrismicSocialButtonType &
  PrismicHiddenFieldType &
  PrismicScoreFieldType &
  PrismicInfoBoxType &
  PrismicModalInfoType &
  PrismicPlatformInfoType &
  PrismicSumsubVerificationType &
  PrismicMt4PasswordType

export type SignupWelcomePageType = {
  data: {
    title: RichTextField
    description: RichTextField
    bottomInfo?: RichTextField
    slices: (PrismicActionButtonType & (PrismicEmailTextfieldType | PrismicPasswordTextfieldType))[]
  }
  uid: string
}

export type IconType = {
  alt?: string
  dimensions?: { width: number; height: number }
  url?: string
  desktop?: {
    url?: string
    alt?: string
    dimensions?: { width: number; height: number }
  }
  desktop_blur?: {
    url?: string
    alt?: string
    dimensions?: { width: number; height: number }
  }
  mobile?: {
    url?: string
    alt?: string
    dimensions?: { width: number; height: number }
  }
  mobile_blur?: {
    url?: string
    alt?: string
    dimensions?: { width: number; height: number }
  }
}

export type TranslationType = {
  pageTranslation: Content.TranslationDocument
  validationMessagesTranslation?: Content.TranslationDocument
  welcomePageLeftSideTranslation?: Content.TranslationDocument
  sectionsTranslation?: Content.TranslationDocument
}

export type PrismicSignStepType = {
  data: {
    icon: IconType
    title: RichTextFieldWithDirection
    description?: RichTextField
    textAlign?: "left" | "center"
    canSkip: boolean
    disableBack: boolean
    bottomInfo?: RichTextField
    section?: SignupSectionType
    isFirstStep?: boolean
    slices: SliceType[]
    prevStep: string
    translation?: TranslationType
    desktopTitle?: RichTextField
    wordSeparator?: string
    dev_switch_to_new_style: boolean
    stage_switch_to_new_style: boolean
    prod_switch_to_new_style: boolean
    signUpOrangeText?: string
    signUpText?: string
  }
  uid: string
}

export type SignupStepType = PrismicSignStepType["data"] & {
  uid: string
  prevStep: string
  sections: SignupSectionType[]
  currentSection?: SignupSectionType
}

export type ObjectWithFieldsType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [fieldName: string]: any
}

export type SignupDataType = {
  [stepName: string]: ObjectWithFieldsType
}

export type ExtraValidationType = {
  message: string
  condition: () => boolean
}

// ACCOUNT
export type AccountOverviewPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    title: RichTextField
    showDemoAccounts: boolean
    showLiveAccounts: boolean
    showManageAccounts: boolean
    showUpgradeToLive: boolean
    showAddDemoAccount: boolean
  }
  uid: string
}

export enum AccountSettingsColumnType {
  LeftColumn = "LeftColumn",
  RightColumn = "RightColumn"
}

export type AccountSettingsDetailsBoxType = {
  primary: {
    title: RichTextField
    show: boolean
  }
  items: {
    label: string
    field: string
    column: AccountSettingsColumnType
  }[]
}

export type AccountSettingsPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    title: RichTextField
    description: RichTextField
    showManagePreferences: boolean
    showChangePassword: boolean
    show2fa: boolean
    slices: AccountSettingsDetailsBoxType[]
  }
  uid: string
}

export type AccountCheckEmailPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    title: RichTextField
    description: RichTextField
    slices: PrismicActionButtonType[]
  }
  uid: string
}

export type AccountCreateNewPasswordPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    steps: {
      title: RichTextField
      description: RichTextField
    }[]
    slices: PrismicActionButtonType[]
  }
  uid: string
}

export type AccountPaymentsPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    title: RichTextField
    description: RichTextField
    accountNotApprovedDescription: RichTextField
  }
  uid: string
}

export type AccountWithdrawPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    title: RichTextField
    firstStep: {
      description: RichTextField
      accountTitle: RichTextField
      amountDescription: RichTextField
      amountPlaceholder: string
      amountErrorMessage: string
    }[]
    secondStep: {
      description: RichTextField
      accountTitle: RichTextField
      amountDescription: RichTextField
    }[]
    slices: PrismicActionButtonType[]
  }
  uid: string
}

export type AccountDepositPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    title: RichTextField
  }
  uid: string
}

export type AccountsListPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    showLiveAccounts: boolean
    showDemoAccounts: boolean
    showAddLiveAccount: boolean
    showAddDemoAccount: boolean
  }
  uid: string
}

export type SettingsPageType = {
  data: {
    slices: SliceType[]
  }
  uid: string
}

export type AddAccountPageType = {
  data: {
    header: RichTextField
    subHeader: RichTextField
    title: RichTextField
    description: RichTextField
    accountTypePlaceholder: string
    currencyPlaceholder: string
    slices: (PrismicActionButtonType & PrismicPasswordTextfieldType)[]
  }
  uid: string
}

// LOGIN

export type LoginPageType = {
  data: {
    title: RichTextFieldWithDirection
    titleOrangeText: any
    description: RichTextField
    linkTextToSignup: RichTextField
    slices: SliceType[]
    wordSeparator?: string
    dev_switch_to_new_style: boolean
    stage_switch_to_new_style: boolean
    prod_switch_to_new_style: boolean
  }
  uid: string
}

// FORGOT PASSWORD
export type ForgotPasswordStepType = {
  icon?: IconType
  title: RichTextField
  description: RichTextField
}

export type ForgotPasswordPageType = {
  data: {
    steps: ForgotPasswordStepType[]
    slices: SliceType[]
  }
  uid: string
}

// RESET PASSWORD
export type ResetPasswordPageType = {
  data: {
    icon?: IconType
    title: RichTextField
    description: RichTextField
    slices: SliceType[]
  }
  uid: string
}

export type ButtonType = {
  text: string
  onClick: () => void
  lightBackground?: boolean
  sx?: object
}

export type ModalType = {
  icon?: IconType
  title?: RichTextField
  text?: RichTextField
  element?: ReactNode
  buttons?: ButtonType[]
  open: boolean
  handleClose?: () => void
  backgroundColor?: string
  hasCloseIcon?: boolean
  children?: ReactNode
  menu?: {
    label: RichTextField
    onClick: () => void
  }[]
  textAlign?: string
  titleMargin?: string
  textFontSize?: string
  textLineHeight?: string
  revertStepDataOnDismiss?: boolean
  indentedList?: boolean
  maxHeight?: string
  overflowY?: string
  width?: string
  zIndex?: number
}

export type OpenModalFuncType = ({ title, text, backgroundColor, handleClose }: Omit<ModalType, "open">) => void

export type AddAccountModalType = ModalType & {
  platform: string
  mt4Data?: PrismicSignStepType
  type?: string
  derivative?: string
}

export type TradingAccountType = {
  LoginName: string
  account_id: string
  alpha_code: string | null
  backend: {
    derivative_type: string
    display_name: string
    id: number
    mode: string
    name: string
    type: string
  }
  balance: {
    cash_balance: string
    currency: string
    total_credit: string
    total_equity: string
    withdrawable_amount: string
  }
  brand: string
  client_profile_id: number
  created: string
  ct_login_id: string | null
  ct_login_password: string | null
  currency: string
  display_name: string
  expires_at: string | null
  external_id: string
  group_name: string
  id: number
  is_allowed_bonus: boolean | null
  links: object
  mt4_user_group: string
  platform: string
  platform_url: string | null
  status: string
  type: string
  username: string
}

export type UserMetadataType = {
  about: {
    addr_city: string
    addr_line_2: string
    addr_street: string
    addr_zip: string
    au_state?: string
    province?: string
    general_knowledge: string
    national_insurance_number?: string
    nationality?: string
    date_of_birth?: string
    es_tax_id?: string
    swap_free_status?: SwapFreeStatus
    kyc_status?: string
    kyc_reminder_sent?: boolean
  }
  btaRef?: string
  cxdRef: string
  kyc?: { [field: string]: string }
  first_name: string
  last_name: string
  has_deposited?: boolean
  personal: {
    addr_country: string
    addr_country_full: string
    first_name: string
    last_name: string
    opt_in: boolean
    promotionalSource: string
    telephone: string
    title: string
    nationality?: string
    nationality_full?: string
    mothers_maiden_name?: string
    nif_code?: string
    gender?: number
    friendly_name?: string
    middle_name?: string
    country_of_birth?: string
    email: string
  }
  redirect: string
  allow_swap_free?: boolean
  mt4_swap_free_request_sent?: string
}

export enum SwapFreeStatus {
  NOT_REQUESTED = "Not requested",
  PENDING = "Swap free Pending",
  ACTIVE = "Swap free Active"
}

export type AppMetadataType = {
  clients: {
    available: boolean
    brand: string
    client_id: number
    client_profile_id: number
    state: string
    type: string
    password?: string
    username?: string
  }[]
  default_currency: object
  has_getprofile_error?: boolean
  previous_experience: string
  trading_accounts: TradingAccountType[]
}

export type AccountDataType = {
  app_metadata?: AppMetadataType
  created_at?: string
  email?: string
  email_verified?: boolean
  identities?: {
    connection: string
    isSocial: boolean
    provider: string
    user_id: string
  }[]
  last_ip?: string
  last_login?: string
  logins_count?: number
  name?: string
  nickname?: string
  picture?: string
  updated_at?: string
  user_id?: string
  user_metadata?: UserMetadataType
}

export type AvailableBrandType = {
  brand?: string
  new_live_enable?: boolean
  new_live_ct_cfd_enable?: boolean
  new_live_ct_spread_enable?: boolean
  new_practice_enable?: boolean
  trading_account_type?: string[]
}

export type BrandsType = {
  available: AvailableBrandType[]
  total: string[]
}

export type PaymentHistoryType = {
  account_external_id: string
  backend_type: string
  currency: string
  date: string
  gateway: string
  id: number
  method: string
  state: number
  success: boolean
  trading_account: string
  trading_account_name: string
  trading_backend: string
  trading_group: string
  type: string
  value: string
  transactions?: TransactionType
  total_transactions?: number
}

export type GeneralApiFieldType = {
  [field: string]: string & OptionType
}

export type MT4UserGroupAndTypeType = {
  type: string
  mt4usergroup_name: string
}

export type PlatformCurrencyType = {
  CLOUDTRADE: {
    currency: {
      [currency: string]: MT4UserGroupAndTypeType[]
    }
    hasAffiliateUserGroup: boolean
  }
  MT4: {
    currency: {
      [currency: string]: MT4UserGroupAndTypeType[]
    }
    hasAffiliateUserGroup: boolean
  }
}

export type PlatformBackendCurrencyType = {
  [currency: string]: {
    type: string
    mt4usergroup_name: string
  }[]
}

export type PlatformBackendDataType = {
  id: number
  country: string
  country_name: string
  whitelabel_id: string
  whitelabel_name: string
  backend_id: string
  backend_name: string
  ranking: number
  currency_supported: string[]
  platform_type: string
  derivative_type: string
  currency?: PlatformBackendCurrencyType
  hasAffiliateUserGroup: boolean
}

export type PlatformBackendType = {
  backends: PlatformBackendDataType[]
  currency_display_name: {
    [currency: string]: string
  }[]
}

export type CountryApiType = {
  banned: boolean
  brand_id: string
  country_iso3_code: string
  country_of_residence_name: string
  phone_number_prefix: string
  option: {
    icon: string
    icon_side: string
    label: string
    value: string
  }
}

export type Validation = {
  messages: Content.ValidationmessagesDocument
}

export type IncompleteSignupInfo = {
  last_page_user_was_on: string
  application_completed?: string
  welcome: { email: string }
  "country-of-residence": { brand_id: string }
}
