import { accentGreenColorLight, mainColor } from "styles"

export const menuButtonStyle = {
  position: "absolute",
  top: "0",
  right: "0",
  fontSize: "25px",
  color: mainColor,
  padding: "5px 15px",
  minWidth: "initial",
  minHeight: "initial",
  paddingBottom: "20px",
  marginRight: "10px",
  zIndex: 1
}

export const popperStyle = {
  zIndex: 1
}

export const growStyle = {
  transformOrigin: "left top"
}

export const paperStyle = {
  marginLeft: "15px",
  border: `1px solid ${accentGreenColorLight}`,
  borderRadius: "8px"
}

export const menuItemIconStyle = {
  width: "16px",
  marginRight: "10px"
}

export const menuListStyle = {
  padding: "0"
}

export const menuItemStyle = {
  height: "43px",
  padding: "0 17px"
}
