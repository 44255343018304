import { LOCAL_STORAGE_UTM_EXPIRY } from "./general"
import * as Sentry from "@sentry/react"
import * as prismic from "@prismicio/client"
import { PlatformBackendType, getLocalStorage, setLocalStorage } from "utils"

const LOCAL_STORAGE_CXD = "cxd"
const LOCAL_STORAGE_CXD_EXPIRY = "cxd_expiry_time"
const LOCAL_STORAGE_UTM_DETAILS = "utm_details"
const NINETY_DAYS_IN_SECONDS = 7776000

interface AffiliateRef {
  name: string
  length: number
}

interface AffiliateParam {
  name: string
  value: string
  expiry: number
}

interface PrismicAffiliateRecord {
  primary: {
    ref: string
    campaign_length: string
  }
}

// @TO-DO i dont think this is being used
// we are manually coding the affiliate refs we
// want to capture. We should add them to prismic and
// fetch them here though.
export const fetchAffiliateRefsFromPrismic = async (): Promise<AffiliateRef[]> => {
  let sanitizedAffiliateRefs = []
  try {
    const client = prismic.createClient("tradenation-signup")
    const res = await client.getByUID("affiliate_tracker", "affiliates")
    if (res && res.data && res.data.slices) {
      sanitizedAffiliateRefs = res.data.slices.map((aff: PrismicAffiliateRecord) => ({
        name: aff.primary.ref,
        length: JSON.parse(aff.primary.campaign_length)
      }))
    }
  } catch (error) {
    Sentry.captureException(error)
    sanitizedAffiliateRefs = []
    console.log("error", error)
  }
  return sanitizedAffiliateRefs
}

export const getCxdRef = () => {
  const cxdRef: string = getLocalStorage(LOCAL_STORAGE_CXD)

  // Check if cxdRef matches either a plain number or a "number_number" pattern
  const regex = /^(?:\d+|\d+_\d+)$/
  if (cxdRef && !regex.test(cxdRef)) {
    return ""
  }

  const expTime: number = JSON.parse(getLocalStorage("cxd_expiry")) || LOCAL_STORAGE_CXD_EXPIRY
  const isLive = Date.now() < expTime

  if (cxdRef && isLive) {
    return cxdRef
  }

  if (cxdRef && !isLive) {
    // clearLocalStorage(LOCAL_STORAGE_CXD)
    // clearLocalStorage(LOCAL_STORAGE_CXD_EXPIRY)
  }

  return cxdRef
}

export const getUTMParameters = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"]

  const params: { [key: string]: string } = {}
  utmParams.forEach((param) => {
    const value = urlParams.get(param)
    if (value) {
      params[param] = value
    }
  })
  return params
}

export const saveUTMParametersToCache = (params) => {
  localStorage.setItem(LOCAL_STORAGE_UTM_DETAILS, JSON.stringify(params))
  localStorage.setItem(LOCAL_STORAGE_UTM_EXPIRY, `${Date.now() + 1000 * NINETY_DAYS_IN_SECONDS}`)
}

export const getUTMParametersCache = () => {
  const utmDetails = JSON.parse(localStorage.getItem(LOCAL_STORAGE_UTM_DETAILS))
  const utmSourceExpiry = Number(localStorage.getItem(LOCAL_STORAGE_UTM_EXPIRY))
  if (utmSourceExpiry <= Date.now() && utmDetails?.utm_source) {
    delete utmDetails.utm_source
    localStorage.setItem(LOCAL_STORAGE_UTM_DETAILS, JSON.stringify(utmDetails))
  }
  return utmDetails || null
}

export const getAffiliates = async () => {
  const affiliateRefs: AffiliateRef[] = await fetchAffiliateRefsFromPrismic()

  const getURLParams = (): AffiliateParam[] => {
    const params = new URLSearchParams(window.location.search)
    const urlParams: AffiliateParam[] = []

    params.forEach((value, key) => {
      const affiliateRef = affiliateRefs.find((ref) => ref.name === key)
      if (affiliateRef != null) {
        urlParams.push({ name: key, value, expiry: affiliateRef.length })
      }
    })

    return urlParams
  }

  const updateCache = (affiliateParam: AffiliateParam) => {
    setLocalStorage(affiliateParam.name, affiliateParam.value)
    const expiryKey = `${affiliateParam.name}_expiry`
    const expiryTimestamp = Date.now() + affiliateParam.expiry * 24 * 60 * 60 * 1000
    setLocalStorage(expiryKey, expiryTimestamp.toString())
  }

  const isExpired = (expiryKey: string): boolean => {
    const expiryTimestamp = getLocalStorage(expiryKey, false)
    if (expiryTimestamp) {
      return Date.now() > Number(expiryTimestamp)
    }
    return true
  }

  const urlParams = getURLParams()

  urlParams.forEach((param) => {
    const cachedValue = getLocalStorage(param.name, false)
    const expiryKey = `${param.name}_expiry`

    if (cachedValue) {
      if (isExpired(expiryKey)) {
        updateCache(param)
      }
    } else {
      updateCache(param)
    }
  })

  return affiliateRefs
}

export const getPlatformsWithAffiliates = (platformBackend: PlatformBackendType) => {
  return platformBackend?.backends?.filter((p) => p.hasAffiliateUserGroup) || []
}
