import styled from "@emotion/styled"
import { Select } from "@mui/material"
import { lightGreyColor } from "styles"

export const Container = styled(Select)`
  fieldset {
    border-radius: 13px;
    border-color: ${lightGreyColor};
  }
`

export const containerStyle = (sx: object) => ({
  borderRadius: "20px",
  ...sx
})
