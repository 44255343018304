import React from "react"
import { LIVE, MT4 } from "utils"
import { MT4LogoIcon, TnLogoIcon } from ".."
import { Row } from "styles/generalStyles"
import { PracticeChip } from "./AccountCard.styled"

type AccountIconProps = {
  backendType: string
  type: string
  isOverviewPage: boolean
}
type AccountLogoProps = Partial<Pick<AccountIconProps, "backendType">>
type AccountDemoChipProps = Pick<AccountIconProps, "isOverviewPage" | "backendType">

const AccountCardLogo = ({ backendType }: AccountLogoProps) => {
  return backendType === MT4 ? <MT4LogoIcon /> : <TnLogoIcon />
}

export const AccountCardDemoChip = ({ isOverviewPage, backendType }: AccountDemoChipProps) => {
  return (
    <Row>
      {!isOverviewPage && <AccountCardLogo backendType={backendType} />}
      <PracticeChip>PRACTICE</PracticeChip>
    </Row>
  )
}

const AccountCardIcon = ({ type, backendType, isOverviewPage }: AccountIconProps) => {
  if (type === LIVE || isOverviewPage) {
    return <AccountCardLogo backendType={backendType} />
  }

  return <AccountCardDemoChip isOverviewPage={isOverviewPage} backendType={backendType} />
}

export default AccountCardIcon
