import styled from "@emotion/styled"
import { accentGreenColor, theme, whiteColor } from "styles"

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 300;
  ${theme.breakpoints.down("lg")} {
    font-size: 14px;
  }
`

export const EquityValue = styled.div`
  margin-left: 5px;
  padding: 6px 10px 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 37px;
  background: ${accentGreenColor};
  color: ${whiteColor};
  font-weight: 400;
  line-height: 21px;
  min-width: 62px;
  text-align: center;
  ${theme.breakpoints.down("lg")} {
    font-size: 13px;
  }
`
