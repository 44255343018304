import React from "react"
import { InputLabel, MenuItem, SelectChangeEvent } from "@mui/material"
import { DropdownType, ExtraFieldProps } from "components"
import { Container, containerStyle } from "./Dropdown.styled"

const Dropdown = ({
  currentValue,
  handleChange,
  placeholder,
  options,
  fieldName,
  sx = {}
}: DropdownType & ExtraFieldProps) => {
  const onChange = (event: SelectChangeEvent<unknown>) => {
    handleChange({ [fieldName]: event.target.value })
  }

  return (
    <>
      <InputLabel sx={{ opacity: "0.5" }} id={`${placeholder}-select-label`}>
        {placeholder}
      </InputLabel>
      <Container
        id={`${placeholder}-select`}
        value={currentValue}
        label={placeholder}
        onChange={onChange}
        sx={containerStyle(sx)}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Container>
    </>
  )
}

export default Dropdown
