import React, { useState } from "react"
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"
import SwapAccountModal from "components/pages/account/accounts/SwapAccountModal"
import { SubHeaderChip, chipIconStyle } from "components/pages/account/accounts/subHeader.styled"
import { useAccountData } from "hooks"
import { FeatureFlag, FULL } from "utils"
import { useFeatureFlagEnabled } from "utils/posthog"

export const useSwapFreeAccount = () => {
  const [swapAccountModalOpen, setSwapAccountModalOpen] = useState(false)
  const { appMetadata } = useAccountData()
  const mayDisplaySwapFree = useFeatureFlagEnabled(FeatureFlag.SWAP_FREE)

  const openSwapAccountModal = () => {
    setSwapAccountModalOpen(true)
  }

  const closeSwapAccountModal = () => {
    setSwapAccountModalOpen(false)
  }

  const displaySwapAccountModal = () => {
    if (swapAccountModalOpen) {
      return <SwapAccountModal open={swapAccountModalOpen} handleClose={closeSwapAccountModal} />
    }

    return null
  }

  const displaySwapAccountChip = () => {
    const { clients } = appMetadata || { clients: [] }

    if (mayDisplaySwapFree && clients.length > 0 && clients[0].type === "live" && clients[0].state === FULL) {
      return (
        <SubHeaderChip
          label="Swap free"
          borderradius="51"
          cursor="pointer"
          icon={<QuestionMarkIcon style={chipIconStyle} />}
          onClick={openSwapAccountModal}
        />
      )
    }

    return null
  }

  return {
    swapAccountModalOpen,
    openSwapAccountModal,
    displaySwapAccountModal,
    displaySwapAccountChip
  }
}
