import React from "react"
import { Text } from "components"
import { StyledAvatar } from "./UserMenu.styled"

type Props = {
  firstName: string
  lastName: string
}

const ProgrammaticAvatar = ({ firstName, lastName }: Props) => {
  let displayName = ""
  if (firstName) {
    displayName = `${firstName.charAt(0).toUpperCase()}`
  }
  if (lastName) {
    displayName += lastName?.charAt(0).toUpperCase()
  }

  return <StyledAvatar>{displayName && <Text text={displayName} />}</StyledAvatar>
}

export default ProgrammaticAvatar
