import React, { useEffect, useRef, useState } from "react"
import Button from "@mui/material/Button"
import Image from "next/image"
import { ActionButtonActionType, ActionButtonVariantType } from "components"
import {
  POSTHOG_BACK_BUTTON_CLICK,
  POSTHOG_CONTINUE_BUTTON_CLICK,
  POSTHOG_SIGNUP_BUTTON_CLICK,
  POSTHOG_SOCIAL_BUTTON_CLICK,
  POSTHOG_SUBMIT_BUTTON_CLICK,
  POSTHOG_LOGIN_BUTTON_CLICK,
  // postHogFireEvent,
  sendMessageToWindow
} from "utils"
import { useSignupData } from "hooks"
import { muiButtonStyle } from "./ActionButton.styled"
import WidgetSignupSocialButtons from "components/socialbuttons/WidgetSignupSocialButtons"
import { Google } from "@mui/icons-material"
import IconButton from "@mui/material/IconButton"
import { Box, Grow, Slide, Typography } from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import styled from "@emotion/styled"

export enum ButtonAnimations {
  Default = "default",
  VariantOne = "variantionOne",
  Static = "staticAnimation"
}

type Props = {
  displayText?: string | undefined
  onClick: () => void
  id?: string
  sx?: object
  disabled?: boolean
  variant?: ActionButtonVariantType | undefined
  lightBackground?: boolean
  textUnderline?: boolean
  image?: {
    src: string
    alt: string
    width: number
    height: number
  }
  actionType?: ActionButtonActionType
  isSignupWidget?: boolean
  isLoginWidget?: boolean
  isSignupOrLogin?: boolean
}

const ActionButton = ({
  displayText,
  id,
  onClick,
  sx = {},
  disabled,
  variant = "contained",
  lightBackground = false,
  textUnderline = false,
  image,
  actionType,
  isSignupWidget,
  isLoginWidget,
  isSignupOrLogin = false
}: Props) => {
  const { stepUid, currentStepPart } = useSignupData()
  const [clicked, setClicked] = useState<boolean>(false)
  const buttonsToAutoClick = ["Continue", "continue", "Log in", "Login", "login", "log in", "select"]

  const timer = () => {
    setClicked(true)
  }

  const animateArrow = () => {
    const timeoutValue = clicked ? 1000 : undefined

    return (
      <Grow in={clicked} dir="right" style={{ transformOrigin: "0 0 0" }} timeout={timeoutValue}>
        <svg
          style={{
            width: "35px",
            objectFit: "cover",
            objectPosition: "left"
          }}
          width="39"
          height="17"
          viewBox="0 0 39 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.3687 0.388184L25.2403 0.878445L37.2403 7.62844L38.7897 8.50002L37.2403 9.3716L25.2403 16.1216L24.3687 16.6119L23.3882 14.8687L24.2597 14.3784L32.931 9.50002H0V7.50002H32.934L24.2597 2.6216L23.3882 2.13133L24.3687 0.388184Z"
            fill="#050F19"
          />
        </svg>
      </Grow>
    )
  }

  const getArrowVisibility = (): string => {
    if (!clicked) {
      return "block"
    } else {
      return "none"
    }
  }

  const btnRef = useRef<HTMLButtonElement | null>(null)
  useEffect(() => {
    type EventType = { keyCode: number }
    const findButtonPressIt = (e: EventType) => {
      const isPlatformSelection = window.location.pathname.includes("/platform-selection")
      if (e.keyCode === 13) {
        if (!isPlatformSelection) {
          if (
            (!disabled && displayText === "Continue") ||
            displayText === "Sign up now" ||
            displayText === "Log in" ||
            buttonsToAutoClick.includes(displayText)
          ) {
            btnRef.current.click()
          }
        }
      }
    }
    document.addEventListener("keydown", findButtonPressIt)
    return () => {
      document.removeEventListener("keydown", findButtonPressIt)
    }
  }, [id, disabled])

  const onButtonClick = () => {
    // POST HOG
    if (!disabled && actionType) {
      // let postHogEvent = ""
      // switch (actionType) {
      //   case ActionButtonActionType.Signup:
      //     postHogEvent = POSTHOG_SIGNUP_BUTTON_CLICK
      //     break
      //   case ActionButtonActionType.Social:
      //     postHogEvent = POSTHOG_SOCIAL_BUTTON_CLICK
      //     break
      //   case ActionButtonActionType.Continue:
      //     postHogEvent = POSTHOG_CONTINUE_BUTTON_CLICK
      //     break
      //   case ActionButtonActionType.Back:
      //     postHogEvent = POSTHOG_BACK_BUTTON_CLICK
      //     break
      //   case ActionButtonActionType.Submit:
      //     postHogEvent = POSTHOG_SUBMIT_BUTTON_CLICK
      //     break
      //   case ActionButtonActionType.Login:
      //     postHogEvent = POSTHOG_LOGIN_BUTTON_CLICK
      //     break
      //   default:
      // }
      //
      // if (postHogEvent) {
      //   postHogFireEvent(postHogEvent, {
      //     currentStep: stepUid,
      //     currentStepPart: currentStepPart + 1
      //   })
      // }
    }
    timer()
    onClick()
  }

  const socialSignupIcons = () => {
    if (image) {
      switch (image.alt) {
        case "Google":
          return <WidgetSignupSocialButtons socialType="Google" onClick={onClick} />
        case "LinkedIn":
          return <WidgetSignupSocialButtons socialType="LinkedIn" onClick={onClick} />
        case "Apple":
          return <WidgetSignupSocialButtons socialType="Apple" onClick={onClick} />
        default:
          return null
      }
    }
  }
  return (
    <Button
      ref={btnRef}
      id={id}
      variant={variant}
      onClick={onButtonClick}
      sx={muiButtonStyle(lightBackground, textUnderline, variant, sx)}
      disabled={disabled}
      disableElevation
      fullWidth={isLoginWidget}
      data-test-id="action-button"
    >
      {isSignupOrLogin && (
        <>
          <Slide
            direction="right"
            in={clicked}
            container={btnRef.current}
            style={{
              position: "absolute",
              right: "55%",
              minHeight: "100%",
              minWidth: "100%",
              transition: "ease-in 0.8s"
            }}
            timeout={1600}
          >
            <Box
              sx={{
                filter: "blur(4px)"
              }}
            >
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  minHeight: "60px",
                  width: "100%",
                  opacity: 0.8
                }}
              />
            </Box>
          </Slide>
        </>
      )}
      {socialSignupIcons()}
      {isSignupOrLogin ? (
        <Box
          sx={{
            p: 2,
            overflow: "hidden",
            display: isSignupOrLogin && "contents"
          }}
        >
          {isSignupOrLogin ? (
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <Typography
                variant="body1"
                fontWeight={"700"}
                sx={{
                  transition: "ease-in 0.4s",
                  transform: clicked ? "scale(1.1)" : "none",
                  fontWeight: "700 !important",
                  fontSize: { xs: "14px", xl: "18px" },
                  color: "#050F19 !important"
                }}
              >
                {displayText}
              </Typography>
              <svg
                style={{
                  display: getArrowVisibility(),
                  width: "35px",
                  objectFit: "cover",
                  objectPosition: "left"
                }}
                width="39"
                height="17"
                viewBox="0 0 39 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.3687 0.388184L25.2403 0.878445L37.2403 7.62844L38.7897 8.50002L37.2403 9.3716L25.2403 16.1216L24.3687 16.6119L23.3882 14.8687L24.2597 14.3784L32.931 9.50002H0V7.50002H32.934L24.2597 2.6216L23.3882 2.13133L24.3687 0.388184Z"
                  fill="#050F19"
                />
              </svg>
              <Box
                sx={{
                  display: clicked ? "flex" : "none",
                  alignItems: "center",
                  height: "100%",
                  width: clicked ? "35px" : "0",
                  transition: "ease-in 0.8s",
                  position: "relative",
                  zIndex: 10
                }}
              >
                <svg
                  style={{
                    display: clicked ? "none" : "flex",
                    width: "35px",
                    objectFit: "cover",
                    objectPosition: "left"
                  }}
                  width="39"
                  height="17"
                  viewBox="0 0 39 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.3687 0.388184L25.2403 0.878445L37.2403 7.62844L38.7897 8.50002L37.2403 9.3716L25.2403 16.1216L24.3687 16.6119L23.3882 14.8687L24.2597 14.3784L32.931 9.50002H0V7.50002H32.934L24.2597 2.6216L23.3882 2.13133L24.3687 0.388184Z"
                    fill="#050F19"
                  />
                </svg>
                {clicked && animateArrow()}
              </Box>
            </Box>
          ) : (
            displayText
          )}
        </Box>
      ) : (
        displayText
      )}
    </Button>
  )
}

export default ActionButton
