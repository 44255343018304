export const getAddressFields = (fieldName: string) => {
  return {
    addressLine1: `${fieldName}_line_1`,
    addressLine2: `${fieldName}_line_2`,
    addressCity: `${fieldName}_city`,
    addressPostcode: `${fieldName}_zip`,
    auState: "au_state",
    zaProvince: "za_province",
    gender: "gender",
    itProvince: "it_province"
  }
}

export const AU_STATES = [
  { value: "101", label: "Australian Capital Territory" },
  { value: "102", label: "New South Wales" },
  { value: "103", label: "Victoria" },
  { value: "104", label: "Queensland" },
  { value: "105", label: "South Australia" },
  { value: "106", label: "Western Australia" },
  { value: "107", label: "Tasmania" },
  { value: "108", label: "Northern Territory" }
]

export const GENDERS = [
  { value: "1", label: "Male" },
  { value: "2", label: "Female" }
]
