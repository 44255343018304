import { Grid } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { Apple, Google, LinkedIn } from "@mui/icons-material"
import React from "react"
import { sendMessageToWindow } from "utils/iframe"

type WidgetSocialButtonsProps = {
  onClick: () => void
}

export const WidgetSocialButtons = ({ onClick }: WidgetSocialButtonsProps) => {
  return (
    <Grid container spacing={2} alignItems="center" justifyContent={"center"}>
      <Grid item>
        <IconButton aria-label="Google">
          <Google
            sx={{ color: "white" }}
            onClick={async () => {
              onClick()
              sendMessageToWindow({
                action: "social-auth",
                data: {
                  provider: "google-oauth2"
                }
              })
            }}
          />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton aria-label="Apple">
          <Apple
            sx={{ color: "white" }}
            onClick={async () => {
              onClick()
              sendMessageToWindow({
                action: "social-auth",
                data: {
                  provider: "apple"
                }
              })
            }}
          />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton aria-label="LinkedIn">
          <LinkedIn
            sx={{ color: "white" }}
            onClick={async () => {
              onClick()
              sendMessageToWindow({
                action: "social-auth",
                data: {
                  provider: "custom-linkedin"
                }
              })
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  )
}
