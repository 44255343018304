import React, { useState } from "react"
import { Divider, Menu } from "@mui/material"
import { ActionButton, Text } from "components"
import { goToPage } from "utils"
import { useAccountData, useMobileOrTabletMode } from "hooks"
import {
  dividerStyle,
  kycButtonStyle,
  menuItemTypographyStyle,
  menuItemViewDashboardStyle,
  menuPaperStyle,
  StyledArrowDropDownIcon,
  StyledAvatar,
  StyledIconButton,
  StyledMenuItem,
  UserBoxContainer,
  UserMenuContainer,
  userNameTextStyle
} from "./UserMenu.styled"
import ProgrammaticAvatar from "./Avatar"
import { pages } from "./AccountHeader"

const UserMenu = () => {
  const { accountData, userMetadata, logout, kycModalData, updateKycModalDisplay } = useAccountData()
  const isMobileOrTablet = useMobileOrTabletMode()

  const firstName = userMetadata?.personal?.first_name || userMetadata?.first_name || ""
  const lastName = userMetadata?.personal?.last_name || userMetadata?.last_name || ""

  const displayUserName = () => {
    return `${firstName} ${lastName}`
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const dismissMenu = () => {
    setAnchorEl(null)
  }

  const goToSettings = () => {
    dismissMenu()
    goToPage("/account/settings")
  }
  const goToOverview = () => {
    dismissMenu()
    goToPage("/account/overview")
  }

  const goToPayments = () => {
    dismissMenu()
    goToPage("/account/payments")
  }

  const handleLogout = async () => {
    dismissMenu()
    logout()
  }

  const openKycModal = () => {
    updateKycModalDisplay()
  }

  const handleMenuItemClick = (page: string) => {
    dismissMenu()
    if (page === "Logout") {
      logout()
    } else {
      goToPage(`/account/${page.toLowerCase()}`)
    }
  }

  if (accountData) {
    return (
      <UserMenuContainer>
        <UserBoxContainer>
          <StyledIconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {accountData && accountData?.identities && accountData?.identities[0]?.isSocial ? (
              <StyledAvatar variant="rounded" alt="user_avatar" src={accountData.picture} />
            ) : (
              <ProgrammaticAvatar firstName={firstName} lastName={lastName} />
            )}
            <Text text={displayUserName()} sx={userNameTextStyle} />
            <StyledArrowDropDownIcon open={Boolean(anchorEl)} />
          </StyledIconButton>
        </UserBoxContainer>
        {
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={dismissMenu}
            PaperProps={{
              elevation: 0,
              sx: menuPaperStyle
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <StyledMenuItem onClick={goToOverview}>
              <Text
                text={displayUserName()}
                sx={menuItemTypographyStyle({
                  lineHeight: "15px",
                  fontSize: "19px"
                })}
              />
              <Text text="View Dashboard" sx={menuItemViewDashboardStyle} />
              {kycModalData.kycDue && (
                <ActionButton displayText="Confirm your details" onClick={openKycModal} sx={kycButtonStyle} />
              )}
            </StyledMenuItem>

            {isMobileOrTablet &&
              pages.map((page) => {
                if (page === "Logout" || page === "Payments" || page === "Settings") {
                  return null
                }
                return (
                  <StyledMenuItem key={page} onClick={() => handleMenuItemClick(page)}>
                    <Text text={page} sx={menuItemTypographyStyle()} />
                  </StyledMenuItem>
                )
              })}

            <Divider variant="middle" sx={dividerStyle} />

            <StyledMenuItem onClick={goToSettings}>
              <Text text="Account Settings" sx={menuItemTypographyStyle()} />
            </StyledMenuItem>
            <StyledMenuItem onClick={goToPayments}>
              <Text text="Payments" sx={menuItemTypographyStyle()} />
            </StyledMenuItem>

            <Divider variant="middle" sx={dividerStyle} />

            <StyledMenuItem onClick={handleLogout} isLast>
              <Text
                text="Logout"
                sx={menuItemTypographyStyle({
                  marginBottom: 1
                })}
              />
            </StyledMenuItem>
          </Menu>
        }
      </UserMenuContainer>
    )
  } else {
    return null
  }
}

export default UserMenu
