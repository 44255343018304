import styled from "@emotion/styled"
import Link from "next/link"
import { whiteColor } from "styles"

export const Container = styled.div<{ height: string; url: string }>`
  height: ${(props) => props.height};
  width: 100%;
  word-wrap: break-word;
  position: relative;
  overflow: hidden;

  p {
    color: ${whiteColor};
  }

  h4 {
    color: ${whiteColor};
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
  }
`

export const BackgroundImageWithLink = styled(Link)<{ url: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: ${(props) => `url(${props.url})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease-in-out;
  filter: brightness(60%);

  &:hover {
    transform: scale(1.1);
  }
`

export const Content = styled.div`
  text-decoration: none;
  position: relative;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.3);
  color: ${whiteColor};
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
`
