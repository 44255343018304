import styled from "@emotion/styled"
import { isPaymentPage } from "utils/general"

export const Container = styled.div<{ path: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: ${({ path }) => (isPaymentPage(path) ? 0 : 1)};
  position: relative;
`
