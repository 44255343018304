import styled from "@emotion/styled"
import { darkBlueColor, whiteColor } from "styles"

export const snackbarStyle = {
  width: "1200px",
  maxWidth: "90%",
  top: "115px !important",
  left: "50% !important",
  transform: "translateX(-50%)",
  zIndex: 1
}

export const Container = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  justify-content: space-between;
  border-radius: 9px;
  background-color: ${darkBlueColor};
  padding: 12px 20px;
  width: 100%;
  gap: 20px;

  p {
    color: ${whiteColor};
    font-size: 14px;
    font-weight: 400;
    margin-left: ${(props) => (props.mobile ? "0" : "15px")};
    text-align: center;
  }
`

export const ContentWrapper = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  gap: 15px;
  align-items: center;
`

export const connectButtonStyle = {
  backgroundColor: whiteColor,
  color: darkBlueColor,
  padding: "10px 15px",
  minHeight: "30px",
  borderRadius: "85px",
  "&:hover": {
    backgroundColor: whiteColor,
    color: darkBlueColor
  }
}

export const closeButtonStyle = {
  color: whiteColor
}
