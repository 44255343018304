/* eslint-disable @typescript-eslint/default-param-last */
import { isBefore, subYears, isValid } from "date-fns"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import moment from "moment"
import { RichTextField } from "@prismicio/types"
import { RichTextBlock } from "prismic-reactjs"
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js"
import { AllFieldsType, EmailTextfieldType, FieldVariation, PasswordTextfieldType } from "components"
import {
  COUNTRY_OF_RESIDENCE_FIELD_NAME,
  getAddressFields,
  getFieldValue,
  ExtraValidationType,
  SignupDataType,
  flattenSignupData
} from "utils"

export const isRichTextValid = (text?: RichTextField) => {
  return !!(text && text.length > 0 && (text[0] as RichTextBlock).text)
}

export const addressValidation = (
  address,
  optional = false,
  isCityField = false,
  messages: { [key: string]: string }
) => {
  if ((optional && !address) || !messages) {
    return []
  }

  const brokenRules = []

  let regX = /[!$&+:;=?@|<>^*%![\]"]/
  if (isCityField) {
    regX = /[!$&+#:;=?@|<>^*()%![\]"]/
  }

  if (!address) {
    brokenRules.push(messages.required_field)
  } else if (regX.test(address)) {
    brokenRules.push(messages.no_special_characters)
  }

  return brokenRules
}

export const postCodeValidation = (postcode, messages: { [key: string]: string }, country?: string) => {
  if (!messages) {
    return []
  }
  if (!postcode && country !== "SC") {
    return [messages.required_field]
  }

  const brokenRules = []

  if (country === "GB") {
    if (
      !/^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i.test(
        postcode
      )
    ) {
      brokenRules.push(messages.enter_valid_post_code)
    }
  } else if (country === "SC") {
    return brokenRules
  } else {
    if (!/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i.test(postcode)) {
      brokenRules.push(messages.enter_valid_post_code)
    }
  }
  return brokenRules
}

export const fieldValidation = (
  field: AllFieldsType,
  value: string,
  signupData: SignupDataType,
  messages: { [key: string]: string },
  required?: boolean,
  extraValidation?: ExtraValidationType[]
) => {
  const getMessage = (key: string) => {
    return messages ? messages[key.toLowerCase()] : ""
  }

  /**
   * Helper function to append 0 to days and month if their values are single digit
   * for the customParseFormat of dayjs to work correctly
   * for example : dayjs(`1/2/1984`, 'DD/MM/YYYY', true) is an invalid date
   *             :  dayjs(`01/02/1984`, 'DD/MM/YYYY', true) is a valid date
   */
  dayjs.extend(customParseFormat)
  type DayjsDateProps = { year: number; month: number; day: number }
  const dayjsDate = ({ year, month, day }: DayjsDateProps) => {
    const dayStr = `0${day}`.slice(-2)
    const monthStr = `0${month}`.slice(-2)
    return dayjs(`${dayStr}/${monthStr}/${year}`, "DD/MM/YYYY", true)
  }

  const emailValidation = (email: string, emailExist?: boolean) => {
    if (email.length === 0 && !required) {
      return []
    }

    const { checkValidation } = field as EmailTextfieldType
    if (!checkValidation) {
      return []
    }

    const brokenRules = []
    if (email.length === 0 && required) {
      brokenRules.push(getMessage("REQUIRED_FIELD"))
    } else if (emailExist) {
      brokenRules.push(getMessage("EMAIL_EXISTS"))
    } else if (!/^[\w\+\-.]+@[+\w-]+(\.[\w-]+)*\.[\w-]{2,25}$/.test(email)) {
      brokenRules.push(getMessage("WRONG_EMAIL"))
    }
    return brokenRules
  }

  const addExtraValidation = (extraValidations: ExtraValidationType[]) => {
    const brokenRules: string[] = []
    extraValidations?.forEach((validation) => {
      if (validation.condition()) {
        brokenRules.push(validation.message)
      }
    })
    return brokenRules
  }

  const passwordValidation = (password = "", currentPassword = "") => {
    const { minCharacters, maxCharacters, lowerAndUpperCase, numberAndSymbol, symbols, noSpecialCharacters } =
      field as PasswordTextfieldType

    if (password.length === 0 && !required) {
      return []
    }

    const brokenRules = []

    if (password.length === 0 && required) {
      brokenRules.push(getMessage("REQUIRED_FIELD"))
    } else if (password === currentPassword) {
      brokenRules.push(getMessage("NEW_PASSWORD_MUST_BE_DIFFERENT"))
    } else {
      if (minCharacters && password.length < minCharacters) {
        brokenRules.push(getMessage("MUST_HAVE_CHARACTERS").replace("{min}", minCharacters.toString()))
      }

      if (maxCharacters && password.length > maxCharacters && maxCharacters > minCharacters) {
        brokenRules.push(
          getMessage("MUST_BE_BETWEEN_CHARACTERS")
            ?.replace("{max}", maxCharacters.toString())
            .replace("{min}", (minCharacters || 1).toString())
        )
      }

      if (lowerAndUpperCase && !(/[a-z]/.test(password) && /[A-Z]/.test(password))) {
        brokenRules.push(getMessage("MUST_HAVE_LOWER_AND_UPPER_CASE"))
      }

      if (numberAndSymbol && !(/[0-9]/.test(password) && new RegExp(`[${symbols}]`).test(password))) {
        brokenRules.push(getMessage("MUST_HAVE_NUMBER_AND_SYMBOL"))
      }

      if (noSpecialCharacters && !/^[ A-Za-z0-9!@#$%^&* ]*$/.test(password)) {
        brokenRules.push(getMessage("MUST_NOT_HAVE_SPECIAL_CHARACTERS"))
      }
    }

    const extraValidationMessages = addExtraValidation(extraValidation)
    if (extraValidationMessages.length > 0) {
      brokenRules.push(...extraValidationMessages)
    }

    return brokenRules
  }

  const confirmPasswordValidation = (confirmPassword: string, password: string) => {
    const brokenRules = []

    if (confirmPassword.length === 0 && required) {
      brokenRules.push(getMessage("REQUIRED_FIELD"))
    } else if (confirmPassword !== password) {
      brokenRules.push(getMessage("PASSWORD_CONFIRMATION_DOES_NOT_MATCH"))
    }

    return brokenRules
  }

  const seychellesPhoneValidation = (phoneNumber: string) => {
    if (/^\+248\d{7}$/.test(phoneNumber)) {
      return true
    }
  }

  const phoneValidation = (phoneNumber: string, countryCode: string) => {
    const phoneCheck = (number: string) => {
      // seychelles phone number \/
      if (number.includes("+248")) {
        return seychellesPhoneValidation(number)
      } else {
        if (!countryCode) {
          return false
        }

        return isValidPhoneNumber(number, countryCode.toUpperCase() as CountryCode)
      }
    }

    if (phoneNumber.length === 0 && !required) {
      return []
    }

    if (phoneNumber.length === 0 && required) {
      return [getMessage("REQUIRED_FIELD")]
    } else {
      if (!phoneCheck(phoneNumber)) {
        if (countryCode === "sc" && phoneNumber.length === 10) {
          return []
        }
        return [getMessage("ENTER_VALID_PHONE_NUMBER")]
      }
    }

    return []
  }

  const dateOfBirthValidation = () => {
    const brokenRules = []
    const dobRegexp =
      // eslint-disable-next-line no-useless-escape
      // DD/MM/YYY validation
      /^(?!(\d{4}\-(((0)[1-9])|((1)[012]))\-(((0)[1-9])|(([1-2])[0-9])|3[01]))$).*/

    if (value.length === 0 && required) {
      brokenRules.push(getMessage("REQUIRED_FIELD"))
    } else {
      if (!dobRegexp.test(value)) {
        brokenRules.push(getMessage("INVALID_DATE_FORMAT"))
      } else {
        const maximumValidDate = subYears(new Date(), 18)
        const [day, month, year] = value.split("/").map(Number)
        const selectedDate = new Date(year, month - 1, day)
        const currentDate = moment(value, "DD/MM/YYYY").toDate()

        if (!isValid(selectedDate)) {
          brokenRules.push(getMessage("INVALID_DATE"))
        } else if (isBefore(maximumValidDate, currentDate)) {
          brokenRules.push(getMessage("MUST_BE_18_OR_OVER"))
        } else if (selectedDate.getFullYear() < 1900) {
          brokenRules.push(getMessage("ENTER_VALID_DOB"))
        }
      }
    }

    return brokenRules
  }

  const cardGroupValidation = () => {
    return required && !value ? [getMessage("SELECT_AN_OPTION")] : []
  }

  const requiredValidation = () => {
    return !value ? [getMessage("REQUIRED_FIELD")] : []
  }

  const denmarkCPRNumberValidation = () => {
    if (!value && !required) {
      return []
    }

    if (!value && required) {
      return [getMessage("REQUIRED_FIELD")]
    }

    if (value && !/^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/i.test(value)) {
      return [getMessage("ENTER_VALID_VALUE")]
    }

    return []
  }

  const zaIdCardNumberValidation = () => {
    if (!value && !required) {
      return []
    }

    if (!value && required) {
      return [getMessage("REQUIRED_FIELD")]
    }

    if (value && !/(([0-9]{2})(0|1)([0-9])([0-3])([0-9]))([ ]?)(([0-9]{4})([ ]?)([0-1][8]([ ]?)[0-9]))/i.test(value)) {
      return [getMessage("ENTER_VALID_VALUE")]
    }

    return []
  }

  const nationalInsuranceNumberValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^[A-CEGHJ-TW-Z][A-CEGHJ-NP-TW-Z]\d{6}[A-D]$/i
      if (
        !filterRegExp.test(compactVal) ||
        ["OO", "CR", "FY", "MW", "NC", "PZ", "TN"].includes(compactVal.substring(0, 2))
      ) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const belgianNationalNumberValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{11}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const year = parseInt(compactVal.substring(0, 2), 10) + 1900
      const month = parseInt(compactVal.substring(2, 4), 10)
      const day = parseInt(compactVal.substring(4, 6), 10)
      if (!dayjsDate({ year, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      const checksum = 97 - (parseInt(compactVal.substring(0, valLen - 2), 10) % 97)
      if (checksum !== parseInt(compactVal.substring(valLen - 2, valLen), 10)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const bulgarianPersonalNumberValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{10}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      let year = parseInt(compactVal.substring(0, 2), 10) + 1900
      const month = parseInt(compactVal.substring(2, 4), 10)
      const day = parseInt(compactVal.substring(4, 6), 10)
      const modYear = month > 20 ? -100 : 0
      year += (month > 40 ? -1 : 1) * modYear
      if (!dayjsDate({ year, month: (month % 40) % 20, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      const weights = [2, 4, 8, 5, 10, 9, 7, 3, 6]
      const toCheck =
        (weights.reduce((x, y, z) => {
          return y * parseInt(compactVal[z], 10) + x
        }, 0) %
          11) %
        10
      if (toCheck !== parseInt(compactVal[valLen - 1], 10)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalIdentificationNumberCzSkValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ /]/g, "").trim()
      const valLen = compactVal.length
      if (valLen !== 9 && valLen !== 10) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      let year = 1900 + parseInt(compactVal.substring(0, 2), 10)
      const month = (parseInt(compactVal.substring(2, 4), 10) % 50) % 20
      const day = parseInt(compactVal.substring(4, 6), 10)
      if (valLen === 9) {
        if (year >= 1980) {
          year -= 100
        }
        if (year > 1953) {
          return [getMessage("ENTER_VALID_VALUE")]
        }
      } else if (year < 1954) {
        year += 100
      }
      const birthDate = dayjsDate({ year, month, day })
      if (birthDate.isValid()) {
        if (valLen === 10) {
          let check = parseInt(compactVal.substring(0, valLen - 1), 10) % 11
          if (birthDate < dayjs("01/01/1985", "DD/MM/YYYY", true)) {
            check %= 10
          }
          if (compactVal[valLen - 1] !== check.toString()) {
            return [getMessage("ENTER_VALID_VALUE")]
          }
        }
        return []
      }
      return [getMessage("ENTER_VALID_VALUE")]
    }
    return []
  }

  const passportNumberValidation = () => {
    if (value) {
      const filterRegExp = /^\d{8,100}$/
      if (!filterRegExp.test(value)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const personalIdentityCodeDkValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{10}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const day = parseInt(compactVal.substring(0, 2), 10)
      const month = parseInt(compactVal.substring(2, 4), 10)
      let year = parseInt(compactVal.substring(4, 6), 10)
      if ("5678".includes(compactVal[6]) && year >= 58) {
        year += 1800
      } else if ("0123".includes(compactVal[6]) || ("49".includes(compactVal[6]) && year >= 37)) {
        year += 1900
      } else {
        year += 2000
      }
      if (!dayjsDate({ year, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const estonianPersonalCodeValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ ]/g, "").trim()
      const filterRegExp = /^\d{11}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const century = 1700 + Math.round(parseInt(compactVal[0], 10) / 2) * 100
      const day = parseInt(compactVal.substring(1, 3), 10)
      const month = parseInt(compactVal.substring(3, 5), 10)
      const year = parseInt(compactVal.substring(5, 7), 10)

      if (!dayjsDate({ year: year + century, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      let check =
        Array.from(compactVal.substring(0, valLen - 1))
          .map((x, y) => {
            return parseInt(x, 10) * ((y % 9) + 1)
          })
          .reduce((x, y) => {
            return y + x
          }) % 11
      if (check === 10) {
        check =
          Array.from(compactVal.substring(0, valLen - 1))
            .map((x, y) => {
              return parseInt(x, 10) * (((y + 2) % 9) + 1)
            })
            .reduce((x, y) => {
              return y + x
            }) % 11
      }
      check %= 10
      if (compactVal[valLen - 1] !== check.toString()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
      return []
    }
    return []
  }

  const esTaxIdValidation = () => {
    if (value) {
      const compactVal = value
        .replace(/([ .-]|ES)/g, "")
        .trim()
        .toUpperCase()
      const filterRegExp = /^[0-9A-Z]\d{7}[0-9A-Z]$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      let toCheck
      if ("KLM".includes(compactVal[0])) {
        toCheck = parseInt(compactVal.substring(1, valLen - 1), 10)
      } else if (!Number.isNaN(parseInt(compactVal[0], 10))) {
        toCheck = parseInt(compactVal.substring(0, valLen - 1), 10)
      } else if ("XYZ".includes(compactVal[0])) {
        toCheck = parseInt("XYZ".indexOf(compactVal[0]) + compactVal.substring(1, valLen - 1), 10)
      }
      if ("ABCDEFGHJNPQRSUVW".includes(compactVal[0])) {
        const number = `${compactVal.substring(1, valLen - 1)}0`
        const numberLen = number.length - 1
        let sum = 0
        for (let i = numberLen; i > -1; i--) {
          if ((numberLen - i) % 2 === 0) {
            sum += parseInt(number[i], 10)
          } else {
            sum += Math.floor((Number(number[i]) * 2) / 10)
            sum += (Number(number[i]) * 2) % 10
          }
        }
        const check = "0123456789"[10 - (sum % 10)]
        toCheck = check + "JABCDEFGHI"[parseInt(check, 10)]
        if (!toCheck.includes(compactVal[valLen - 1])) {
          return [getMessage("ENTER_VALID_VALUE")]
        }
      }

      const check = "TRWAGMYFPDXBNJZSQVHLCKE"[Number(toCheck) % 23]
      if (check !== compactVal[valLen - 1]) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const dssInvestorShareValidation = () => {
    if (value) {
      const filterRegExp = /^\d{10}$/
      if (!filterRegExp.test(value)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const personalIdHrValidation = () => {
    if (value) {
      const compactVal = value
        .replace(/([ .-]|HR)/g, "")
        .trim()
        .toUpperCase()
      const filterRegExp = /^\d{11}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const check = Array.from(compactVal).reduce((x, y) => {
        return ((((x || 10) * 2) % 11) + parseInt(y, 10)) % 10
      }, 5)
      if (check !== 1) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const fiscalCodeValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim().toUpperCase()
      const filterRegExp = /^[A-Z]{6}[0-9LMNP-V]{2}[A-EHLMPRST][0-9LMNP-V]{2}[A-Z][0-9LMNP-V]{3}[A-Z]$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      const evenValues: { [key: number | string]: number } = {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        A: 0,
        B: 1,
        C: 2,
        D: 3,
        E: 4,
        F: 5,
        G: 6,
        H: 7,
        I: 8,
        J: 9,
        K: 10,
        L: 11,
        M: 12,
        N: 13,
        O: 14,
        P: 15,
        Q: 16,
        R: 17,
        S: 18,
        T: 19,
        U: 20,
        V: 21,
        W: 22,
        X: 23,
        Y: 24,
        Z: 25
      }
      const oddValues: { [key: number | string]: number } = {
        0: 1,
        1: 0,
        2: 5,
        3: 7,
        4: 9,
        5: 13,
        6: 15,
        7: 17,
        8: 19,
        9: 21,
        A: 1,
        B: 0,
        C: 5,
        D: 7,
        E: 9,
        F: 13,
        G: 15,
        H: 17,
        I: 19,
        J: 21,
        K: 2,
        L: 4,
        M: 18,
        N: 20,
        O: 11,
        P: 3,
        Q: 6,
        R: 8,
        S: 12,
        T: 14,
        U: 16,
        V: 10,
        W: 22,
        X: 25,
        Y: 24,
        Z: 23
      }
      const toCheck = Array.from(compactVal.substring(0, valLen - 1)).reduce((x, y, z) => {
        return (z % 2 === 0 ? oddValues[y] : evenValues[y]) + x
      }, 0)
      if ("ABCDEFGHIJKLMNOPQRSTUVWXYZ"[toCheck % 26] !== compactVal[valLen - 1]) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalIdentityCardNumberLiValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim().toUpperCase()
      const filterRegExp = /^[A-Z]{2}\d{8}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalIdentityCardNumberNlValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim().padStart(9, "0")
      const filterRegExp = /^\d{9}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      const toCheck =
        (Array.from(compactVal.substring(0, valLen - 1)).reduce((x, y, z) => {
          return (9 - z) * parseInt(y, 10) + x
        }, 0) -
          parseInt(compactVal[valLen - 1], 10)) %
        11
      if (toCheck !== 0) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const personalCodeLtValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{11}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const century = 1700 + Math.round(parseInt(compactVal[0], 10) / 2) * 100
      const year = parseInt(compactVal.substring(1, 3), 10)
      const month = parseInt(compactVal.substring(3, 5), 10)
      const day = parseInt(compactVal.substring(5, 7), 10)
      if (!dayjsDate({ year: year + century, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      let check =
        Array.from(compactVal.substring(0, valLen - 1))
          .map((x, y) => {
            return parseInt(x, 10) * ((y % 9) + 1)
          })
          .reduce((x, y) => {
            return y + x
          }) % 11
      if (check === 10) {
        check =
          Array.from(compactVal.substring(0, valLen - 1))
            .map((x, y) => {
              return parseInt(x, 10) * (((y + 2) % 9) + 1)
            })
            .reduce((x, y) => {
              return y + x
            }) % 11
      }
      check %= 10
      if (compactVal[valLen - 1] !== check.toString()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      return []
    }
    return []
  }

  const personalCodeLvValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{11}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const century = 1800 + parseInt(compactVal[6], 10) * 100
      const day = parseInt(compactVal.substring(0, 2), 10)
      const month = parseInt(compactVal.substring(2, 4), 10)
      const year = parseInt(compactVal.substring(4, 6), 10)

      if (!dayjsDate({ year: year + century, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      return []
    }
    return []
  }

  const nationalIdentificationNumberMtValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{7}[ABGHLMPZ]$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const personalIdNoValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{11}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const day = parseInt(compactVal.substring(0, 2), 10)
      const month = parseInt(compactVal.substring(2, 4), 10)
      const year = parseInt(compactVal.substring(4, 6), 10) + 1900
      if (!dayjsDate({ year, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      return []
    }
    return []
  }

  const nationalIdentificationNumberPlValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{11}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const year = parseInt(compactVal.substring(0, 2), 10)
      const month = parseInt(compactVal.substring(2, 4), 10) % 20
      const day = parseInt(compactVal.substring(4, 6), 10)
      const century = 1900 + Math.floor(parseInt(compactVal.substring(2, 4), 10) / 20) * 100

      if (!dayjsDate({ year: year + century, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
      const toCheck =
        (((10 -
          weights.reduce((x, y, z) => {
            return y * parseInt(compactVal[z], 10) + x
          }, 0)) %
          10) +
          10) %
        10
      if (compactVal[valLen - 1] !== toCheck.toString()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const taxNumberPlValidation = () => {
    if (value) {
      const compactVal = value.replace(/([ .-]|PL)/g, "").trim()
      const filterRegExp = /^\d{10}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7, -1]
      const toCheck =
        weights.reduce((x, y, z) => {
          return y * parseInt(compactVal[z], 10) + x
        }, 0) % 11
      if (toCheck !== 0) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const taxNumberPtValidation = () => {
    if (value) {
      const compactVal = value.replace(/([ .-]|PT)/g, "").trim()
      const filterRegExp = /^\d{9}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      const toCheck =
        ((((11 -
          Array.from(compactVal.substring(0, valLen - 1)).reduce((x, y, z) => {
            return (9 - z) * Number(y) + x
          }, 0)) %
          11) +
          11) %
          11) %
        10
      if (compactVal[valLen - 1] !== toCheck.toString()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalIdentificationNumberRoValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^[1-69]\d{12}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const centuryCodes: { [key: number | string]: number } = {
        1: 1900,
        2: 1900,
        3: 1800,
        4: 1800,
        5: 2000,
        6: 2000
      }
      const year = parseInt(compactVal.substring(1, 3), 10)
      const month = parseInt(compactVal.substring(3, 5), 10) % 20
      const day = parseInt(compactVal.substring(5, 7), 10)
      const century = centuryCodes[compactVal[0]] || 1900
      if (!dayjsDate({ year: year + century, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const valLen = compactVal.length
      const weights = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9]
      const toCheck =
        weights.reduce((x, y, z) => {
          return y * parseInt(compactVal[z], 10) + x
        }, 0) % 11
      const check = toCheck === 10 ? "1" : toCheck.toString()
      if (compactVal[valLen - 1] !== check.toString()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const personalIdentityNumberValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ +.-]/g, "").trim()
      const filterRegExp = /^\d{10}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const year = parseInt(compactVal.substring(0, 2), 10) + 1900
      const month = parseInt(compactVal.substring(2, 4), 10) % 20
      const day = parseInt(compactVal.substring(4, 6), 10)
      if (!dayjsDate({ year, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const numberLen = compactVal.length - 1
      let sum = 0
      for (let i = numberLen; i > -1; i--) {
        if ((numberLen - i) % 2 === 0) {
          sum += parseInt(compactVal[i], 10)
        } else {
          sum += Math.floor((Number(compactVal[i]) * 2) / 10)
          sum += (Number(compactVal[i]) * 2) % 10
        }
      }
      if (sum % 10 !== 0) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const personalIdentificationNumberSiValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ /]/g, "").trim()
      const filterRegExp = /^\d{13}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalPassportNumberCyValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^(E[1-9]{6}|K[1-9]{8})$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalPassportNumberLiValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^[A-Z]\d{5}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalPassportNumberNlValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^[A-NP-Z]{2}[A-NP-Z0-9]{6}\d$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalPassportNumberLtValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{8}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalPassportNumberMtValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{7}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalPassportNumberPtValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^[A-Z]{1,2}\d{6}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalPassportNumberRoValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^\d{8,9}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nationalPassportNumberSkValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ .-]/g, "").trim()
      const filterRegExp = /^[A-Z]{2}\d{7}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const personalIdentityCodeFiValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ ]/g, "").trim().toUpperCase()
      const filterRegExp = /^[0123]\d[01]\d\d\d[-+A]\d\d\d[0-9ABCDEFHJKLMNPRSTUVWXY]$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const centuryCodes: { [key: string]: number } = {
        "+": 1800,
        "-": 1900,
        A: 2000
      }
      const day = parseInt(compactVal.substring(0, 2), 10)
      const month = parseInt(compactVal.substring(2, 4), 10)
      const century = centuryCodes[compactVal[6]]
      const year = parseInt(compactVal.substring(4, 6), 10) + century
      const individual = parseInt(compactVal.substring(7, 10), 10)
      if (!dayjsDate({ year, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      if (individual < 2) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
      if (individual > 899) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const offset = parseInt(compactVal.substring(0, 6) + compactVal.substring(7, 10), 10) % 31
      if ("0123456789ABCDEFHJKLMNPRSTUVWXY"[offset] !== compactVal[10]) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const personalIdentityCodeIsValidation = () => {
    if (value) {
      const compactVal = value.replace(/[-]/g, "").trim().toUpperCase()
      const filterRegExp = /^[01234567]\d[01]\d\d\d\d\d\d[09]$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const day = parseInt(compactVal.substring(0, 2), 10) % 40
      const month = parseInt(compactVal.substring(2, 4), 10)
      let year = parseInt(compactVal.substring(4, 6), 10)
      if (compactVal[9] === "9") {
        year += 1900
      } else {
        year += 2000
      }
      if (!dayjsDate({ year, month, day }).isValid()) {
        return [getMessage("ENTER_VALID_VALUE")]
      }

      const weights = [3, 2, 7, 6, 5, 4, 3, 2, 1, 0]
        .map((x, y) => {
          return x * parseInt(compactVal[y], 10)
        })
        .reduce((x, y) => {
          return y + x
        })
      if (weights % 11 !== 0) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const idCardValidation = () => {
    if (value) {
      const compactVal = value.replace(/[ /]/g, "").trim()
      const filterRegExp = /^\d{13}$/
      if (!filterRegExp.test(compactVal)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const cpfNumberValidation = () => {
    if (value) {
      const filterRegExp = /^(?![0-9]{3}\s[0-9]{3}\s[0-9]{3}\s[0-9]{2}$)\d{11}$|^\d{3}\.\d{3}\.\d{3}-\d{2}$/
      if (!filterRegExp.test(value)) {
        return [getMessage("ENTER_VALID_VALUE")]
      }
    }
    return []
  }

  const nameValidation = () => {
    const nameLength = value.replaceAll(" ", "").length

    if (required && nameLength === 0) {
      return [getMessage("REQUIRED_FIELD")]
    }

    if (!required && nameLength === 0) {
      return []
    }

    if (nameLength === 1 || !/^[a-zA-Z\s-.']+$/g.test(value)) {
      return [getMessage("ENTER_VALID_NAME")]
    }

    return []
  }

  switch (field.variation) {
    case FieldVariation.Datefield:
      return dateOfBirthValidation()
    case FieldVariation.CardGroup:
      return cardGroupValidation()
    case FieldVariation.Textfield:
      const brokenRules = []
      if (required) {
        brokenRules.push(...requiredValidation())
      }
      if (brokenRules.length === 0) {
        if (field.fieldName.includes("name")) {
          brokenRules.push(...nameValidation())
        }

        switch (field.fieldName) {
          case "cpr_number":
            brokenRules.push(...denmarkCPRNumberValidation())
            break
          case "id_card_number":
            brokenRules.push(...zaIdCardNumberValidation())
            break
          case "national_insurance_number":
            brokenRules.push(...nationalInsuranceNumberValidation())
            break
          case "belgian_national_number":
            brokenRules.push(...belgianNationalNumberValidation())
            break
          case "bulgarian_personal_number":
            brokenRules.push(...bulgarianPersonalNumberValidation())
            break
          case "national_identification_number_cz":
          case "personal_number_sk":
            brokenRules.push(...nationalIdentificationNumberCzSkValidation())
            break
          case "passport_number":
            brokenRules.push(...passportNumberValidation())
            break
          case "personal_identity_code_dk":
            brokenRules.push(...personalIdentityCodeDkValidation())
            break
          case "estonian_personal_code":
            brokenRules.push(...estonianPersonalCodeValidation())
            break
          case "es_tax_id":
          case "nif_code":
            brokenRules.push(...esTaxIdValidation())
            break
          case "dss_investor_share":
            brokenRules.push(...dssInvestorShareValidation())
            break
          case "personal_id_hr":
            brokenRules.push(...personalIdHrValidation())
            break
          case "fiscal_code":
          case "tax_code":
            brokenRules.push(...fiscalCodeValidation())
            break
          case "national_identity_card_number_li":
            brokenRules.push(...nationalIdentityCardNumberLiValidation())
            break
          case "national_identity_card_number_nl":
            brokenRules.push(...nationalIdentityCardNumberNlValidation())
            break
          case "personal_code_lt":
            brokenRules.push(...personalCodeLtValidation())
            break
          case "personal_code_lv":
            brokenRules.push(...personalCodeLvValidation())
            break
          case "national_identification_number_mt":
            brokenRules.push(...nationalIdentificationNumberMtValidation())
            break
          case "personal_id_no":
            brokenRules.push(...personalIdNoValidation())
            break
          case "national_identification_number_pl":
            brokenRules.push(...nationalIdentificationNumberPlValidation())
            break
          case "tax_number_pl":
            brokenRules.push(...taxNumberPlValidation())
            break
          case "tax_number_pt":
            brokenRules.push(...taxNumberPtValidation())
            break
          case "national_identification_number_ro":
            brokenRules.push(...nationalIdentificationNumberRoValidation())
            break
          case "personal_identity_number":
            brokenRules.push(...personalIdentityNumberValidation())
            break
          case "personal_identification_number_si":
            brokenRules.push(...personalIdentificationNumberSiValidation())
            break
          case "national_passport_number_cy":
            brokenRules.push(...nationalPassportNumberCyValidation())
            break
          case "national_passport_number_li":
            brokenRules.push(...nationalPassportNumberLiValidation())
            break
          case "national_passport_number_nl":
            brokenRules.push(...nationalPassportNumberNlValidation())
            break
          case "national_passport_number_lt":
            brokenRules.push(...nationalPassportNumberLtValidation())
            break
          case "national_passport_number_mt":
            brokenRules.push(...nationalPassportNumberMtValidation())
            break
          case "national_passport_number_pt":
            brokenRules.push(...nationalPassportNumberPtValidation())
            break
          case "national_passport_number_ro":
            brokenRules.push(...nationalPassportNumberRoValidation())
            break
          case "national_passport_number_sk":
            brokenRules.push(...nationalPassportNumberSkValidation())
            break
          case "personal_identity_code_fi":
            brokenRules.push(...personalIdentityCodeFiValidation())
            break
          case "personal_identity_code_is":
            brokenRules.push(...personalIdentityCodeIsValidation())
            break
          case "id_card":
            brokenRules.push(...idCardValidation())
            break
          case "cpf_number":
            brokenRules.push(...cpfNumberValidation())
            break
          case "withdraw-amount":
            brokenRules.push(...addExtraValidation(extraValidation || []))
            break
          default:
        }
      }
      return brokenRules
    case FieldVariation.EmailTextfield:
      return emailValidation(value)
    case FieldVariation.PasswordTextfield:
      return passwordValidation(value, "")
    case FieldVariation.ConfirmPasswordTextfield:
      return confirmPasswordValidation(value, getFieldValue(signupData, field.passwordFieldToCompare))
    case FieldVariation.AddressSearchfield:
      const countryOfResidence = getFieldValue(signupData, COUNTRY_OF_RESIDENCE_FIELD_NAME)
      const { addressLine1, addressLine2, addressCity, addressPostcode } = getAddressFields(field.fieldName)
      const validations = [
        ...addressValidation(getFieldValue(signupData, addressLine1), false, false, messages),
        ...addressValidation(getFieldValue(signupData, addressLine2), true, false, messages),
        ...addressValidation(getFieldValue(signupData, addressCity), false, true, messages),
        ...postCodeValidation(getFieldValue(signupData, addressPostcode), messages, countryOfResidence)
      ]

      return validations
    case FieldVariation.PhoneTextfield:
      return phoneValidation(value, flattenSignupData(signupData).countryCode || "")
    default:
      return required ? requiredValidation() : []
  }
}
