import React, { useState, useEffect, useMemo } from "react"
import { Card, CardGroupType, ExtraFieldProps } from "components"
import { useSignupData } from "hooks"
import { compareValidFieldsAndValues, getDescriptionText, getFieldValue } from "utils"

const OTHER_SELECTED = "other_selected"

const CardGroup = ({
  currentValue,
  handleChange,
  options,
  fieldName,
  signupData,
  allowMultipleSelection
}: CardGroupType & ExtraFieldProps) => {
  const { stepUid: uid, getCurrentSignupData } = useSignupData()

  const finalOptions = useMemo(() => {
    if (uid === "job-role") {
      const employmentIndustry = getFieldValue(getCurrentSignupData(), "employment_industry")

      return options.filter((option) => option.value.includes(employmentIndustry))
    }

    return options.filter((option) =>
      compareValidFieldsAndValues(option.showConditionalField, option.showConditionalValue, getCurrentSignupData())
    )
  }, [options, uid])

  // selectedOptions contains the indexes of the selected options
  const [selectedOptions, setSelectionOptions] = useState<number[]>([])

  useEffect(() => {
    if (selectedOptions.length === 0 && currentValue !== "") {
      const values = currentValue.split(",")
      const selected = finalOptions.reduce((acc: number[], option, index) => {
        if (values.includes(option.value)) {
          acc.push(index)
        }
        return acc
      }, [])

      setSelectionOptions(selected)
    }
  }, [currentValue])

  const unselectOption = (values: number[], optionIndex: number) => {
    const selected = [...values]
    selected.splice(optionIndex, 1)
    return selected
  }

  const findContinueButtonClickIt = () => {
    const buttons = document.querySelectorAll("button") // You can use a more specific selector if needed
    buttons.forEach((button) => {
      if (button.id === "continue") {
        setTimeout(() => {
          button.click()
        }, 200)
      }
    })
  }

  const handleClick = (value: string, otherSelected: boolean, index: number) => {
    if (allowMultipleSelection) {
      // newValues contains the indexes of the selected options
      let newValues = []
      const optionIndex = selectedOptions.indexOf(index)
      if (optionIndex > -1) {
        newValues = unselectOption(selectedOptions, optionIndex)
      } else {
        if (value !== "none") {
          newValues = [...selectedOptions, index]
          // Unselect the "None" option if it's selected
          const noneOptionIndex = finalOptions.findIndex((option) => option.value === "none")
          if (newValues.includes(noneOptionIndex)) {
            newValues = unselectOption(
              newValues,
              selectedOptions.findIndex((option) => option === noneOptionIndex)
            )
          }
        } else {
          newValues = [index]
        }
      }
      setSelectionOptions(newValues)
      handleChange({
        [fieldName]: finalOptions
          .reduce((acc: string[], option, idx) => {
            if (newValues.includes(idx)) {
              acc.push(option.value)
            }
            return acc
          }, [])
          .join(","),
        [OTHER_SELECTED]: otherSelected
      })
    } else {
      setSelectionOptions([index])
      handleChange({
        [fieldName]: value,
        [OTHER_SELECTED]: otherSelected,
        [`${fieldName}_label`]: getDescriptionText(
          finalOptions[index].originalDescription || finalOptions[index].description
        )
      })
      if (value !== "source_question_affiliate_referral") {
        findContinueButtonClickIt()
      }
    }
  }

  const getOtherValue = () => {
    return currentValue || ""
  }

  return (
    <>
      {finalOptions.map(({ value, title, description, textfieldOnClick }, index) => {
        return (
          <Card
            key={value}
            title={title}
            description={description}
            value={textfieldOnClick ? getOtherValue() : value}
            textfieldOnClick={textfieldOnClick}
            selected={selectedOptions.includes(index) || (textfieldOnClick && uid && signupData[uid][OTHER_SELECTED])}
            handleChange={(newValue: string, otherSelected: boolean) => handleClick(newValue, otherSelected, index)}
            fieldName={fieldName}
          />
        )
      })}
    </>
  )
}

export default CardGroup
