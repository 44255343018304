import { useMediaQuery } from "@mui/material"
import { theme } from "styles"

export const useMobileMode = () => {
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"))
  const isLandscape = useMediaQuery("(orientation: landscape) and (max-width: 1024px)")

  return isMobileSize || isLandscape
}

export const useMobileOrTablet = () => {
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"))
  const isLandscape = useMediaQuery("(orientation: landscape) and (max-width: 1024px)")

  const isTablet = useMediaQuery(
    "(min-device-width: 540px) and (max-device-width: 1368px) and (orientation: portrait), (min-device-width: 820px) and (max-device-width: 1368px) and (orientation: landscape)"
  )

  return isMobileSize || isLandscape || isTablet
}

export const useMobileOrTabletMode = () => {
  return useMediaQuery(theme.breakpoints.down("md"))
}

export const useDesktopMode = () => {
  return useMediaQuery(theme.breakpoints.up("md"))
}

export const useDesktopLMode = () => {
  return useMediaQuery(theme.breakpoints.down("lg2"))
}

export const useDesktopXLMode = () => {
  return useMediaQuery(theme.breakpoints.up("xl"))
}

export const useDesktopXLModeDown = () => {
  return useMediaQuery(theme.breakpoints.down("xl"))
}
