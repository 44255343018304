import styled from "@emotion/styled"
import { Box, Container, Toolbar } from "@mui/material"
import { darkGreyColor, theme, whiteColor } from "styles"

export const StyledContainer = styled(Container)`
  display: contents;
`

export const StyledToolbar = styled(Toolbar)`
  margin: 130px;

  @media (max-width: 1200px) {
    margin: auto;
  }

  ${theme.breakpoints.down("lg")} {
    margin: 0 0;
    align-items: center;
  }
`
export const MiddleMenuContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  ${theme.breakpoints.down("md")} {
    display: none;
    justify-content: flex-start;
  }
`

export const bottomBoxStyle = {
  flexGrow: 1,
  display: {
    xs: "none",
    md: "flex"
  },
  justifyContent: {
    lg: "center",
    md: "flex-start"
  }
}

export const buttonStyle = (page: string, currentPage: string) => ({
  color: whiteColor,
  borderRadius: "10px",
  backgroundColor: page.toLowerCase() === currentPage ? "#3B4B5B" : "none",
  minHeight: "37px",
  padding: "10px 30px",
  "&:hover": {
    backgroundColor: darkGreyColor
  },
  transition: "background-color 0.2s"
})
