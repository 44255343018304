import React from "react"

const WalletIcon = () => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame" clipPath="url(#clip0_11301_25668)">
      <g id="Camada 2">
        <g id="Camada 1">
          <path
            id="Vector"
            d="M22.17 7.53V2C22.17 1.46957 21.9593 0.960859 21.5842 0.585786C21.2091 0.210714 20.7004 0 20.17 0L2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 17.05C0 17.5804 0.210714 18.0891 0.585786 18.4642C0.960859 18.8393 1.46957 19.05 2 19.05H20.2C20.7304 19.05 21.2391 18.8393 21.6142 18.4642C21.9893 18.0891 22.2 17.5804 22.2 17.05V13.51C22.5511 13.48 22.8782 13.3197 23.1169 13.0606C23.3557 12.8015 23.4888 12.4623 23.49 12.11V8.94C23.4907 8.58113 23.3546 8.23548 23.1093 7.9735C22.8641 7.71151 22.5281 7.55291 22.17 7.53ZM2 1H20.2C20.4652 1 20.7196 1.10536 20.9071 1.29289C21.0946 1.48043 21.2 1.73478 21.2 2V2.32C20.8973 2.14103 20.5516 2.0477 20.2 2.05H2C1.64836 2.0477 1.3027 2.14103 1 2.32V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1ZM20.2 18H2C1.73478 18 1.48043 17.8946 1.29289 17.7071C1.10536 17.5196 1 17.2652 1 17V4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H20.2C20.4652 3 20.7196 3.10536 20.9071 3.29289C21.0946 3.48043 21.2 3.73478 21.2 4V7.52H16.46C15.6643 7.52 14.9013 7.83607 14.3387 8.39868C13.7761 8.96129 13.46 9.72435 13.46 10.52C13.46 11.3156 13.7761 12.0787 14.3387 12.6413C14.9013 13.2039 15.6643 13.52 16.46 13.52H21.17V17.05C21.1575 17.3014 21.0507 17.5388 20.8709 17.7148C20.6911 17.8909 20.4516 17.9928 20.2 18ZM22.46 12.08C22.46 12.1914 22.4158 12.2982 22.337 12.377C22.2582 12.4557 22.1514 12.5 22.04 12.5H16.46C15.9296 12.5 15.4209 12.2893 15.0458 11.9142C14.6707 11.5391 14.46 11.0304 14.46 10.5C14.46 9.96957 14.6707 9.46086 15.0458 9.08579C15.4209 8.71071 15.9296 8.5 16.46 8.5H22C22.1114 8.5 22.2182 8.54425 22.297 8.62302C22.3758 8.70178 22.42 8.80861 22.42 8.92L22.46 12.08Z"
            fill="black"
          />
          <path
            id="Vector_2"
            d="M16.5 11.2396C16.9529 11.2396 17.32 10.8725 17.32 10.4196C17.32 9.96674 16.9529 9.59961 16.5 9.59961C16.0471 9.59961 15.68 9.96674 15.68 10.4196C15.68 10.8725 16.0471 11.2396 16.5 11.2396Z"
            fill="black"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_11301_25668">
        <rect width="23.46" height="19.02" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default WalletIcon
