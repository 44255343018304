/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-template */
/* eslint-disable one-var */

// @ts-ignore
export const injectGTM = (id) => {
  // eslint-disable-next-line func-names, prettier/prettier
  ;(function (w, d, s, l, i) {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || []
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      // eslint-disable-next-line eqeqeq
      dl = l != "dataLayer" ? "&l=" + l : ""
    // @ts-ignore
    j.async = true
    if (process.env.NEXT_PUBLIC_USE_CUSTOM_GTM_SERVER === 'true') {
      // @ts-ignore
      j.src = `https://${window.location.host}/tags/init.js?id=` + i.replace(/^GTM-/, '') + dl
    } else {
      // @ts-ignore
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
    }
    // @ts-ignore
    f?.parentNode.insertBefore(j, f)
  })(window, document, "script", "dataLayer", id)
}
