import React from "react"
import { Paper } from "@mui/material"
import { RichTextField } from "@prismicio/types"
import { Text, RichText } from "components"
import { Column, Row } from "styles"
import { Container, FieldContainer, paperStyle } from "./DetailsBox.styled"

type Props = {
  title?: RichTextField
  data: {
    title: string
    value: string
  }[][]
}

const DetailsBox = ({ title, data }: Props) => {
  return (
    <Container>
      <RichText field={title} />
      <Paper elevation={0} sx={paperStyle}>
        <Row alignItems="flex-start">
          {data.map((column) => (
            <Column key={JSON.stringify(title)}>
              {column.map((field) => (
                <FieldContainer key={field.title}>
                  <Text text={field.title} opacity />
                  <Text text={field.value} />
                </FieldContainer>
              ))}
            </Column>
          ))}
        </Row>
      </Paper>
    </Container>
  )
}

export default DetailsBox
