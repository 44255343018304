import React, { useState, useEffect } from "react"
import { Link, Paper, Typography } from "@mui/material"
import { Icon, RichText, InfoBoxType, WidgetProps } from "components"
import { SignupDataType, compareValidFieldsAndValues, isRichTextValid } from "utils"
import { TextContainer, Container, paperStyle, forgotPasswordTextStyle } from "./InfoBox.styled"

type Props = Omit<InfoBoxType, "sliceType"> & {
  signupData?: SignupDataType
  onClick?: () => void
  border?: string
  textFontSize?: string
  textFontWeight?: string
  sx?: object
  widgetprops?: WidgetProps
  isWidget?: boolean
}

const InfoBox = (props: Props) => {
  const {
    defaultTitle,
    defaultTitleColor,
    defaultText,
    defaultTextColor,
    defaultBackgroundColor,
    defaultIcon,
    defaultIconAlign,
    defaultHasOpacity,
    options,
    signupData,
    onClick,
    border = "0",
    textFontSize,
    textFontWeight,
    sx = {},
    widgetprops,
    isWidget
  } = props

  const { handleForgetPassword, forgotPasswordText } = widgetprops || {}

  const getIconAlign = (align: "Top" | "Middle" | "Bottom" | undefined) => {
    if (align === "Top") {
      return "flex-start"
    }
    if (align === "Bottom") {
      return "flex-end"
    }
    return "center"
  }

  const [title, setTitle] = useState(defaultTitle)
  const [titleColor, setTitleColor] = useState(defaultTitleColor)
  const [text, setText] = useState(defaultText)
  const [textColor, setTextColor] = useState(defaultTextColor)
  const [backgroundColor, setBackgroundColor] = useState(defaultBackgroundColor)
  const [icon, setIcon] = useState(defaultIcon)
  const [iconAlign, setIconAlign] = useState(getIconAlign(defaultIconAlign))
  const [hasOpacity, setHasOpacity] = useState(defaultHasOpacity)

  useEffect(() => {
    setBackgroundColor(defaultBackgroundColor)
  }, [defaultBackgroundColor])

  useEffect(() => {
    setTitleColor(defaultTitleColor)
  }, [defaultTitleColor])

  useEffect(() => {
    setTextColor(defaultTextColor)
  }, [defaultTextColor])

  useEffect(() => {
    let auxTitle = defaultTitle
    let auxTitleColor = defaultTitleColor
    let auxText = defaultText
    let auxTextColor = defaultTextColor
    let auxBackgroundColor = defaultBackgroundColor
    let auxIcon = defaultIcon
    let auxIconAlign = getIconAlign(defaultIconAlign)
    let auxHasOpacity = defaultHasOpacity
    if (signupData) {
      options?.forEach((option) => {
        if (compareValidFieldsAndValues(option.conditionalField, option.conditionalValue, signupData)) {
          auxTitle = option.title
          auxTitleColor = option.titleColor
          auxText = option.text
          auxTextColor = option.textColor
          auxBackgroundColor = option.backgroundColor
          auxIcon = option.icon
          auxIconAlign = getIconAlign(option.iconAlign)
          auxHasOpacity = option.hasOpacity
        }
      })
    }
    setTitle(auxTitle)
    setTitleColor(auxTitleColor)
    setText(auxText)
    setTextColor(auxTextColor)
    setBackgroundColor(auxBackgroundColor)
    setIcon(auxIcon)
    setIconAlign(auxIconAlign)
    setHasOpacity(auxHasOpacity)
  }, [signupData, options, defaultText])

  if (!isRichTextValid(title) && !isRichTextValid(text)) {
    return null
  }

  return (
    <Paper
      elevation={0}
      sx={{
        ...paperStyle(backgroundColor, onClick, border, isWidget),
        ...sx
      }}
      onClick={onClick}
    >
      <Container
        backgroundColor={backgroundColor}
        iconAlign={iconAlign}
        hasOpacity={hasOpacity}
        titleColor={titleColor}
        textColor={textColor}
        textFontSize={textFontSize}
        textFontWeight={textFontWeight}
        isWidget={isWidget}
      >
        <Icon icon={icon} />
        <TextContainer>
          <RichText field={title} />
          {widgetprops ? (
            <>
              <Typography variant="body1" onClick={handleForgetPassword}>
                <Link style={forgotPasswordTextStyle}>{forgotPasswordText}</Link>
              </Typography>
            </>
          ) : (
            <RichText field={text} />
          )}
        </TextContainer>
      </Container>
    </Paper>
  )
}

export default InfoBox
