import React from "react"
import { ActionButton, Modal, Text } from "components"
import { RowRightAligned } from "styles"
import { buttonStyle, ButtonWrapper, titleStyle } from "./KYCModal.styled"

type Props = {
  show: boolean
  handleClose: () => void
  handleConfirm: () => void
}

const KYCFinancesModal = ({ show, handleClose, handleConfirm }: Props) => {
  return (
    <Modal open={show} width="610" textAlign="left" textFontSize="14px" textLineHeight="22px" maxHeight="95%">
      <Text text="Product Eligibility" variant="h5" sx={titleStyle} />
      <Text text="Financial Spread Bets and CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. As your financial status has changed the products we offer may no longer be suitable for you. " />
      <ButtonWrapper>
        <RowRightAligned gap="20px">
          <ActionButton displayText="Edit" onClick={handleClose} lightBackground sx={buttonStyle} />
          <ActionButton displayText="Confirm" onClick={handleConfirm} sx={buttonStyle} />
        </RowRightAligned>
      </ButtonWrapper>
    </Modal>
  )
}

export default KYCFinancesModal
