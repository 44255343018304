import styled from "@emotion/styled"
import { accentGreenColor, darkBlueColor, theme, whiteColor } from "styles"

export const actionCardStyle = (sx = {}) => ({
  padding: "0",
  cursor: "initial",
  [theme.breakpoints.down("lg")]: { width: "361px", maxWidth: "361px", height: "166px" },
  [theme.breakpoints.down("sm")]: { width: "100%", maxWidth: "361px" },
  ...sx
})

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CardContent = styled.div<{ mobile: boolean }>`
  display: flex;
  gap: ${(props) => (props.mobile ? "10px" : "0")};
  flex: 1;
  justify-content: space-between;
  position: relative;
  padding: 40px 25px 35px 25px;
  ${theme.breakpoints.down("lg")} {
    gap: 13px;
    padding: 24px 29px 25px 29px;
  }
`

export const AccountDataWrapper = styled.div`
  text-align: left;
  ${theme.breakpoints.down("lg")} {
    & > :nth-of-type(1) {
      font-size: 12px;
      font-weight: 500;
      margin-top: 0;
    }
  }
`

export const LeftColumn = styled(Column)`
  align-items: flex-start;
  gap: 10px;
  ${theme.breakpoints.down("lg")} {
    gap: 9px;
  }

  h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
  }

  p {
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    text-align: left;
    margin-top: 3px;
  }
`

export const RightColumn = styled(Column)`
  align-items: flex-end;
  gap: 10px;

  ${theme.breakpoints.down("lg")} {
    gap: 9px;
  }
`

export const bottomButtonStyle = {
  color: accentGreenColor,
  [theme.breakpoints.down("lg")]: {
    fontSize: "13px",
    fontWeight: "400"
  }
}

export const PracticeChip = styled.div`
  background-color: ${darkBlueColor};
  border-radius: 3px;
  color: ${whiteColor};
  font-size: 11px;
  padding: 0 10px;
`
