import React from "react"
import { ActionButton, ActionButtonActionType } from "components"
import { IconType, getSocialLogin } from "utils"
import { buttonStyle, widgetButtonStyle } from "./SocialButtons.styled"
import { useRouter } from "next/router"

type Props = {
  onClick: () => void
  onFail: () => void
  icon: IconType
  name?: string
  type: string
  isSignupWidget?: boolean
}

const GoogleButton = ({ onClick, icon, name, type, isSignupWidget }: Props) => {
  const { asPath } = useRouter()
  const prefix = asPath.includes("/signup") ? "signup" : "login"
  const id = `${prefix}google`
  const {
    url,
    dimensions: { width, height }
  } = icon

  const onButtonClick = async () => {
    onClick()
    await getSocialLogin("google-oauth2")
  }

  return (
    <ActionButton
      id={id}
      displayText={name || type}
      variant="outlined"
      onClick={onButtonClick}
      image={{ src: url, width, height, alt: type }}
      lightBackground
      sx={isSignupWidget ? widgetButtonStyle : buttonStyle}
      actionType={ActionButtonActionType.Social}
      isSignupWidget={isSignupWidget}
    />
  )
}

export default GoogleButton
