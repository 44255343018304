import styled from "@emotion/styled"

export const ListTextWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  line-height: 30px;
`
