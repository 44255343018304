/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import DashboardIcon from "@mui/icons-material/Dashboard"
import LogoutIcon from "@mui/icons-material/Logout"
import PaymentIcon from "@mui/icons-material/Payment"
import PersonIcon from "@mui/icons-material/Person"
import { IconButton, List, ListItemButton, ListItemText } from "@mui/material"
import * as prismicH from "@prismicio/helpers"
import { PrismicLink } from "@prismicio/react"
import { AlternateLanguage, EmptyImageFieldImage, FilledImageFieldImage } from "@prismicio/types"
import Link from "next/link"
import { AnimatedLogo } from "components"
import { getId } from "utils"
import ExpandedMenu from "./ExpandedMenu"
import {
  FirstListItem,
  MenuItemContainer,
  StyledBox,
  StyledButton,
  StyledCloseIcon,
  StyledCollapse,
  StyledDrawer,
  StyledRichText,
  User,
  UserDataListItem
} from "./MobileMenu.styled"

type Props = {
  open: boolean
  handleClose: () => void
  menuItems: any
  expandedMenuItems: any
  right_side_menu: any
  logo: FilledImageFieldImage | EmptyImageFieldImage
  logowithoutbrandname: FilledImageFieldImage | EmptyImageFieldImage
  languages: AlternateLanguage<"header", string>[]
  lang: string
  userData: any
  logout: () => void
  top: number
}

const MobileMenu = ({
  open,
  handleClose,
  menuItems,
  expandedMenuItems,
  right_side_menu,
  userData,
  logout,
  top
}: Props) => {
  const [openedMenus, setOpenedMenus] = useState<number[]>([])
  const [openUserMenu, setOpenUserMenu] = useState(false)

  const hostname = process.env.NEXT_PUBLIC_HOST_NAME

  const handleToggleSection = (id: number) => {
    if (openedMenus.includes(id)) {
      setOpenedMenus((previous) => previous.filter((menuId) => menuId !== id))
    } else {
      setOpenedMenus((previous) => [...previous, id])
    }
  }

  const handleLogout = () => {
    logout()
    setOpenUserMenu(false)
  }

  return (
    <StyledDrawer top={top} anchor="left" open={open} onClose={handleClose} hideBackdrop={true} id="mobile-menu">
      <List>
        <FirstListItem
          secondaryAction={
            <IconButton aria-label="close" onClick={handleClose}>
              <StyledCloseIcon fontSize="medium" />
            </IconButton>
          }
        >
          <AnimatedLogo width={170} height={58} />
        </FirstListItem>
        <UserDataListItem userdata={userData}>
          {!userData &&
            right_side_menu.map((menuItem, key) => (
              <StyledButton
                LinkComponent={PrismicLink}
                href={(menuItem.menu_link as { url: string }).url}
                variant={key ? "contained" : "outlined"}
                key={key}
                color={key ? "primary" : "secondary"}
                className={`${getId(menuItem.menu_label)}-mobile-menu-button`}
              >
                {menuItem.menu_label}
              </StyledButton>
            ))}
          {userData && (
            <>
              <User onClick={() => setOpenUserMenu((prev) => !prev)}>
                {`${userData.firstName} ${userData.lastName.substring(0, 1)}.`}
                {openUserMenu ? <ExpandLess /> : <ExpandMore />}
              </User>
            </>
          )}
        </UserDataListItem>
        {userData && (
          <StyledCollapse in={openUserMenu} timeout="auto" unmountOnExit>
            <ListItemButton LinkComponent={Link} href={`${hostname}account/overview`}>
              <DashboardIcon />
              Account Overview
            </ListItemButton>
            <ListItemButton LinkComponent={Link} href={`${hostname}account/payments`}>
              <PaymentIcon />
              Payments
            </ListItemButton>
            <ListItemButton LinkComponent={Link} href={`${hostname}account/settings`}>
              <PersonIcon />
              Account Settings
            </ListItemButton>
            <ListItemButton onClick={handleLogout}>
              <LogoutIcon />
              Log out
            </ListItemButton>
          </StyledCollapse>
        )}
        <StyledBox>
          {menuItems.map((menuItem, key) => (
            <MenuItemContainer className={`${getId(prismicH.asText(menuItem.menu_label))}-top-level`} key={key}>
              <ListItemButton onClick={() => handleToggleSection(menuItem.group_id)}>
                <ListItemText>
                  <StyledRichText variant="h4" field={menuItem.menu_label} />
                </ListItemText>
                {openedMenus.includes(menuItem.group_id) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <ExpandedMenu
                slices={expandedMenuItems.filter((item) => item.primary.group_id === menuItem.group_id)}
                open={openedMenus.includes(menuItem.group_id)}
              />
            </MenuItemContainer>
          ))}
        </StyledBox>
      </List>
    </StyledDrawer>
  )
}

export default MobileMenu
