import React from "react"
import { ActionButton } from "components"
import { clearAllSignupData, goToPage } from "utils"
import { buttonStyle } from "./DevButton.styled"

const DevButton = () => {
  const handleClearStorage = () => {
    sessionStorage.removeItem("access_token")
    localStorage.removeItem("access_token")
    localStorage.removeItem("preferredLanguage")
    localStorage.removeItem("platform_currencies")
    localStorage.removeItem("user_info_forceupdate")
    clearAllSignupData()
    goToPage("/signup/welcome")
  }

  return <ActionButton displayText="Clear" onClick={handleClearStorage} sx={buttonStyle} />
}

export default DevButton
