import React from "react"
import * as S from "./Notification.styled"

type NotificationProps = {
  title: string
  onClick?: () => void
}

const NotificationIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="42" height="42" rx="21" fill="#D90000" />
      <path
        d="M24.2853 28.8495C21.3321 30.2954 17.6878 29.7649 15.2417 27.2579C13.1094 25.0725 12.4425 21.954 13.2409 19.1849L13.5863 20.5059L14.8653 20.1546L13.8372 16.222L10 17.2757L10.3427 18.5866L12.212 18.0733C10.9316 21.4728 11.6294 25.475 14.3053 28.2175C17.2745 31.2606 21.7568 31.8076 25.2699 29.8586L24.2853 28.8495Z"
        fill="white"
      />
      <path
        d="M17.434 14.1505C20.3873 12.7046 24.0316 13.2351 26.4777 15.7421C28.6392 17.9575 29.2949 21.1317 28.4448 23.9289L27.7202 22.6426L26.5734 23.3212L28.5597 26.8471L32 24.8114L31.3379 23.6361L29.6023 24.6631C30.7505 21.3221 30.0211 17.4545 27.414 14.7825C24.4449 11.7394 19.9626 11.1924 16.4494 13.1414L17.434 14.1505Z"
        fill="white"
      />
    </svg>
  )
}

const Notification = ({ title, onClick }: NotificationProps) => {
  return (
    <S.NotificationBackground>
      <S.NotificationContainer onClick={onClick ? onClick : null}>
        <S.NotificationIcon>
          <NotificationIcon />
        </S.NotificationIcon>
        <S.NotificationTitle>{title}</S.NotificationTitle>
      </S.NotificationContainer>
    </S.NotificationBackground>
  )
}

export default Notification
