import React, { useState } from "react"
import { Elements } from "prismic-reactjs"
import { ActionButton, Checkbox, FieldVariation, Loading, Modal, Text } from "components"
import { ObjectWithFieldsType, getRichText } from "utils"
import { Row } from "styles"
import { useAccountData } from "hooks"
import { ContentContainer, buttonStyle, okButtonStyle, subtitleStyle, titleStyle } from "./SwapAccountModal.styled"

type Props = {
  open: boolean
  handleClose: () => void
}

enum Steps {
  Request,
  AfterRequest
}

const SwapAccountModal = ({ open, handleClose }: Props) => {
  const { swapAccount, swapAccountLoading } = useAccountData()

  const [step, setStep] = useState(Steps.Request)

  const [checkboxes, setCheckboxes] = useState({
    checkbox1: "",
    checkbox2: ""
  })

  const displayHeaders = () => {
    let title = "Swap Free account request"
    let text = "To apply for a swap-free account please read and acknowledge the following terms and conditions."

    if (step === Steps.AfterRequest) {
      title = "Swap Free account requested"
      text =
        "Your request for a swap free account is being processed. Please allow some time for us to respond to your request."
    }

    return (
      <>
        <Text text={title} variant="h2" sx={titleStyle} />
        <Text text={text} sx={subtitleStyle} />
      </>
    )
  }

  const displayRequestStep = () => {
    if (step === Steps.Request) {
      const checkbox1Label = getRichText([
        {
          text: "I confirm I am applying for a Swap Free account on religious grounds",
          type: Elements.paragraph
        }
      ])

      const checkbox2Label = getRichText([
        {
          text: "I confirm I have read and understood the Swap Free Terms and Conditions",
          type: Elements.paragraph
        }
      ])

      const onCheckboxHandleChange = (value: ObjectWithFieldsType) => {
        setCheckboxes({
          ...checkboxes,
          ...value
        })
      }

      const commonProps = {
        value: "checked",
        required: true,
        handleChange: onCheckboxHandleChange,
        controlledFieldsDisplay: [],
        content: []
      }

      const buttonDisabled = () => {
        return !checkboxes.checkbox1 || !checkboxes.checkbox2
      }

      const onSubmitRequest = async () => {
        const res = await swapAccount()

        if (res) {
          setStep(Steps.AfterRequest)
        } else {
          handleClose()
        }
      }

      const onCheckboxTermsAndConditionsClick = () => {
        window.open(
          "https://tnfev2.cdn.prismic.io/tnfev2/ZmMTC5m069VX1jkA_SCIslamic-Swap-Free-AccountTermsandConditions-240401TradeNationFinancialMarketsLtd–.pdf",
          "_blank"
        )
      }

      return (
        <>
          {displayHeaders()}
          <ContentContainer>
            <Checkbox
              label={checkbox1Label}
              currentValue={checkboxes.checkbox1}
              fieldName="checkbox1"
              variation={FieldVariation.Checkbox}
              {...commonProps}
            />
            <Checkbox
              label={checkbox2Label}
              currentValue={checkboxes.checkbox2}
              fieldName="checkbox2"
              variation={FieldVariation.Checkbox}
              onTextClick={onCheckboxTermsAndConditionsClick}
              allTextClickable
              {...commonProps}
            />
            <Row margin="10px 0 0 0">
              <ActionButton displayText="Cancel" onClick={handleClose} lightBackground sx={buttonStyle} />
              <ActionButton
                displayText="Submit"
                onClick={onSubmitRequest}
                sx={buttonStyle}
                disabled={buttonDisabled()}
              />
            </Row>
          </ContentContainer>
        </>
      )
    }

    return null
  }

  const displayAfterRequest = () => {
    if (step === Steps.AfterRequest) {
      return (
        <>
          {displayHeaders()}
          <ActionButton displayText="OK" onClick={handleClose} sx={okButtonStyle} />
        </>
      )
    }
    return null
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      {displayRequestStep()}
      {displayAfterRequest()}
      {swapAccountLoading && <Loading />}
    </Modal>
  )
}

export default SwapAccountModal
