import styled from "@emotion/styled"
import { Box, IconButton, Theme } from "@mui/material"
import RichText from "components/text/RichText" // Can't import from index.ts
import { darkBlueColor, theme, whiteColor } from "styles"

type DisclaimerTextProps = {
  desktop_font_size: "Small - 12px (default)" | "Medium - 14px" | "Large - 16px"
  mobile_font_size: "Small - 9px (default)" | "Medium - 10px" | "Large - 11px"
  font_colour: string | null
  theme: Theme
}

export const DISMISS_LENGTH_MS = 300

export const AccountPageSection = styled.section`
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 100;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  background-color: ${whiteColor};
`

export const AccountPageDisclaimerWrapper = styled.div`
  width: auto;
  padding: 10px;
`

export const DisclaimerSection = styled.section<{
  dismissing: boolean
  top: boolean
  dismissed: boolean
  background: string | null
  isOnAccountPage: boolean
}>`
  ${({ top }) => (top ? "position:sticky; top: -1px;" : "position:fixed; top:auto;")}

  opacity: ${({ dismissing }) => (dismissing ? "0" : "100%")};
  width: 100vw;
  background: ${({ background }) => background ?? whiteColor};
  border-bottom: ${({ isOnAccountPage }) => (isOnAccountPage ? "none" : "1px solid rgba(255,255,255,0.2)")};
  transition: opacity ${DISMISS_LENGTH_MS}ms ease-in-out;
  ${({ dismissed }) => dismissed && "display: none;"}
  padding-left: 7px;
  z-index: 101;
  min-height: 70px;

  ${theme.breakpoints.down("sm")} {
    border-bottom: 3px solid #fff;
  }
`
export const DisclaimerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "dismissable" && prop !== "topAligned" && prop !== "isExpandable"
})<{
  dismissable: boolean
  topAligned: boolean
  isExpandable: boolean
}>`
  ${({ dismissable, topAligned }) => (dismissable && !topAligned ? "padding: 0px 0 24px;" : "padding: 12px 0;")}
  ${theme.breakpoints.down("xl")} {
    margin-right: ${({ topAligned }) => (topAligned ? "0" : "100px")};
  }

  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: ${({ dismissable }) => (dismissable ? "50px" : 0)};

  ${theme.breakpoints.down("md")} {
    padding-right: ${({ isExpandable, dismissable }) =>
      isExpandable && dismissable
        ? "60px"
        : (isExpandable && !dismissable) || (!isExpandable && dismissable)
        ? "32px"
        : 0};
  }
`

export const DisclaimerText = styled(RichText)<DisclaimerTextProps>`
  color: ${({ font_colour }) => font_colour ?? darkBlueColor};
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;

  a {
    color: ${({ font_colour }) => font_colour ?? darkBlueColor};
  }
  ${theme.breakpoints.down("sm")} {
    font-size: 8px;
    line-height: 14px;
  }
`

export const CloseBtn = styled(IconButton)<DisclaimerTextProps>`
  font-size: 13px;
  ${theme.breakpoints.down("md")} {
    font-size: 13px;
    line-height: 14px;
  }
  padding: 10px 0 6px 0;
  color: ${({ font_colour }) => font_colour ?? darkBlueColor};
  svg {
    width: 14px;
    height: 14px;
    padding-bottom: 1px;
  }
`

export const ControlButton = styled(IconButton)`
  ${theme.breakpoints.down("md")} {
    padding: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const ControlButtons = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;

  ${theme.breakpoints.down("md")} {
    top: 8px;
  }

  .expand-button-wrapper {
    display: none;
    ${theme.breakpoints.down("md")} {
      display: block;
    }
  }
`
