import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/react"
import { Drawer } from "@mui/material"
import { ActionButton, FieldVariation, Loading, Modal, OptionType, Text } from "components"
import { useAccountData, useMobileMode, useSignupData } from "hooks"
import {
  compareValidFieldsAndValues,
  COUNTRY_OF_RESIDENCE_FIELD_NAME,
  EMPLOYMENT_INDUSTRY_FIELD_NAME,
  EMPLOYMENT_STATUS_FIELD_NAME,
  JOB_ROLE_FIELD_NAME,
  NATIONALITY_FIELD_NAME,
  ObjectWithFieldsType
} from "utils"
import { RowRightAligned } from "styles"
import { useKycUpdateMutation } from "redux/features/accountapi/accountApi"
import KYCDetails from "./KYCDetails"
import {
  titleStyle,
  FieldsWrapper,
  ButtonWrapper,
  buttonStyle,
  MobileDrawerContainer,
  mobileDrawerStyle
} from "./KYCModal.styled"
import { createClient } from "../../prismicio"

export enum Area {
  CountryOfResidence,
  Address,
  Nationality,
  WorkExperience,
  Finances
}

export const EMPLOYMENT_STATUS_VALUES_HIDE_FIELDS = ["unemployed", "retired", "student"]

const updateDeactivatedAreas = (deactivated: boolean) => {
  return {
    [Area.CountryOfResidence]: deactivated,
    [Area.Address]: deactivated,
    [Area.Nationality]: deactivated,
    [Area.WorkExperience]: deactivated,
    [Area.Finances]: deactivated
  }
}

const getSliceData = (slice) => {
  return slice.items.map((elem) => ({
    value: elem.value,
    label: elem.description[0].text
  }))
}

const getJurisdiction = (cor: string) => {
  if (cor.toLowerCase() === "gb") return "eu"
  if (cor.toLowerCase() === "au") return "au"
  return "sc"
}

const getFinancesSlice = (data) => {
  const brands = ["eu", "au", "sc", "bs", "za"]
  const res = {}
  const slices = data.data.slices.filter((slice: { variation: string }) => slice.variation === FieldVariation.CardGroup)

  brands.forEach((brand) => {
    const slice = slices.find((slice: { primary }) =>
      compareValidFieldsAndValues(slice.primary.showConditionalField, slice.primary.showConditionalValue, {
        "country-of-residence": { brand_id: brand }
      })
    )
    res[brand] = getSliceData(slice)
  })

  return res
}

const getExtraDetailsItems = (extraDetails, cor: string) => {
  return extraDetails.data.slices.find(
    (slice: { primary: { showConditionalValue: string } }) => slice.primary.showConditionalValue === cor
  ).items as OptionType[]
}

const KYCModal = () => {
  const { userMetadata, appMetadata, kycModalData, updateKycModalDisplay } = useAccountData()
  const { updatePromptMessage } = useSignupData()
  const [kycUpdate, { isLoading: kycUpdateLoading }] = useKycUpdateMutation()
  const isMobile = useMobileMode()

  const [fetchedAllData, setFetchedAllData] = useState(false)
  const [currentJurisdiction, setCurrentJurisdiction] = useState("")
  // Work Experience
  const [employmentStatusList, setEmploymentStatusList] = useState([])
  const [employmentIndustryList, setEmploymentIndustryList] = useState([])
  const [jobRoleList, setJobRoleList] = useState([])
  // Finances
  const [annualIncomeList, setAnnualIncomeList] = useState({})
  const [assetWorthList, setAssetWorthList] = useState({})
  // Extra Details
  const [auStatesList, setAuStatesList] = useState<OptionType[]>([])
  const [zaProvinceList, setZaProvinceList] = useState<OptionType[]>([])
  const [itProvinceList, setItProvinceList] = useState<OptionType[]>([])

  useEffect(() => {
    const getPrismicData = async () => {
      const client = createClient()

      // Employment Status
      const employmentStatus = await client.getByUID("signupstep", "employment-status")
      setEmploymentStatusList(getSliceData(employmentStatus.data.slices[0]))

      // Employment Industry
      const employmentIndustry = await client.getByUID("signupstep", "employment-industry")
      setEmploymentIndustryList(getSliceData(employmentIndustry.data.slices[0]))

      // Job Role
      const jobRole = await client.getByUID("signupstep", "job-role")
      setJobRoleList(getSliceData(jobRole.data.slices[0]))

      // Finances
      const brand = appMetadata?.clients[0].brand
      setCurrentJurisdiction(brand)

      // Annual Income
      const annualIncome = await client.getByUID("signupstep", "annual-income")
      const annualIncomeSlice = getFinancesSlice(annualIncome)
      if (annualIncomeSlice) {
        setAnnualIncomeList(annualIncomeSlice)
      }

      // Asset Worth
      const assetWorth = await client.getByUID("signupstep", "asset-worth")
      const assetWorthSlice = getFinancesSlice(assetWorth)
      if (assetWorthSlice) {
        setAssetWorthList(assetWorthSlice)
      }

      // Extra details
      const extraDetails = await client.getByUID("signupstep", "extra-details")
      setAuStatesList(getExtraDetailsItems(extraDetails, "AU"))
      setZaProvinceList(getExtraDetailsItems(extraDetails, "ZA"))
      setItProvinceList(getExtraDetailsItems(extraDetails, "IT"))

      setFetchedAllData(true)
    }

    getPrismicData()
  }, [appMetadata])

  const getProvinceList = (addrCountry: string) => {
    if (addrCountry === "ZA") return zaProvinceList
    if (addrCountry === "IT") return itProvinceList
    return []
  }

  const getFields = () => {
    // Country Of Residence
    const { addr_country: addrCountry = "", addr_country_full: addrCountryFull = "" } = userMetadata?.personal || {}

    // Address
    const {
      addr_street: addrStreet = "",
      addr_line_2: addrLine2 = "",
      addr_city: addrCity = "",
      addr_zip: addrPostCode = "",
      province = ""
    } = userMetadata?.about || {}
    const provinceList = getProvinceList(addrCountry)
    const auState = userMetadata?.about?.au_state?.toString() || ""

    // Nationality
    const { nationality = "", nationality_full: nationalityFull = "" } = userMetadata?.personal || {}

    // Work Experience
    const {
      employment_status: employmentStatus = "",
      employment_industry: employmentIndustry = "",
      job_role: jobRole = ""
    } = userMetadata?.kyc || {}

    return [
      {
        value: addrCountry,
        display: addrCountryFull,
        fieldName: COUNTRY_OF_RESIDENCE_FIELD_NAME,
        options: [],
        label: "Country of Residence",
        required: true,
        area: Area.CountryOfResidence
      },
      { value: addrStreet, fieldName: "addr_line_1", options: [], label: "Line 1", required: true, area: Area.Address },
      { value: addrLine2, fieldName: "addr_line_2", options: [], label: "Line 2", area: Area.Address },
      { value: addrCity, fieldName: "addr_city", options: [], label: "City", required: true, area: Area.Address },
      {
        value: addrPostCode,
        fieldName: "addr_zip",
        options: [],
        label: "Postcode",
        required: true,
        area: Area.Address
      },
      {
        value: auState,
        display: auStatesList.find((elem) => elem.value === auState)?.label || "",
        fieldName: "au_state",
        options: auStatesList,
        label: "State",
        required: true,
        area: Area.Address,
        displayIfCountryOfResidenceIs: ["AU"]
      },
      {
        value: province,
        display: provinceList.find((elem) => elem.value === province)?.label || "",
        fieldName: "province",
        options: provinceList,
        label: "Province",
        required: true,
        area: Area.Address,
        displayIfCountryOfResidenceIs: ["ZA", "IT"]
      },
      {
        value: nationality,
        display: nationalityFull,
        fieldName: NATIONALITY_FIELD_NAME,
        options: [],
        label: "Nationality",
        required: true,
        area: Area.Nationality
      },
      {
        value: employmentStatus,
        display: employmentStatusList.find((elem) => elem.value === employmentStatus)?.label || "",
        fieldName: EMPLOYMENT_STATUS_FIELD_NAME,
        options: employmentStatusList,
        label: "Employment Status",
        required: true,
        area: Area.WorkExperience
      },
      {
        value: employmentIndustry,
        display: employmentIndustryList.find((elem) => elem.value === employmentIndustry)?.label || "",
        fieldName: EMPLOYMENT_INDUSTRY_FIELD_NAME,
        options: employmentIndustryList,
        label: "Industry",
        required: true,
        hide: EMPLOYMENT_STATUS_VALUES_HIDE_FIELDS.includes(employmentStatus),
        area: Area.WorkExperience
      },
      {
        value: jobRole,
        display: jobRoleList.find((elem) => elem.value === jobRole)?.label || "",
        fieldName: JOB_ROLE_FIELD_NAME,
        options: jobRoleList,
        label: "Job Role",
        required: true,
        hide: EMPLOYMENT_STATUS_VALUES_HIDE_FIELDS.includes(employmentStatus),
        area: Area.WorkExperience
      },
      {
        value: "",
        display: "",
        fieldName: "annual_income",
        options: annualIncomeList,
        label: "Annual Income",
        required: true,
        area: Area.Finances
      },
      {
        value: "",
        display: "",
        fieldName: "asset_worth",
        options: assetWorthList,
        label: "Savings and Investments",
        required: true,
        area: Area.Finances
      }
    ]
  }

  const [fields, setFields] = useState([])
  const [initialAddress, setInitialAddress] = useState(null)
  useEffect(() => {
    if (fetchedAllData) {
      setFields(getFields())
    }
  }, [fetchedAllData])

  useEffect(() => {
    const {
      addr_street: addrStreet = "",
      addr_line_2: addrLine2 = "",
      addr_city: addrCity = "",
      addr_zip: addrPostCode = "",
      au_state: auState = "",
      province = ""
    } = userMetadata?.about || {}
    const { addr_country: addrCountry = "" } = userMetadata?.personal || {}

    setInitialAddress({
      addrStreet,
      addrLine2,
      addrCity,
      addrPostCode,
      addrCountry,
      auState,
      province
    })
  }, [userMetadata])

  useEffect(() => {
    if (currentJurisdiction && fields.length > 0) {
      const updatedFields = [...fields]

      const annualIncomeIndex = fields.findIndex((field) => field.fieldName === "annual_income")
      updatedFields[annualIncomeIndex].value = ""
      updatedFields[annualIncomeIndex].display = ""

      const assetWorthIndex = fields.findIndex((field) => field.fieldName === "asset_worth")
      updatedFields[assetWorthIndex].value = ""
      updatedFields[assetWorthIndex].display = ""

      setFields(updatedFields)
    }
  }, [currentJurisdiction])

  const [deactivatedAreas, setDeactivatedAreas] = useState(updateDeactivatedAreas(false))

  useEffect(() => {
    updateKycModalDisplay()
  }, [userMetadata?.about?.kyc_status])

  if (!kycModalData.kycDue || !kycModalData) {
    return null
  }

  const handleClose = () => {
    updateKycModalDisplay({ display: false })
  }

  const toggleArea = (area: Area, open: boolean) => {
    setDeactivatedAreas({
      ...updateDeactivatedAreas(open),
      [area]: false
    })
  }

  const updateFields = (fieldValues: { [fieldName: string]: { value: string; display: string; hide?: boolean } }) => {
    const updatedFields = [...fields]

    Object.keys(fieldValues).forEach((fieldName) => {
      const fieldIndex = updatedFields.findIndex((field) => field.fieldName === fieldName)

      updatedFields[fieldIndex].value = fieldValues[fieldName].value
      updatedFields[fieldIndex].display = fieldValues[fieldName].display
      updatedFields[fieldIndex].hide = fieldValues[fieldName].hide
    })

    // Clear or restore initial address
    if (fieldValues?.country_of_residence) {
      setCurrentJurisdiction(getJurisdiction(fieldValues.country_of_residence.value))

      const addrStreetIndex = updatedFields.findIndex((field) => field.fieldName === "addr_line_1")
      const addrLine2Index = updatedFields.findIndex((field) => field.fieldName === "addr_line_2")
      const addrCityIndex = updatedFields.findIndex((field) => field.fieldName === "addr_city")
      const addrPostCodeIndex = updatedFields.findIndex((field) => field.fieldName === "addr_zip")
      const auStateIndex = updatedFields.findIndex((field) => field.fieldName === "au_state")
      const provinceIndex = updatedFields.findIndex((field) => field.fieldName === "province")

      const provinceList = getProvinceList(fieldValues.country_of_residence.value)
      updatedFields[provinceIndex].options = provinceList

      if (fieldValues.country_of_residence.value !== initialAddress.addrCountry) {
        updatedFields[addrStreetIndex].value = ""
        updatedFields[addrLine2Index].value = ""
        updatedFields[addrCityIndex].value = ""
        updatedFields[addrPostCodeIndex].value = ""
        updatedFields[auStateIndex].value = ""
        updatedFields[auStateIndex].display = ""
        updatedFields[provinceIndex].value = ""
        updatedFields[provinceIndex].display = ""
      } else {
        updatedFields[addrStreetIndex].value = initialAddress.addrStreet
        updatedFields[addrLine2Index].value = initialAddress.addrLine2
        updatedFields[addrCityIndex].value = initialAddress.addrCity
        updatedFields[addrPostCodeIndex].value = initialAddress.addrPostCode
        updatedFields[auStateIndex].value = initialAddress.auState
        updatedFields[auStateIndex].display =
          auStatesList.find((elem) => elem.value === initialAddress.auState)?.label || ""
        updatedFields[provinceIndex].value = initialAddress.province
        updatedFields[provinceIndex].display =
          provinceList.find((elem) => elem.value === initialAddress.province)?.label || ""
      }
    }

    setFields(updatedFields)
  }

  const displayCountryOfResidence = () => {
    return (
      <KYCDetails
        title="Country of Residence"
        fields={fields.filter((field) => field.area === Area.CountryOfResidence)}
        deactivated={deactivatedAreas[Area.CountryOfResidence]}
        toggleArea={toggleArea}
        area={Area.CountryOfResidence}
        updateFields={updateFields}
      />
    )
  }

  const displayAddress = () => {
    return (
      <KYCDetails
        title="Address"
        fields={fields.filter((field) => field.area === Area.Address)}
        deactivated={deactivatedAreas[Area.Address]}
        toggleArea={toggleArea}
        area={Area.Address}
        updateFields={updateFields}
        countryOfResidence={fields.find((field) => (field.fieldName = COUNTRY_OF_RESIDENCE_FIELD_NAME))?.value}
      />
    )
  }

  const displayNationality = () => {
    return (
      <KYCDetails
        title="Nationality"
        fields={fields.filter((field) => field.area === Area.Nationality)}
        deactivated={deactivatedAreas[Area.Nationality]}
        toggleArea={toggleArea}
        area={Area.Nationality}
        updateFields={updateFields}
      />
    )
  }

  const displayWorkExperience = () => {
    return (
      <KYCDetails
        title="Work experience"
        fields={fields.filter((field) => field.area === Area.WorkExperience)}
        deactivated={deactivatedAreas[Area.WorkExperience]}
        toggleArea={toggleArea}
        area={Area.WorkExperience}
        updateFields={updateFields}
      />
    )
  }

  const displayFinances = () => {
    return (
      <KYCDetails
        title="Financial Profile"
        fields={fields
          .filter((field) => field.area === Area.Finances)
          .map((field) => ({
            ...field,
            options: field.options[currentJurisdiction]
          }))}
        deactivated={deactivatedAreas[Area.Finances]}
        toggleArea={toggleArea}
        area={Area.Finances}
        updateFields={updateFields}
      />
    )
  }

  const disabledSaveButton = () => {
    const originalFields = getFields()
    let hasChanges = false
    let invalid = false
    const addrCountry = fields.find((field) => field.fieldName === COUNTRY_OF_RESIDENCE_FIELD_NAME)?.value
    originalFields.forEach((field) => {
      const currentField = fields.find((f) => f.fieldName === field.fieldName)
      if (currentField?.value !== field.value) {
        hasChanges = true
      }
      if (!currentField?.value && currentField?.required && !currentField?.hide) {
        if (!currentField?.displayIfCountryOfResidenceIs) {
          invalid = true
        } else if (currentField.displayIfCountryOfResidenceIs.includes(addrCountry)) {
          invalid = true
        }
      }
    })

    return !hasChanges || invalid
  }

  const onSaveChanges = async () => {
    const addrCountry = fields.find((field) => field.fieldName === COUNTRY_OF_RESIDENCE_FIELD_NAME).value
    const province = fields.find((field) => field.fieldName === "province").value

    const address = {
      addr_line_1: fields.find((field) => field.fieldName === "addr_line_1").value,
      addr_line_2: fields.find((field) => field.fieldName === "addr_line_2").value,
      addr_city: fields.find((field) => field.fieldName === "addr_city").value,
      addr_zip: fields.find((field) => field.fieldName === "addr_zip").value,
      addr_country: addrCountry
    } as ObjectWithFieldsType

    if (addrCountry === "AU") {
      address.au_state = fields.find((field) => field.fieldName === "au_state").value
    } else if (["ZA", "IT"].includes(addrCountry)) {
      address.province = province
    }

    const employmentIndustry = fields.find((field) => field.fieldName === EMPLOYMENT_INDUSTRY_FIELD_NAME)
    const jobRole = fields.find((field) => field.fieldName === JOB_ROLE_FIELD_NAME)

    const body = {
      "employment-status": {
        employment_status: fields.find((field) => field.fieldName === "employment_status").value
      },
      "employment-industry": {
        [EMPLOYMENT_INDUSTRY_FIELD_NAME]: employmentIndustry.hide ? "" : employmentIndustry.value
      },
      "job-role": {
        [JOB_ROLE_FIELD_NAME]: jobRole.hide ? "" : jobRole.value
      },
      "annual-income": {
        annual_income: fields.find((field) => field.fieldName === "annual_income").value
      },
      "asset-worth": {
        asset_worth: fields.find((field) => field.fieldName === "asset_worth").value
      },
      address
    }

    try {
      const res = await kycUpdate({ body }).unwrap()

      if (res === "ok") {
        updatePromptMessage({
          message: "Update successfully sent",
          success: true
        })
      } else {
        updatePromptMessage({
          message: "Please contact support: support@tradenation.com",
          success: false
        })
      }
    } catch (error) {
      Sentry.captureException(error)

      updatePromptMessage({
        message: "Please contact support: support@tradenation.com",
        success: false
      })
    }

    handleClose()
  }

  const displayContent = () => {
    return (
      <>
        <Text text="Confirm your details" variant="h5" sx={titleStyle} />
        <Text text="We are required by regulation to keep your information up to date. Please review and update your details below." />
        <FieldsWrapper>
          {displayCountryOfResidence()}
          {displayAddress()}
          {displayNationality()}
          {displayWorkExperience()}
          {displayFinances()}
        </FieldsWrapper>
        <ButtonWrapper>
          <RowRightAligned gap="20px">
            {kycModalData.dismissible && (
              <ActionButton displayText="Update later" onClick={handleClose} lightBackground sx={buttonStyle} />
            )}
            <ActionButton
              displayText="Save changes"
              onClick={onSaveChanges}
              sx={buttonStyle}
              disabled={disabledSaveButton()}
            />
          </RowRightAligned>
        </ButtonWrapper>
        {kycUpdateLoading && <Loading />}
      </>
    )
  }

  if (isMobile) {
    return (
      <Drawer open={kycModalData.display} anchor="bottom" PaperProps={{ sx: mobileDrawerStyle }}>
        <MobileDrawerContainer>{displayContent()}</MobileDrawerContainer>
      </Drawer>
    )
  }

  return (
    <Modal
      data-testid="kyc-modal"
      open={kycModalData.display}
      width="610"
      textAlign="left"
      textFontSize="14px"
      textLineHeight="22px"
      maxHeight="95%"
    >
      {displayContent()}
    </Modal>
  )
}

export default KYCModal
