import React, { ReactElement } from "react"
import { IconButton as MUIIconButton } from "@mui/material"

type Props = {
  icon: ReactElement
  sx?: object
  onClick: () => void
  id?: string
}

const IconButton = ({ icon, sx = {}, onClick, id }: Props) => {
  return (
    <MUIIconButton id={id || ""} sx={sx} onClick={onClick}>
      {icon}
    </MUIIconButton>
  )
}

export default IconButton
