import styled from "@emotion/styled"
import { lightGreyColor } from "styles"

export const InfoBoxesArea = styled.div<{ hideTopBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  border-top: ${(props) => (props.hideTopBorder ? "0" : `1px solid ${lightGreyColor}`)};
  text-align: left;
`

export const accountTypeTextStyle = {
  fontSize: "14px",
  fontWeight: "700"
}
