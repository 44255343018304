import React, { useRef } from "react"
import Toolbar from "@mui/material/Toolbar"
import { Container } from "@mui/material"
import { AnimatedLogo, LanguageSelector } from "components"
import { DisclaimerProps } from "components/layout/props/disclaimerProps"
import { StyledAppBar, toolbarStyle } from "./Header.styled"
import Disclaimer from "./Disclaimer"

const Header = ({ disclaimer, showDisclaimerOnly = true }: DisclaimerProps & { showDisclaimerOnly?: boolean }) => {
  const appBarRef = useRef(null)

  return (
    <>
      {disclaimer && <Disclaimer {...(disclaimer.data as any)} isOnAccountPage={false} />}
      {!showDisclaimerOnly && (
        <StyledAppBar position="sticky" disclaimerheight={88} ref={appBarRef}>
          <Container maxWidth="xl">
            <Toolbar disableGutters sx={toolbarStyle}>
              <AnimatedLogo width={250} height={40} />
              <LanguageSelector />
            </Toolbar>
          </Container>
        </StyledAppBar>
      )}
    </>
  )
}

export default Header
