import styled from "@emotion/styled"
import { lightGreyColor, lightMainColor, mainColor } from "styles"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 13px;
  margin: 10px 0;
`

export const textfieldInputStyle = {
  textAlign: "center",
  padding: "8px",
  fontSize: "18px",
  fontWeight: 700
}

export const textfieldStyle = (incorrectCode: boolean) => ({
  "& .MuiFilledInput-root": {
    borderRadius: "6px",
    backgroundColor: incorrectCode ? lightMainColor : lightGreyColor,
    height: "41px",
    border: incorrectCode ? `1px solid ${mainColor}` : 0
  },
  ".MuiFilledInput-root:hover": {
    backgroundColor: incorrectCode ? lightMainColor : lightGreyColor
  }
})

export const incorrectCodeStyle = {
  fontSize: "13px",
  color: mainColor
}
