import { lightGreyColor } from "styles"

export const textfieldStyle = {
  borderRadius: "13px",
  borderColor: lightGreyColor
}

export const boxStyle = {
  borderRadius: "13px",
  borderColor: lightGreyColor,
  borderWidth: "1px",
  marginTop: "-12px",
  height: "69px",
  fontSize: "12px",
  fontWeight: "400",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  position: "relative"
}
