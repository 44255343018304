import { FieldVariation } from "components"
import { isDevEnvironment, isFinEnvironment, isStageEnvironment, getCxdRef } from "utils"

export const constructCurrencySlice = (
  currencies: { currencyName: string; currencyLabel: string }[],
  brand: string,
  slices
) => {
  const actualSlice = slices.find(
    (slice) => slice.variation === FieldVariation.CardGroup && slice.primary.showConditionalValue === brand
  )
  const items = currencies.map((each) => {
    const description =
      actualSlice?.items?.find((currentItem) => currentItem.value === each.currencyName)?.description || null
    const currencyName = each.currencyName === "USDT" ? "USD₮" : each.currencyName
    return {
      value: each.currencyName,
      title: [],
      description: description || [
        {
          type: "paragraph",
          text: `${each.currencyLabel} (${currencyName})`,
          spans: [],
          direction: "ltr"
        }
      ],
      textfieldOnClick: false,
      showConditionalField: null,
      showConditionalValue: null,
      score: null
    }
  })

  const fullSliceObject = {
    variation: "cardGroup",
    version: "sktwi1xtmkfgx8626",
    items,
    primary: {
      slice_id: 8,
      title: [],
      description: [],
      required: true,
      fieldName: "currency",
      showConditionalField: "brand_id",
      showConditionalValue: brand,
      defaultValue: null,
      allowMultipleSelection: false,
      hasEditIcon: false,
      minScore: null
    },
    id: "field$ae05be0f-c2e9-4518-addc-c75f62c5b31d",
    slice_type: "field",
    slice_label: null
  }

  return fullSliceObject
}

export const getAllowedCurrencies = (
  brand: string,
  platform: string,
  nationality: string,
  countryCode: string,
  currencies = []
) => {
  const cxdRef = getCxdRef()
  if (isFinEnvironment() || isStageEnvironment() || isDevEnvironment()) {
    // Change when ticket ready for prod
    if (countryCode === "IN" && cxdRef?.startsWith("37067_")) {
      const defaultSdCurrencies = ["USDT"]
      const currencyMapping = {
        USDT: ["USDT"]
      }
      return [...defaultSdCurrencies, ...(currencyMapping[nationality] || []), ...(currencyMapping[countryCode] || [])]
    }
  }

  if (brand === "bs" && platform === "CLOUDTRADE") {
    const defaultBsCurrencies = ["USD", "EUR"]
    const currencyMapping = {
      GB: ["GBP"],
      GS: ["GBP"],
      CH: ["CHF"],
      LI: ["CHF"],
      AU: ["AUD"],
      CX: ["AUD"],
      CC: ["AUD"],
      NR: ["AUD"],
      NF: ["AUD"],
      HM: ["AUD"],
      NO: ["NOK"],
      SE: ["SEK"],
      DK: ["DKK"],
      ZA: ["ZAR"]
    }

    return [...defaultBsCurrencies, ...(currencyMapping[nationality] || []), ...(currencyMapping[countryCode] || [])]
  }

  if (brand === "sc" && platform === "CLOUDTRADE") {
    const defaultScCurrencies = ["USD"]
    const currencyMapping = {
      NO: ["NOK"],
      ZA: ["ZAR"]
    }

    return [...defaultScCurrencies, ...(currencyMapping[nationality] || []), ...(currencyMapping[countryCode] || [])]
  }

  return currencies
}

export const getCurrencies = (
  brand: string,
  platform: string,
  nationality: string,
  countryCode: string,
  sli: { items: { value: string }[] }
) => {
  const currencies = []

  if ((brand === "bs" && platform === "CLOUDTRADE") || (brand === "sc" && platform === "CLOUDTRADE")) {
    const allowedCurrencies = getAllowedCurrencies(brand, platform, nationality, countryCode)
    const items = sli.items.filter((item) => allowedCurrencies.includes(item.value))
    const bsCurrency = { ...sli, items }

    currencies.push(bsCurrency)
  } else {
    currencies.push(sli)
  }

  return currencies
}

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case "USD":
      return "$"
    case "EUR":
      return "€"
    case "GBP":
      return "£"
    case "USDT":
      return "USD₮"
    default:
      return currency
  }
}

export const formatCurrencyValue = (value: number | string, currencySymbol?: string) =>
  `${currencySymbol ? `${currencySymbol} ` : ""}${Number(value).toLocaleString("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`
