import React, { useState, useEffect } from "react"
import dayjs, { Dayjs } from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateField } from "@mui/x-date-pickers/DateField"
import { DatefieldType, ExtraFieldProps } from "components"
import { textfieldStyle } from "./Datefield.styled"
import { SxProps } from "@mui/material"

const getDate = (value: string) => {
  return value ? dayjs(value, "DD/MM/YYYY") : null
}

type Props = DatefieldType &
  ExtraFieldProps & {
    error: boolean
    sx?: SxProps
  }

const Datefield = ({ handleChange, fieldName, placeholder, currentValue, sx = null }: Props) => {
  const [value, setValue] = useState<Dayjs | null>()

  const handleDateChange = (newValue: Dayjs) => {
    if (newValue) {
      const formatted = newValue.format("DD/MM/YYYY")
      handleChange({ [fieldName]: formatted })
    }
  }

  useEffect(() => {
    setValue(getDate(currentValue))
  }, [currentValue])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateField
        label={placeholder}
        name={fieldName}
        value={value}
        onChange={handleDateChange}
        format="DD/MM/YYYY"
        sx={sx || textfieldStyle}
      />
    </LocalizationProvider>
  )
}

export default Datefield
