import React from "react"
import { Text } from "components"
import { Container, textStyle } from "./RiskWarning.styled"

const RiskWarning = () => {
  return (
    <Container>
      <Text
        text="Financial Spread Bets and CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 66.2% of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money."
        sx={textStyle}
      />
    </Container>
  )
}

export default RiskWarning
