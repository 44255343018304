import React, { useCallback, useState } from "react"
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Button } from "@mui/material"
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom"
import { Icon } from "components"
import ConnectIcon from "components/tradingview/ConnectIcon.svg"
import { FeatureFlag, goToPage } from "utils"
import { useAccountData } from "hooks"
import {
  growStyle,
  menuButtonStyle,
  menuItemStyle,
  menuListStyle,
  paperStyle,
  menuItemIconStyle,
  popperStyle
} from "./AccountCardMenu.styled"

const connectTradingViewIcon = {
  url: ConnectIcon,
  alt: "Connect",
  dimensions: {
    height: 15,
    width: 8
  }
}

type Props = {
  isTNAccount?: boolean
  isLiveAccount?: boolean
  accountId?: number
}

const AccountCardMenu = ({ isTNAccount, isLiveAccount, accountId }: Props) => {
  const { connectTradingView, shouldDisplayTradingViewButton } = useAccountData()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const goToPayments = useCallback(() => {
    goToPage(`/account/payments${accountId && `?id=${accountId}`}`)
  }, [accountId])

  return (
    <>
      <Button variant="text" onClick={handleClick} sx={menuButtonStyle}>
        ...
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="right-start"
        transition
        disablePortal
        sx={popperStyle}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={growStyle}>
            <Paper sx={paperStyle}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  sx={menuListStyle}
                >
                  {isLiveAccount && (
                    <MenuItem onClick={goToPayments} sx={menuItemStyle}>
                      <VerticalAlignBottomIcon sx={menuItemIconStyle} />
                      Payments
                    </MenuItem>
                  )}
                  <>
                    {shouldDisplayTradingViewButton && isTNAccount && (
                      <MenuItem onClick={connectTradingView} sx={menuItemStyle} id="connect-to-tv-menu-item">
                        <Icon icon={connectTradingViewIcon} sx={menuItemIconStyle} />
                        Connect to TradingView
                      </MenuItem>
                    )}
                  </>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default AccountCardMenu
