import { useEffect } from "react"
import { Router } from "next/router"

const isProd = process.env.NODE_ENV === "production"

export const useInterceptNextDataHref = ({ router, namespace }: { router: Router; namespace: string }) => {
  useEffect(() => {
    if (router.pageLoader?.getDataHref && isProd) {
      const originalGetDataHref = router.pageLoader.getDataHref
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      router.pageLoader.getDataHref = function (...args: any[]) {
        const r = originalGetDataHref.call(router.pageLoader, ...args)
        return r && r.startsWith("/_next/data") ? `/${namespace}${r}` : r
      }
    }
  }, [router, namespace])
}
