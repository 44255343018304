/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Sentry from "@sentry/react"
// import {
//   LanguagesForTranslationsType,
//   MaintenanceModeType,
//   RiskWarningLayoutType
// } from "components"
import {
  createTN2Client
  // jurisdictionToLocaleMapping
} from "utils"

export const shouldShowDisclaimerOnly = (category: string, subCategory: string) => {
  if ((category === "signup" && subCategory === "welcome") || category === "login") {
    return true
  }

  return false
}

// export const fetchComponentsFromAltPrismic = async (jurisdiction: string) => {
//   const client = createTN2Client()
//   const locale = jurisdictionToLocaleMapping[jurisdiction]

//   try {
//     // Need to convert first to "unknown" to be able to convert
//     // to the correct type
//     const disclaimer = (await client.getSingle("disclaimer", {
//       lang: locale
//     })) as RiskWarningLayoutType

//     // @ts-ignore
//     const maintenance = (await client.getSingle("maintenance_mode", {
//       lang: "en-gb"
//     })) as MaintenanceModeType

//     // @ts-ignore
//     const languagesForTranslations = (await client.getSingle("languages", {
//       lang: locale
//     })) as LanguagesForTranslationsType

//     let arr = []

//     if (
//       languagesForTranslations &&
//       languagesForTranslations.data &&
//       languagesForTranslations.data.languages &&
//       languagesForTranslations.data.languages[0]
//     ) {
//       arr = Object.keys(languagesForTranslations.data.languages[0]).filter(
//         (key) => languagesForTranslations.data.languages[0][key] === true
//       )
//     } else {
//       arr = ["gbr"]
//     }
//     return {
//       disclaimer: disclaimer,
//       signup_maintenanceMode: maintenance.data.signup,
//       account_maintenanceMode: maintenance.data.account,
//       available_languages: arr
//     }
//   } catch (error) {
//     Sentry.captureException(error)
//     console.error("Failed to fetch TN2 Components:", error)
//   }
// }

export const fetchComponentsFromAltPrismic = async (jurisdiction: string) => {
  const client = createTN2Client()
  // const locale = jurisdictionToLocaleMapping[jurisdiction]
  // console.log("jurisdiction: ", jurisdiction)
  // console.log("locale: ", locale)
  try {
    // Need to convert first to "unknown" to be able to convert
    // to the correct type
    // @ts-ignore
    const maint = (await client.getSingle("maintenance_mode", {
      lang: "en-gb"
    })) as any

    return {
      signup_maintenanceMode: maint.data.signup,
      account_maintenanceMode: maint.data.account
    }
  } catch (error) {
    console.error("Failed to fetch TN2 Components:", error)
    Sentry.captureException(error)
  }
}
