import React, { createContext, useEffect, useState, useContext, useRef } from "react"
import { useRouter } from "next/router"
import { Language } from "components"
import {
  getUrlParam,
  shouldAllowTranslation,
  removeUrlParam,
  updateLastVisitedStep,
  setLocalStorage,
  LOCAL_STORAGE_LAST_PAGE
} from "utils"
import { Context } from "pages/_app"

const ENGLISH = {
  language_code: "en",
  language_display_name: "English"
}

type LanguageContextProps = {
  language: Language
  setLanguage: (lang: Language) => void
  supportedLanguages: Language[]
  flag?: any
}

export const LanguageContext = createContext<LanguageContextProps>({
  language: ENGLISH,
  setLanguage: () => {},
  supportedLanguages: [ENGLISH]
})

type LanguageContextProviderProps = {
  children: React.ReactNode
  languages: Language[]
}

const LanguageContextProvider: React.FC<LanguageContextProviderProps> = ({ children, languages }) => {
  const isInitialRender = useRef(true)
  const { jurisdiction, signupData, stepUid, updateSignupData } = useContext(Context)
  const [languagesForTranslations, setLanguagesForTranslations] = useState<Language[]>(languages || [])
  const [currentLanguage, setCurrentLanguage] = useState<Language | null>(ENGLISH)

  const handleLanguageChange = (language: Language) => {
    if (shouldAllowTranslation(jurisdiction)) {
      setCurrentLanguage(language)
    }
  }

  const { asPath } = useRouter()

  const saveCurrentLanguageInDbAndSignupData = async () => {
    updateSignupData({
      ...signupData,
      preferred_language: { language_code: currentLanguage.language_code }
    })
    await updateLastVisitedStep(signupData, stepUid, currentLanguage, true)
  }

  useEffect(() => {
    // Prevent sending a request on render and on welcome page
    if (isInitialRender.current || stepUid === "welcome") {
      isInitialRender.current = false
    } else {
      saveCurrentLanguageInDbAndSignupData()
    }
  }, [currentLanguage])

  useEffect(() => {
    setLanguagesForTranslations(languages)
  }, [languages])

  useEffect(() => {
    if (jurisdiction && !shouldAllowTranslation(jurisdiction)) {
      setCurrentLanguage(ENGLISH)
      return
    }

    let lang = signupData?.preferred_language?.language_code
    if (!lang) {
      lang = getUrlParam("lang")
      if (lang) {
        removeUrlParam("lang")
      }
    }
    const langObj = languagesForTranslations && languagesForTranslations.find((l) => l.language_code === lang)
    if (langObj) {
      setCurrentLanguage(langObj)
    } else {
      setCurrentLanguage(ENGLISH)
    }
  }, [asPath, signupData, jurisdiction])

  if (currentLanguage) {
    return (
      <LanguageContext.Provider
        value={{
          language: currentLanguage,
          setLanguage: handleLanguageChange,
          supportedLanguages: languagesForTranslations
        }}
      >
        {children}
      </LanguageContext.Provider>
    )
  }

  return <></>
}

export default LanguageContextProvider
