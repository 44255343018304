import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/react"
import { Elements } from "prismic-reactjs"
import {
  Modal,
  Toggle,
  ActionButton,
  Text,
  TextfieldInputBoxes,
  Loading,
  AppTwoFactorAuthentication,
  ActionButtonActionType
} from "components"
import { Row, RowRightAligned } from "styles"
import { useModal } from "hooks"
import {
  LOCAL_STORAGE_2FA,
  OK,
  clear2faCode,
  clearLocalStorage,
  getLocalStorage,
  getRichText,
  removeUrlParam
} from "utils"
import { useVerify2faMutation } from "redux/features/accountapi/accountApi"
import { headerStyle, multiTextStyle, textStyle, actionButtonStyle } from "./TwoFactorAuthentication.styled"

enum SetupSteps {
  SettingUp,
  Success,
  Fail
}

const TwoFactorAuthentication = () => {
  const [active, setActive] = useState(!!getLocalStorage(LOCAL_STORAGE_2FA))
  const [showModal, setShowModal] = useState(false)
  const [setupStep, setSetupStep] = useState(SetupSteps.SettingUp)
  const [appSelected, setAppSelected] = useState(false)
  const [textCodeSelected, setTextCodeSelected] = useState(false)
  const [code, setCode] = useState(clear2faCode())

  const { openModal: openActivationModal, displayModal: displayActivationModal } = useModal()

  const on2faChange = (checked: boolean) => {
    setActive(checked)
    if (checked) {
      setShowModal(true)
    } else {
      clearLocalStorage(LOCAL_STORAGE_2FA)
    }
  }

  const onModalClose = () => {
    setShowModal(false)
    if (setupStep !== SetupSteps.Success) {
      setActive(false)
    }
  }
  useEffect(() => {
    if (showModal) {
      setAppSelected(false)
      setTextCodeSelected(false)
      setCode(clear2faCode())
    }
  }, [showModal])

  const onAppSelect = () => {
    setAppSelected(true)
  }

  const onTextCodeSelect = () => {
    setTextCodeSelected(true)
  }

  const [verify2fa, { isLoading: verify2faLoading }] = useVerify2faMutation()

  const onCodeSubmit = async () => {
    try {
      const body = { code: code.join("") }
      const result = await verify2fa({ body }).unwrap()
      setSetupStep(result.status === OK ? SetupSteps.Success : SetupSteps.Fail)
    } catch (error) {
      setSetupStep(SetupSteps.Fail)
      Sentry.captureException(error)
    }
  }

  const onCodeVerify = (isValid: boolean) => {
    setSetupStep(isValid ? SetupSteps.Success : SetupSteps.Fail)
  }

  useEffect(() => {
    if ([SetupSteps.Success, SetupSteps.Fail].includes(setupStep)) {
      let param = "textSuccess"
      let modalFeedbackText = getRichText([
        {
          text: "Setup Complete - Next Time You Login You Will Be Asked For A Text Code",
          type: Elements.paragraph
        }
      ])
      if (setupStep === SetupSteps.Fail) {
        param = "fail"
        modalFeedbackText = getRichText([
          {
            text: "Unfortunately we have failed to authenticate. Try again or contact Support@tn.com",
            type: Elements.paragraph
          }
        ])
      }

      window.history.replaceState(null, "", `?2fa=${param}`)
      onModalClose()

      const handleClose = () => {
        removeUrlParam("m")
      }
      openActivationModal({
        text: modalFeedbackText,
        handleClose
      })
    }
  }, [setupStep])

  const displayModalContent = () => {
    if (appSelected) {
      return <AppTwoFactorAuthentication onCodeVerify={onCodeVerify} />
    }

    if (textCodeSelected) {
      return (
        <>
          <Text text="We have texted your registered mobile number with your code" variant="h3" sx={headerStyle} />
          <TextfieldInputBoxes value={code} handleChange={setCode} />
          <Text
            multiText={[
              { text: "Didn't receive a code?", sx: multiTextStyle },
              { text: "Tap here", link: "hello" }
            ]}
            sx={textStyle}
          />
          <RowRightAligned>
            <ActionButton
              displayText="Submit"
              onClick={onCodeSubmit}
              disabled={!code.every((elem) => elem)}
              sx={actionButtonStyle}
              actionType={ActionButtonActionType.Continue}
            />
          </RowRightAligned>
        </>
      )
    }

    return (
      <>
        <Text text="Please select one of the options" variant="h3" sx={headerStyle} />
        <Row>
          <ActionButton displayText="App" onClick={onAppSelect} />
          <ActionButton displayText="Text Code" onClick={onTextCodeSelect} />
        </Row>
      </>
    )
  }

  return (
    <>
      <Toggle checked={active} labelOff="Deactivated" labelOn="Activated" onChange={on2faChange} />
      <Modal open={showModal} handleClose={onModalClose}>
        {displayModalContent()}
      </Modal>
      {displayActivationModal()}
      {verify2faLoading && <Loading />}
    </>
  )
}

export default TwoFactorAuthentication
