import React, { useState, useEffect, useContext } from "react"
import Image from "next/image"
import { IconButton, Card } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { ActionButton, Text } from "components"
import { useAccountData, useMobileOrTabletMode } from "hooks"
import { LOCAL_STORAGE_NOT_SHOW_TRADING_VIEW_PROMPT, getLocalStorage, setLocalStorage } from "utils"
import TradingViewLogoWhite from "./TradingViewLogoWhite.svg"
import { Container, ContentWrapper, connectButtonStyle, closeButtonStyle } from "./TradingViewPrompt.styled"
import { Context } from "pages/_app"
const TradingViewPrompt = () => {
  const { connectTradingView, shouldDisplayTradingViewButton } = useAccountData()
  const isMobileOrTablet = useMobileOrTabletMode()
  const { featureToggles } = useContext(Context)
  const [open, setOpen] = useState(false)

  const showPrompt = !getLocalStorage(LOCAL_STORAGE_NOT_SHOW_TRADING_VIEW_PROMPT) && shouldDisplayTradingViewButton

  useEffect(() => {
    if (showPrompt) {
      setOpen(true)
    }
  }, [showPrompt])

  const onClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== "clickaway") {
      setLocalStorage(LOCAL_STORAGE_NOT_SHOW_TRADING_VIEW_PROMPT, "true")
      setOpen(false)
    }
  }

  if (!showPrompt) {
    return null
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "105%", marginBottom: "40px" }}
    >
      <Card
        sx={{
          background: "#050F19",
          borderRadius: "9px",
          minHeight: "64px",
          maxWidth: "90%",
          color: "white",
          fontSize: "14px",
          fontWeight: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Container mobile={isMobileOrTablet}>
          <ContentWrapper mobile={isMobileOrTablet}>
            <Image src={TradingViewLogoWhite} alt="Trading View" height={23} width={119} />
            <Text
              text="Connect your Trade Nation account with TradingView and join the largest financial community on the web."
              sx={{
                textAlign: "start",
                height: "auto"
              }}
            />
          </ContentWrapper>
          <ContentWrapper mobile={isMobileOrTablet}>
            <ActionButton
              id="connect-to-tv-banner-cta"
              displayText="Connect to TradingView"
              onClick={connectTradingView}
              sx={connectButtonStyle}
            />
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="small" sx={closeButtonStyle} />
            </IconButton>
          </ContentWrapper>
        </Container>
      </Card>
    </div>
  )
}

export default TradingViewPrompt
