import styled from "@emotion/styled"

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  cursor: initial;
  border-radius: 14px;
  transition: opacity 0.2s;
  &:hover {
    cursor: pointer;
  }
`

export const launchButtonStyle = {
  minWidth: "175px"
}
