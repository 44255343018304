import { useEffect } from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCustomEvent = (name: string, dependencies: any[]) => {
  const event = new CustomEvent(name)

  useEffect(() => {
    document.dispatchEvent(event)
  }, dependencies)
}
