import { TableHeaderType } from "components"

export const muiTableStyle = {
  minWidth: 650
}

export const muiTableHeadCell = (header: TableHeaderType) => ({
  width: `${header.width}px`
})

export const muiTableBodyRow = {
  "&:last-child td, &:last-child th": {
    border: 0
  }
}
