import * as React from "react"
import { CircularProgress } from "@mui/material"
import { Container } from "./Loading.styled"

const Loading = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  )
}

export default Loading
