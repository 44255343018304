/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react"

export const useNavbarVisibility = () => {
  const [show, setShow] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false)
      } else {
        // if scroll up show the navbar
        setShow(true)
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY)
    }
  }

  // debounce function
  const debounce = (func: any, wait: number) => {
    let timeout: any
    return (...args: any) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(context, args), wait)
    }
  }

  // debounce the controlNavbar function
  const debouncedControlNavbar = debounce(controlNavbar, 50)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", debouncedControlNavbar)

      // cleanup function
      return () => {
        window.removeEventListener("scroll", debouncedControlNavbar)
      }
    }
    return () => {}
  }, [lastScrollY])

  return show
}
