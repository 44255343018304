import React, { ChangeEvent } from "react"
import { RadioGroup as MUIRadioGroup, FormControlLabel, Radio } from "@mui/material"
import { RichText, ExtraFieldProps, RadioGroupType } from "components"

const RadioGroup = ({ currentValue, handleChange, options, fieldName }: RadioGroupType & ExtraFieldProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange({ [fieldName]: event.target.value })
  }

  return (
    <MUIRadioGroup value={currentValue} onChange={onChange}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={<RichText field={option.label} />}
        />
      ))}
    </MUIRadioGroup>
  )
}

export default RadioGroup
