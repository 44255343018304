import styled from "@emotion/styled"
import { accentGreenColorLight, darkBlueColor, primaryGreyColor } from "styles"

export const BottomLeft = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 10px;
  opacity: 0.6;
`
export const muiCardManageAccountsStyle = (isSelected: boolean, isMobile: boolean, sx = {}) => ({
  width: "412px",
  maxWidth: isMobile ? "300px" : "100%",
  minHeight: "153px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  cursor: "pointer",
  backgroundColor: primaryGreyColor,
  color: darkBlueColor,
  transition: "background-color 0.2s, color 0.2s, border 0.2s",
  "&:hover": {
    border: `1px solid ${accentGreenColorLight}`
  },
  border: isSelected ? `1px solid ${accentGreenColorLight}` : "1px solid transparent",
  boxShadow: "none",
  position: "relative",
  padding: "35px 25px 35px 25px",
  borderRadius: "14px",
  overflow: "visible",
  ...sx
})
