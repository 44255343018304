import styled from "@emotion/styled"
import { blueColor, scrollStyle, whiteColor } from "styles"

export const Container = styled.div`
  padding: 0;
  background-color: ${whiteColor};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

export const PopoverContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 265px;
  ${scrollStyle}
`

export const TopHandle = styled.div`
  position: fixed;
  width: 100%;
  background-color: ${whiteColor};
  height: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    width: 38px;
    height: 4px;
    border-radius: 58px;
    background-color: #d9d9d9;
  }
`

export const Content = styled.div<{
  marginTop: string
  minWidth: string
  padding: string
}>`
  padding: ${(props) => props.padding};
  margin-top: ${(props) => props.marginTop};
  min-width: ${(props) => props.minWidth};
`

export const swipeableDrawerStyle = {
  zIndex: 1600
}

export const paperStyle = (fixedHeight: boolean) => ({
  maxHeight: "calc(70%)",
  minHeight: fixedHeight ? "calc(70%)" : "initial",
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px"
})

export const TextfieldWrapper = styled.div<{ mobile?: boolean }>`
  position: ${(props) => (props.mobile ? "fixed" : "initial")};
  padding: ${(props) => (props.mobile ? "0 20px 20px 20px" : "20px")};
  margin-top: ${(props) => (props.mobile ? "40px" : "0")};
  width: 100%;
  background-color: ${whiteColor};
`

export const popoverStyle = {
  height: "300px",
  minWidth: "510px",
  marginTop: "10px",
  paper: {
    borderRadius: "13px"
  }
}

export const popoverPaperStyle = {
  borderRadius: "13px",
  border: `1px solid ${blueColor}`,
  boxShadow: "none",
  overflow: "hidden"
}
