import React, { ReactElement } from "react"
import {
  TableCellProps,
  FilledInputProps,
  InputBaseComponentProps,
  OutlinedInputProps,
  TextFieldVariants
} from "@mui/material"
import { ImageField, RichTextField } from "@prismicio/types"
import { NextPage } from "next"
import { IconType, ObjectWithFieldsType, SignupDataType } from "utils"

export enum FieldVariation {
  Default = "default",
  Dropdown = "dropdown",
  Textfield = "textfield",
  EmailTextfield = "emailTextfield",
  PasswordTextfield = "passwordTextfield",
  PhoneTextfield = "phoneTextfield",
  ConfirmPasswordTextfield = "confirmPasswordTextfield",
  Checkbox = "checkbox",
  RadioGroup = "radioGroup",
  CardGroup = "cardGroup",
  Datefield = "datefield",
  Searchfield = "searchfield",
  AddressSearchfield = "addressSearchfield"
}

export enum SliceNameType {
  Field = "field",
  InfoBox = "info_box",
  ScoreField = "score_field",
  HiddenField = "hidden_field",
  ActionButton = "action_button",
  SocialButton = "social_button",
  Modal = "modal",
  PlatformInfo = "platform_info"
}

export type OptionType = {
  value: string
  label: string
  prefix?: ReactElement
}

export type TextAlignType = "left" | "center" | "right"

// Dropdown
export type PrismicDropdownType = {
  variation: FieldVariation.Dropdown
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    placeholder?: string
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    hasEditIcon?: boolean
  }
  items: OptionType[]
  slice_type: string
}

export type DropdownType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  placeholder?: string
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  options: OptionType[]
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  variation: FieldVariation.Dropdown
  sliceType?: SliceNameType.Field
}

// Textfield
export type PrismicTextfieldType = {
  variation: FieldVariation.Textfield
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    placeholder?: string
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    hasEditIcon?: boolean
    textAlign?: TextAlignType
    autoFocus?: boolean
  }
  slice_type: string
}

export type TextfieldType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  placeholder?: string
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  textAlign?: TextAlignType
  autoFocus?: boolean
  variation: FieldVariation.Textfield
  type?: string
  sliceType?: SliceNameType.Field
  disabled?: boolean
}

export type TextfieldProps = Omit<TextfieldType, "variation"> & {
  variant?: TextFieldVariants
  inputProps?: InputBaseComponentProps
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps>
  autoFocus?: boolean
  widgetprops?: WidgetProps
}

// Email Textfield
export type PrismicEmailTextfieldType = {
  variation: FieldVariation.EmailTextfield
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    placeholder?: string
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    hasEditIcon?: boolean
    checkValidation?: boolean
  }
  slice_type: string
}

export type EmailTextfieldType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  placeholder?: string
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  checkValidation?: boolean
  variation: FieldVariation.EmailTextfield
  sliceType?: SliceNameType.Field
}

// Password Textfield
export type PrismicPasswordTextfieldType = {
  variation: FieldVariation.PasswordTextfield
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    placeholder?: string
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    displayConfirmPassword: boolean
    showRules?: boolean
    defaultValue?: string
    hasEditIcon?: boolean
    minCharacters?: number
    maxCharacters?: number
    lowerAndUpperCase?: boolean
    numberAndSymbol?: boolean
    symbols?: string
    noSpecialCharacters?: boolean
  }
  slice_type: string
}

export type PasswordTextfieldType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  placeholder?: string
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  displayConfirmPassword: boolean
  showRules?: boolean
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  minCharacters?: number
  maxCharacters?: number
  lowerAndUpperCase?: boolean
  numberAndSymbol?: boolean
  symbols?: string
  noSpecialCharacters?: boolean
  variation: FieldVariation.PasswordTextfield
  sliceType?: SliceNameType.Field
}

export type ConfirmPasswordTextfieldType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  placeholder?: string
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  passwordFieldToCompare: string
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  variation: FieldVariation.ConfirmPasswordTextfield
  sliceType?: SliceNameType.Field
}

export type AllPasswordTextfieldTypes = Omit<TextfieldType, "variation"> & {
  displayConfirmPassword?: boolean
  showRules?: boolean
  passwordFieldToCompare?: string
}

// Phone Textfield
export type PrismicPhoneTextfieldType = {
  variation: FieldVariation.PhoneTextfield
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    placeholder?: string
    countrySelectorPlaceholder?: string
    countrySearchPlaceholder?: string
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    hasEditIcon?: boolean
  }
  slice_type: string
}

export type PhoneTextfieldType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  placeholder?: string
  countrySelectorPlaceholder?: string
  countrySearchPlaceholder?: string
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  variation: FieldVariation.PhoneTextfield
  sliceType?: SliceNameType.Field
}

// Checkbox
export type PrismicCheckboxType = {
  variation: FieldVariation.Checkbox
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    fieldName: string
    value: string
    label: RichTextField
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    hasEditIcon?: boolean
  }
  items: {
    text: string
    clickValue: string
  }[]
  slice_type: string
}

export type CheckboxType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  fieldName: string
  value: string
  label: RichTextField
  showConditionalField?: string
  showConditionalValue?: string
  content: {
    text: string
    clickValue: string
  }[]
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  variation: FieldVariation.Checkbox
  sliceType?: SliceNameType.Field
}

// Radio Group
export type PrismicRadioGroupType = {
  variation: FieldVariation.RadioGroup
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    hasEditIcon?: boolean
  }
  items: {
    value: string
    label: RichTextField
  }[]
  slice_type: string
}

export type RadioGroupType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  options: {
    value: string
    label: RichTextField
  }[]
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  variation: FieldVariation.RadioGroup
  sliceType?: SliceNameType.Field
}

// Card Group
export type CardGroupItem = {
  value: string
  title: RichTextField
  description: RichTextField
  textfieldOnClick: boolean
  showConditionalField?: string
  showConditionalValue?: string
  originalDescription?: RichTextField
}
export type PrismicCardGroupType = {
  variation: FieldVariation.CardGroup
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    allowMultipleSelection?: boolean
    hasEditIcon?: boolean
  }
  items: CardGroupItem[]
  slice_type: string
}

export type CardGroupType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  allowMultipleSelection?: boolean
  hasEditIcon?: boolean
  options: CardGroupOptionType[]
  controlledFieldsDisplay: string[]
  defaultValue?: string
  variation: FieldVariation.CardGroup
  sliceType?: SliceNameType.Field
}

export type CardGroupOptionType = {
  value: string
  title: RichTextField
  description: RichTextField
  textfieldOnClick: boolean
  showConditionalField?: string
  showConditionalValue?: string
  originalDescription?: RichTextField
}

// Datefield
export type PrismicDatefieldType = {
  variation: FieldVariation.Datefield
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    placeholder?: string
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    hasEditIcon?: boolean
  }
  slice_type: string
}

export type DatefieldType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  fieldName: string
  placeholder?: string
  showConditionalField?: string
  showConditionalValue?: string
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  variation: FieldVariation.Datefield
  sliceType?: SliceNameType.Field
  helperText?: string
}

// Searchfield
export type PrismicSearchfieldType = {
  variation: FieldVariation.Searchfield
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    placeholder?: string
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    api?: string
    hasEditIcon?: boolean
  }
  items: OptionType[]
  slice_type: string
}

export type SearchfieldType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  placeholder?: string
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  api?: string
  options: OptionType[]
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  variation: FieldVariation.Searchfield
  sliceType?: SliceNameType.Field
  countrySearchPlaceholder?: string
}

// Address Searchfield
export type PrismicAddressSearchfieldType = {
  variation: FieldVariation.AddressSearchfield
  primary: {
    title?: RichTextField
    description?: RichTextField
    required: boolean
    placeholder?: string
    fieldName: string
    showConditionalField?: string
    showConditionalValue?: string
    defaultValue?: string
    hasEditIcon?: boolean
    manualEntryText: string
    searchAddressText: string
    searchForAnotherText: string
    cantFindText: string
    line1Placeholder: string
    line2Placeholder: string
    cityPlaceholder: string
    postcodePlaceholder: string
  }
  slice_type: string
}

export type AddressSearchfieldType = {
  title?: RichTextField
  description?: RichTextField
  required: boolean
  placeholder?: string
  fieldName: string
  showConditionalField?: string
  showConditionalValue?: string
  options: OptionType[]
  controlledFieldsDisplay: string[]
  defaultValue?: string
  hasEditIcon?: boolean
  variation: FieldVariation.AddressSearchfield
  sliceType?: SliceNameType.Field
  manualEntryText?: string
  searchForAnotherText?: string
  searchAddressText?: string
  cantFindText?: string
  line1Placeholder: string
  line2Placeholder: string
  cityPlaceholder: string
  postcodePlaceholder: string
}

// Hidden Field
export type PrismicHiddenFieldType = {
  primary: {
    fieldName: string
    defaultValue: string
  }
  items: {
    value: string
    conditionalField: string
    conditionalValue: string
  }[]
  slice_type: string
}

export type HiddenFieldType = {
  fieldName: string
  defaultValue: string
  options: {
    value: string
    conditionalField: string
    conditionalValue: string
  }[]
}

// Score Field
export type PrismicScoreFieldType = {
  primary: {
    fieldName: string
    passingScore: number
  }
  items: {
    points: number
    conditionalField: string
    conditionalValue: string
  }[]
  slice_type: string
}

export type ScoreFieldType = {
  fieldName: string
  passingScore: number
  options: {
    points: number
    conditionalField: string
    conditionalValue: string
  }[]
}

export type AllPrismicFieldsType =
  | PrismicDropdownType
  | PrismicTextfieldType
  | PrismicEmailTextfieldType
  | PrismicPasswordTextfieldType
  | PrismicPhoneTextfieldType
  | PrismicCheckboxType
  | PrismicRadioGroupType
  | PrismicCardGroupType
  | PrismicDatefieldType
  | PrismicSearchfieldType
  | PrismicAddressSearchfieldType

export type AllFieldsType =
  | DropdownType
  | TextfieldType
  | EmailTextfieldType
  | PasswordTextfieldType
  | ConfirmPasswordTextfieldType
  | PhoneTextfieldType
  | CheckboxType
  | RadioGroupType
  | CardGroupType
  | DatefieldType
  | SearchfieldType
  | AddressSearchfieldType

export type ExtraFieldProps = {
  currentValue?: string
  handleChange?: (values: ObjectWithFieldsType) => void
  sx?: object
  signupData?: SignupDataType
  checkValidation?: boolean
  uid?: string
  setErrorMessage?: React.Dispatch<React.SetStateAction<string[]>>
  testid?: string
}

// Action Button
export enum ActionButtonActionType {
  Continue = "continue",
  Back = "back",
  Signup = "Signup",
  Submit = "submit",
  Social = "social",
  Login = "login",
  ForgotPassword = "forgotPassword",
  ExtraInfo = "extraInfo",
  Cancel = "cancel"
}

export type ActionButtonVariantType = "contained" | "text" | "outlined"

export type PrismicActionButtonType = {
  primary: {
    displayText?: string
    actionType:
      | ActionButtonActionType.Continue
      | ActionButtonActionType.Submit
      | ActionButtonActionType.Social
      | ActionButtonActionType.Login
      | ActionButtonActionType.ForgotPassword
      | ActionButtonActionType.Back
      | ActionButtonActionType.ExtraInfo
      | ActionButtonActionType.Cancel
    defaultRedirect: string
    variant: ActionButtonVariantType
    disableConditionalField: string
    disableConditionalValue: string
  }
  items: {
    redirectTo: string
    conditionalField: string
    conditionalValue: string
  }[]
  slice_type: string
}

export type ActionButtonType = {
  displayText?: string
  actionType:
    | ActionButtonActionType.Continue
    | ActionButtonActionType.Submit
    | ActionButtonActionType.Social
    | ActionButtonActionType.Login
    | ActionButtonActionType.ForgotPassword
    | ActionButtonActionType.Back
    | ActionButtonActionType.ExtraInfo
    | ActionButtonActionType.Cancel
  variant: ActionButtonVariantType
  defaultRedirect: string
  disableConditionalField: string
  disableConditionalValue: string
  redirectOptions: {
    redirectTo: string
    conditionalField: string
    conditionalValue: string
  }[]
}

// Social Button
export enum SocialNameType {
  Google = "Google",
  Apple = "Apple",
  Facebook = "Facebook",
  LinkedIn = "LinkedIn"
}

export type PrismicSocialButtonType = {
  primary: {
    icon: IconType
    name: string
    type: SocialNameType.Google | SocialNameType.Apple | SocialNameType.Facebook | SocialNameType.LinkedIn
    display: boolean
  }
  slice_type: string
}

export type SocialButtonType = {
  icon: IconType
  name: string
  type: SocialNameType.Google | SocialNameType.Apple | SocialNameType.Facebook | SocialNameType.LinkedIn
  display: boolean
  sliceType?: SliceNameType.SocialButton
}

// Info Box
export type PrismicInfoBoxType = {
  primary: {
    defaultTitle?: RichTextField
    defaultTitleColor?: string
    defaultText: RichTextField
    defaultTextColor?: string
    defaultBackgroundColor?: string
    defaultIcon?: IconType
    defaultIconAlign?: "Top" | "Middle" | "Bottom"
    defaultHasOpacity?: boolean
    displayAtBottom?: boolean
    fieldName?: string
    fieldValue?: string
  }
  items: {
    conditionalField: string
    conditionalValue: string
    title?: RichTextField
    titleColor?: string
    text: RichTextField
    textColor?: string
    backgroundColor?: string
    icon?: IconType
    iconAlign?: "Top" | "Middle" | "Bottom"
    hasOpacity?: boolean
  }[]
  slice_type: string
}

export type InfoBoxType = {
  defaultTitle?: RichTextField
  defaultTitleColor?: string
  defaultText: RichTextField
  defaultTextColor?: string
  defaultBackgroundColor?: string
  defaultIcon?: IconType
  defaultIconAlign?: "Top" | "Middle" | "Bottom"
  defaultHasOpacity?: boolean
  displayAtBottom?: boolean
  fieldName?: string
  fieldValue?: string
  options?: {
    conditionalField: string
    conditionalValue: string
    title?: RichTextField
    titleColor?: string
    text: RichTextField
    textColor?: string
    backgroundColor?: string
    icon?: IconType
    iconAlign?: "Top" | "Middle" | "Bottom"
    hasOpacity?: boolean
  }[]
  sliceType?: SliceNameType.InfoBox
  variation?: FieldVariation.PasswordTextfield
}

// Modal
export type PrismicModalInfoType = {
  primary: {
    icon: IconType
    title: RichTextField
    description?: RichTextField
    displayOnPageLoad?: boolean
    showConditionalField?: string
    showConditionalValue?: string
    buttonText?: string
    secondButtonText?: string
  }
  items: {
    conditionalDescription: RichTextField
    conditionalField: string
    conditionalValue: string
  }[]
  slice_type: string
}

export type ModalInfoType = {
  icon: IconType
  title: RichTextField
  description?: RichTextField
  displayOnPageLoad?: boolean
  showConditionalField?: string
  showConditionalValue?: string
  options?: {
    conditionalDescription: RichTextField
    conditionalField: string
    conditionalValue: string
  }[]
  buttonText?: string
  secondButtonText?: string
}

// Mt4 Password

export type PrismicMt4PasswordType = {
  primary: {
    selectAccountTypeText: string
  }
  slice_type: string
}

// Sumsub Verification
export type PrismicSumsubVerificationType = {
  primary: {
    bearWithUsMessage: string
    longerThanExpectedMessage: string
    enterInformationManually: string
  }
  slice_type: string
}

// Platform Info
export type PrismicPlatformInfoType = {
  primary: {
    title: RichTextField
    description?: RichTextField
    image: IconType
    backgroundTopColor?: string
    backgroundBottomColor?: string
    textColor?: string
    summarisedTitle?: RichTextField
    summarisedDescription?: RichTextField
    fieldName: string
    value: string
    changeText?: string
    defaultOrder: number
    platformType?: string
    derivativeType?: string
  }
  items: {
    order: number
    conditionalField: string
    conditionalValue: string
  }[]
  backend_id?: string
  id?: string
  slice_type: string
}

export type PlatformInfoType = {
  title: RichTextField
  description?: RichTextField
  image: IconType
  backgroundTopColor?: string
  backgroundBottomColor?: string
  textColor?: string
  summarisedTitle?: RichTextField
  summarisedDescription?: RichTextField
  fieldName: string
  value: string
  changeText?: string
  defaultOrder: number
  platformType?: string
  derivativeType?: string
  options?: {
    order: number
    conditionalField: string
    conditionalValue: string
  }[]
  backend_id?: string
  id?: string
}

// Layout (Header, Risk Warning)
export type LayoutType = {
  alternate_languages: []
  first_publication_date: string
  href: string
  id: string
  lang: string
  last_publication_date: string
  linked_documents: string[]
  slugs: string[]
  tags: string[]
  type: string
  uid: string
  url: string
}

type LogoType = {
  alt?: string
  copyright?: string
  dimensions: {
    height: number
    width: number
  }
  url: string
}

type LinkType = {
  first_publication_date: string
  id: string
  isBroken: boolean
  lang: string
  last_publication_date: string
  link_type: string
  slug: string
  tags: string[]
  type: string
  uid: string
}

type SliceType = {
  id: string
  items: {
    label: string
    link: LinkType
  }[]
  primary: {
    group_id: number
    link: LinkType
    title: RichTextField
  }
  slice_label?: string
  slice_type: string
  variation: string
  version: string
}

export type HeaderLayoutType = LayoutType & {
  data: {
    left_side_menu: {
      group_id: number
      menu_label: RichTextField
    }[]
    logo: LogoType
    logout_timeout_after_popup: number
    logowithoutbrandname: LogoType
    redirect_message: RichTextField
    right_side_menu: {
      menu_label: string
      menu_link: {
        link_type: string
        target: string
        url: string
      }
    }[]
    slices: SliceType[]
  }
  type: "header"
  uid: "header"
}

export type RiskWarningLayoutType = LayoutType & {
  data: {
    background_colour: string
    banner_position: boolean
    desktop_font_size: string
    disclaimer_text: RichTextField
    dismissable: boolean
    font_colour: string
    mobile_font_size: string
    risk_percentage: number
    save_preference: boolean
    short_disclaimer_text: RichTextField
  }
  type: "disclaimer"
}

export type MaintenanceModeType = LayoutType & {
  data: {
    account: boolean
    signup: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    slices: any[]
  }
}

export type LanguagesForTranslationsType = LayoutType & {
  data: {
    languages: {
      [lang: string]: boolean
    }[]
  }
}

export type TableHeaderType = {
  field: string
  headerName: string
  width: number
  align?: TableCellProps["align"]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  Layout?: (any) => ReactElement
}

export type WidgetProps = {
  isTradingViewConnectionPage?: boolean
  handleForgetPassword?: () => void
  forgotPasswordText?: string
  infoBoxText?: string
  isLogin?: boolean
  tn2WidgetData?: TN2WidgetDataType
}

export type TN2WidgetDataType = {
  data: {
    title?: RichTextField
    description?: RichTextField
    signup_title?: RichTextField
    signup_description?: RichTextField
    is_trading_view_connection_page?: boolean
    forgot_password_text?: string
    login_to_signup_text?: string
    signup_to_login_text?: string
    background_image?: { url: string }
    single_sign_on_text?: string
    allow_signin?: boolean
    allow_signup?: boolean
    input_background_color?: string
  }
}

export type LanguageForTranslation = Language & { enable: boolean }

export type Language = {
  language_code: string
  language_display_name: string
  flag?: ImageField
}

export type Prompt = { message: string; success: boolean; richTextMessage?: RichTextField }
