import { useState, useEffect } from "react"
// import { getLocalStorage, isPerformanceCookieAccepted, setLocalStorage } from "utils"
import { AccountDataType } from "utils"

export const POSTHOG_SIGNUP_BUTTON_CLICK = "Signup_Button_Click"
export const POSTHOG_SOCIAL_BUTTON_CLICK = "Social_Button_Click"
export const POSTHOG_CONTINUE_BUTTON_CLICK = "Continue_Button_Click"
export const POSTHOG_BACK_BUTTON_CLICK = "Back_Button_Click"
export const POSTHOG_SUBMIT_BUTTON_CLICK = "Submit_Button_Click"
export const POSTHOG_LOGIN_BUTTON_CLICK = "Login_Button_Click"

// const LOCAL_STORAGE_POSTHOG_STEP_INIT_TIME = "posthog_step_init_time"

// export const postHogInit = () => {
//   const env = process.env.NEXT_PUBLIC_ENV.toLowerCase()
//   const defaultFeatureFlags = {
//     [`${FeatureFlag.TRADINGVIEW_CTA}_${env}`]: false,
//     [FeatureFlag.SWAP_FREE]: false
//   }
//   if (
//     typeof window !== "undefined" &&
//     process.env.NEXT_PUBLIC_POSTHOG_API_KEY &&
//     process.env.NEXT_PUBLIC_POSTHOG_API_HOST
//   ) {
//     posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
//       api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST,
//       capture_pageview: false,
//       bootstrap: {
//         featureFlags: {
//           ...defaultFeatureFlags
//         }
//       }
//     })
//     posthog.capture("pageview")
//   }
// }

// export const postHogStepInit = () => {
//   if (isPerformanceCookieAccepted()) {
//     setLocalStorage(LOCAL_STORAGE_POSTHOG_STEP_INIT_TIME, Date.now().toString())
//     posthog.capture("$pageview")
//   }
// }

// const addTimeInStep = (eventName: string) => {
//   if (
//     [
//       POSTHOG_CONTINUE_BUTTON_CLICK,
//       POSTHOG_BACK_BUTTON_CLICK,
//       POSTHOG_SIGNUP_BUTTON_CLICK,
//       POSTHOG_SUBMIT_BUTTON_CLICK,
//       POSTHOG_SOCIAL_BUTTON_CLICK
//     ].includes(eventName)
//   ) {
//     const stepInitTime = Number(getLocalStorage(LOCAL_STORAGE_POSTHOG_STEP_INIT_TIME))
//     const currentTime = Date.now()
//     const timeInStepInMilliseconds = currentTime - stepInitTime
//     const minutes = Math.floor(timeInStepInMilliseconds / 60000)
//     const seconds = ((timeInStepInMilliseconds % 60000) / 1000).toFixed(0)
//     return { timeInStep: `${minutes} min, ${seconds} sec` }
//   }

//   return {}
// }

// export const postHogFireEvent = (eventName: string, eventParams = {}) => {
//   if (isPerformanceCookieAccepted()) {
//     posthog.capture(`${eventName}_ONB`, {
//       ...eventParams,
//       ...addTimeInStep(eventName)
//     })
//   }
// }

export const postHogIdentify = (userData: AccountDataType) => {
  // @ts-ignore
  if (window?.posthog?.identify) {
    const client = userData?.app_metadata?.clients[0]
    const userCountry = userData?.user_metadata?.personal?.addr_country_full
    const username = client?.username || ""
    const jur = username.split("_")[0]
    let brand = client?.brand || ""
    if (brand === "eu") {
      brand = "UK"
    }
    const whitelabel = `${jur} ${brand}`
    const host = window.location.host
    const cubeProfileId = client?.client_profile_id || ""
    const cubeUrl = `${process.env.NEXT_PUBLIC_CUBE_USER_DETAILS}${cubeProfileId}`

    // @ts-ignore
    window.posthog.identify(`${host}:${cubeProfileId}`, {
      registrationCountry: userCountry,
      whitelabel: whitelabel,
      cubeUrl: cubeUrl
    })
  }
}

export const useFeatureFlagEnabled = (flagName: string) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY.slice(4, process.env.NEXT_PUBLIC_POSTHOG_API_KEY.length)
    const checkFeatureFlag = () => {
      //@ts-ignore
      if (typeof window !== "undefined" && window.posthog && window.posthog.isFeatureEnabled) {
        const posthogUserCookie = document.cookie.includes(`ph_phc_${POSTHOG_KEY}`)
        if (posthogUserCookie) {
          //@ts-ignore
          const flagState = window.posthog.isFeatureEnabled(flagName)
          setIsEnabled(flagState)
          setIsLoaded(true)
        } else {
          console.warn("PostHog identification cookies not set yet.")
        }
      }
    }
    const intervalId = setInterval(() => {
      checkFeatureFlag()
    }, 1000)

    return () => clearInterval(intervalId)
  }, [flagName])

  if (!isLoaded) {
    return false
  }
  return isEnabled
}
export enum FeatureFlag {
  SWAP_FREE = "swap_free",
  TRADINGVIEW_CTA = "tn2_tradingview_cta",
  TN2_TRADER_DEV = "tn2_trader"
}
