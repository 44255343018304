/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { Collapse, Fade, Link, List, Typography, useMediaQuery } from "@mui/material"
import Container from "@mui/material/Container"
import * as prismicH from "@prismicio/helpers"
import { PrismicLink } from "@prismicio/react"
import { linkResolver } from "prismicio"
import { RichText } from "components"
import { getId } from "utils"
import { useCustomEvent } from "hooks"
import { theme } from "styles"
import {
  StyledBox,
  StyledGridBox,
  StyledLink,
  StyledListItemButton,
  StyledMenuList,
  StyledPaper,
  StyledPopper,
  fadeStyle,
  tabletListStyle
} from "./ExpandedMenu.styled"

const ExpandedMenu = ({
  open,
  anchor,
  slices
}: {
  slices: any | undefined | null
  open: boolean | undefined | null
  anchor?: HTMLAnchorElement | undefined | null
}) => {
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"))
  const columns = slices?.length

  // set min height of titles
  const titles = slices?.map((slice) => prismicH.asText(slice.primary.title))
  const longTitleExists = titles?.some((title) => title.length > 15)
  const titlesMinHeight = longTitleExists ? 88 : 0

  useCustomEvent("menuChanged", [slices, open])

  return isTablet ? (
    <Collapse in={open} className="expanded-menu-mobile" timeout="auto" unmountOnExit>
      <List sx={tabletListStyle} component="div" disablePadding>
        {slices?.map((menuItem) => {
          const hasTitle = prismicH.asText(menuItem.primary.title).length > 0
          return (
            <StyledBox className={`${getId(prismicH.asText(menuItem.primary.title))}-column`} key={menuItem.id}>
              {hasTitle && (
                <StyledListItemButton
                  LinkComponent={PrismicLink}
                  href={linkResolver(menuItem.primary.link) ?? (menuItem.primary.link as any).url}
                  target={(menuItem.primary.link as any).target ?? "_self"}
                  onClick={() => {
                    // fireGtmEvent('Menu_Link_Click', {
                    //   Link_Text: prismicH.asText(menuItem.primary.title),
                    //   Destination_URL: (menuItem.primary.link as any).url
                    //     ? (menuItem.primary.link as any).url
                    //     : 'No Link',
                    //   Column_Title: prismicH.asText(menuItem.primary.title),
                    //   Locale: locale,
                    // });
                  }}
                  className={`${getId(prismicH.asText(menuItem.primary.title))}-col-title`}
                >
                  <RichText field={menuItem.primary.title} />
                </StyledListItemButton>
              )}
              {menuItem.items.map((item, key) => (
                <StyledListItemButton
                  key={key}
                  className={`${getId(item.label)}-link`}
                  hastitle={hasTitle}
                  LinkComponent={PrismicLink}
                  href={linkResolver(item.link) ?? (item.link as any).url}
                  target={(item.link as any).target ?? "_self"}
                  onClick={() => {
                    // fireGtmEvent('Menu_Link_Click', {
                    //   Link_Text: item.label,
                    //   Destination_URL: (item.link as any).url
                    //     ? (item.link as any).url
                    //     : 'No Link',
                    //   Column_Title: prismicH.asText(menuItem.primary.title),
                    //   Locale: locale,
                    // });
                  }}
                >
                  {item.label}
                </StyledListItemButton>
              ))}
            </StyledBox>
          )
        })}
      </List>
    </Collapse>
  ) : (
    <StyledPopper id="expanded-menu" open={open} anchorEl={anchor} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} style={fadeStyle}>
          <StyledPaper>
            <Container maxWidth="xl">
              <StyledGridBox className="expanded-menu-gridbox" columns={columns || 0}>
                {slices?.map((menuItem) => (
                  <StyledMenuList
                    className={`${getId(prismicH.asText(menuItem.primary.title))}-column`}
                    key={menuItem.id}
                    theme={theme}
                  >
                    {menuItem.primary.title &&
                      ((menuItem.primary.link as any).url ? (
                        <StyledLink
                          href={linkResolver(menuItem.primary.link) || (menuItem.primary.link as any).url || "#"}
                          target={(menuItem.primary.link as any).target ?? "_self"}
                          underline="hover"
                          color="white"
                          className={`gtm_header_link ${getId(prismicH.asText(menuItem.primary.title))}-col-title`}
                          onClick={() => {
                            // fireGtmEvent('Menu_Link_Click', {
                            //   Link_Text: prismicH.asText(
                            //     menuItem.primary.title
                            //   ),
                            //   Destination_URL: (menuItem.primary.link as any)
                            //     .url
                            //     ? (menuItem.primary.link as any).url
                            //     : 'No Link',
                            //   Column_Title: prismicH.asText(
                            //     menuItem.primary.title
                            //   ),
                            //   Locale: locale,
                            // });
                          }}
                          titlesMinHeight={titlesMinHeight}
                        >
                          <RichText color="white" field={menuItem.primary.title} mb={3} />
                        </StyledLink>
                      ) : (
                        <RichText color="white" field={menuItem.primary.title} mb={3} />
                      ))}
                    {menuItem.items.map((item, key) => (
                      <Link
                        key={key}
                        href={linkResolver(item.link) || (item.link as any).url || "#"}
                        target={(item.link as any).target ?? "_self"}
                        underline="hover"
                        color="inherit"
                        py={1}
                        className={`gtm_header_link ${getId(item.label)}-link`}
                        onClick={() => {
                          // fireGtmEvent('Menu_Link_Click', {
                          //   Link_Text: item.label,
                          //   Destination_URL: (item.link as any).url
                          //     ? (item.link as any).url
                          //     : 'No Link',
                          //   Column_Title: prismicH.asText(
                          //     menuItem.primary.title
                          //   ),
                          //   Locale: locale,
                          // });
                        }}
                      >
                        <Typography color="white">{item.label}</Typography>
                      </Link>
                    ))}
                  </StyledMenuList>
                ))}
              </StyledGridBox>
            </Container>
          </StyledPaper>
        </Fade>
      )}
    </StyledPopper>
  )
}

export default ExpandedMenu
