import { blackColor, whiteColor } from "styles"

export const buttonStyle = {
  backgroundColor: whiteColor,
  color: blackColor,
  height: "80px",
  width: "80px",
  minWidth: "initial",
  padding: "10px",
  fontSize: "20px",
  borderRadius: "50%",
  border: `2px solid ${blackColor}`,
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
  position: "fixed",
  bottom: "50px",
  right: "100px",
  zIndex: 1000,
  cursor: "pointer"
}
