import React from "react"
import { Collapse, FormHelperText } from "@mui/material"
import { Container } from "./FieldErrorMessage.styled"

type Props = {
  padding: string
  errorMessage: string[]
}

const FieldErrorMessage = ({ padding, errorMessage }: Props) => {
  return (
    <Container padding={padding}>
      {errorMessage.map((errorLine) => (
        <Collapse key={errorLine}>
          <FormHelperText error>{errorLine}</FormHelperText>
        </Collapse>
      ))}
    </Container>
  )
}

export default FieldErrorMessage
