import React from "react"
import CountUp from "react-countup"
import { getCurrencySymbol } from "utils"
import { BalanceContainer, BalanceValue, CurrencySymbol } from "./AccountBalance.styled"

type Props = {
  balance: string
  currency: string
}

const AccountBalance = ({ balance, currency }: Props) => {
  const currencySymbol = getCurrencySymbol(currency)
  return (
    <BalanceContainer>
      <CurrencySymbol>{currencySymbol}</CurrencySymbol>
      <BalanceValue>
        <CountUp duration={0.8} separator="," decimal="." decimals={2} useEasing={false} end={Number(balance)} />
      </BalanceValue>
    </BalanceContainer>
  )
}

export default AccountBalance
