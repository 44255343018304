import React from "react"
import { useRouter } from "next/router"
import { goToHome, goToPage } from "utils"
import { whiteColor } from "styles"
import AnimatedDesktopLogo from "./AnimatedDesktopLogo"
import { LogoWithoutBrandNameWrapper, TextLogoWrapper } from "./AnimatedLogo.styled"

type LogoProps = {
  fontColor?: string
  width?: number
  height?: number
  withoutText?: boolean
}

const AnimatedLogo = ({
  fontColor: desktopFontColor = whiteColor,
  width: desktopWidth = 207,
  height: desktopHeight = 58,
  withoutText
}: LogoProps) => {
  const { asPath } = useRouter()

  const hasLink = !asPath.includes("/signup/") || asPath.includes("/signup/welcome")

  const onClick = () => {
    if (hasLink) {
      if (asPath.includes("/account")) {
        goToPage("/account/overview")
      } else {
        goToHome()
      }
    }
  }

  return (
    <>
      {withoutText ? (
        <LogoWithoutBrandNameWrapper>
          <AnimatedDesktopLogo
            fontColor={desktopFontColor}
            width={desktopWidth}
            height={desktopHeight}
            withoutText={withoutText}
            onClick={onClick}
            hasLink={hasLink}
          />
        </LogoWithoutBrandNameWrapper>
      ) : (
        <TextLogoWrapper>
          {process.env.NEXT_PUBLIC_IS_TN === "false" ? (
            <h1
              style={{
                color: "skyblue",
                marginRight: 30,
                background: "blue",
                padding: 10
              }}
            >
              TD MODE
            </h1>
          ) : (
            <AnimatedDesktopLogo
              fontColor={desktopFontColor}
              width={desktopWidth}
              height={desktopHeight}
              withoutText={withoutText}
              onClick={onClick}
              hasLink={hasLink}
            />
          )}
        </TextLogoWrapper>
      )}
    </>
  )
}

export default AnimatedLogo
