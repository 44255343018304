import React, { useState, useEffect, useRef } from "react"
import { RichTextField } from "@prismicio/types"
import { InputBaseComponentProps, Paper } from "@mui/material"
import { Textfield, RichText } from "components"
import { ObjectWithFieldsType } from "utils"
import { muiCardStyle, textfieldInputPropsStyle, textfieldStyle } from "./Card.styled"

type Props = {
  title: RichTextField
  description: RichTextField
  value: string
  textfieldOnClick: boolean
  selected: boolean
  handleChange: (value: string, otherSelected: boolean) => void
  fieldName: string
}

const Card = ({ title, description, value, textfieldOnClick, selected, handleChange, fieldName }: Props) => {
  const [textfieldMode, setTextfieldMode] = useState(selected && textfieldOnClick)
  const [textfieldValue, setTextfieldValue] = useState(textfieldOnClick ? value : "")

  useEffect(() => {
    if (textfieldOnClick) {
      setTextfieldValue(value)
    }
  }, [value])

  useEffect(() => {
    if (!selected && textfieldMode) {
      setTextfieldMode(false)
    }
  }, [selected])

  const onClick = () => {
    if (textfieldOnClick) {
      if (!textfieldMode) {
        setTextfieldMode(true)
        handleChange("", true)
        setTextfieldValue("")
      }
    } else {
      handleChange(value, false)
    }
  }

  const onTextfieldChange = (newValue: ObjectWithFieldsType) => {
    handleChange(newValue[fieldName], true)
    setTextfieldValue(newValue[fieldName])
  }

  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    if (selected && ref && ref.current) {
      // Scroll for the selected card to be centered on the screen.
      // The scroll effect is inside a setTimeout, otherwise sometimes it doesn't scroll.
      setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: "smooth", block: "center" })
      }, 50)
    }
  }, [selected, ref])

  return (
    <Paper
      ref={ref}
      elevation={0}
      sx={muiCardStyle(selected, textfieldOnClick, textfieldMode, title)}
      onClick={onClick}
    >
      {textfieldMode ? (
        <Textfield
          currentValue={textfieldValue}
          required
          placeholder=""
          handleChange={onTextfieldChange}
          fieldName={fieldName}
          controlledFieldsDisplay={[]}
          variant="standard"
          sx={textfieldStyle}
          inputProps={{ style: textfieldInputPropsStyle } as InputBaseComponentProps}
          autoFocus
        />
      ) : (
        <>
          <RichText field={title} />
          <RichText field={description} />
        </>
      )}
    </Paper>
  )
}

export default Card
