import styled from "@emotion/styled"

export const LegalElement = styled.div`
  font-family: Inter;
  border: 1px solid #f5f3f2;
  border-radius: 8px;
  h6 {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  p {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.5;
    margin: 10px 0;
    padding: 0 10px;
  }
`
