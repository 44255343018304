import styled from "@emotion/styled"
import { TextField as MUITextfield } from "@mui/material"
import { accentGreenColor, darkBlueColor, lightGreyColor, lightMainColor, whiteColor } from "styles"

export const TextField = styled(MUITextfield)`
  fieldset {
    border-radius: 13px;
    border-color: ${lightGreyColor};
  }
`

export const searchFieldTextStyle = {
  width: "100%"
}

export const List = styled.div`
  padding: 10px 0;
  background: ${whiteColor};
  border: 1px solid ${accentGreenColor};
  border-radius: 13px;
  max-height: 350px;
  overflow: scroll;
  overflow-x: auto;
  z-index: 100;

  li {
    list-style-type: none;
    padding: 10px 20px;
    transition: background-color 0.2s;
    font-size: 14px;
    line-height: 20px;
    color: ${darkBlueColor};

    &:hover,
    &:focus {
      background-color: ${lightMainColor};
    }
  }
`

export const optionsListBottomMultiTextTopStyle = {
  opacity: 0.5
}

export const optionsListBottomMultiTextBottomStyle = {
  textDecoration: "underline",
  cursor: "pointer"
}

export const optionsListBottomTextStyle = {
  padding: "20px",
  fontSize: "13px"
}
