import React from "react"
import Image from "next/image"
import { RichTextField } from "@prismicio/types"
import { RichText } from "components"
import TradingViewLogoWhite from "components/tradingview/TradingViewLogoWhite.svg"
import { ListTextWithIcon } from "./TextWithImages.styled"

type Props = {
  description: RichTextField
  useTradingViewLogo?: boolean
}

const TextWithImages = ({ description, useTradingViewLogo }: Props) => {
  const fullDescription = [...description]

  return (
    <>
      {fullDescription.map((line, index) => {
        const key = `${JSON.stringify(line)}-${index}`
        if (
          line.type === "image" &&
          index < fullDescription.length - 1 &&
          fullDescription[index + 1]?.type === "list-item"
        ) {
          const text = fullDescription.splice(index + 1, 1)

          let displayTradingViewLogo = false
          if (useTradingViewLogo && "text" in text[0] && text[0].text.includes("TradingView")) {
            displayTradingViewLogo = true
          }

          const getText = () => {
            if (displayTradingViewLogo && "text" in text[0]) {
              return [{ ...text[0], text: text[0].text.replace("TradingView", "") }]
            }

            return text
          }

          return (
            <ListTextWithIcon key={key}>
              <Image
                src={line.url?.replace("?auto=compress,format", "")}
                alt={line.alt || "Icon"}
                width={line.dimensions.width}
                height={line.dimensions.height}
              />
              <RichText field={getText() as RichTextField} displayEmptyText />
              {displayTradingViewLogo && <Image src={TradingViewLogoWhite} alt="Trading View" />}
            </ListTextWithIcon>
          )
        }

        return <RichText key={key} field={[line]} displayEmptyText />
      })}
    </>
  )
}

export default TextWithImages
