import { darkBlueColor, lightGreyColor, whiteColor } from "../../styles"

export const buttonStyle = {
  height: "50px",
  minHeight: "50px",
  border: "1px solid transparent",
  fontSize: "16px",
  width: "100%",
  fontWeight: 400,
  lineHeight: "19.36px"
}

export const widgetButtonStyle = {
  height: "50px",
  minHeight: "50px",
  border: `1px solid ${lightGreyColor}`,
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "19.36px",
  backgroundColor: "transparent",
  color: whiteColor,
  "&:hover": {
    border: `1px solid ${lightGreyColor}`,
    backgroundColor: "rgba(232, 236, 241, 0.5)",
    color: darkBlueColor
  }
}
