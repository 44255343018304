import styled from "@emotion/styled"

export const Separator = styled.div<{ lineColor: string; margin?: string }>`
  position: relative;
  text-align: center;
  margin: ${({ margin }) => (margin ? `${margin}` : "10px 0")};
  &:before {
    content: "";
    position: absolute;
    width: 40%;
    height: 1px;
    top: 50%;
    left: 0;
    background-color: ${({ lineColor }) => lineColor};
  }
  &:after {
    content: "";
    position: absolute;
    width: 40%;
    height: 1px;
    top: 50%;
    right: 0;
    background-color: ${({ lineColor }) => lineColor};
  }
`

export const InnerSeparator = styled.div`
  background-color: transparent;
  padding: 10px;
  position: relative;
  display: inline-block;
`
