import React, { useState, useMemo } from "react"
import { FieldErrorMessage } from "components"

export const useFieldErrorMessage = () => {
  const [errorMessage, setErrorMessage] = useState<string[]>([])
  const error = useMemo(() => errorMessage.length > 0, [errorMessage])

  const displayErrorMessage = (padding = "0", extraErrorMessages: string[] = []) => {
    return <FieldErrorMessage padding={padding} errorMessage={[...errorMessage, ...extraErrorMessages]} />
  }

  return { errorMessage, setErrorMessage, error, displayErrorMessage }
}
