import React from "react"
import { ActionButton, ActionButtonActionType } from "components"
import { IconType, getSocialLogin, sendMessageToWindow } from "utils"
import { buttonStyle, widgetButtonStyle } from "./SocialButtons.styled"

type Props = {
  onClick: () => void
  onFail: () => void
  icon: IconType
  name?: string
  type: string
  isSignupWidget?: boolean
}

const FacebookButton = ({ icon, name, type, isSignupWidget }: Props) => {
  const {
    url,
    dimensions: { width, height }
  } = icon

  const onClick = async () => {
    if (isSignupWidget) {
      await getSocialLogin("facebook")
    }
  }

  return (
    <ActionButton
      displayText={name || type}
      variant="outlined"
      onClick={onClick}
      image={{ src: url, width, height, alt: type }}
      lightBackground
      sx={isSignupWidget ? widgetButtonStyle : buttonStyle}
      actionType={ActionButtonActionType.Social}
      isSignupWidget={isSignupWidget}
    />
  )
}

export default FacebookButton
