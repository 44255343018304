import React, { useState, useEffect } from "react"
import { Collapse } from "@mui/material"
import { Address } from "react-loqate"
import "react-loqate/dist/react-loqate.cjs.development.css"
import { TransitionGroup } from "react-transition-group"
import { ActionButton, ExtraFieldProps, AddressSearchfieldType } from "components"
import { COUNTRY_OF_RESIDENCE_FIELD_NAME, getAddressFields, getFieldValue, ObjectWithFieldsType } from "utils"
import ManualAddress from "./ManualAddress"
import SearchAddress from "./SearchAddress"
import { changeModeTextButtonStyle } from "./AddressSearchfield.styled"

const ADDRESS_LIST_CLASS = "address-auto-populate-list"

const AddressSearchfield = ({
  handleChange,
  placeholder,
  fieldName,
  signupData,
  checkValidation,
  manualEntryText,
  searchAddressText,
  searchForAnotherText,
  cantFindText,
  line1Placeholder,
  line2Placeholder,
  cityPlaceholder,
  postcodePlaceholder
}: AddressSearchfieldType & ExtraFieldProps) => {
  const { addressLine1, addressLine2, addressCity, addressPostcode } = getAddressFields(fieldName)

  const signupDataAddressLine1 = getFieldValue(signupData, addressLine1)
  const signupDataAddressLine2 = getFieldValue(signupData, addressLine2)
  const signupDataAddressCity = getFieldValue(signupData, addressCity)
  const signupDataAddressPostcode = getFieldValue(signupData, addressPostcode)

  const [enterManually, setEnterManually] = useState(
    !!signupDataAddressLine1 || !!signupDataAddressLine2 || !!signupDataAddressCity || !!signupDataAddressPostcode
  )

  const [selectedAddressFromList, setSelectedAddressFromList] = useState(false)

  let actionButtonDisplayText = manualEntryText
  if (enterManually) {
    if (selectedAddressFromList) {
      actionButtonDisplayText = searchForAnotherText
    } else {
      actionButtonDisplayText = searchAddressText
    }
  }
  const [addressLine1Value, setAddressLine1Value] = useState(signupDataAddressLine1)
  const updateAddressLine1 = (value: ObjectWithFieldsType) => {
    setAddressLine1Value(value[addressLine1])
    handleChange({ [addressLine1]: value[addressLine1] })
  }

  const [addressLine2Value, setAddressLine2Value] = useState(signupDataAddressLine2)
  const updateAddressLine2 = (value: ObjectWithFieldsType) => {
    setAddressLine2Value(value[addressLine2])
    handleChange({ [addressLine2]: value[addressLine2] })
  }

  const [addressCityValue, setAddressCityValue] = useState(signupDataAddressCity)
  const updateAddressCity = (value: ObjectWithFieldsType) => {
    setAddressCityValue(value[addressCity])
    handleChange({ [addressCity]: value[addressCity] })
  }

  const [addressPostcodeValue, setAddressPostcodeValue] = useState(signupDataAddressPostcode)
  const updateAddressPostcode = (value: ObjectWithFieldsType) => {
    setAddressPostcodeValue(value[addressPostcode])
    handleChange({ [addressPostcode]: value[addressPostcode] })
  }

  const onAddressSelect = (address: Address) => {
    const { Company, Line1, Line2, Line3, Line4, City, PostalCode } = address
    const addressSelectLine1 = `${Company && `${Company}, `}` + Line1 || Line2
    const addressSelectLine2 = Line1 ? Line2 + `${Line3 && `, ${Line3}`}` + `${Line4 && `, ${Line4}`}` : ""

    setAddressLine1Value(addressSelectLine1)
    setAddressLine2Value(addressSelectLine2)
    setAddressCityValue(City)
    setAddressPostcodeValue(PostalCode)
    const handleChangeParams = {
      [addressLine1]: addressSelectLine1,
      [addressLine2]: addressSelectLine2,
      [addressCity]: City,
      [addressPostcode]: PostalCode
    }

    handleChange(handleChangeParams)
    setSelectedAddressFromList(true)
    setEnterManually(true)
  }

  const handleEnterManually = () => {
    if (enterManually) {
      const handleChangeParams = {
        [addressLine1]: "",
        [addressLine2]: "",
        [addressCity]: "",
        [addressPostcode]: ""
      }
      handleChange(handleChangeParams)
    } else {
      const handleChangeParams = {
        [addressLine1]: addressLine1Value,
        [addressLine2]: addressLine2Value,
        [addressCity]: addressCityValue,
        [addressPostcode]: addressPostcodeValue
      }
      handleChange(handleChangeParams)
    }
    setEnterManually(!enterManually)
  }

  const [highlightedIndex, setHighlightedIndex] = useState(-1)

  const error = checkValidation && !enterManually

  const country = getFieldValue(signupData, COUNTRY_OF_RESIDENCE_FIELD_NAME)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.preventDefault()
        setHighlightedIndex((prevIndex) => {
          const addresses = document.querySelectorAll(`.${ADDRESS_LIST_CLASS} > li`)
          const maxIndex = addresses.length - 1
          let newIndex = prevIndex

          if (event.key === "ArrowDown") {
            newIndex = prevIndex < maxIndex ? prevIndex + 1 : 0
          } else if (event.key === "ArrowUp") {
            newIndex = prevIndex > 0 ? prevIndex - 1 : maxIndex
          } else {
            newIndex = 0
          }

          return newIndex
        })
      }
    }
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  useEffect(() => {
    const availableAddresses = document.querySelectorAll(`.${ADDRESS_LIST_CLASS} > li`)
    const selectedAddress = availableAddresses[highlightedIndex]
    if (selectedAddress) {
      selectedAddress.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      })
    }
  }, [highlightedIndex])

  return (
    <TransitionGroup>
      {enterManually ? (
        <Collapse in key="address_fields">
          <ManualAddress
            checkValidation={checkValidation}
            signupData={signupData}
            fieldName={fieldName}
            country={country}
            addressLine1Value={addressLine1Value}
            updateAddressLine1={updateAddressLine1}
            addressLine2Value={addressLine2Value}
            updateAddressLine2={updateAddressLine2}
            addressCityValue={addressCityValue}
            updateAddressCity={updateAddressCity}
            addressPostcodeValue={addressPostcodeValue}
            updateAddressPostcode={updateAddressPostcode}
            line1Placeholder={line1Placeholder}
            line2Placeholder={line2Placeholder}
            cityPlaceholder={cityPlaceholder}
            postcodePlaceholder={postcodePlaceholder}
          />
        </Collapse>
      ) : (
        <Collapse in key="address_manual">
          <SearchAddress
            country={country}
            error={error}
            placeholder={placeholder}
            ADDRESS_LIST_CLASS={ADDRESS_LIST_CLASS}
            onAddressSelect={onAddressSelect}
            setEnterManually={setEnterManually}
            highlightedIndex={highlightedIndex}
            setHighlightedIndex={setHighlightedIndex}
            cantFindText={cantFindText}
            manualEntryText={manualEntryText}
          />
        </Collapse>
      )}
      <ActionButton
        displayText={actionButtonDisplayText}
        variant="text"
        textUnderline
        onClick={handleEnterManually}
        sx={changeModeTextButtonStyle}
      />
    </TransitionGroup>
  )
}

export default AddressSearchfield
