import styled from "@emotion/styled"
import { Box, Button, Collapse, Drawer, ListItem, ListItemButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import RichText from "components/text/RichText" // Can't import from index.ts
import { theme, whiteColor } from "styles"

export const MenuItemContainer = styled(Box)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
`

export const User = styled(ListItemButton)`
  font-size: 20px;
  font-weight: 700;
  color: ${whiteColor};
  display: flex;
  justify-content: space-between;
`

export const StyledCollapse = styled(Collapse)`
  & > * {
    font-weight: 600;
  }
  svg {
    margin-right: 10px;
  }
`

export const StyledDrawer = styled(Drawer)<{ top: number }>`
  .MuiPaper-root {
    background-color: ${theme.palette.secondaryMidnightBlue.main};
    width: 100%;
    color: ${whiteColor};
    top: ${({ top }) => top}px;
    height: ${({ top }) => `calc(100% - ${top}px)`};
  }

  .MuiList-root {
    padding-top: 0;
  }
`

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${whiteColor};
`

export const FirstListItem = styled(ListItem)`
  height: 88px;
`

export const TradingIn = styled(Typography)`
  margin-left: auto;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${whiteColor};
  span {
    margin-left: 10px;
  }
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserDataListItem = styled(ListItem)<{ userdata: any }>`
  justify-content: ${({ userdata }) => (userdata ? "center" : "space-between")};
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
`

export const StyledButton = styled(Button)`
  width: 45%;
`

export const StyledRichText = styled(RichText)`
  color: ${whiteColor};
`

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`
