import styled from "@emotion/styled"
import { darkBlueColor } from "styles"

export const titleStyle = {
  fontSize: "18px",
  lineHeight: "21px",
  fontWeight: "500",
  color: darkBlueColor
}

export const subtitleStyle = {
  fontSize: "17px",
  lineHeight: "20px",
  weight: 300,
  opacity: "40%"
}

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    text-align: left;
    font-size: 13px;
    line-height: 18px;
    color: ${darkBlueColor};
    font-weight: 300;
  }
`

export const buttonStyle = {
  flex: 1,
  height: "60px"
}

export const okButtonStyle = {
  marginTop: "10px",
  height: "60px",
  width: "160px",
  maxWidth: "100%"
}
