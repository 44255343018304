import React, { useRef, forwardRef, useImperativeHandle } from "react"
import { RichTextField } from "@prismicio/types"
import { Icon, RichText } from "components"
import { IconType, isRichTextValid, validObject } from "utils"
import { PageTitleAndDescription } from "styles"
import { TextContainer, iconStyle } from "./TopTextInfo.styled"

type Props = {
  textAlign?: string
  icon?: IconType
  iconSx?: object
  title?: RichTextField
  description?: RichTextField
  gap?: number
  padding?: string
  margin?: string
  indentedList?: boolean
  titleFontSize?: string
  titleLineHeight?: string
  textFontSize?: string
  textLineHeight?: string
  widget?: boolean
}

interface ScrollableDivMethods {
  resetScroll: () => void
}

const TopTextInfo = forwardRef<ScrollableDivMethods, Props>(function TopTextInfo(
  {
    textAlign,
    icon,
    iconSx = {},
    title,
    description,
    gap = 10,
    padding,
    margin,
    indentedList,
    titleFontSize,
    titleLineHeight,
    textFontSize,
    textLineHeight,
    widget = false
  }: Props,
  ref
) {
  const divRef = useRef<HTMLDivElement>(null)

  const resetScroll = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0
    }
  }
  useImperativeHandle(ref, () => ({
    resetScroll
  }))

  if (!validObject(icon) && !isRichTextValid(title) && !isRichTextValid(description)) {
    return null
  }

  const titleProps: { fontSize?: string; lineHeight?: string } = {}
  if (titleFontSize) {
    titleProps.fontSize = titleFontSize
  }
  if (titleLineHeight) {
    titleProps.lineHeight = titleLineHeight
  }

  return (
    <TextContainer
      ref={divRef}
      textAlign={textAlign}
      margin={margin ? margin : validObject(icon) ? "-20px 0 0 0" : "0"}
    >
      <Icon icon={icon} sx={iconStyle(iconSx)} />
      {(isRichTextValid(title) || isRichTextValid(description)) && (
        <PageTitleAndDescription
          gap={gap.toString()}
          padding={padding}
          textFontSize={textFontSize}
          textLineHeight={textLineHeight}
        >
          <RichText field={title} widget={widget} {...titleProps} />
          <RichText field={description} indentedList={indentedList} widget={widget} />
        </PageTitleAndDescription>
      )}
    </TextContainer>
  )
})

export default TopTextInfo
