/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useContext } from "react"
import * as Sentry from "@sentry/react"
import { Elements } from "prismic-reactjs"
import { FormHelperText } from "@mui/material"
import { ActionButton, Loading, ActionButtonActionType, ActionButtonType } from "components"
import {
  APPLICATION_FAILED_MESSAGE,
  ObjectWithFieldsType,
  SignupDataType,
  SliceType,
  clearLocalStorage,
  disableActionButton,
  fieldValidation,
  getActionButtons,
  getFieldValue,
  getFieldsAndInfoBoxes,
  getRichText,
  getScoreFields,
  showField,
  transformPostBody,
  postIncompleteSignUpInfo,
  clearAllSignupData,
  USER_ALREADY_REGISTERED_USING_NORMAL_SIGNUP,
  redirectAfterLogin,
  sendMessageToWindow
} from "utils"
import { useMobileMode, useModal, useSignupData } from "hooks"
import { LanguageContext } from "context"
import { useNewUserSignupMutation } from "redux/features/accountapi/oldAccountApi"
import { Context } from "pages/_app"
import SkipButton from "./SkipButton"
import PrevButton from "./PrevButton"

type Props = {
  slices: SliceType[]
  canSkip?: boolean
  onClick?: () => void
  prevStep?: string
  continueButtonDisabled?: boolean
}

const ActionButtons = ({ slices, canSkip = false, onClick, prevStep, continueButtonDisabled }: Props) => {
  const { setLoading, validationMessages } = useContext(Context)
  const { language } = useContext(LanguageContext)
  const {
    signupData,
    currentStepNumberOfParts,
    currentStepPart,
    getCurrentSignupData,
    currentStepSignupData,
    updateCurrentStepPart,
    continueToNextStep,
    stepUid: uid
  } = useSignupData()
  const isMobile = useMobileMode()
  const { openModal, displayModal: displayErrorModal } = useModal()

  const { fields } = getFieldsAndInfoBoxes(slices)
  const scoreFields = getScoreFields(slices)
  const actionButtons = getActionButtons(slices)
  const [createNewUser, { isLoading: isLoadingCreateNewUser }] = useNewUserSignupMutation()
  const [signupError, setSignupError] = useState(null)

  // Invalid Step
  const [invalidStep, setInvalidStep] = useState(true)
  const stepIsInvalid = () => {
    let isInvalid = false
    fields.forEach((field, index) => {
      if (currentStepNumberOfParts === 1 || currentStepPart === index) {
        const { required, fieldName } = field
        if (
          showField(field, getCurrentSignupData()) &&
          fieldValidation(
            field,
            currentStepSignupData[fieldName] || "",
            getCurrentSignupData(),
            validationMessages,
            required
          ).length > 0
        ) {
          isInvalid = true
        }
      }
    })

    return isInvalid
  }

  useEffect(() => {
    setInvalidStep(stepIsInvalid())
  }, [currentStepSignupData, currentStepPart])

  const displayBackButton = () => {
    const backButtonSlice = slices.find(
      (slice) => slice.slice_type === "action_button" && slice.primary.actionType === ActionButtonActionType.Back
    )
    if (prevStep && backButtonSlice) {
      return (
        <PrevButton
          prevStep={prevStep}
          displayText={(backButtonSlice?.primary?.displayText as string) || "Back"}
          sx={actionButtons.length === 0 && !isMobile ? { width: "100%" } : {}}
        />
      )
    }

    return null
  }

  const clearSignupData = () => {
    clearAllSignupData()
    localStorage.removeItem("cxd")
    localStorage.removeItem("cxd_expiry")
  }

  const actionButtonAction = async (newData: SignupDataType, actionButton?: ActionButtonType) => {
    if (actionButton) {
      const { actionType } = actionButton
      switch (actionType) {
        case ActionButtonActionType.Continue:
          if (currentStepPart < currentStepNumberOfParts - 1) {
            updateCurrentStepPart(currentStepPart + 1)
          } else {
            continueToNextStep(slices)
          }
          break
        case ActionButtonActionType.Submit:
          setLoading(true)

          const { lastPageUserWasOn, last_page_user_was_on, ...currentSignupData } = getCurrentSignupData()
          const body = transformPostBody(currentSignupData, language)

          const openErrorModal = (error = null) => {
            if (openModal) {
              openModal({
                title: getRichText([{ text: "FAIL", type: Elements.heading2 }]),
                text: getRichText([
                  {
                    text: error ? error : "Something went wrong. Please try again later.",
                    type: Elements.paragraph
                  }
                ])
              })
            }
          }
          try {
            try {
              setLoading(true)
              await createNewUser({ body }).unwrap()
              const isTradingViewSignup = sessionStorage.getItem("trading_view_state")
              if (isTradingViewSignup) {
                sendMessageToWindow({
                  action: "authorize-tradingview",
                  data: { authSuccess: true }
                })
                return
              }
              clearLocalStorage(USER_ALREADY_REGISTERED_USING_NORMAL_SIGNUP)
              clearSignupData()
              redirectAfterLogin()
              localStorage.removeItem("token_expiration_time")
            } catch (error) {
              setLoading(false)
              setSignupError(error?.data?.message || APPLICATION_FAILED_MESSAGE)
              console.log(error)
              Sentry.captureException(error)
            }
          } catch (error) {
            setLoading(false)
            openErrorModal()
            Sentry.captureException(error)
          }
          break
        default:
      }
    }
  }

  const displayButtons = () => {
    if (actionButtons.length === 0) {
      return null
    }

    return actionButtons
      .filter((actionButton) => actionButton.actionType !== ActionButtonActionType.Back)
      .map((actionButton) => {
        const { actionType, displayText, variant, disableConditionalField, disableConditionalValue } = actionButton

        const disableButton =
          invalidStep || disableActionButton(disableConditionalField, disableConditionalValue, getCurrentSignupData())

        const actionButtonProps = {
          id: actionType === ActionButtonActionType.Continue && "continue",
          displayText: (() => {
            let text = displayText

            if (!text) {
              if (actionType === ActionButtonActionType.Continue) {
                text = "Continue"
              } else if (actionType === ActionButtonActionType.Submit) {
                text = "Submit"
              }
            }

            return text
          })(),
          variant,
          actionType,
          onClick: () => {
            if (!disableButton) {
              if (onClick) {
                onClick()
              } else {
                setLoading(true)
                const currentSignupData: ObjectWithFieldsType = getCurrentSignupData()

                // Add score fields to current signup data step
                const scoreFieldsData = scoreFields.reduce((acc: ObjectWithFieldsType, scoreField) => {
                  const { options, fieldName, passingScore } = scoreField
                  let points = 0
                  options.forEach((option) => {
                    if (getFieldValue(currentSignupData, option.conditionalField) === option.conditionalValue) {
                      points += option.points
                    }
                  })
                  return {
                    ...acc,
                    [fieldName]: points >= passingScore ? "true" : "false"
                  }
                }, {})

                actionButtonAction(
                  {
                    ...signupData,
                    [uid]: { ...currentStepSignupData, ...scoreFieldsData }
                  },
                  actionButton
                )
              }
            }
          },
          disabled: disableButton || continueButtonDisabled,
          sx: !prevStep && actionButtons.length === 1 && !isMobile ? { width: "100%" } : {}
        }

        return <ActionButton key={actionButtonProps.displayText} {...actionButtonProps} />
      })
  }

  return (
    <>
      {displayBackButton()}
      {displayButtons()}
      {/* refactor this*/}
      {signupError && <FormHelperText error>{signupError}</FormHelperText>}
      <SkipButton canSkip={canSkip} slices={slices} actionButtonAction={actionButtonAction} />
      {isLoadingCreateNewUser && <Loading />}
      {displayErrorModal()}
    </>
  )
}

export default ActionButtons
