import styled from "@emotion/styled"
import { blackColor, whiteColor } from "styles"

export const LanguageSelectorStyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: none;
  align-items: center;
  justify-content: end;
  margin-left: auto;
  z-index: 1;
`

export const SelectorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`

export const OptionsContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  width: auto;
  top: 32px;
  right: -4px;
  display: flex;
  align-items: start;
  flex-direction: column;
  background-color: ${({ isOpen }) => (isOpen ? "#f8f8f8" : "transparent")};
  border-radius: 10px;
  padding: 15px;
  z-index: 1;
`

export const Option = styled.div`
  margin-bottom: 8px;
  margin-left: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
`

export const selectedLanguageStyle = {
  ml: 1,
  color: whiteColor,
  fontSize: "12px"
}

export const languageNameStyle = {
  ml: "5px",
  fontWeight: 300,
  fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
  color: blackColor,
  fontSize: "14px"
}
