/* eslint-disable @typescript-eslint/naming-convention */
import React from "react"
import Router from "next/router"
import Image from "next/image"
import { RichTextField } from "@prismicio/types"
import { Elements } from "prismic-reactjs"
import {
  ActionButtonType,
  AddressSearchfieldType,
  AllFieldsType,
  AllPrismicFieldsType,
  CardGroupType,
  CheckboxType,
  DatefieldType,
  DropdownType,
  EmailTextfieldType,
  FieldVariation,
  HiddenFieldType,
  InfoBoxType,
  ModalInfoType,
  PlatformInfoType,
  PasswordTextfieldType,
  PhoneTextfieldType,
  PrismicActionButtonType,
  PrismicHiddenFieldType,
  PrismicInfoBoxType,
  PrismicModalInfoType,
  PrismicPlatformInfoType,
  PrismicScoreFieldType,
  RadioGroupType,
  ScoreFieldType,
  SearchfieldType,
  SliceNameType,
  TextfieldType,
  SocialButtonType,
  PrismicSocialButtonType,
  RichText,
  CardGroupOptionType,
  ActionButtonActionType
} from "components"
import { ListTextWithIcon } from "components/text/TextWithImages.styled"
import { ObjectWithFieldsType, PlatformBackendType, SignupDataType, SliceType } from "utils"

export const COUNTRY_OF_RESIDENCE_FIELD_NAME = "country_of_residence"
export const NATIONALITY_FIELD_NAME = "nationality"
export const EMPLOYMENT_INDUSTRY_FIELD_NAME = "employment_industry"
export const EMPLOYMENT_STATUS_FIELD_NAME = "employment_status"
export const JOB_ROLE_FIELD_NAME = "job_role"
export const CURRENT_STEP_PART = "current_step_part"
export const CURRENT_STEP_NUMBER_OF_PARTS = "current_step_number_of_parts"
export const LIVE = "Live"
export const LIVE_APPROVED = "live_approved" // can be removed
export const DEMO = "Demo"
export const PENDING = "pending"
export const FULL = "Full"
export const UPGRADE = "upgrade"
export const UPGRADE_APPROVED = "upgrade_approved" // can be removed
export const OK = "OK"
export const SUCCESS = "success"
export const ERROR = "ERROR"
export const MT4 = "MT4"
export const CLOUDTRADE = "CloudTrade"
export const CFD = "CFD"
export const SPREAD = "Spread"
export const WITHDRAW = "Withdraw"
export const DEPOSIT = "Deposit"
export const APPLICATION_FAILED_MESSAGE =
  "There has been a problem with your application. Please contact support for further assistance."

export const flattenSignupData = (data: ObjectWithFieldsType) => {
  const flattenedData: ObjectWithFieldsType = {}

  function flatten(currentObj, parentKey = "") {
    for (const key in currentObj) {
      if (!currentObj.hasOwnProperty(key)) continue

      const newKey = parentKey ? `${parentKey}.${key}` : key
      if (typeof currentObj[key] == "object" && currentObj[key] !== null) {
        flatten(currentObj[key], newKey)
      } else {
        flattenedData[key] = currentObj[key]
      }
    }
  }

  flatten(data)
  return flattenedData
}

export const getFieldValue = (data: ObjectWithFieldsType, fieldName: string): string => {
  const flattenedData = flattenSignupData(data)
  return flattenedData[fieldName] || ""
}

export const flattenPrismicField = (slice: AllPrismicFieldsType) => {
  // Dropdown
  if (slice.variation === FieldVariation.Dropdown) {
    const res = {} as DropdownType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.placeholder = slice.primary.placeholder
    res.fieldName = slice.primary.fieldName
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.options = slice.items.map((option) => ({
      value: option.value,
      label: option.label
    }))
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Textfield
  if (slice.variation === FieldVariation.Textfield) {
    const res = {} as TextfieldType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.placeholder = slice.primary.placeholder
    res.fieldName = slice.primary.fieldName
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.textAlign = slice.primary.textAlign
    res.autoFocus = slice.primary.autoFocus
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Email Textfield
  if (slice.variation === FieldVariation.EmailTextfield) {
    const res = {} as EmailTextfieldType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.placeholder = slice.primary.placeholder
    res.fieldName = slice.primary.fieldName
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.checkValidation = slice.primary.checkValidation
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Password Textfield
  if (slice.variation === FieldVariation.PasswordTextfield) {
    const res = {} as PasswordTextfieldType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.placeholder = slice.primary.placeholder
    res.fieldName = slice.primary.fieldName
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.displayConfirmPassword = slice.primary.displayConfirmPassword
    res.showRules = slice.primary.showRules
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.minCharacters = slice.primary.minCharacters
    res.maxCharacters = slice.primary.maxCharacters
    res.lowerAndUpperCase = slice.primary.lowerAndUpperCase
    res.numberAndSymbol = slice.primary.numberAndSymbol
    res.symbols = slice.primary.symbols
    res.noSpecialCharacters = slice.primary.noSpecialCharacters
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Phone Textfield
  if (slice.variation === FieldVariation.PhoneTextfield) {
    const res = {} as PhoneTextfieldType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.placeholder = slice.primary.placeholder
    res.countrySelectorPlaceholder = slice.primary.countrySelectorPlaceholder
    res.countrySearchPlaceholder = slice.primary.countrySearchPlaceholder
    res.fieldName = slice.primary.fieldName
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Checkbox
  if (slice.variation === FieldVariation.Checkbox) {
    const res = {} as CheckboxType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.fieldName = slice.primary.fieldName
    res.value = slice.primary.value
    res.label = slice.primary.label
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.content = slice.items.map((item) => ({
      text: item.text,
      clickValue: item.clickValue
    }))
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Radio Group
  if (slice.variation === FieldVariation.RadioGroup) {
    const res = {} as RadioGroupType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.fieldName = slice.primary.fieldName
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.options = slice.items.map((option) => ({
      value: option.value,
      label: option.label
    }))
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Card Group
  if (slice.variation === FieldVariation.CardGroup) {
    const res = {} as CardGroupType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.fieldName = slice.primary.fieldName
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.allowMultipleSelection = slice.primary.allowMultipleSelection
    res.options = slice.items.map((item) => {
      const option: CardGroupOptionType = {
        value: item.value,
        title: item.title,
        description: item.description,
        textfieldOnClick: item.textfieldOnClick,
        showConditionalField: item.showConditionalField,
        showConditionalValue: item.showConditionalValue
      }
      if (item?.originalDescription) {
        option.originalDescription = item.originalDescription
      }
      return option
    })
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Datefield
  if (slice.variation === FieldVariation.Datefield) {
    const res = {} as DatefieldType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.fieldName = slice.primary.fieldName
    res.placeholder = slice.primary.placeholder
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Searchfield
  if (slice.variation === FieldVariation.Searchfield) {
    const res = {} as SearchfieldType
    res.title = slice.primary.title
    res.description = slice.primary.description
    res.required = slice.primary.required
    res.placeholder = slice.primary.placeholder
    res.fieldName = slice.primary.fieldName
    res.showConditionalField = slice.primary.showConditionalField
    res.showConditionalValue = slice.primary.showConditionalValue
    res.api = slice.primary.api
    res.options = slice.items.map((option) => ({
      value: option.value,
      label: option.label
    }))
    res.defaultValue = slice.primary.defaultValue
    res.hasEditIcon = slice.primary.hasEditIcon
    res.variation = slice.variation
    res.sliceType = SliceNameType.Field
    return res
  }

  // Address Searchfield (is not any of the other variations)
  const res = {} as AddressSearchfieldType
  res.title = slice.primary.title
  res.description = slice.primary.description
  res.required = slice.primary.required
  res.placeholder = slice.primary.placeholder
  res.fieldName = slice.primary.fieldName
  res.showConditionalField = slice.primary.showConditionalField
  res.showConditionalValue = slice.primary.showConditionalValue
  res.defaultValue = slice.primary.defaultValue
  res.hasEditIcon = slice.primary.hasEditIcon
  res.variation = slice.variation
  res.sliceType = SliceNameType.Field
  res.manualEntryText = slice.primary.manualEntryText
  res.searchAddressText = slice.primary.searchAddressText
  res.searchForAnotherText = slice.primary.searchForAnotherText
  res.cantFindText = slice.primary.cantFindText
  res.line1Placeholder = slice.primary.line1Placeholder
  res.line2Placeholder = slice.primary.line2Placeholder
  res.cityPlaceholder = slice.primary.cityPlaceholder
  res.postcodePlaceholder = slice.primary.postcodePlaceholder
  return res
}

export const flattenPrismicModal = (slice: PrismicModalInfoType) => {
  const res = {} as ModalInfoType
  res.icon = slice.primary.icon
  res.title = slice.primary.title
  res.description = slice.primary.description
  res.displayOnPageLoad = slice.primary.displayOnPageLoad
  res.showConditionalField = slice.primary.showConditionalField
  res.showConditionalValue = slice.primary.showConditionalValue
  res.options = slice.items.map((option) => ({
    conditionalDescription: option.conditionalDescription,
    conditionalField: option.conditionalField,
    conditionalValue: option.conditionalValue
  }))
  res.buttonText = slice.primary.buttonText
  res.secondButtonText = slice.primary.secondButtonText
  return res
}

export const flattenPrismicPlatformInfo = (slice: PrismicPlatformInfoType) => {
  const res = {} as PlatformInfoType
  res.title = slice.primary.title
  res.description = slice.primary.description
  res.image = slice.primary.image
  res.backgroundTopColor = slice.primary.backgroundTopColor
  res.backgroundBottomColor = slice.primary.backgroundBottomColor
  res.textColor = slice.primary.textColor
  res.summarisedTitle = slice.primary.summarisedTitle
  res.summarisedDescription = slice.primary.summarisedDescription
  res.fieldName = slice.primary.fieldName
  res.value = slice.primary.value
  res.changeText = slice.primary.changeText
  res.defaultOrder = slice.primary.defaultOrder
  res.platformType = slice.primary.platformType
  res.derivativeType = slice.primary.derivativeType
  res.options = slice.items.map((option) => ({
    order: option.order,
    conditionalField: option.conditionalField,
    conditionalValue: option.conditionalValue
  }))
  res.id = slice.id
  res.backend_id = slice.backend_id
  return res
}

export const flattenPrismicActionButton = (button: PrismicActionButtonType) => {
  const res = {} as ActionButtonType
  res.displayText = button.primary.displayText
  res.actionType = button.primary.actionType
  res.variant = button.primary.variant
  res.defaultRedirect = button.primary.defaultRedirect
  res.disableConditionalField = button.primary.disableConditionalField
  res.disableConditionalValue = button.primary.disableConditionalValue
  res.redirectOptions = button.items.map((option) => ({
    redirectTo: option.redirectTo,
    conditionalField: option.conditionalField,
    conditionalValue: option.conditionalValue
  }))
  return res
}

export const flattenPrismicSocialButton = (button: PrismicSocialButtonType) => {
  const res = {} as SocialButtonType
  res.icon = button.primary.icon
  res.name = button.primary.name
  res.type = button.primary.type
  res.display = button.primary.display
  return res
}

export const flattenPrismicHiddenField = (field: PrismicHiddenFieldType) => {
  const res = {} as HiddenFieldType
  res.fieldName = field.primary.fieldName
  res.defaultValue = field.primary.defaultValue
  res.options = field.items.map((option) => ({
    value: option.value,
    conditionalField: option.conditionalField,
    conditionalValue: option.conditionalValue
  }))
  return res
}

export const flattenPrismicScoreField = (field: PrismicScoreFieldType) => {
  const res = {} as ScoreFieldType
  res.fieldName = field.primary.fieldName
  res.passingScore = field.primary.passingScore
  res.options = field.items.map((option) => ({
    points: option.points,
    conditionalField: option.conditionalField,
    conditionalValue: option.conditionalValue
  }))
  return res
}

export const flattenPrismicInfoBox = (infoBox: PrismicInfoBoxType) => {
  const res = {} as InfoBoxType
  res.defaultTitle = infoBox.primary.defaultTitle
  res.defaultTitleColor = infoBox.primary.defaultTitleColor
  res.defaultText = infoBox.primary.defaultText
  res.defaultTextColor = infoBox.primary.defaultTextColor
  res.defaultBackgroundColor = infoBox.primary.defaultBackgroundColor
  res.defaultIcon = infoBox.primary.defaultIcon
  res.defaultIconAlign = infoBox.primary.defaultIconAlign
  res.defaultHasOpacity = infoBox.primary.defaultHasOpacity
  res.displayAtBottom = infoBox.primary.displayAtBottom
  res.fieldName = infoBox.primary.fieldName
  res.fieldValue = infoBox.primary.fieldValue
  res.options = infoBox.items.map((option) => ({
    conditionalField: option.conditionalField,
    conditionalValue: option.conditionalValue,
    title: option.title,
    titleColor: option.titleColor,
    text: option.text,
    textColor: option.textColor,
    backgroundColor: option.backgroundColor,
    icon: option.icon,
    iconAlign: option.iconAlign,
    hasOpacity: option.hasOpacity
  }))
  res.sliceType = SliceNameType.InfoBox
  return res
}

export const getDayMonthYear = (date: Date) => {
  const d = new Date(date)
  let day = `${d.getDate()}`
  let month = `${d.getMonth() + 1}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return { day, month, year }
}

export const formatDate = (date: Date | null, separator = "-") => {
  if (!date) {
    return ""
  }

  const { day, month, year } = getDayMonthYear(date)

  if (separator === "-") {
    return [year, month, day].join("-")
  } else {
    return [day, month, year].join(separator)
  }
}

export const displayDateInText = (date: Date) => {
  const day = `${date.getDate()}`
  const month = date.toLocaleString("default", { month: "long" })
  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}

export const getSocialDate = (birthdays: { date: { day: number; month: number; year: number } }[]) => {
  if (birthdays && birthdays.length > 0 && birthdays[0].date) {
    const { day, month, year } = birthdays[0].date
    const dayStr = `0${day}`.slice(-2)
    const monthStr = `0${month}`.slice(-2)
    return `${year}-${monthStr}-${dayStr}`
  }
  return ""
}

export const getDateWithTime = (date: Date) => {
  const day = `0${date.getDate()}`.slice(-2)
  const month = `0${date.getMonth() + 1}`.slice(-2)
  const year = date.getFullYear()
  const hour = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
}

export const compareValidFieldsAndValues = (fieldsStr: string, valuesStr: string, signupData: SignupDataType) => {
  if (!fieldsStr || !valuesStr) {
    return true
  }

  let valid = true
  // Loop through all the conditional fields and compare with their respective values
  // Each field will have 2 lists: include and exclude
  // Include is the list that checks if the value is in it
  // Exclude is the list that checks if the value is not in it
  // The exclude list is populated but checking the values that start with '!'
  const fields = fieldsStr.replaceAll(" ", "").split(",")
  const values = valuesStr.replaceAll(" ", "").split(",")
  const fieldVisibility = fields.reduce(
    (acc: { [field: string]: { include: string[]; exclude: string[] } }, field, index) => {
      if (index < values.length) {
        if (acc[field]) {
          if (values[index].startsWith("!")) {
            acc[field].exclude.push(values[index].slice(1))
          } else {
            acc[field].include.push(values[index])
          }
        } else {
          if (values[index].startsWith("!")) {
            acc[field] = {
              exclude: [values[index].slice(1)],
              include: []
            }
          } else {
            acc[field] = {
              include: [values[index]],
              exclude: []
            }
          }
        }
      }
      return acc
    },
    {}
  )

  Object.keys(fieldVisibility).forEach((field) => {
    const fieldValue = getFieldValue(signupData, field)
    const { exclude, include } = fieldVisibility[field]
    if (exclude.length > 0 && (exclude.includes(fieldValue) || !fieldValue)) {
      valid = false
    } else if (exclude.length === 0 && !include.includes(fieldValue)) {
      valid = false
    }
  })
  return valid
}

export const disableActionButton = (fields: string, values: string, signupData: SignupDataType) => {
  if (!fields || !values) {
    return false
  }

  return compareValidFieldsAndValues(fields, values, signupData)
}

export const showField = (field: AllFieldsType, signupData: SignupDataType) => {
  const { showConditionalField, showConditionalValue } = field

  return compareValidFieldsAndValues(showConditionalField, showConditionalValue, signupData)
}

export const shouldAddConfirmPassword = (field: AllFieldsType) => {
  return field.variation === FieldVariation.PasswordTextfield && field.displayConfirmPassword
}

export const getFieldsAndInfoBoxes = (slices: SliceType[]) => {
  const fields = slices.filter((slice) =>
    [SliceNameType.Field as string, SliceNameType.InfoBox as string].includes(slice.slice_type)
  )
  const fieldsAndInfoBoxes = fields.reduce((acc: (AllFieldsType | InfoBoxType)[], field) => {
    if (field.slice_type === SliceNameType.InfoBox) {
      const flattenInfoBox = flattenPrismicInfoBox(field)
      acc.push(flattenInfoBox)
    } else {
      const flattenField = flattenPrismicField(field)

      // Add list of fields which visibility depend on this field
      const controlledFieldsDisplay = fields.reduce((thisAcc: string[], _field) => {
        const thisFlattenedField = flattenPrismicField(_field)
        if (
          thisFlattenedField.showConditionalField?.includes(flattenField.fieldName) &&
          !thisAcc.includes(thisFlattenedField.fieldName)
        ) {
          const confirmPassword: string[] = shouldAddConfirmPassword(thisFlattenedField)
            ? [`${thisFlattenedField.fieldName}_confirm`]
            : []
          return [...thisAcc, thisFlattenedField.fieldName, ...confirmPassword]
        }
        return thisAcc
      }, [])
      flattenField.controlledFieldsDisplay = controlledFieldsDisplay

      acc.push(flattenField)
      // Add confirm password if it should be displayed
      if (shouldAddConfirmPassword(flattenField)) {
        acc.push({
          ...flattenField,
          placeholder: "Confirm your password",
          fieldName: `${flattenField.fieldName}_confirm`,
          variation: FieldVariation.ConfirmPasswordTextfield,
          passwordFieldToCompare: flattenField.fieldName
        })
      }
    }
    return acc
  }, [])

  return {
    fieldsAndInfoBoxes,
    fields: fieldsAndInfoBoxes.filter((el) => el.sliceType === SliceNameType.Field) as AllFieldsType[],
    infoBoxes: fieldsAndInfoBoxes.filter((el) => el.sliceType === SliceNameType.InfoBox) as InfoBoxType[]
  }
}

export const getCheckboxes = (slices: SliceType[]) => {
  const checkboxes = slices.filter(
    (slice) => slice.slice_type === SliceNameType.Field && slice.variation === FieldVariation.Checkbox
  )
  return checkboxes.map((checkbox) => flattenPrismicField(checkbox)) as CheckboxType[]
}

export const getInfoModals = (slices: SliceType[]) => {
  const modals = slices.filter((slice) => slice.slice_type === SliceNameType.Modal)
  return modals.map((modal) => flattenPrismicModal(modal))
}

export const getInfoModal = (slices: SliceType[]) => {
  const modal = slices.find((slice) => slice.slice_type === SliceNameType.Modal)
  if (modal) {
    return flattenPrismicModal(modal)
  }
  return null
}

export const getPlatformsInfo = (
  slices: SliceType[],
  cxdRef: string,
  brand: string,
  signupData: SignupDataType,
  platformBackend: PlatformBackendType["backends"],
  isAffiliateMt4Group: boolean
) => {
  const platforms: PrismicPlatformInfoType[] = slices.filter((slice) => slice.slice_type === SliceNameType.PlatformInfo)

  let finalPlatforms: PrismicPlatformInfoType[] = new Array(platforms.length)
  if (isAffiliateMt4Group) {
    finalPlatforms = [platforms.find((platform) => platform.primary.value === MT4)]
  } else {
    // EACH BACKEND, PULL OUT THE platform_type
    platformBackend.forEach((backend) => {
      const { ranking, backend_id, id, platform_type, derivative_type } = backend

      const platform = platforms.find(
        (pl) =>
          pl.primary.platformType.toLocaleLowerCase() === platform_type.toLocaleLowerCase() &&
          pl.primary.derivativeType.toLocaleLowerCase() === derivative_type.toLocaleLowerCase()
      )
      if (platform) {
        finalPlatforms[ranking - 1] = {
          ...platform,
          backend_id,
          id: id.toString()
        }
      }
    })
  }

  if (!cxdRef && signupData?.welcome?.utm_source === "tradingview") {
    finalPlatforms = finalPlatforms.filter((platform) => platform.primary.platformType !== "MT4")
  }

  return finalPlatforms.filter((p) => !!p).map((platform) => flattenPrismicPlatformInfo(platform))
}

export const getHiddenFields = (slices: SliceType[]) => {
  return slices
    .filter((slice) => slice.slice_type === SliceNameType.HiddenField)
    .map((el) => flattenPrismicHiddenField(el))
}

export const getScoreFields = (slices: SliceType[]) => {
  return slices
    .filter((slice) => slice.slice_type === SliceNameType.ScoreField)
    .map((el) => flattenPrismicScoreField(el))
}

export const getActionButtons = (slices: SliceType[]) => {
  const buttons = slices.filter((slice) => slice.slice_type === SliceNameType.ActionButton)
  return buttons.map((button) => flattenPrismicActionButton(button))
}

export const getButtonByAction = (actionButtons: ActionButtonType[], actionType: ActionButtonActionType) => {
  return actionButtons.find((btn) => btn.actionType === actionType)
}

export const getSocialButtons = (slices: SliceType[]) => {
  const buttons = slices.filter((slice) => slice.slice_type === SliceNameType.SocialButton)
  return buttons.map((button) => flattenPrismicSocialButton(button))
}

export const closedModalData = {
  open: false,
  handleClose: () => {}
}

export const getUrlParam = (param: string) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(param) || ""
}

export const removeUrlParam = (param: string) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  urlParams.delete(param)
  window.history.replaceState({}, "", window.location.pathname)
}

export const setUrlParam = (param: string, value: string) => {
  const currentURL = new URL(window.location.href)
  currentURL.searchParams.set(param, value)
  window.history.replaceState({}, "", currentURL.toString())
}

export const goToPage = (page: string) => {
  if (window) {
    const queryString = window.location.search
    if (page === "/account/withdraw-success") {
      Router.push(`${page}`)
    } else {
      Router.push(`${page}${queryString}`)
    }
  }
}

export const goToHome = () => {
  window.open(process.env.NEXT_PUBLIC_HOST_NAME, "_self")
}

export const goToLogin = () => {
  Router.push(`${process.env.NEXT_PUBLIC_HOST_NAME}login`)
}

export const accountTypeDisplayName = (param: string) => {
  switch (param) {
    case "Trade Nation UK":
    case "Trade Nation AU":
    case "Trade Nation BS":
    case "Trade Nation ZA":
      return "Trade Nation"
    case "Trade Nation UK Practice":
    case "Trade Nation AU Practice":
    case "Trade Nation BS Practice":
    case "Trade Nation ZA Practice":
      return "Trade Nation Practice"
    case "MT4 Variable Spreads Demo":
      return "MT4 Variable Spreads Practice"
    case "MT4 Variable Spreads":
    case "MT4":
      return "MT4 Variable Spreads"
    default:
      return "Trade Nation"
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatText = (text: any, signupData?: SignupDataType) => {
  if (!signupData) {
    return text
  }

  return text.map((content) => {
    const bits = content?.text?.split(/[{}]+/).map((bit: string) => {
      const fieldValue = getFieldValue(signupData, bit)
      return fieldValue || bit
    })
    if (bits) {
      return {
        ...content,
        text: bits.join("")
      }
    }
    return content
  }) as RichTextField
}

// LOCAL STORAGE
export const LOCAL_STORAGE_SIGNUP_DATA = "signup_data"
export const LOCAL_STORAGE_ACCESS_TOKEN = "access_token"
export const LOCAL_STORAGE_LAST_PAGE = "lastPageUserWasOn"
export const LOCAL_STORAGE_2FA = "two_factor_authentication" // can be removed
export const LOCAL_STORAGE_PLATFORM_BACKEND = "platform_backend"
export const LOCAL_STORAGE_PLATFORM_CURRENCIES = "platform_currencies"
export const LOCAL_STORAGE_USER_INFO = "user_info"
export const LOCAL_STORAGE_USER_INFO_TS = "user_info_ts"
export const LOCAL_STORAGE_USER_INFO_FORCE_UPDATE = "user_info_forceupdate"
export const LOCAL_STORAGE_SANITISED_USER_INFO = "sanitised_user_info"
export const LOCAL_STORAGE_ACTIVE_INCOMPLETE_APPLICATION = "active_incomplete_application"
export const LOCAL_STORAGE_AUTH_STATER = "authState"
export const LOCAL_STORAGE_AUTH_NONCE = "authNonce"
export const LOCAL_STORAGE_EXPIRED_TIME = "expiredTime"
export const LOCAL_STORAGE_UTM = "utm"
export const LOCAL_STORAGE_UTM_EXPIRY = "utm_expiry_time"
export const LOCAL_STORAGE_CXD = "cxd"
export const LOCAL_STORAGE_CXD_EXPIRY = "cxd_expiry_time"
export const LOCAL_STORAGE_REFERRAL_CODE = "referral_code"
export const USER_ALREADY_REGISTERED_USING_NORMAL_SIGNUP = "userAlreadyRegisterUsingNormalSignup"
export const LOCAL_STORAGE_NOT_SHOW_TRADING_VIEW_PROMPT = "not_show_trading_view_prompt"
export const LOCAL_STORAGE_SELECTED_MT4_ACCOUNT = "selected_mt4_account"

export const setLocalStorage = (item: string, data: object | string) => {
  // To prevent server side error
  if (typeof window !== "undefined") {
    localStorage.setItem(item, typeof data === "string" ? data : JSON.stringify(data))
  }
}

export const getLocalStorage = (item: string, allowParsing = true) => {
  // To prevent server side error
  if (typeof window !== "undefined") {
    const listToReturnItemValue = [
      LOCAL_STORAGE_ACCESS_TOKEN,
      LOCAL_STORAGE_LAST_PAGE,
      LOCAL_STORAGE_EXPIRED_TIME,
      LOCAL_STORAGE_CXD,
      LOCAL_STORAGE_CXD_EXPIRY,
      LOCAL_STORAGE_UTM,
      LOCAL_STORAGE_UTM_EXPIRY,
      LOCAL_STORAGE_REFERRAL_CODE,
      LOCAL_STORAGE_ACTIVE_INCOMPLETE_APPLICATION,
      LOCAL_STORAGE_AUTH_STATER,
      LOCAL_STORAGE_AUTH_NONCE,
      LOCAL_STORAGE_SANITISED_USER_INFO,
      USER_ALREADY_REGISTERED_USING_NORMAL_SIGNUP
    ]
    const data = localStorage.getItem(item)
    if (data) {
      if (listToReturnItemValue.includes(item) || !allowParsing) {
        return data
      }

      return JSON.parse(data)
    }

    return null
  }

  return null
}

export const clearLocalStorage = (item: string) => {
  // To prevent server side error
  if (typeof window !== "undefined") {
    localStorage.removeItem(item)
  }
}

export const clearAllSignupData = () => {
  localStorage.removeItem("signup_data")
  localStorage.removeItem("lastPageUserWasOn")
}

export const clearSessionStorage = (item: string) => {
  // To prevent server side error
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem(item)
  }
}

// Two-Factor Authentication
export const clear2faCode = () => Array(6).fill("")

export const displayTextWithImages = (description: RichTextField) => {
  const fullDescription = [...description]
  return fullDescription.map((line, index) => {
    const key = `${JSON.stringify(line)}-${index}`
    if (
      line.type === "image" &&
      index < fullDescription.length - 1 &&
      fullDescription[index + 1]?.type === "list-item"
    ) {
      const text = fullDescription.splice(index + 1, 1)
      return (
        <ListTextWithIcon key={key}>
          <Image
            src={line.url.replace("?auto=compress,format", "")}
            alt={line.alt || "Icon"}
            width={line.dimensions.width}
            height={line.dimensions.height}
          />
          <RichText field={text as RichTextField} displayEmptyText />
        </ListTextWithIcon>
      )
    }
    return <RichText key={key} field={[line]} displayEmptyText />
  })
}

export const getRichText = (content: { text: string; type: Elements }[]) => {
  return content.map((c) => ({
    spans: [],
    text: c.text,
    type: c.type
  })) as RichTextField
}

export const hexToRgb = (hex: string) => {
  const red = parseInt(hex.substring(1, 3), 16)
  const green = parseInt(hex.substring(3, 5), 16)
  const blue = parseInt(hex.substring(5, 7), 16)
  return `${red}, ${green}, ${blue}`
}

export const getDescriptionText = (value: RichTextField) => {
  if ("text" in value[0]) {
    return value[0].text
  }
  return ""
}

export const validObject = (obj: object | null | undefined) => {
  return obj && Object.keys(obj).length > 0
}

export const isDevEnvironment = () => {
  return !["STAGE", "PROD", "DEV"].includes(process.env.NEXT_PUBLIC_ENV)
}

export const isFinEnvironment = () => {
  return process.env.NEXT_PUBLIC_ENV === "DEV"
}

export const isDevOrFinEnvironment = () => {
  return !["STAGE", "PROD"].includes(process.env.NEXT_PUBLIC_ENV)
}

export const isStageEnvironment = () => {
  return process.env.NEXT_PUBLIC_ENV === "STAGE"
}

export const isProdEnvironment = () => {
  return process.env.NEXT_PUBLIC_ENV === "PROD"
}

export const redirectAfterLogin = () => {
  const cachedLastLocation = sessionStorage.getItem("lastLocation")
  if (cachedLastLocation) {
    goToPage(cachedLastLocation)
  } else {
    goToPage("/account/overview")
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const shouldAllowTranslation = (jurisdiction) => {
  return jurisdiction === "FSA" || jurisdiction === "SCB"
}

export const noAccountsSubHeaderText = getRichText([
  {
    text: "You don't have any active accounts yet",
    type: Elements.heading2
  }
])

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isPaymentPage(asPath: string) {
  return ["payments", "/deposit"].some((path) => asPath.includes(path)) && !asPath.includes("/deposit-success")
}

export const checkTokenIsStillValid = () => {
  const authToken = localStorage.getItem("access_token") || window.location.href.includes("access_token")

  if (!authToken) {
    goToPage("/signup/welcome")
  }

  const checkTokenValidity = () => {
    let tokenIsValid = true
    const expirationTime = localStorage.getItem("token_expiration_time")

    if (expirationTime) {
      const currentTime = new Date().getTime()

      if (currentTime < Number(expirationTime)) {
        tokenIsValid = true
      } else {
        tokenIsValid = false
      }
    } else {
      tokenIsValid = false
    }
    return tokenIsValid
  }
  const res = checkTokenValidity()
  return res
}

export const getLang = () => {
  let lang = "en-bs"
  if (typeof localStorage !== "undefined") {
    const userInfoLocalStorage = localStorage.getItem(LOCAL_STORAGE_USER_INFO)
    const parsedUserInfo = JSON.parse(userInfoLocalStorage)
    if (parsedUserInfo?.app_metadata?.clients?.length > 0) {
      const { brand } = parsedUserInfo.app_metadata.clients[0]
      if (brand) {
        lang = `en-${brand === "eu" ? "gb" : brand}`
      }
    }
  }

  return lang
}

export function shuffleArray<T>(array: T[]): T[] {
  const shuffledArray = [...array]
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1))

    ;[shuffledArray[i], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[i]]
  }
  return shuffledArray
}

export const getParamsFromString = (str: string) => {
  const beginningOfLink = str.indexOf("?link=") + 6
  const endOfLink = str.indexOf("&em=")
  const lnk = str.slice(beginningOfLink, endOfLink)

  const beginningOfEmail = str.indexOf("&em=") + 4
  const endOfEmail = str.length
  const em = str.slice(beginningOfEmail, endOfEmail)

  const beginningOfAcctId = str.indexOf("?aid=") + 5
  const endOfAcctId = str.indexOf("&cid=")
  const acctId = str.slice(beginningOfAcctId, endOfAcctId)

  return {
    platform: "platform.tradenation.com",
    link: lnk,
    accId: acctId,
    email: em
  }
}
