import React, { useState } from "react"
import { RichTextField } from "@prismicio/types"
import { Elements, RichTextBlock } from "prismic-reactjs"
import { AllFieldsType, Modal, ModalInfoType } from "components"
import {
  AddAccountModalType,
  closedModalData,
  CLOUDTRADE,
  DEMO,
  getRichText,
  IconType,
  isRichTextValid,
  LIVE,
  ModalType,
  MT4,
  PrismicSignStepType,
  showField,
  SignupDataType
} from "utils"

import { useSignupData } from "hooks"

const closedAddAccountModalData = {
  ...closedModalData,
  platform: "",
  listOfBrands: []
}

export const useAddAccountModal = () => {
  const [addAccountModalData, setAddAccountModalData] = useState<AddAccountModalType>(closedAddAccountModalData)

  const openAddAccountModal = (
    title: string,
    text: string,
    derivative: string,
    mt4Data?: PrismicSignStepType,
    isLiveAccount?: boolean
  ) => {
    setAddAccountModalData({
      open: true,
      title:
        title &&
        getRichText([
          {
            text: title,
            type: Elements.heading2
          }
        ]),
      text:
        text &&
        getRichText([
          {
            text,
            type: Elements.paragraph
          }
        ]),
      handleClose: () => setAddAccountModalData(closedAddAccountModalData),
      platform: mt4Data ? MT4 : CLOUDTRADE,
      mt4Data,
      type: isLiveAccount ? LIVE : DEMO,
      derivative
    })
  }

  return {
    addAccountModalData,
    openAddAccountModal
  }
}

export const useModal = () => {
  const [modalData, setModalData] = useState<ModalType>(closedModalData)

  const closeModal = () => {
    setModalData(closedModalData)
  }

  const openModal = ({
    icon,
    title,
    text,
    handleClose,
    buttons,
    menu,
    hasCloseIcon,
    textAlign,
    children,
    zIndex
  }: Omit<ModalType, "open">) => {
    setModalData({
      icon,
      title,
      text,
      open: true,
      buttons,
      menu,
      hasCloseIcon,
      textAlign,
      handleClose: () => {
        closeModal()
        if (handleClose) {
          handleClose()
        }
      },
      children,
      zIndex
    })
  }

  const displayModal = (
    titleMargin = "0",
    textAlign = "center",
    textFontSize = "17px",
    textLineHeight = "25px",
    indentedList = false
  ) => {
    if (modalData.open) {
      return (
        <Modal
          {...modalData}
          titleMargin={titleMargin}
          textAlign={textAlign}
          textFontSize={textFontSize}
          textLineHeight={textLineHeight}
          indentedList={indentedList}
        />
      )
    }
    return null
  }

  return {
    modalData,
    openModal,
    closeModal,
    displayModal
  }
}

export const useFieldConditionalModal = () => {
  const { updateCurrentStepSignupData, currentStepSignupData, currentStepNextData, updateCurrentStepNextData } =
    useSignupData()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [icon, setIcon] = useState<IconType>()
  const [title, setTitle] = useState<RichTextField>()
  const [description, setDescription] = useState<RichTextField>()
  const [btnText, setBtnText] = useState<string>("Continue")

  const updateDescription = (modalProps: ModalInfoType, signupData: SignupDataType) => {
    // Get the correct description
    const { options, buttonText } = modalProps
    let newDescription = modalProps.description
    options?.forEach((option) => {
      const { conditionalField, conditionalValue, conditionalDescription } = option
      if (
        showField(
          {
            showConditionalField: conditionalField,
            showConditionalValue: conditionalValue
          } as AllFieldsType,
          signupData
        )
      ) {
        newDescription = conditionalDescription
      }
    })

    // If there's no description yet, it populates it
    // and doesn't open the modal
    if (!description) {
      setDescription(newDescription)
    } else {
      // Check if the new description is different from the current
      // Update it only if it's different
      let different = false
      if (description.length !== newDescription?.length) {
        different = true
      } else {
        newDescription?.forEach((block: RichTextBlock, index) => {
          if (block.text !== (description[index] as RichTextBlock).text) {
            different = true
          }
        })
      }
      if (different) {
        setDescription(newDescription)
        if (options.length > 0 && isRichTextValid(newDescription)) {
          setModalIsOpen(true)
        }
      }
      if (buttonText) {
        setBtnText(buttonText)
      }
    }
  }

  const initializeModal = (modalProps: ModalInfoType, signupData: SignupDataType) => {
    const { icon: newIcon, title: newTitle, description: newDescription, displayOnPageLoad } = modalProps
    setIcon(newIcon)
    setTitle(newTitle)
    if (isRichTextValid(newDescription)) {
      updateDescription(modalProps, signupData)
    }

    if (displayOnPageLoad) {
      setModalIsOpen(true)
    }
  }

  const updateModalStatus = (modalProps: ModalInfoType, signupData: SignupDataType) => {
    updateDescription(modalProps, signupData)
  }

  const closeModal = (skipUpdateStep = true) => {
    if (!skipUpdateStep) {
      updateCurrentStepSignupData({
        ...currentStepSignupData,
        ...currentStepNextData
      })
    } else {
      setDescription(null)
    }
    updateCurrentStepNextData({})
    setModalIsOpen(false)
  }

  const displayModal = () => {
    if (modalIsOpen) {
      return (
        <Modal
          open
          handleClose={closeModal}
          hasCloseIcon
          icon={icon}
          title={title}
          text={description}
          buttons={[
            {
              text: btnText,
              onClick: () => closeModal(false)
            }
          ]}
        />
      )
    }
    return null
  }

  return {
    initializeModal,
    updateModalStatus,
    displayModal
  }
}
