import styled from "@emotion/styled"
import { lightGreyColor } from "styles"

export const Option = styled.div<{ padding: string; borderBottom: string }>`
  border-bottom: ${(props) => props.borderBottom};
  padding: ${(props) => props.padding};
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${lightGreyColor};
  }
`
