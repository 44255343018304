import React, { useState, Dispatch, SetStateAction, ReactNode } from "react"
import { useSessionStorage } from "hooks"

type DisclaimerContextProps = {
  dismissed: boolean
  setDismissed: Dispatch<SetStateAction<boolean>>
  height: number
  setHeight: Dispatch<SetStateAction<number>>
  expanded: boolean
  setExpanded: Dispatch<SetStateAction<boolean>>
}

export const DisclaimerContext = React.createContext<DisclaimerContextProps>({
  dismissed: false,
  setDismissed: () => {},
  height: 0,
  setHeight: () => {},
  expanded: false,
  setExpanded: () => {}
})

type DisclaimerContextProviderProps = {
  children: ReactNode
  savePreference: boolean
}

const DisclaimerContextProvider: React.FC<DisclaimerContextProviderProps> = ({
  children,
  savePreference
}: DisclaimerContextProviderProps) => {
  const [dismissed, setDismissed] = useSessionStorage(
    "disclaimer",
    false,
    !savePreference // bypass session storage if user doesn't want to save preference
  )

  const [expanded, setExpanded] = useSessionStorage("expanded", true)

  const [height, setHeight] = useState(0)

  return (
    <DisclaimerContext.Provider
      value={{
        dismissed,
        setDismissed,
        height,
        setHeight,
        expanded,
        setExpanded
      }}
    >
      {children}
    </DisclaimerContext.Provider>
  )
}

export default DisclaimerContextProvider
