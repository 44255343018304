import * as Sentry from "@sentry/react"
import { useSignupData } from "hooks"
import { getFieldValue, SliceType, updateLastVisitedStep } from "utils"
import { useScoreCheckMutation } from "redux/features/signupapi/signupApi"
import { useContext } from "react"
import { LanguageContext } from "context/LanguageContext"
import { useRouter } from "next/router"

export const useScoreCheck = () => {
  const router = useRouter()
  const { signupData, getCurrentSignupData, continueToNextStep, currentStepSignupData } = useSignupData()
  const { language } = useContext(LanguageContext)

  const [scoreCheck, { isLoading }] = useScoreCheckMutation()

  const brand = getFieldValue(signupData, "brand_id")
  const experienceQuestionAu1 = getCurrentSignupData()["experience-questions-au-1"]
  const experienceQuestionAu2 = getCurrentSignupData()["experience-questions-au-2"]
  let auExperienceQuestions

  if (experienceQuestionAu1 && experienceQuestionAu2 && brand === "au") {
    auExperienceQuestions = {
      ...getCurrentSignupData(),
      ["experience-questions"]: { ...experienceQuestionAu1, ...experienceQuestionAu2 }
    }
  }

  const scoreCheckValidation = async (slices: SliceType[], uid: string) => {
    const body = {
      ...(experienceQuestionAu1 && experienceQuestionAu2 && brand === "au"
        ? auExperienceQuestions
        : getCurrentSignupData()),
      section: uid
    }

    let result
    try {
      result = await scoreCheck({ body }).unwrap()
    } catch (error) {
      Sentry.captureException(error)
    }

    if (result?.pass) {
      let extraData = {}
      if ("question_amount" in result) {
        extraData = {
          [uid]: {
            ...currentStepSignupData,
            question_amount: result.question_amount
          }
        }
      }
      continueToNextStep(slices, extraData)
    } else if (result && result.pass === false) {
      await updateLastVisitedStep(signupData, "declined-account", language)
      router.push("/signup/declined-account")
    }
  }

  return { scoreCheckValidation, isLoading }
}
