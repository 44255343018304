import React from "react"
import Flags from "country-flag-icons/react/3x2"
import { countryFlagStyle } from "./CountryFlag.styled"

type Props = {
  countryCode: string
  fieldName: string
  margin?: string
}

const CountryFlag = ({ countryCode, fieldName, margin = "0" }: Props) => {
  if (fieldName.includes("country") || fieldName.includes("nationality")) {
    const FlagComponent = Flags[countryCode]

    if (FlagComponent) {
      return <FlagComponent style={countryFlagStyle(margin)} />
    }
  }

  return null
}

export default CountryFlag
