import React from "react"
import { ActionButton } from "components"
import { Container, launchButtonStyle } from "./AccountLaunchButton.styled"

type Props = {
  show: boolean
  onLaunchButtonClick: () => void
}

const AccountLaunchButton = ({ show, onLaunchButtonClick }: Props) => {
  return (
    <>
      {show && (
        <Container data-testid="action-button-container">
          <ActionButton displayText="Launch" sx={launchButtonStyle} onClick={onLaunchButtonClick} />
        </Container>
      )}
    </>
  )
}

export default AccountLaunchButton
