/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Content } from "@prismicio/client"
import { useSignupData } from "./signupData"
import { jurisdictionToLocaleMapping } from "utils"
import { useTranslation } from "./useTranslation"

const hideRiskWarningPages = ["forgot-password"]

type Props = {
  riskWarnings: any
  translations?: Content.TranslationDocument[]
}

const useRiskWarning = ({ riskWarnings, translations }: Props) => {
  const { jurisdiction } = useSignupData()
  const currentLocale = jurisdictionToLocaleMapping[jurisdiction || "FSA"] || null

  const [riskWarningDocument, setRiskWarningDocument] = useState(
    riskWarnings?.find((doc) => doc.lang === currentLocale)
  )

  const [riskWarningTranslationDocument, setRiskWarningTranslationDocument] = useState(
    translations?.find((doc) => doc.lang === currentLocale)
  )

  const riskWarning: any = useTranslation(
    { pageTranslation: riskWarningTranslationDocument },
    riskWarningDocument as any
  )

  useEffect(() => {
    if (jurisdiction) {
      const locale = jurisdictionToLocaleMapping[jurisdiction]
      setRiskWarningDocument(riskWarnings?.find((doc) => doc.lang === locale) || null)
      setRiskWarningTranslationDocument(translations?.find((doc) => doc.lang === locale) || null)
    }
  }, [jurisdiction, riskWarnings, translations])

  const { asPath } = useRouter()
  const [hideRiskWarning, setHideRiskWarning] = useState(false)
  useEffect(() => {
    let shouldHideRiskWarning = false
    hideRiskWarningPages.forEach((page: string) => {
      if (asPath.includes(page)) {
        shouldHideRiskWarning = true
      }
    })

    setHideRiskWarning(shouldHideRiskWarning)
  }, [asPath])

  return { riskWarning: hideRiskWarning ? null : riskWarning }
}

export default useRiskWarning
