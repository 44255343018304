import React from "react"
import { RichTextField } from "@prismicio/types"
import { RichText, TextWithImages } from "components"
import PlatformBanner from "components/pages/signup/PlatformSelection/PlatformBanner"
import { IconType } from "utils"
import { Container, Content } from "./PlatformCard.styled"

type Props = {
  image: IconType
  title: RichTextField
  description: RichTextField
  textColor: string
  background: string
  onClick: () => void
}

const PlatformCard = ({ image, title, description, textColor, background, onClick }: Props) => {
  return (
    <Container onClick={onClick} data-test-id="platform-card">
      {image && <PlatformBanner image={image} alt={`${title}`} />}
      <Content textColor={textColor} background={background}>
        <RichText field={title} />
        <TextWithImages description={description} useTradingViewLogo />
      </Content>
    </Container>
  )
}

export default PlatformCard
