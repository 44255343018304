import React, { useEffect, useRef, useState } from "react"
import Box from "@mui/material/Box"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { ReactCarousel, muiButtonGroupBoxStyle, muiArrowBoxStyle, muiArrowStyle } from "./Carousel.styled"

type Props = {
  children: JSX.Element[]
  darkButtons?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCurrentSlideIndex?: any
  initialSelectedPlatformIndex?: number
}

const Carousel = ({ children, darkButtons, setCurrentSlideIndex, initialSelectedPlatformIndex = 0 }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const carouselRef = useRef<any>()

  const [display, setDisplay] = useState(false)

  useEffect(() => {
    if (initialSelectedPlatformIndex > 0 && carouselRef && carouselRef.current) {
      carouselRef.current.goToSlide(initialSelectedPlatformIndex)
    } else {
      setDisplay(true)
    }
  }, [initialSelectedPlatformIndex])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ButtonGroup = ({ next, previous, carouselState }: any) => {
    const { currentSlide, totalItems } = carouselState
    return (
      <Box display="flex" sx={muiButtonGroupBoxStyle} justifyContent="space-between">
        <Box onClick={() => previous()} sx={muiArrowBoxStyle(darkButtons, currentSlide === 0)}>
          <ArrowBackIosNewIcon sx={muiArrowStyle(darkButtons)} />
        </Box>
        <Box onClick={() => next()} sx={muiArrowBoxStyle(darkButtons, currentSlide >= totalItems - 1)}>
          <ArrowForwardIosIcon sx={muiArrowStyle(darkButtons)} />
        </Box>
      </Box>
    )
  }

  if (initialSelectedPlatformIndex < 0) {
    return null
  }

  return (
    <ReactCarousel
      ref={carouselRef}
      display={display}
      additionalTransfrom={0}
      arrows={false}
      autoPlay={false}
      autoPlaySpeed={3000}
      centerMode={false}
      customButtonGroup={<ButtonGroup />}
      dotListClass=""
      draggable
      focusOnSelect={false}
      keyBoardControl
      minimumTouchDrag={80}
      renderButtonGroupOutside
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3500,
            min: 600
          },
          items: 1,
          partialVisibilityGutter: 40
        }
      }}
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      afterChange={(previousSlide, { currentSlide }) => {
        setCurrentSlideIndex(currentSlide)
        if (!display) {
          setDisplay(true)
        }
      }}
    >
      {children}
    </ReactCarousel>
  )
}

export default Carousel
