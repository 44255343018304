import React, { ReactNode } from "react"
import { Button, Card, CardActions } from "@mui/material"
import { CardContent, CardContentCenterAligned, muiCardStyle, muiCardActionsStyle } from "./BasicCard.styled"

type Props = {
  children?: ReactNode
  buttons?: {
    text: string
  }[]
  contentCenterAligned?: boolean
  sx?: object
}

const BasicCard = ({ children, buttons = [], contentCenterAligned, sx = {} }: Props) => {
  const displayContent = () => {
    if (contentCenterAligned) {
      return <CardContentCenterAligned>{children}</CardContentCenterAligned>
    }
    return <CardContent>{children}</CardContent>
  }

  const displayButtons = () => {
    if (buttons.length === 0) {
      return null
    }
    return (
      <CardActions sx={muiCardActionsStyle}>
        {buttons.map((button) => (
          <Button key={button.text} size="small">
            {button.text}
          </Button>
        ))}
      </CardActions>
    )
  }

  return (
    <Card sx={muiCardStyle(sx)}>
      {displayContent()}
      {displayButtons()}
    </Card>
  )
}

export default BasicCard
