import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { GeneralPostFeedbackType, AddAccountProps } from "redux/types"
import { getRtkQueryTagTypes } from "redux/utils"
import { AccountDataType, BrandsType, ObjectWithFieldsType, PaymentHistoryType } from "utils"

const LOCAL_STORAGE_ACCESS_TOKEN = "access_token"

const ACCEPTED_ORIGINS = ["https://tradenation.com", "https://v2.stage.tradenation.com", "https://tn245.com"]
export const accountApi = createApi({
  reducerPath: "accountapi",
  tagTypes: getRtkQueryTagTypes(),
  baseQuery: fetchBaseQuery({
    // baseUrl: process.env.NEXT_PUBLIC_REACT_APP_API_URL,
    baseUrl: process.env.NEXT_PUBLIC_ACCOUNTS_API_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getUser: builder.query<AccountDataType, void>({
      query: () => "/auth0/user",
      providesTags: ["User"]
    }),
    getBrands: builder.query<BrandsType, void>({
      query: () => "/brands",
      providesTags: ["Brands"]
    }),
    addAccount: builder.mutation<{ external_id: string; status: string }, { body: AddAccountProps }>({
      query: ({ body }) => ({
        url: "/tradecore",
        method: "POST",
        body
      }),
      invalidatesTags: ["User"]
    }),
    getPaymentsHistory: builder.query<
      PaymentHistoryType[],
      { accountId: number; params: { page: number; perPage: number } }
    >({
      query: ({ accountId, params }) =>
        `/accounts/${accountId}/history?page_number=${params.page}&rows_per_page=${params.perPage}`,
      providesTags: ["PaymentsHistory"]
    }),
    getPaymentGateways: builder.query<{ opening_type: string }[], { accountId: number }>({
      query: ({ accountId }) => `/accounts/${accountId}/payment_gateways`
    }),
    getPaymentUrl: builder.query<string, { accountId: number }>({
      query: ({ accountId }) => `/accounts/${accountId}/payment_url?gateway=praxis`,
      providesTags: ["Deposit"]
    }),
    withdraw: builder.mutation<
      { statusCode: number; message?: string },
      { accountId: number; body: { amount: number } }
    >({
      query: ({ accountId, body }) => ({
        url: `/accounts/${accountId}/withdraw`,
        method: "POST",
        body
      })
    }),
    sendSmsCode: builder.mutation<GeneralPostFeedbackType, { body: { telephone: string } }>({
      query: ({ body }) => {
        const origin = window.location.origin
        if (!ACCEPTED_ORIGINS.includes(origin)) {
          console.log("Not on a accepted origin (since we dont make requests to twilio in dev)")
          return
        }
        return {
          url: `${process.env.NEXT_PUBLIC_HOST_NAME}signup/api/send-2fa-code`,
          method: "POST",
          body
        }
      }
    }),
    verify2fa: builder.mutation<GeneralPostFeedbackType, { body: { code: string } }>({
      query: ({ body }) => {
        const origin = window.location.origin
        if (!ACCEPTED_ORIGINS.includes(origin)) {
          console.log("Not on a accepted origin (since we dont make requests to twilio in dev)")
          return
        }
        return {
          url: `${process.env.NEXT_PUBLIC_HOST_NAME}signup/api/verify-2fa`,
          method: "POST",
          body
        }
      }
    }),
    userMetadata: builder.mutation<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      { body: { item_name: string; item_value: string } }
    >({
      query: ({ body }) => ({
        url: "/auth0/usermetadata",
        method: "POST",
        body
      })
    }),
    swapAccount: builder.mutation<
      {
        status?: number
        success?: string
        data: { message: string }
      },
      { body: { account_id: number; is_at_client_level: boolean } }
    >({
      query: ({ body }) => ({
        url: "/finsa/swapfree",
        method: "POST",
        body
      }),
      invalidatesTags: ["User"]
    }),
    changeMT4Password: builder.mutation<
      { status: string; msg: string },
      { body: { account_id: number; external_id: string; password: string } }
    >({
      query: ({ body }) => ({
        url: "/finsa/tradingaccount/update",
        method: "POST",
        body
      })
    }),
    cloudtradeAccountData: builder.mutation<{ url: string }, { body: { account_id: number } }>({
      query: ({ body }) => ({
        url: "/cloudtrade/login",
        method: "POST",
        body
      })
    }),
    resetPassword: builder.mutation<string, { body: { client_profile_id: number } }>({
      query: ({ body }) => ({
        url: "/finsa/pwdrst",
        method: "POST",
        body
      })
    }),
    forgotPassword: builder.mutation<{ status: string; message: string }, { body: { email: string } }>({
      query: ({ body }) => ({
        url: "/finsa/pwdfgt",
        method: "POST",
        body
      })
    }),
    kycUpdate: builder.mutation<string, { body: ObjectWithFieldsType }>({
      query: ({ body }) => ({
        url: "/finsa/kyc_update",
        method: "POST",
        body
      }),
      invalidatesTags: ["User"]
    }),
    finaltoForm: builder.mutation<any, { body: any }>({
      query: ({ body }) => ({
        url: "/finsa/finalto",
        method: "POST",
        body,
        responseHandler: (response) => response.text()
      }),
      transformResponse: (response: string) => {
        try {
          return JSON.parse(response)
        } catch {
          return { message: response }
        }
      }
    })
  })
})

export const {
  useGetUserQuery,
  useGetBrandsQuery,
  useAddAccountMutation,
  useGetPaymentsHistoryQuery,
  useGetPaymentGatewaysQuery,
  useGetPaymentUrlQuery,
  useWithdrawMutation,
  useSendSmsCodeMutation,
  useVerify2faMutation,
  useUserMetadataMutation,
  useSwapAccountMutation,
  useChangeMT4PasswordMutation,
  useCloudtradeAccountDataMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useKycUpdateMutation,
  useFinaltoFormMutation
} = accountApi

export default accountApi
