import { isStageEnvironment } from "utils"

type Message = {
  action: "authenticate" | "forgot-password" | "signup" | "authorize-tradingview" | "social-auth" | "user-login"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export const sendMessageToWindow = (message: Message) => {
  window.parent.postMessage(
    message,
    process.env.NODE_ENV === "development" ? "http://localhost:3001/" : process.env.NEXT_PUBLIC_HOST_NAME
  )
}

export const handleReceiveMessageFromWindow = (event) => {
  const isDevEnv = process.env.NODE_ENV === "development"
  const isStageEnv = isStageEnvironment()
  const isProductionEnv = process.env.NODE_ENV === "production"
  const isLocalhost = event.origin === "http://localhost:3000"
  const hostname = process.env.NEXT_PUBLIC_HOST_NAME

  if (
    (isDevEnv && !isLocalhost) ||
    (isStageEnv && event.origin !== hostname) ||
    (isProductionEnv && event.origin !== hostname)
  ) {
    return
  }

  const { action, data } = event.data as Message

  if (action === "authorize-tradingview" && data.tradingViewState) {
    sessionStorage.setItem("trading_view_state", data.tradingViewState)
  }
}
